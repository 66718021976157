; (function (angular, _) {
  "use strict";

  angular.module('kudosPublishers')
    .controller(
      'PublisherDashboardController',
      [
        '$stateParams',
        '$state',
        'publisher',
        'latestTweets',
        'categoryOverview',
        'sharesByChannel',
        'recentlyExplainedPublications',
        'recentlyActiveAuthors',
        'exampleExplainedPublications',
        'favoriteReports',
        'PublisherReportService',
        'shareActivityCountQuery',
        'explainActivityCountQuery',
        'registeredAuthorActivityCountQuery',
        function (
          $stateParams,
          $state,
          publisher,
          latestTweets,
          categoryOverview,
          sharesByChannel,
          recentlyExplainedPublications,
          recentlyActiveAuthors,
          exampleExplainedPublications,
          favoriteReports,
          PublisherReportService,
          shareActivityCountQuery,
          explainActivityCountQuery,
          registeredAuthorActivityCountQuery
        ) {

          var self = this;

          self.publisher = publisher;
          self.latestTweets = latestTweets.tweets;
          self.categoryOverview = categoryOverview.rows;
          self.sharesByChannel = sharesByChannel.rows;
          self.recentlyExplainedPublications = recentlyExplainedPublications.rows;
          self.recentlyActiveAuthors = recentlyActiveAuthors.rows;
          self.exampleExplainedPublications = exampleExplainedPublications.rows;

          self.favoriteReports = favoriteReports;

          self.shareActivityCountQuery = shareActivityCountQuery;
          self.explainActivityCountQuery = explainActivityCountQuery;
          self.registeredAuthorActivityCountQuery = registeredAuthorActivityCountQuery;

          self.removeReportFromFavorites = function(reportName) {
            PublisherReportService.removeReportFromFavorites($stateParams.shortcode, reportName)
              .then(function () {
                return PublisherReportService.getFavoritedReports($stateParams.shortcode);
              })
            .then(function () {
              var index = _.findIndex(self.favoriteReports, {name: reportName});
              if (index > -1) {
                self.favoriteReports.splice(index, 1);
              }
              });
          };

          self.showMore = {
            recentlyExplainedPublications: {
              _minLimit: 3,
              _showMore: false,
              toggle: function () {
                this._showMore = !this._showMore;
              },
              getLabel: function () {
                return (this._showMore ? 'Less' : 'More') + '...';
              },
              getLimit: function () {
                return (this._showMore ? self.recentlyExplainedPublications.length : this._minLimit);
              },
              showLabel: function () {
                return (self.recentlyExplainedPublications.length > this._minLimit);
              }
            },
            recentlyActiveAuthors: {
              _minLimit: 5,
              _showMore: false,
              toggle: function () {
                this._showMore = !this._showMore;
              },
              getLabel: function () {
                return (this._showMore ? 'Less' : 'More') + '...';
              },
              getLimit: function () {
                return (this._showMore ? self.recentlyActiveAuthors.length : this._minLimit);
              },
              showLabel: function () {
                return (self.recentlyActiveAuthors.length > this._minLimit);
              }
            }
          };
        }
      ]
    );

} (window.angular, window._));
