; (function (angular, _) {
  'use strict';

  angular
    .module('kudosCharts')
      .factory(
        'queryChartService',
        [
          function () {
            return {
              massageData: massageData,
              cumulateQueryResults: cumulateQueryResults,
              massageMetadata: massageMetadata
            };

            function massageData (query, numberOfDays, cumulate) {
              var queryResults = query.results;

              if (!!cumulate) {
                queryResults = cumulateQueryResults(query.results);
              }

              var lastSixResults = _.takeRight(queryResults, 6);

              var queryResults = _.map(lastSixResults, function (result) {
                return {
                  series: 0,
                  x: (new Date(result.year + '/' + result.month + '/1')).getTime(),
                  y: result.count
                };
              });

              return [
                {
                  key: query.metadata.name,
                  values: queryResults
                }
              ];
            }

            function cumulateQueryResults (queryResults) {
              var cumulativeCount = 0;

              return _.map(queryResults, function (queryResult) {
                // For some reason, queryResult is a reference, and by
                // cumulating here, it affects everything else bound to
                // this query dataset. So we clone it so the cumulation
                // is only reflected in this directive.
                queryResult = _.clone(queryResult);

                queryResult.count += cumulativeCount;

                cumulativeCount = queryResult.count;

                return queryResult;
              });
            }

            function massageMetadata(query, title) {
              return {
                xAxisLabel: query.metadata.name,
                yAxisLabel: '',
                title: title
              };
            }

          }
        ]
      );

}(window.angular, window._));

