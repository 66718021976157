; (function (angular, _) {

  "use strict";

  angular.module('kudosAdmin')
    .controller(
      'AdminPublisherController',
      [
        'publishers',
        'AdminReportService',
        'PublisherService',
        function (publishers, AdminReportService, PublisherService) {
          var publisherStates = PublisherService.STATES;

          var FILTER_ALL = {
            label: 'All',
            states: [] // Empty means all states
          };

          var FILTER_CUSTOMERS = {
            label: 'Customers',
            states: [publisherStates.CUSTOMER]
          };

          var FILTER_PAST_CUSTOMERS = {
            label: 'Past Customers',
            states: [publisherStates.EX_CUSTOMER]
          };

          var selectedFilterButton = 'Customers';  //do customers first, as ALL takes a long time to load

          var self = this;

          self.viewStates = {
            LOADING: 1,
            READY: 2
          };

          self.viewState = self.viewStates.READY;

          self.publishers = publishers.publishers;
          self.gridOptions = AdminReportService.compileGridOptions.publisher_report(self.publishers);

          // Create filter buttons from consts
          self.filterButtons = _.map(
            [
              FILTER_CUSTOMERS, //do customers first, as ALL takes a long time to load
              FILTER_PAST_CUSTOMERS,
              FILTER_ALL
            ],
            function (filter) {
              return {
                name: filter.label,
                getButtonClass: function () {
                  return {
                    active: (selectedFilterButton === filter.label)
                  };
                },
                onClick: function () {
                  // If already selected, do nothing
                  if (selectedFilterButton === filter.label) {
                    return;
                  }

                  // If not already selected, reload grid with new publisher filter
                  selectedFilterButton = filter.label;
                  reloadPublishers(filter);
                }
              };
            }
          );

          // Reloads the publisher grid with the provided filter
          function reloadPublishers(filter) {
            self.viewState = self.viewStates.LOADING;

            PublisherService.getCustomerPublishers(filter.states.join(','))
              .then(function (publishers) {
                self.gridOptions = AdminReportService.compileGridOptions.publisher_report(publishers.publishers);
              })
              .finally(function () {
                self.viewState = self.viewStates.READY;
              });
          }

        }
      ]
    );

} (window.angular, window._));
