; (function (angular) {

  'use strict';

  angular.module('kudosModals')
    .directive('kudosConfirmationModal', [
      function () {
        return {
          scope: {},
          bindToController: {
            options: '=',
            buttonHandler: '='
          },
          templateUrl: 'kudosModals/confirmation-modal.directive.html',
          controllerAs: 'vm',
          controller: function () {
            var self = this;

            // Closes the modal when the X is clicked on the top right
            self.cancel = function () {
              self.buttonHandler({
                isDismissed: true
              });
            };
          }
        };
      }
    ]);

}(window.angular));
