; (function (angular, _) {

  "use strict";

  angular.module('kudosInstitutions').config([
    '$stateProvider',
    'institutionReports',
    function ($stateProvider, institutionReports) {

    // Now set up the institution states
      $stateProvider
        .state('institution', {
          url: '/institutions/{shortcode}',
          abstract: true,
          templateUrl: "partials/institution.html",
          controller: 'InstitutionController as institution',
          resolve: {
            institution: ['$stateParams', '$state', '$timeout', '$q', 'InstitutionService', 'SessionService', function ($stateParams, $state, $timeout, $q, InstitutionService) {

              return InstitutionService.getInstitution($stateParams.shortcode)
                .then(function (response) {
                  return response.data.data.institution;
                })
                .catch(function () {
                  $state.go('error', {},  {errorCode: 500});
                });
            }],

            latestTweets: ["$stateParams", "$state", "$timeout", "$q", "InstitutionService", function ($stateParams, $state, $timeout, $q, InstitutionService) {
              var deferred = $q.defer();

              InstitutionService.getInstitutionsLatestTweets($stateParams.shortcode)
                .then(function (response) {
                  deferred.resolve(response.data.data.latest_tweets);
                })
                .catch(function () {
                  $state.go('error', {}, {errorCode: 500});
                });

                return deferred.promise;
            }],

            favoriteReports: ["$stateParams", "$q", "$state", "InstitutionReportService", function ($stateParams, $q, $state, InstitutionReportService) {
              return InstitutionReportService
                .getFavoritedReports($stateParams.shortcode)
                  .then(function (response) {
                    return _.map(response.data.data, function(value, key) {
                      return _.find(institutionReports, {name: value.report_name})
                    });
                  })
                  .catch(function() {
                    $state.go('500');
                  });
            }],

            countries: ["CountryService", "$q", "$state", function (CountryService, $q, $state) {
              var deferred = $q.defer();

              CountryService
                .getAllCountries()
                  .then(function (response) {
                    deferred.resolve(response.data.data.countries);
                  })
                  .catch(function () {
                    $state.go('error', {}, {errorCode: 500});
                  });

              return deferred.promise;
            }],

            categoryOverview: ["$stateParams", "$state", "$q", "InstitutionReportService", function ($stateParams, $state, $q, InstitutionReportService) {
              var deferred = $q.defer(); //$q is an angular promise

              InstitutionReportService
                .getReport($stateParams.shortcode, 'category_overview')
                  .then(function (response) {
                    deferred.resolve(response.data.data.report);
                  })
                  .catch(function() {
                    $state.go('error', {}, {errorCode: 500});
                  });

              return deferred.promise;
            }],

            recentlyActiveResearchers: ["$stateParams", "$state", "$q", "InstitutionReportService", function ($stateParams, $state, $q, InstitutionReportService) {
              var deferred = $q.defer();

              InstitutionReportService
                .getReport($stateParams.shortcode, 'recently_active_researchers')
                  .then(function (response) {
                    deferred.resolve(response.data.data.report);
                  })
                  .catch(function() {
                    $state.go('error', {}, {errorCode: 500});
                  });

              return deferred.promise;
            }],

            exampleExplainedPublications: ["$state", "$stateParams", "InstitutionReportService", function ($state, $stateParams, InstitutionReportService) {
              return InstitutionReportService.getReport($stateParams.shortcode, 'explained_publications_examples')
                .then(function (response) {
                  return response.data.data.report;
                })
                .catch(function () {
                  $state.go('error', {}, {errorCode: 500});
                });
            }],

            recentlyExplainedPublications: ["$stateParams", "$state", "$q", "InstitutionReportService", function ($stateParams, $state, $q, InstitutionReportService) {
              var deferred = $q.defer();

              InstitutionReportService
                .getReport($stateParams.shortcode, 'recently_explained_publications')
                .then(function (response) {
                  deferred.resolve(response.data.data.report);
                })
                .catch(function() {
                  $state.go('error', {}, {errorCode: 500});
                });

              return deferred.promise;
            }]
          }
        })
        .state('institution.dashboard', {
          url: '',
          templateUrl: "partials/institution.dashboard.html"
        })

        .state('institution.search', {
          url: '/search',
          controller: 'InstitutionSearchController as search',
          templateUrl: "partials/institution.search.html"
        })
        .state('institution.invitations', {
          url: '/invitations',
          templateUrl: "partials/institution.invitations.html"
        })
        .state('institution.showcase', {
          url: '/showcase',
          templateUrl: "partials/institution.showcase.html"
        })
        .state('institution.resources', {
          url: '/resources',
          templateUrl: "partials/institution.resources.html"
        })
        .state('institution.branding', {
          url: '/branding',
          templateUrl: "partials/institution.branding.html"
        })
        .state('institution.account', {
          url: '/account',
          templateUrl: "partials/institution.account.html"
        })
        .state('institution.report_listing', {  // Report List Route
          url: '/reports',
          templateUrl: "partials/institution.report_listing.html"
        })
        .state('institution.report', {  // Report Parent Route
          url: '/reports',
          template: '<div ui-view><div/>',
          abstract: true
        });

      institutionReports.forEach(function (reportMetadata) {
        $stateProvider.state('institution/report/' + reportMetadata.name, {
          parent: 'institution.report',
          abstract: true,
          templateUrl: 'partials/institution.report_detail.html',
          controller: 'InstitutionReportController as vm',
          url: '/' + reportMetadata.name,
          resolve: {
            reportDetail: ["$stateParams", "$state", "InstitutionReportService", function($stateParams, $state, InstitutionReportService) {
              return InstitutionReportService.getReport($stateParams.shortcode, reportMetadata.name)
                .then(function (response) {
                  return response.data.data.report;
                })
              .catch(function () {
                $state.go('error', {}, {errorCode: 500});
              });
            }],
            favoritedReport: ["$stateParams", "$state", "InstitutionReportService", function($stateParams, $state, InstitutionReportService) {
              return InstitutionReportService.getFavoritedReport($stateParams.shortcode, reportMetadata.name)
                .then(function (response) {
                  return response.data.data;
                })
                .catch(function (response) {
                  $state.go('500');
                });
            }],
            reportMetadata: function () {
              return angular.extend({}, reportMetadata);
            }
          }
        });

        $stateProvider.state('institution/report/' + reportMetadata.name + '/table', {
          parent: 'institution/report/' + reportMetadata.name,
          controller: ["reportGridOptions", "$scope", function (reportGridOptions, $scope) {
            $scope.reportGridOptions = reportGridOptions;
          }],
          template: '<div ui-grid="reportGridOptions" ui-grid-resize-columns class="grid"></div>',
          url: '',
          resolve: {
            reportGridOptions: ["reportDetail", "InstitutionReportService", function (reportDetail, InstitutionReportService) {
              // Get raw report data and compile it to be UI-GRID friendly
              return InstitutionReportService.compileGridOptions[reportMetadata.name](reportDetail);
            }]
          }
        });

        var availableReportCharts = reportMetadata.availableCharts;

        // Only create report detail chart routes if chart definitions are defined for that report
        if (angular.isDefined(availableReportCharts)) {
          _.each(availableReportCharts, function (reportChartType) {
            var reportChartTypeKebabCase = _.kebabCase(reportChartType);

            $stateProvider.state('institution/report/' + reportMetadata.name + '/' + reportChartType, {
              parent: 'institution/report/' + reportMetadata.name,
              controller: ["reportChartOptions", "$scope", function (reportChartOptions, $scope) {
                $scope.reportChartOptions = reportChartOptions;
              }],
              template: '<kudos-' + reportChartTypeKebabCase + ' report-chart-options="reportChartOptions"></kudos-' + reportChartTypeKebabCase + '>',
              url: '/' + reportChartTypeKebabCase,
              resolve: {
                reportChartOptions: ["reportDetail", "InstitutionReportService", function (reportDetail, InstitutionReportService) {
                  return InstitutionReportService.compileChartOptions[reportMetadata.name][reportChartType](reportDetail);
                }]
              }
            });
          });
        }
      });
    }
  ]);
} (window.angular, window._));
