; (function (angular, _) {
  'use strict';
  angular
    .module('kudosPublications')
    .factory(
      'PublicationService',
      [
        '$analytics',
        '$q',
        '$location',
        '$http',
        'ModalService',
        'NotificationService',
        'SessionService',
        function(
          $analytics,
          $q,
          $location,
          $http,
          ModalService,
          NotificationService,
          SessionService
        ) {

          var apiBase = '/internal_api/publications/';
          var pub = {
            getPublication: getPublication,
            getPublicationInterventions: getPublicationInterventions,
            getPublications: getPublications,
            getPublicationsByContributor: getPublicationsByContributor,
            encodeDOI: encodeDOI,
            decodeDOI: decodeDOI
          };

          function getPublication(doi) {
            return $http.get(apiBase + encodeDOI(doi));
          }

          function getPublicationInterventions(doi) {
            return $http.get(apiBase + encodeDOI(doi) + '/interventions');
          }

          function getPublications (filters, limit) {
            var filterArray = [];

            // Creates an array of all the filters from provided filters object
            _.each(_.keys(filters), function(filterKey) {
              filterArray.push(filterKey + '=' + filters[filterKey]);
            });

            // Adds limit filter to filter array
            if (angular.isDefined(limit)) {
              filterArray.push('limit=' + limit);
            }

            // If filtersObject and limit has been provided, make this into GET param string
            var filterString = (!!filterArray.length ? ('?' + filterArray.join('&')) : '');

            return $http.get(apiBase + filterString);
          }

          function getPublicationsByContributor (email) {
            return $http.get(apiBase + 'by_contributor/' + email);
          }

          function encodeDOI (doi) {
            // We double-encode slashes because INTERNETS.
            // Slashes are interpreted as slashes even when they're encoded once, and Apache actively blocks encoded slashes.
            // see https://github.com/growkudos/growkudos/issues/5118#issuecomment-152466933
            return encodeURIComponent(doi).replace(/%2F/g, "%252F");
          }

          function decodeDOI (encodedDOI) {
            encodedDOI = encodedDOI.replace(/%252F/g, "%2F");
            return decodeURIComponent(encodedDOI);
          }

          pub.placeholderText = {
            microTitle: 'Add a short title to make your publication easier to find and understand.',
            laySummary: 'Add a simple, non-technical explanation or the lay summary of your publication to make it more accessible to a broader audience. Please note that this is not the abstract, but a plain language summary to help more people find and understand your work.',
            impactStatement: 'Add an explanation of what is unique and/or timely about your work, and the difference it might make to help increase readership.',
            authorPerspective: "Add your own personal perspective about this publication. Note that this is your opportunity to comment as an individual, whereas the 'What's it about?' and 'Why is it Important?' sections are jointly created by one or more authors."
          };

          // Accepts a map of keys with their new values
          function update(doi, changedAttributes){
            return $http.put( apiBase + encodeDOI(doi), changedAttributes);
          }

          pub.updatePublicationField = function(publication, fieldName, newValue){
            var updatePacket    = {};
            var fieldNameAlias  = pub.getFieldNameAlias(fieldName);

            updatePacket[fieldName] = newValue;
            return update(publication.id, updatePacket)
              .then(function (response) {
                $analytics.eventTrack("edit " + fieldName, {  category: 'publication edits', label: 'ux2' });
                return response.data;
              })
              .catch(function (data) {
                var errorMessage  = 'The ' + fieldNameAlias.replace('_', ' ') + ' could not be updated';
                var error         = errorMessage;

                if (data) {
                  // Always provide some sort of error
                  error = (angular.isUndefined(data.statusText) ? data : data.statusText);

                  // Uses serverside error text if provided
                  if (data.data.errors.length) {
                    error = _.head(data.data.errors);
                  }

                  errorMessage = errorMessage + ': ' + error;
                  errorMessage = errorMessage.replace(fieldName, fieldNameAlias).replace('_', ' ');
                }

                NotificationService.error(errorMessage, 'Update failed');

                return $q.reject(error);
              });
          };

          pub.refreshMetadata = function(publication) {
            return $http.post( apiBase + encodeDOI(publication.id) + '/refresh_metadata', {})
              .then(function (response) {
                NotificationService.success('Metadata refreshed');
                // Get the updated publication
                // At the moment the refresh_metadata POST request returns HTML, so just query the
                return response;
              }, function() {
                NotificationService.error('An error occurred, please try again.');
                return $q.reject();
              });
          };

          pub.claim = function(doi) {

            if(!SessionService.userIsLoggedIn()){
              NotificationService.error('You must sign in to claim articles.');
              return $q.reject('Not authenticated');
            }

            var claimConfirmationModalOptions = {
              okButton: {
                text: 'Yes - this is my publication',
                isDismissed: false,
                classes: 'btn-primary'
              },
              cancelButton: {
                text: 'Cancel',
                isDismissed: true,
                classes: 'btn-muted'
              },
              title: 'Claim Publication',
              content: 'Please confirm that you are an author of this publication. Your name as shown below will be listed as a contributor on the Kudos publication page, and we may notify your co-authors if they are registered with us.',
              subContent: SessionService.currentUser().display_name
            };

            return ModalService
              .openConfirmationModal(claimConfirmationModalOptions)
                .result
                  // If the confirm modal button is pressed
                  .then(function () {
                    return $http.post(apiBase + encodeDOI(doi) + '/claim', {});
                  })
                    // If the claim post request is successful
                    .then(function (data) {
                      $analytics.eventTrack('Claim', {  category: 'Article', label: doi });

                      NotificationService.success('You have successfully claimed this publication');

                      if (!SessionService.userIsVerified()) {
                        NotificationService.warning('You cannot explain and enrich this publication to help increase its impact yet because your registration is not yet confirmed. Please check your email for a welcome message with a confirmation link.');
                      }
                      return data.data;
                    })
                    .catch(function(response) {
                      // Create alerts if catch is thrown as a result of failed request
                      if(angular.isDefined(response.data) && response.data.errors.length){
                        response.data.errors.forEach(function(error) {
                          NotificationService.error(error);
                        });

                        return $q.reject(response.data.errors[0]);
                      }
                      return $q.reject(response);
                    });
          };

          pub.unclaim = function(doi) {

            var unClaimConfirmationModalOptions = {
              okButton: {
                text: 'Yes — unclaim publication',
                isDismissed: false,
                classes: 'btn-primary'
              },
              cancelButton: {
                text: 'Cancel',
                isDismissed: true,
                classes: 'btn-muted'
              },
              title: 'Unclaim Publication',
              content: 'By unclaiming this publication, you will remove it from your account. You will no longer be listed as an author on the Kudos publication page and will no longer have access to detailed metrics for this publication.',
              subContent: false
            };

            return ModalService
              .openConfirmationModal(unClaimConfirmationModalOptions)
                .result
                  // If the confirmation modal confirm button is pressed
                  .then(function () {
                    return $http.post(apiBase + encodeDOI(doi) + '/unclaim', {});
                  })
                    // If the unclaim post request is successful
                    .then(function (data) {
                      $analytics.eventTrack('Unclaim', {  category: 'Article', label: doi });
                      NotificationService.success('Unclaimed successfully');
                      return data.data;
                    })
                    .catch(function(response) {
                      // Create alerts if catch is thrown as a result of failed request
                      if(angular.isDefined(response.data) && response.data.errors){
                        response.data.errors.forEach(function(error) {
                          NotificationService.error(error);

                          return $q.reject(response.data.errors[0]);
                        });
                      }
                      return $q.reject(response);
                    });
          };

          pub.getFieldNameAlias = function (fieldName) {
            var fieldNameAliasLookup = {
              micro_title: 'plain language title'
            };

            return fieldNameAliasLookup[fieldName] || fieldName;
          };

          pub.flashRecommendedAction = false;

          return pub;
        }
      ]
    );

}(window.angular, window._));
