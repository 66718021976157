; (function (angular) {

  "use strict";

  angular.module('kudosAdmin')
    .controller(
      'AdminDashboardController',
      [
        'categoryOverview',
        'exampleExplainedPublications',
        function (categoryOverview, exampleExplainedPublications) {
          var self = this;

          self.categoryOverview = categoryOverview;
          self.exampleExplainedPublications = exampleExplainedPublications;
        }
      ]
    );

} (window.angular));
