; (function (angular) {
  "use strict";

  angular
    .module('kudosInstitutions')
    .controller(
      'InstitutionAffiliationController',
      [
        '$timeout',
        'InstitutionAffiliationService',
        'InstitutionSuggestionService',
        '$window',
        function ($timeout, InstitutionAffiliationService, InstitutionSuggestionService, $window) {
          var self = this;

          self.state = InstitutionSuggestionService.state;

          self.processButton = {
            state: 'init'
          };

          self.manualSelectionMode = false;
          self.manualSelection = undefined;

          self.selectInstitution = InstitutionSuggestionService.selectInstitution;
          self.getSelectedInstitution = InstitutionSuggestionService.getSelectedInstitution;

          self.getCountryFlagClass = getCountryFlagClass;
          self.getLoadingIconClass = getLoadingIconClass;
          self.getInstitutionSelectionClass = getInstitutionSelectionClass;
          self.toggleManualSelection = toggleManualSelection;
          self.inManualSelection = inManualSelection;
          self.getSuggestedInstitutions = InstitutionSuggestionService.getSuggestedInstitutions;

          self.saveInstitutionAssociation = saveInstitutionAssociation;

          self.displaySuggestedInstitutions = InstitutionSuggestionService.displaySuggestedInstitutions;
          self.save = save;
          self.returnToReferer = false;

          function inManualSelection () {
            return self.manualSelectionMode;
          }

          function toggleManualSelection () {
            self.manualSelectionMode = !self.manualSelectionMode;
            self.processButton.state = 'init';

            InstitutionSuggestionService.resetState();
          }

          function getInstitutionSelectionClass (institution) {
            return {
              active: (self.getSelectedInstitution() === institution)
            };
          }

          function getCountryFlagClass (institution) {
            return 'flag-icon-' + institution.country_code.toLowerCase();
          }

          function getLoadingIconClass () {
            if (self.state.loading) {
              return 'fa-circle-o-notch fa-spin';
            }

            return 'fa-search';
          }

          function save () {
            self.returnToReferer = true;
            saveInstitutionAssociation();
          }

          function saveInstitutionAssociation () {
            self.processButton.state = 'waiting';

            InstitutionAffiliationService
              .saveInstitutionAssociation(self.getSelectedInstitution() || self.manualSelection)
              .then(function(data) {
                if (data.tokenClaimsInvalid) {
                  var searchParams = 'goto=' + encodeURIComponent(redirectURL());
                  redirectToURL('/sessions/new?' + searchParams);
                } else {
                  handleSaveSuccess();
                }
              })
              .catch(function(error) { handleSaveError(error); });
          }

          function handleSaveSuccess () {
            self.processButton.state = 'success';

            $timeout(function(){
              redirectToURL(redirectURL());
            }, 1000);
          }

          function redirectURL () {
            if (self.returnToReferer) {
              return $window.location.pathname + $window.location.search;
            }
            return '/register/job_role';
          }

          function redirectToURL (location){
            $window.location.href = location;
          }

          function handleSaveError (error) {
            console.error('Error saving institution affiliation: ' + error);
            self.processButton.state = 'error';
          }
        }
      ]
    );
}(window.angular));
