; (function (angular) {
  'use strict';

  angular.module('kudosPublications')
    .directive(
      'publicationResourcesAdd',
        function () {
          return {
            scope: {
              featureGuardAttrs: '=?',
            },
            controllerAs: 'vm',
            templateUrl: 'kudosPublications/publicationResourcesAdd.directive.html',
            require: '^^publicationResources',
            link: function (scope, element, attrs, publicationResourcesController) {
              scope.gotoAddResourceState = publicationResourcesController.gotoAddResourceState;
            },
            controller: function($scope) {
              var self = this;

              self.userIsAdmin = $scope.featureGuardAttrs.user_is_admin;
              self.userHasPremium = $scope.featureGuardAttrs.user_has_premium;
              self.publisherName = $scope.featureGuardAttrs.publisher_name;

              self.addResourcesIsEnabled = addResourcesIsEnabledForUser();

              function addResourcesIsEnabledForUser() {
                if (self.userIsAdmin || self.userHasPremium || self.publisherName) {
                  return true;
                }

                return false;
              }
            }
          };
        });

}(window.angular));
