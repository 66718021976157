; (function (angular) {
  'use strict';

  angular.module('kudosPublications')
    .directive(
      'kudosPublicationRecommendedAction', function() {
        return {
          scope: {
            action: '=',
            mailto: '='
          },
          link: function (scope) {
            scope.getRecommendedActionTitleText = getRecommendedActionTitleText;
            scope.getRecommendedActionText = getRecommendedActionText;
            scope.getRecommendedActionButtonText = getRecommendedActionButtonText;
            scope.chooseAction = chooseAction;

            function getRecommendedActionTitleText () {
              if (angular.isArray(scope.action)) {
                return scope.action[1];
              }
            }

            function getRecommendedActionText () {
              if (angular.isArray(scope.action)) {
                return scope.action[0];
              }
            }

            function getRecommendedActionButtonText () {
              if (angular.isArray(scope.action)) {
                return scope.action[2];
              }
            }

            function getScrollElementId () {
              if (angular.isArray(scope.action)) {
                return scope.action[3];
              }

              return;
            }

            function chooseAction () {
              if(scope.action[3] === 'action-invite-coauthors') {
                sendEmail();
              } else {
                scrollToAction();
              }
            }

            function sendEmail () {
              location.href = "mailto:?" + scope.mailto;
            }

            function scrollToAction () {
              // Append to hash for template ID
              var scrollTargetId = getScrollElementId();

              if (angular.isUndefined(scrollTargetId)) {
                return;
              }

              var scrollOffset = -60;
              var scrollTarget = angular.element('#' + scrollTargetId);

              scrollTo(scrollTarget, scrollOffset);
            }

            function scrollTo (element, offset) {
              var body = angular.element('body,html');
              body.animate({
                scrollTop: element.offset().top + offset
              }, 300);
            }
          },
          templateUrl: 'kudosPublications/directives/kudosPublicationRecommendedAction/kudosPublicationRecommendedAction.directive.html',
        };
      }
    );

}(window.angular));
