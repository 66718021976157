; (function (angular) {
  'use strict';

  angular
  .module('kudos')
  .directive('toggleClass', [
    'toggleClassService',
    function (
      toggleClassService
    ) {
      return {
        scope: {
          className: '@',
          toggleGroup: '@'
        },
        link: function (scope, element) {
          element.find('a').on('click', function (event) {
            event.stopImmediatePropagation();
          });

          element.find('.toggle-class-click').on('click', toggleClass);

          function toggleClass () {
            if (hasClass()) {
              removeClass();
            } else {
              addClass();
            }
          }

          scope.toggler = {on: addClass, off: removeClass, isOn: hasClass};
          if (scope.toggleGroup) {
            addToToggleGroup(scope.toggleGroup, scope.$id, scope.toggler);
          }

          function hasClass() {
            return element.hasClass(scope.className);
          }

          function addClass() {
            element.addClass(scope.className);
          }

          function removeClass() {
            element.removeClass(scope.className);
          }

          function addToToggleGroup(groupId, uid, toggler) {
            toggleClassService.addToGroup(groupId, uid, toggler);
            scope.$on('$destroy', function () {
              toggleClassService.removeFromGroup(groupId, uid);
            });
          }
        }
      };
    }
  ]);

}(window.angular));
