; (function (angular) {
  'use strict';

  angular.module('core')
    .config(['RestangularProvider', function (RestangularProvider) {
      RestangularProvider.setBaseUrl('/internal_api');

      RestangularProvider.setResponseExtractor(function(response, operation) {
        var newResponse;
        // This is a get for a list
        if (operation !== "getList") {
          // If it's an element, then we just return the "regular" response as there's no object wrapping it
          newResponse = response.data;
        }
        return newResponse;
      });
    }]);

}(window.angular));
