; (function (angular, _, moment) {

  'use strict';

  angular
    .module('kudos')
      .factory(
        'RecommendationService',
        [
          '$http',
          'NotificationService',
          function ($http, NotificationService) {
            return {
              skipRecommendation: skipRecommendation,
              updateRecommendation: updateRecommendation
            };

            function skipRecommendation (id) {
              return updateRecommendation(id, {
                skipped_until: moment().startOf('day').add(7, 'days').format('YYYY-MM-DD')
              });
            }

            function updateRecommendation (id, attributes) {
              return $http.put('/internal_api/recommendations/' + id, attributes)
                .catch(function (response) {
                  _.each(response.data.errors, function (error) {
                    NotificationService.error(error);
                  });
                });
            }
          }
        ]
      );

}(window.angular, window._, window.moment));
