; (function (angular, _) {
  'use strict';
  angular
    .module('kudosPublishers')
    .factory(
      'PublisherService',
      [
      'Restangular',
      'NotificationService',
      function (Restangular, NotificationService) {
        var Publisher = Restangular.service('publishers');

        Publisher.STATES = {
          CUSTOMER: 'customer',
          NON_CUSTOMER: 'non_customer',
          EX_CUSTOMER: 'ex_customer'
        };

        Publisher.searchPublisherUsers = function (publisher, email, name) {
          var query = {};

          if (!!email) query.email = email;
          if (!!name) query.name =  name;

          return Publisher.one(publisher.short_code).one('/profiles').get(query);
        };

        Publisher.removePublisherAvatar = function (publisher) {
          return Publisher.one(publisher.short_code).one('/avatar').one('/remove').remove();
        };

        Publisher.getCustomerPublishers = function (states) {
          return Publisher.one('').get({state: states});
        };

        Publisher.findPublisherByPartialName = function (searchString) {
          var query = {};
          query.query = searchString;

          return Publisher.one('search').get(query)
            .then(function (response) {
              return response.search_results;
            })
            .catch(function () {
              NotificationService.error("Couldn't perform search");
              return [];
            });
        };
        Publisher.publisherSubscriptionStates = _.values(Publisher.STATES);

        return Publisher;
      }
    ]
  );

}(window.angular, window._));
