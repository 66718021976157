; (function (angular, _) {

  "use strict";

  angular.module('kudosAdmin')
    .controller(
      'AdminController',
      [
        '$state',
        'SessionService',
        function ($state, SessionService) {
          var institutionStates = [
            'admin.manage_institutions.search',
            'admin.institutions'
          ];

          var self = this;

          self.userIsAdmin = SessionService.userIsAdmin;
          self.currentUser = SessionService.currentUser;

          self.isInstitutionTabOpen = function () {
            return _.includes(institutionStates, $state.current.name);
          };
        }
      ]
    );

} (window.angular, window._));
