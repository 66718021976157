; (function (angular) {
  "use strict";

  angular.module("controllers")
    .controller(
      "ErrorController",
      [
        "error",
        function (error) {
          var self = this;

          self.errorCode = error.errorCode;

          if(error.errorMessage){
            self.errorMessage = error.errorMessage;
          }else{
            switch (self.errorCode) {
              case 401:
                self.title = "Not authenticated";
                self.errorMessage = "You are not authenticated to view this page.";
                break;
              case 403:
                self.title = "Not authorized";
                self.errorMessage = "You are not authorized to view this page.";
                break;
              case 404:
                self.title = "Not what you're looking for?";
                self.errorMessage = "We've hit a problem because the page you're trying to access, doesn't exist.";
                break;
              case 500:
                self.title = "Looks like we've hit a problem.";
                self.errorMessage = "The page you are trying to access is not available. We're working on fixing the problem but if it persists, please get in touch. In the meantime, try refreshing the page.";
                break;
              default:
                self.title = "Server error";
                self.errorMessage = "An error has occurred.";
            }
          }
        }
      ]);
})(window.angular);
