(function (angular) {
  'use strict';

  angular.module('kudosPublications').component('featuredImagePanel', {
    templateUrl:
      'kudosPublications/components/featuredImagePanel/featuredImagePanel.html',
    bindings: {
      articleId: '@',
      imageUrl: '@',
      imageCredits: '<',
    },
    controllerAs: 'vm',
    controller: [
      'NotificationService',
      'UnsplashService',
      '$q',
      '$http',
      '$rootScope',
      function (NotificationService, UnsplashService, $q, $http, $rootScope) {
        var self = this;
        self.progressButton = {};

        // Setup state.
        self.$onInit = function () {
          self.reset();

          $rootScope.$broadcast('FeaturedImagePanelOnInitEvent', {
            article_id: self.articleId,
            image_url: self.imageUrl,
          });
        };

        var NO_MORE_PAGES = 'no more pages for query';
        var GENERAL_ERROR =
          'We’ve hit a problem. Please try again or contact support if the problem persists.';

        self.hasImage = function () {
          if (self.imageUrl === '') {
            return false;
          }
          return true;
        };

        self.getImageUrl = function () {
          if (self.hasImage()) {
            return self.imageUrl;
          }
          return '/fontawesome-icons/regular/file-image.svg';
        };

        self.reset = function () {
          self.progressButton.state = 'init';
          self.hideSelectPhotoPanel();
          self.page = 1;
          self.selected = null;
          self._previousQuery = null;
          self.photos = null;
        };

        self.setSelected = function (photo) {
          self.selected = photo;
        };

        self.showSelectPhotoPanel = function () {
          self.showPhotos = true;
        };

        self.hideSelectPhotoPanel = function () {
          self.showPhotos = false;
        };

        self.isPreviousDisabled = function () {
          return self._loading || self.page <= 1;
        };

        var nextDisabled = true;
        self.isNextDisabled = function () {
          return self._loading || nextDisabled;
        };

        self.previousPage = function (query) {
          if (!self.isPreviousDisabled()) {
            self.page--;
            self._search(query);
          }
        };

        self.nextPage = function (query) {
          self.page++;
          self._search(query);
        };

        var queryTotalHits = {};
        self.getPhotos = function (query) {
          nextDisabled = false;
          if (
            angular.isDefined(queryTotalHits[query]) &&
            self.page > queryTotalHits[query]
          ) {
            return $q.reject(NO_MORE_PAGES);
          }

          var options = {
            orientation: UnsplashService.OPTIONS.ORIENTATION.LANDSCAPE_ONLY,
          };
          return UnsplashService.getPhotos(query, options, self.page).then(
            function (urlsAndPages) {
              if (self.page >= urlsAndPages.totalPages) {
                nextDisabled = true;
              }

              queryTotalHits = {};
              queryTotalHits[query] = urlsAndPages.totalPages;
              return urlsAndPages.urls;
            }
          );
        };

        self.isSearchDisabled = function (query) {
          return self._loading || !query || query === self._previousQuery;
        };

        self.search = function (query) {
          if (self.isSearchDisabled(query)) {
            return;
          }
          self._previousQuery = query;
          self.page = 1;

          self._search(query);
        };

        self._search = function (query) {
          self._loading = true;

          // TODO: should only show after receiving photos from getPhotos()? but would need a loading indicator
          self.showSelectPhotoPanel();

          return self
            .getPhotos(query)
            .then(function (photos) {
              self._loading = false;

              self.photos = photos;
            })
            .catch(function (rejection) {
              self._loading = false;

              NotificationService.error(GENERAL_ERROR);

              if (rejection === NO_MORE_PAGES) {
                self.photos = []; // empty array shows message
                return;
              }
              return $q.reject(rejection);
            });
        };

        self.deletePhoto = function () {
          return $http
            .delete(
              '/internal_api/publication_featured_image/' + self.articleId
            )
            .then(function () {
              self.imageUrl = '';

              $rootScope.$broadcast('FeaturedImageDeleteEvent', {
                article_id: self.articleId,
                image_url: self.imageUrl,
              });
            })
            .catch(function (responseOrError) {
              // TODO: log the responseOrError for monitoring
              NotificationService.error(GENERAL_ERROR);
              if (responseOrError instanceof Error) {
                throw responseOrError;
              }
            });
        };

        self.savePhoto = function () {
          self._loading = true;

          var photo = self.selected;
          self.progressButton.state = 'waiting';

          var body = {
            image_url: photo.url,
            preview_image_url: photo.preview,
            credit_link: photo.creditUserLink,
            credit_user: photo.creditUserName,
            download_location: photo.downloadLocation,
          };

          return $http
            .post(
              '/internal_api/publication_featured_image/save/' + self.articleId,
              body
            )
            .then(function (response) {
              self._loading = false;

              var savedPhoto = response.data.data;
              self.imageUrl = savedPhoto.saved_preview_image_url;
              self.reset();

              $rootScope.$broadcast('FeaturedImageUpdateEvent', {
                article_id: self.articleId,
                image_url: photo.url,
              });
            })
            .catch(function (responseOrError) {
              self._loading = false;

              // TODO: log the responseOrError for monitoring
              self.progressButton.state = 'error';
              NotificationService.error(GENERAL_ERROR);
              if (responseOrError instanceof Error) {
                throw responseOrError;
              }
            });
        };
      },
    ],
  });
})(window.angular, window._);
