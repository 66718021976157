; (function (angular) {
  "use strict";

  angular.module('kudosPublishers')
    .controller(
      'PublisherController',
      [
        '$stateParams',
        '$state',
        'publisher',
        'publisherReports',
        'NotificationService',
        'PublisherService',
        'SessionService',
        function (
          $stateParams,
          $state,
          publisher,
          publisherReports,
          NotificationService,
          PublisherService,
          SessionService
        ) {
          var DEFAULT_PUBLISHER_AVATAR = '/images/avatar_publisher_default.png';
          var DEFAULT_PUBLISHER_AVATAR_THUMB = '/images/avatar_publisher_default_thumb.png';
          var DEFAULT_PUBLISHER_AVATAR_THUMB_2X = '/images/avatar_publisher_default_thumb@2x.png';

          var self = this;

          self.publisher = publisher;
          self.publisherReports = publisherReports;

          self.shortCode = $stateParams.shortcode;

          // This function mitigates the limitations of ui-sref-active with handling complex state hierarchies.
          self.isReportTabOpen = function () {
            return ($state.includes('publisher.reports') || $state.includes('publisher.reports.list'));
          };

          self.isAdmin = function () {
            return SessionService.userIsAdmin();
          }

          self.getPublisherAvatarTrimmed = function () {
            if (angular.isDefined(self.publisher.avatar) && angular.isDefined(self.publisher.avatar.avatar.trimmed)) {
              return self.publisher.avatar.avatar.trimmed.url;
            } else {
              return DEFAULT_PUBLISHER_AVATAR;
            }
          };

          self.getPublisherAvatarThumb = function () {
            if (angular.isDefined(self.publisher.avatar) && angular.isDefined(self.publisher.avatar.avatar.thumb)) {
              return self.publisher.avatar.avatar.thumb.url;
            } else {
              return DEFAULT_PUBLISHER_AVATAR_THUMB;
            }
          };

          self.getPublisherAvatarThumb2x = function () {
            if (angular.isDefined(self.publisher.avatar) && angular.isDefined(self.publisher.avatar.avatar.thumb_2x)) {
              return self.publisher.avatar.avatar.thumb_2x.url;
            } else {
              return null;
            }
          };

          self.getPublisherAvatarThumbSet = function () {
            if (angular.isDefined(self.publisher.avatar) && angular.isDefined(self.publisher.avatar.avatar.thumb)) {
              if (angular.isDefined(self.publisher.avatar.avatar.thumb_2x)) {
                return self.publisher.avatar.avatar.thumb.url + " 1x, " + self.publisher.avatar.avatar.thumb_2x.url + " 2x";
              }
              else {
                return self.publisher.avatar.avatar.thumb.url + " 1x"; //only a low-res thumbnail is available
              }
            } else {
              //no thumbnail is available
              return DEFAULT_PUBLISHER_AVATAR_THUMB + " 1x, " + DEFAULT_PUBLISHER_AVATAR_THUMB_2X + " 2x";
            }
          };


          self.avatarUploadComplete = function (fileItem, response) {
            NotificationService.success('Successfully uploaded \'' + fileItem.file.name + '\' as logo');
            setPublisher(response.data.publisher);
          };

          self.avatarUploadError = function (fileItem, response) {
            NotificationService.error('Logo upload \'' + fileItem.file.name + '\' failed, please try again: ' + response.errors.join('; '));
          };

          self.removeAvatar = function () {
            PublisherService.removePublisherAvatar(self.publisher)
              .then(
                function (data) {
                  NotificationService.success('Successfully removed logo!');
                  setPublisher(data.publisher);
                },
                function () {
                  NotificationService.error('Could not remove logo!');
                }
              );
          };

          function setPublisher(publisher) {
            self.publisher = publisher;
          }
        }
      ]
    );

} (window.angular));
