(function (angular) {
  'use strict';

  angular.module('kudosProfiles').component('kudosChangeInstitutionButton', {
    templateUrl: 'kudosProfiles/components/kudosChangeInstitutionButton.component.html',
    controllerAs: 'vm',
    bindings: {
      profileId: '@',
      displayName: '@',
    },
    controller: ['ProfileService', 'NotificationService', '$window', function (ProfileService, NotificationService, $window) {
      var self = this;

      self.changeInstitution = function() {
        var shortCode = prompt("Please enter Kudos institution short code:");
        if (shortCode === null) {
          return;
        }

        ProfileService.changeInstitution(self.profileId, shortCode).then(
          function (response) {
            NotificationService.success(self.displayName + " institution updated to '" + response.data.institution_name + "'");
            $window.location.reload();
          },
          function () {
            NotificationService.error(self.displayName + ' institution could not be updated');
          }
        );
      }
    }]
  });

} (window.angular));