(function(angular){
  'use strict';
  angular
    .module('kudos')
      .directive('truncateExpand', [
        function () {
          return {
            scope: {
              content: '@',
              limit: '@'
            },
            link: function (scope) {
              scope.showExpand = (scope.content.length > scope.limit);
              scope.isExpanded = false;
            },
            templateUrl: 'kudos/directives/truncateExpand.html',
          };
        }
    ]);
})(window.angular);
