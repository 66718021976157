; (function (angular) {

  'use strict';
  angular.module('kudos')
    .directive('kudosSidebar', ["$document", function ($document) {
      return {

        templateUrl: 'kudos/directives/kudosSidebar.html',
        controllerAs: "vm",
        controller: ["$scope", "SessionService", function($scope, SessionService){
          var self = this;

          self.toggleShown = toggleShown;
          self.isLoggedIn = SessionService.userIsLoggedIn;
          self.isAdmin = SessionService.userIsAdmin;
          self.currentUser = SessionService.currentUser;
          self.signOut = SessionService.signOut;

          self.showLoginForm = SessionService.showLoginForm;
          self.userIsLoggedIn = SessionService.userIsLoggedIn;

          function toggleShown() {
            self.isShown = !self.isShown;
            $scope.$emit('sidebar.shownChange', self.isShown);
          }

          $scope.$on('sidebar.toggleShown', toggleShown);
          $scope.$on('sidebar.hide', function () {
            self.isShown = false;
            $scope.$emit('sidebar.shownChange', self.isShown);
          });
          $scope.$on('sidebar.show', function () {
            self.isShown = true;
            $scope.$emit('sidebar.shownChange', self.isShown);
          });
        }],
        link: function (scope, element, attrs) {
          $document.click(function(event) {
            if(!event.target.closest('.sidebar') && !event.target.closest('.navbar')){
              if(element.find('.sidebar').is(":visible")) {
                scope.$emit('sidebar.hide');
                scope.$digest();
              }
            }
          });
        }
      };
    }]);

}(window.angular));
