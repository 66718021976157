(function (angular) {
  'use strict';

  angular.module('angulartics.kudos.analytics')
    .config(['$analyticsProvider', function ($analyticsProvider) {
      $analyticsProvider.settings.ga.anonymizeIp = true;
      // Log kudos referrals.
      // @deprecated Use the `/events/` API in preference.
      $analyticsProvider.registerEventTrack(function (action, properties) {
        // only log article referrals to the Kudos API - ignore other events
        if (action === "Referral" && properties.category === "Article" && !!properties.label){

          // Grab the service manually (rather than using DI) because you can't inject services
          // in the config phase. We know this event will only happen once we're in the run phase,
          // so this is safe.
          var $http = angular.element(document.documentElement).injector().get('$http');
          var decodedDoi = encodeURIComponent(properties.label).replace(/%2F/g, "%252F");

          $http.post("/internal_api/publications/" + decodedDoi + "/kudos_referral", {});
        }
      });

      // Validation of the event-tracking logging (currently in the publication page `resolve`)
      $analyticsProvider.registerPageTrack(function (path) {
        // Check if we are on the publications page
        if(path.substr(0, 14) === '/publications/') {
          // If on /publications/ route, we can assume its an angular page and $http can be injected
          var $http = angular.element(document.documentElement).injector().get('$http');

          // Pull the encoded DOI out of the path string
          var eventToLog = {
            category: 'publication',
            action: 'pageview_validation',
            label: path
          };

          $http.post("/internal_api/events", eventToLog);
        }
      });
    }]);
})(window.angular);
