; (function (angular) {

  'use strict';
  angular.module('kudosCards')
    .directive('kudosActionExplainCard', [
      function () {
        return {
          templateUrl: 'kudosCards/kudosActionExplainCard.directive.html',
          controllerAs: 'vm',
          scope: {},
          bindToController: {
            organisation: '=',
            publication: '=',
            recommendation: '='
          },
          controller: [
            'OrganisationExplainNudgeService',
            'CardDeckService',
            function (OrganisationExplainNudgeService, CardDeckService) {
              var self = this;

              self.cardState = {
                completed: false
              };

              self.orgMessage = '';

              self.createExplainNudge = createExplainNudge;
              self.nextCard = CardDeckService.nextCard;

              function createExplainNudge () {
                OrganisationExplainNudgeService.createNudge(self.organisation.short_code, self.publication.kudos_internal_id, self.recommendation, self.orgMessage)
                  .then(function (response) {
                    self.cardState.completed = angular.isDefined(response);
                  });
              }
            }
          ]
        };
      }
    ]);

}(window.angular));

