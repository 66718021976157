; (function (angular, _) {
  'use strict';
  angular
    .module('kudosAdmin')
    .factory(
      'AdminReportService',
      [
        function () {

          var cellContentTemplate = function (innerTemplate) {
            return '<div class="ui-grid-cell-contents">' + innerTemplate + '</div>';
          };

          var adminReportService = {};

          adminReportService.compileGridOptions = {};

          adminReportService.compileGridOptions.publications_by_contributor_list = function (publicationList) {
            return {
              enableSorting: true,
              minRowsToShow: 20,
              columnDefs: [
                {
                  displayName: 'Title',
                  field: 'title',
                  headerCellClass: 'wrap-words',
                  cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encoded_doi}}" target="_blank" ng-bind-html="row.entity.title"></a>')
                },
                {
                  displayName: 'DOI',
                  field: 'doi',
                  headerCellClass: 'wrap-words'
                },
                {
                  displayName: 'Loaded by publisher',
                  field: 'loaded_by_publisher'
                },
                {
                  displayName: 'Owner by publisher',
                  field: 'owned_by_publisher'
                }
              ],
              data: _.map(publicationList, function (publication) {
                return {
                  title: publication.title,
                  encoded_doi: publication.encoded_doi,
                  doi: publication.id,
                  loaded_by_publisher: null,  // TODO in Ruby API
                  owned_by_publisher: publication.publisher.name
                };
              })
            };

          };

          adminReportService.compileGridOptions.publisher_report = function (publishers) {
            return {
              enableSorting: true,
              minRowsToShow: 20,
              columnDefs: [
                {
                  displayName: 'Preferred Publisher Name',
                  field : 'name',
                  maxWidth: 240,
                  cellTemplate: cellContentTemplate('<a target="_self" ng-href="/publishers/{{ row.entity.shortCode }}" title="{{ row.entity.name }} ({{ row.entity.shortCode }})">{{ row.entity.name }}</a>'),
                },
                {
                  displayName: 'Contact',
                  field: 'admins',
                },
                {
                  displayName: 'FTP details',
                  field: 'ftpDetails',
                  enableColumnMenu: false,
                },
                {
                  displayName: 'DOI/cover loading',
                  field: 'loadingEnabled',
                  headerCellClass: 'wrap-words',
                  enableColumnMenu: false,
                  maxWidth: 90
                },
                {
                  displayName: 'Usage loading',
                  field: 'usageLoadingEnabled',
                  headerCellClass: 'wrap-words',
                  enableColumnMenu: false,
                  maxWidth: 70
                },
                {
                  displayName: 'Status',
                  field: 'currentStateLabel',
                },
                {
                  displayName: 'Edit',
                  field: 'shortCode',
                  cellTemplate: cellContentTemplate('<a target="_self" ng-href="/publishers/edit/{{ row.entity.shortCode }}">Edit</a>'),
                  enableColumnMenu: false,
                  maxWidth: 50
                }
              ],
              data: _.map(publishers, function (publisher) {
                return {
                  name: publisher.name,
                  shortCode: publisher.short_code,
                  admins: publisher.admins.join(', '),
                  ftpDetails: (publisher.ftp_details ? publisher.ftp_details.username + ' / ' + publisher.ftp_details.password : ""),
                  loadingEnabled: (publisher.loading_enabled ? "Yes" : "No"),
                  usageLoadingEnabled: (publisher.usage_loading_enabled ? "Yes" : "No"),
                  currentStateLabel: (publisher.current_state_label),
                  autoLoaded: (publisher.auto_loaded ? "Yes" : "No")
                };
              })
            };
          };

          adminReportService.compileGridOptions.institution_report = function (institutions) {
            return {
              enableSorting: true,
              minRowsToShow: 20,
              columnDefs: [
                {
                  displayName: 'Preferred Institution Name',
                  field: 'displayName',
                  cellTemplate: cellContentTemplate('<a target="_self" ng-href="/institutions/{{ row.entity.shortCode }}">{{row.entity.displayName}}</a>'),
                  minWidth: 350,
                  sort: {
                    direction: 'asc',
                    priority: 0
                  }
                },
                {
                  displayName: 'Country',
                  field: 'country',
                  minWidth: 100
                },
                {
                  displayName: 'Kudos Code',
                  field: 'shortCode',
                  maxWidth: 95
                },
                {
                  displayName: 'Status',
                  field: 'currentStateLabel',
                  cellTemplate: cellContentTemplate('{{ COL_FIELD }}')
                },
                {
                  displayName: 'Expiry Date',
                  field: 'expiryDate',
                  maxWidth: 100,
                  cellTemplate: cellContentTemplate('{{COL_FIELD | date:"MMM yyyy"}}')
                },
                {
                  displayName: 'Edit',
                  field: 'ringgoldId',
                  maxWidth: 70,
                  cellTemplate: cellContentTemplate('<a target="_self" ng-href="/admin/manage_institutions/edit/{{ COL_FIELD }}">Edit</a>')
                }
              ],
              data: institutions
            };

          };

          adminReportService.compileGridOptions.organisations_report = function (organisations) {
            return {
              enableSorting: true,
              minRowsToShow: 20,
              columnDefs: [
                {
                  displayName: 'Organisation Name',
                  field: 'name',
                  minWidth: 350,
                  sort: {
                    direction: 'asc',
                    priority: 0
                  }
                },
                {
                  displayName: 'Short Code',
                  field: 'shortCode',
                  cellTemplate: cellContentTemplate('<a target="_self" ng-href="/orgs/{{ row.entity.shortCode }}">{{row.entity.shortCode}}</a>'),
                },
                {
                  displayName: 'FTP details',
                  field: 'ftpDetails',
                  enableColumnMenu: false,
                },
                {
                  displayName: 'Status',
                  field: 'currentStateLabel',
                },
                {
                  displayName: 'Type',
                  field: 'type',
                },
                {
                  displayName: 'Edit',
                  field: 'shortCode',
                  cellTemplate: cellContentTemplate('<a target="_self" ng-href="/admin/organisations/{{ row.entity.shortCode }}">Edit</a>'),
                  enableColumnMenu: false,
                  maxWidth: 50
                }
              ],
              data: _.map(organisations, function (organisation) {
                return {
                  name: organisation.name,
                  shortCode: organisation.short_code,
                  ftpDetails: (organisation.ftp_details ? organisation.ftp_details.username + ' / ' + organisation.ftp_details.password : ""),
                  currentStateLabel: organisation.current_state_label,
                  type: organisation.type
                };
              })
            };

          };

          return adminReportService;
        }
      ]
    );

}(window.angular, window._));
