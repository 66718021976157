; (function (angular) {

  'use strict';

  angular.module("kudosCharts")
    .directive("kudosMonthlyCountLineChart", [
      'queryChartService',
      function (queryChartService) {
        return {
          scope: {
            query: '=',
            title: '@'
          },

          template:'<kudos-mini-bar-chart ng-if="queryData.length" data="queryData" metadata="queryMetadata"></kudos-mini-bar-chart>',

          link: function (scope) {

            init();

            function init () {
              scope.queryData = queryChartService.massageData(scope.query, 6);
              scope.queryMetadata = queryChartService.massageMetadata(scope.query, scope.title);
            }
          }
        };
      }
    ]);

}(window.angular));
