; (function (angular) {

  "use strict";

  angular.module('controllers')
    .controller(
      'InstitutionShowcaseController',
      [
        'institution',
        'PageTrackerService',
        function (institution, PageTrackerService) {
          var self = this;

          self.institution = institution;

          self.backgroundImageContainerClass = {
            'background-present': !!self.institution.banner
          };

          self.backgroundImageContainerStyle = {};

          if (!!self.institution.banner) {
            self.backgroundImageContainerStyle['background-image'] = 'url(' + self.institution.banner + ')';
          }
          PageTrackerService.trackPageView('/showcase/' + self.institution.code);
        }
      ]
    );

} (window.angular));

