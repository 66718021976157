; (function (angular) {
  'use strict';

  angular.module('kudosAbout')
    .controller(
      'AboutInstitutionsController',
      [
        'extraInfoBoxService',
        function (extraInfoBoxService) {
          var self = this;

          self.showExtraInfoBoxes = extraInfoBoxService.showExtraInfoBoxes = {
            maximize: false,
            mobilize: false,
            support: false,
            showcase: false,
            broaden: false,
            streamline: false,
          };

          self.toggleExtraInfoBox = extraInfoBoxService.toggleExtraInfoBox;
          self.getExtraInfoBoxIndicatorClass = extraInfoBoxService.getExtraInfoBoxIndicatorClass;

        }
      ]);
})(window.angular);
