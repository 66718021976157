; (function (angular, _) {

  'use strict';

  angular.module("kudosCharts")
    .directive("kudosLineChart", function () {
      return {
        scope: {
          reportChartOptions: '='
        },

        template:'<nvd3 options="options" data="data"></nvd3>',

        link: function (scope) {

          scope.options = {
            chart: {
              type: 'lineChart',
              height: 450,
              margin : {
                top: 20,
                right: 20,
                bottom: 60,
                left: 65
              },
              //x-scaling function
              x: function(data) {
                return data.x;
              },
              //y-scaling function
              y: function(data) {
                return data.y;
              },

              xAxis: angular.extend(
                {},
                { //default x-axis options
                  axisLabel: 'Date',
                  tickFormat: function(data) {
                    return d3.time.format('%d %b %y')(new Date(data))
                  },
                  showMaxMin: false,
                  staggerLabels: true
                },
                (scope.reportChartOptions.metadata.xAxis) || {}
              ),

              yAxis: angular.extend(
                {},
                { //default y-axis options
                  axisLabel: 'Y Axis',
                  tickFormat: function(data){
                    return d3.format(',')(data);
                  },
                  axisLabelDistance: 20
                },
                (scope.reportChartOptions.metadata.yAxis) || {}
              )
            }
          };

          scope.data = _.map(scope.reportChartOptions.datasets, function (dataSet) {
            return {
              key: dataSet.y.label,
              values: dataSet.data
            }
          });

        }
      };
    }
  );
}(window.angular, window._));

