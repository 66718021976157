; (function (angular, _) {

  "use strict";

  angular.module('controllers')
    .controller(
      'InstitutionController',
      [
        'institution',
        'institutionReports',
        'countries',
        'categoryOverview',
        'recentlyActiveResearchers',
        'latestTweets',
        'recentlyExplainedPublications',
        'exampleExplainedPublications',
        'favoriteReports',
        'ValidationService',
        'InstitutionService',
        'InstitutionReportService',
        'SessionService',
        'NotificationService',
        '$state',
        '$sce',
        '$rootScope',
        '$sanitize',
        function (
          institution,
          institutionReports,
          countries,
          categoryOverview,
          recentlyActiveResearchers,
          latestTweets,
          recentlyExplainedPublications,
          exampleExplainedPublications,
          favoriteReports,
          ValidationService,
          InstitutionService,
          InstitutionReportService,
          SessionService,
          NotificationService,
          $state,
          $sce,
          $rootScope,
          $sanitize
        ) {
          var self = this;

          self.currentUser = SessionService.currentUser();

          self.countries = countries;
          self.dashboardKPIs = institution.dashboard_kpis;
          self.latestTweets = latestTweets;
          self.categoryOverview = categoryOverview;

          self.exampleExplainedPublications = exampleExplainedPublications;
          self.recentlyActiveResearchers = recentlyActiveResearchers;
          self.favoriteReports = favoriteReports;

          self.goUrlViewsPath = $state.href('institution/report/go_url_views',{}, {absolute: true});
          self.removeReportFromFavorites = function(reportName) {
            InstitutionReportService.removeReportFromFavorites($state.params.shortcode, reportName)
              .then(function () {
                return InstitutionReportService.getFavoritedReports($state.params.shortcode);
              })
            .then(function() {
              var index = _.findIndex(self.favoriteReports, {name: reportName});
              if (index > -1) {
                self.favoriteReports.splice(index, 1);
              }
            });
          };

          self.setInstitution = function (institution) {
            self.institution = institution;
          };

          self.pageState = {
            recentlyActiveResearcherWidget: {
              showAll: false
            },
            editing: {
              newAdminUserEmail: true
            }
          };

          self.showAddInstAdminForm = false;
          self.newAdminUserEmail = '';

          var DEFAULT_INSTITUTION_LOGO = '/images/institution.png';

          var RECENTLY_EXPLAINED_DEFAULT_COUNT = 3;
          var RECENTLY_EXPLAINED_EXPANDED_COUNT = 20;

          self.recentlyExplainedPublications = recentlyExplainedPublications.slice(0, RECENTLY_EXPLAINED_DEFAULT_COUNT);
          self.showMoreLabel = "More...";
          self.showRecentlyExplainedPublications = function() {
            if(recentlyExplainedPublications.length > 3) {
              return true;
            } else {
              return false;
            }
          };


          self.removeAdminFromCurrentInstitution = function(admin_to_remove) {
            InstitutionService.removeInstitutionAdmin(admin_to_remove, self.institution).then(function() {
              InstitutionService.getInstitution(self.institution.shortCode)
                .then(function (response) {
                  self.setInstitution(response.data.data.institution);
                })
                .catch(function () {
                  $state.go('error', {},  {errorCode: 500});
                });
            });
          };

          self.addAdminToCurrentInstitution = function(new_admin_email) {
            InstitutionService.addInstitutionAdmin(new_admin_email, self.institution).then(function() {
              // the route returns the new admin but we just need o refresh the institution
              // since it contains a brief list of its admins and should be updated anyway
              InstitutionService.getInstitution(self.institution.shortCode)
                .then(function (response) {
                  self.setInstitution(response.data.data.institution);
                })
                .catch(function () {
                  $state.go('error', {},  {errorCode: 500});
                });
              self.newAdminUserEmail = '';
              self.pageState.editing.newAdminUserEmail = true;  // FIXME this gets overridden by the kudosEditableTextDirective after this function runs :(
            });
          };

          self.toggleShowMore = function(){
            self.recentlyExplainedExpanded = !self.recentlyExplainedExpanded;
            var limit = self.recentlyExplainedExpanded ? RECENTLY_EXPLAINED_EXPANDED_COUNT : RECENTLY_EXPLAINED_DEFAULT_COUNT;
            self.recentlyExplainedPublications = recentlyExplainedPublications.slice(0, limit);
            if(self.showMoreLabel === "More...") {
              self.showMoreLabel = "Less...";
            } else {
              self.showMoreLabel = "More...";
            }
          };

          // This function mitigates the limitations of ui-sref-active with handling complex state hierarchies.
          self.isReportTabOpen = function () {
            return ($state.includes('institution.report') || $state.includes('institution.report_listing'));
          };

          self.toggleShowAllRecentlyActive = function () {
            self.pageState.recentlyActiveResearcherWidget.showAll = !self.pageState.recentlyActiveResearcherWidget.showAll;
          };

          self.getShowAllRecentlyActive = function () {
            return self.pageState.recentlyActiveResearcherWidget.showAll;
          };

          self.cancelEditing = function () {
            angular.extend(self.pageState.editing, {
              displayName: false,
              displayCountry: false,
              website: false,
              blurb: false
            });
          };

          self.updateDisplayName = function (newDisplayName) {
            self.cancelEditing();
            if(angular.isUndefined(newDisplayName) || self.institution.displayName === newDisplayName) {
              return;
            }

            InstitutionService.updateInstitutionField(self.institution, 'displayName', newDisplayName)
              .then(function (data) {
                self.setInstitution(data.data.institution);
              });
          };

          self.updateDisplayCountry = function (newDisplayCountry) {
            self.cancelEditing();
            if(angular.isUndefined(newDisplayCountry)) {
              return;
            }

            InstitutionService.updateInstitutionField(self.institution, 'displayCountry', newDisplayCountry)
              .then(function (data) {
                self.setInstitution(data.data.institution);
              });
          };

          self.updateBlurb = function (newBlurb) {
            self.cancelEditing();
            if(angular.isUndefined(newBlurb) || self.institution.blurb === newBlurb) {
              return;
            }

            InstitutionService.updateInstitutionField(self.institution, 'blurb', newBlurb)
              .then(function (data) {
                self.setInstitution(data.data.institution);
              });
          };

          self.updateWebsite = function (newWebsite) {
            self.cancelEditing();
            if(angular.isUndefined(newWebsite) || self.institution.website === newWebsite) {
              return;
            }

            // Validate URL
            var validationError = self.validate.url(newWebsite);
            if(!!validationError) {
              return validationError;
            }

            InstitutionService.updateInstitutionField(self.institution, 'website', newWebsite)
              .then(function (data) {
                self.setInstitution(data.data.institution);
              });
          };

          self.getInstitutionLogo = function () {
            return (self.institution.logo || DEFAULT_INSTITUTION_LOGO);
          };

          self.logoUploadComplete = function (fileItem, response) {
            NotificationService.success('Successfully uploaded \'' + fileItem.file.name + '\' as logo');
            self.setInstitution(response.data.institution);
          };

          self.logoUploadError = function (fileItem, response) {
            NotificationService.error('Logo upload \'' + fileItem.file.name + '\' failed, please try again: ' + response.errors.join('; '));
          };

          self.removeLogo = function () {
            InstitutionService.removeInstitutionLogo(self.institution.code)
              .then(
                function (data) {
                  NotificationService.success('Successfully removed logo!');
                  self.setInstitution(data.data.data.institution);
                },
                function () {
                  NotificationService.error('Could not remove logo!');
                }
              );
          };

          self.bannerUploadComplete = function (fileItem, response) {
            NotificationService.success('Successfully uploaded \'' + fileItem.file.name + '\' as banner!');
            self.setInstitution(response.data.institution);
          };

          self.bannerUploadError = function (fileItem) {
            NotificationService.error('Banner upload \'' + fileItem.file.name + '\' failed, please try again.');
          };

          self.removeBanner = function () {
            InstitutionService.removeInstitutionBanner(self.institution.code)
              .then(
                function (data) {
                  NotificationService.success('Successfully removed banner!');
                  self.setInstitution(data.data.data.institution);
                },
                function () {
                  NotificationService.error('Could not remove banner!');
                }
              );
          };

          self.validate = {
            url: function (url) {
              return ValidationService.isUrl(url, 'URL must start with "http://" or "https://"', true);
            }
          };

          self.setInstitution(institution);
          self.cancelEditing();

          self.saveShowcasePublicationOrder = function (sortedArray) {
            InstitutionService.reOrderInstitutionShowcasePublications(self.institution, sortedArray)
              .then(function () {
                NotificationService.success('The institution showcase publications have been successfully re-ordered.');
              });
          };

          self.saveShowcaseProfileOrder = function (sortedArray) {
            InstitutionService.reOrderInstitutionShowcaseProfiles(self.institution, sortedArray)
              .then(function () {
                NotificationService.success('The institution showcase profiles have been successfully re-ordered.');
              });
          };

          self.showcasePublicationListSortTemplate = function (publication) {
            var publicationTitle = (!!publication.micro_title ? publication.micro_title : publication.title);
            return $sce.trustAsHtml('<a href="{{ publication.article_url }}">' + $sanitize(publicationTitle) + '</a>');
          };

          self.showcaseProfileListSortTemplate = function (profile) {
            return $sce.trustAsHtml('<a target="_self" ng-href="/profile/' + profile.custom_username + '">' + $sanitize(profile.display_name) + '</a>');
          };

          self.showcaseUrl = (window.location.protocol + '//' + window.location.host + '/showcase/' + self.institution.code);
          self.inviteUrl = (window.location.protocol + '//' + window.location.host + '/go/' + self.institution.code);
        }
      ]
    );

} (window.angular, window._));
