; (function (angular) {
  'use strict';

  angular.module('kudosPublications')
    .directive('kudosPublicationBox', function () {
      return {
        scope: {
          publication: '=',
          halfWidth: '=?'
        },
        templateUrl: 'kudosPublications/kudosPublicationBox.directive.html',
        replace: true
      };
    });

}(window.angular));
