; (function (angular, _) {
  'use strict';
  angular
    .module('kudosProfiles')
    .factory(
      'ProfileService',
      [
        '$http',
        '$analytics',
        '$q',
        'NotificationService',
        'Upload',
        function ($http, $analytics, $q, NotificationService, Upload) {

          // Accepts a map of keys with their new values
          function update(accountId, changedAttributes){
            return $http.put( "/internal_api/accounts/" + accountId, changedAttributes);
          }

          return {
            uploadAvatarImage: uploadAvatarImage,

            getProfile: function (id) {
              return $http.get('/internal_api/accounts/' + id);
            },

            updateAccount: function (accountId, changedAttributes) {
              var changedAttributesKeys = _.keys(changedAttributes);
              return update(accountId, changedAttributes)
                .then(function(response) {
                    var profile = response.data.data.profile;
                    // Force image refresh if avatar has changed
                    if(changedAttributes.avatar || changedAttributes.avatar_crop){
                      _.each(profile.avatar, function(url, key) {
                        profile.avatar[key] = url + '?decache='+Math.random();
                      });
                    }
                    return response;
                  })
                .then(
                  function (response) {
                    _.each(changedAttributesKeys, function (changedAttribute) {
                      $analytics.eventTrack("edit " + changedAttribute, {  category: 'account edits', label: 'ux2' });
                    });
                    var tokenClaimsInvalid = response.headers('X-Token-Claims-Invalid');
                    return {
                      data: response.data.data,
                      tokenClaimsInvalid: tokenClaimsInvalid && tokenClaimsInvalid.toLowerCase() === 'true' ? true : false
                    };

                  })
                .catch(function (error) {
                    NotificationService.error('The ' + changedAttributesKeys.join(', ') + ' could not be updated, please try again.');
                    return $q.reject(error);
                  }
                );
            },

            userIsProfile: function (user, profile) {
              return user.id === profile.id;
            },

            promoteAccount: function (accountId) {
              var deferred = $q.defer();

              $http.post('/accounts/promote?id=' + accountId, {})
                .then(
                  function (response) {
                    return deferred.resolve(response.data);
                  },
                  function (error) {
                    return deferred.reject(error);
                  }
                );

              return deferred.promise;

            },

            demoteAccount: function (accountId) {
              var deferred = $q.defer();

              $http.post('/accounts/demote?id=' + accountId, {})
                .then(
                  function (response) {
                    return deferred.resolve(response.data);
                  },
                  function (error) {
                    return deferred.reject(error);
                  }
                );

              return deferred.promise;

            },

            removeClaimedPublications: function (accountId) {
              var deferred = $q.defer();

              $http.post('/accounts/remove_claimed_publications?id=' + accountId, {})
                .then(
                  function (response) {
                    return deferred.resolve(response.data);
                  },
                  function (error) {
                    return deferred.reject(error);
                  }
                );

              return deferred.promise;
            },

            deleteAccount: function (accountId) {
              var deferred = $q.defer();

              $http.post('/accounts/delete?id=' + accountId, {})
                .then(
                  function (response) {
                    return deferred.resolve(response.data);
                  },
                  function (error) {
                    return deferred.reject(error);
                  }
                );

              return deferred.promise;
            },

            changeInstitution: function (accountId, shortCode) {
              return $http.post('/accounts/change_institution?id=' + accountId + '&short-code=' + shortCode, {});
            },

            searchProfiles: function (email) {
              email = encodeURIComponent(email);
              return $http.get('/internal_api/accounts/search?email=' + email)
                .then(function (response) {
                  return response.data.data.accounts;
                });
            },

            makeUserPremium: function (usersApiUrl) {
              var deferred = $q.defer();
              $http.post(usersApiUrl, {}, {withCredentials: true})
                .then(
                  function (response) {
                    return deferred.resolve(response.data);
                  },
                  function (error) {
                    if (error.status === 409) {
                      return deferred.reject('User is not eligible for a new membership');
                    } else {
                      return deferred.reject('Unexpected error making new membership');
                    }
                  }
                );

              return deferred.promise;
            },
          };

          function uploadAvatarImage (accountId, file) {
            return Upload.upload({
                url: '/internal_api/accounts/'+accountId+'/avatar',
                method: 'POST',
                fields: { 'profile[id]': accountId },
                sendFieldsAs: 'json',
                file: file,
                fileFormDataName: 'profile[avatar]'
            });
          }
        }
      ]
    );

}(window.angular, window._));
