; (function (angular) {
  'use strict';
  angular
    .module('kudosAdmin')
    .factory(
      'AdminService',
      [
      'Restangular',
      function (Restangular) {
        return Restangular.service('admin');
      }]);

}(window.angular));
