; (function (angular) {
  'use strict';

  angular
    .module('kudosModals')
      .factory(
        'ModalService',
        [
          '$uibModal',
          '$rootScope',
          function ($uibModal, $rootScope) {

            var ModalService = {
              openConfirmationModal: function (options) {

                options = angular.extend({}, options, {});

                var modalInstance;
                var modalScope = $rootScope.$new();

                function createModalOptions(options) {
                  var modalOptions = {};
                  var modalDefaultOkButton = {
                    text: 'Ok',
                    isDismissed: false,
                    classes: 'btn-primary'
                  };

                  var modalDefaultCancelButton = {
                    text: 'Cancel',
                    isDismissed: true,
                    classes: 'btn-muted'
                  };

                  modalOptions.okButton = (options.okButton || modalDefaultOkButton);
                  modalOptions.cancelButton = (options.cancelButton || modalDefaultCancelButton);
                  modalOptions.title = (options.title || 'Please Confirm');
                  modalOptions.content = (options.content || false);

                  if (angular.isUndefined(options.subContent)) {
                    modalOptions.subContent = 'Are you sure you want to do this?';
                  } else {
                    modalOptions.subContent = options.subContent;
                  }

                  return modalOptions;
                }

                modalScope.options = createModalOptions(options);

                modalScope.pressButton = function (button) {
                  if (button.isDismissed) {
                    modalInstance.dismiss('cancel');
                  } else {
                    modalInstance.close('ok');
                  }
                };

                modalInstance = $uibModal.open({
                  animation: true,
                  template: '<kudos-confirmation-modal options="options" button-handler="pressButton"></kudos-confirmation-modal>',
                  scope: modalScope
                });

                return modalInstance;
              }
            };

            return ModalService;
          }
        ]
      );

}(window.angular));
