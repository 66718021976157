; (function (angular) {

  'use strict';
  angular.module('kudosCards')
    .directive('kudosActionShareCard', [
      'CardDeckService',
      function (CardDeckService) {
        return {
          templateUrl: 'kudosCards/kudosActionShareCard.directive.html',
          controllerAs: 'vm',
          scope: {},
          bindToController: {
            publication: '=',
            recommendation: '='
          },
          controller: [
            function () {
              var self = this;

              self.cardStep = 0;
              self.hideSkip = false;

              self.proceedToSharing = proceedToSharing;
              self.onShareSuccess = onShareSuccess;
              self.nextCard = CardDeckService.nextCard;
              self.showNextButton = showNextButton;

              function showNextButton () {
                self.hideSkip = true;
              }

              function nextStep () {
                if (self.cardStep < 2) {
                  self.cardStep++;
                }

                self.hideSkip = (self.cardStep === 2);
              }

              function onShareSuccess () {
                nextStep();
              }

              function proceedToSharing () {
                nextStep();
              }
            }
          ]
        };
      }
    ]);

}(window.angular));
