; (function (angular, _) {
  'use strict';

  angular.module('kudosPublications')
    .directive('publicationResources', function () {
      return {
        scope: {},
        bindToController: {
          doi: '@',
          resources: '=?',
          featureGuardAttrs: '=?'
        },
        templateUrl: 'kudosPublications/publicationResources.directive.html',
        controllerAs: 'vm',
        controller: [
          '$rootScope',
          '$timeout',
          'ModalService',
          'NotificationService',
          'ResourceService',
          function ($rootScope, $timeout, ModalService, NotificationService, ResourceService) {
            var self = this;

            self.state = {};
            self.state.listing = true;
            self.state.model = {};
            self.state.progressButtonState = 'init';

            self.gotoListResourcesState = gotoListResourcesState;
            self.gotoAddResourceState = gotoAddResourceState;
            self.gotoEditResourceState = gotoEditResourceState;
            self.deleteResource = deleteResource;
            self.saveResource = saveResource;
            self.isResourceFormInvalid = isResourceFormInvalid;
            self.scrollToResourcePanel = scrollToResourcePanel;

            function isResourceFormInvalid () {
              if (angular.isUndefined(self.state.formInstance)) {
                return true;
              }

              return self.state.formInstance.$invalid;
            }

            function deleteResource (resource) {
              confirmResourceDeletion(
                resource,
                function () {
                  ResourceService
                    .delete(resource.id)
                    .then(handleDeleteSuccess(resource))
                    .then(broadcastResourceUpdateEvent)
                    .catch(handleDeleteCatch);
                }
              );
            }

            function gotoListResourcesState () {
              self.state.listing = true;
              self.state.model = {};
            }

            function gotoAddResourceState () {
              gotoEditResourceState({});
            }

            function gotoEditResourceState (resource) {
              self.state.listing = false;
              self.state.model = resource;

              self.scrollToResourcePanel();
            }

            function scrollToResourcePanel() {
              var element = angular.element('#action-resource');

              var pixelOffset = -60;
              var body = angular.element('body,html');

              body.animate({
                scrollTop: element.offset().top + pixelOffset
              }, 300);
            }

            function saveResource () {
              self.state.progressButtonState = 'waiting';

              var resourceModel = self.state.model;

              if (angular.isDefined(resourceModel.id)) {
                return updateResource(resourceModel);
              }

              return createResource(resourceModel);
            }

            function createResource (newResource) {
              newResource.publication_doi = self.doi;

              ResourceService
                .create(newResource)
                .then(handleSaveSuccess)
                .then(broadcastResourceUpdateEvent)
                .catch(handleSaveCatch);
            }

            function updateResource (resource) {
              ResourceService
                .update(resource)
                .then(handleSaveSuccess)
                .then(broadcastResourceUpdateEvent)
                .catch(handleSaveCatch);
            }

            function handleSaveSuccess (resource) {
              self.state.progressButtonState = 'success';

              // Add or update resource in self.resources
              addToOrUpdateLocalResources(resource);

              $timeout(
                function () {
                  self.state.progressButtonState = 'init';
                  gotoListResourcesState();
                },
                2000
              );
            }

            function addToOrUpdateLocalResources (resource) {
              // If saved resource is new (no id), add it to the local list
              if (angular.isUndefined(self.state.model.id)) {
                self.resources.push(resource);
              } else {
                var updatedResourceIndex = _.findIndex(self.resources, { id: resource.id });

                self.resources[updatedResourceIndex] = resource;
              }
            }

            function handleSaveCatch () {
              self.state.progressButtonState = 'error';

              NotificationService.error('There was an error saving the resource.');

              $timeout(
                function () {
                  self.state.progressButtonState = 'init';
                },
                3000
              );
            }

            function handleDeleteSuccess (deletedResource) {
              return function () {
                self.resources = _.remove(self.resources, function (resource) {
                  return resource !== deletedResource;
                });
              };
            }

            function handleDeleteCatch () {
              NotificationService.error('There was an error removing the resource.');
            }

            function broadcastResourceUpdateEvent (resources) {
              $rootScope.$broadcast('publicationResourcesUpdated', resources);
            }

            function confirmResourceDeletion (resource, onConfirmCallback) {

              var deleteConfirmationModalOptions = {
                okButton: {
                  text: 'Delete',
                  isDismissed: false,
                  classes: 'btn-danger'
                },
                cancelButton: {
                  text: 'Cancel',
                  isDismissed: true
                },
                title: 'Delete resource.',
                content: 'Are you sure you want to remove the publication resource titled: ' + resource.title + '?',
                subContent: ''
              };

              ModalService
                .openConfirmationModal(deleteConfirmationModalOptions)
                .result
                .then(onConfirmCallback);
            }
          }
        ]
      };
    });
}(window.angular, window._));
