; (function (angular) {
  "use strict";

  angular
    .module('kudosPublishers')
    .directive('loaderRecordLogs', ['PublisherReportService', '$state',
      function (PublisherReportService, $state) {
        return {
          restrict: 'E',
          scope: {},
          bindToController: {
            publisher: '=',
            loaderRecord: '='
          },

          templateUrl: 'kudosPublishers/directives/loaderRecordLogs.directive.html',
          controllerAs: 'ctrl',

          controller: function () {
            var self = this;
            self.logs = [];
            self.getMessageTypeIcon = getMessageTypeIcon;
            self.getLoaderRecordLogsCsvUrl = getLoaderRecordLogsCsvUrl;
            self.$onInit = init;

            function init() {
              getLoaderRecordLogs(self.publisher, self.loaderRecord);
            }

            function getMessageTypeIcon(messageType) {
              switch (messageType) {
                case "Debug":
                  return '<i class="fa fa-stethoscope fa-lg text-muted" aria-hidden="true" title="Debug message"></i>';
                case "Info":
                  return '<i class="fa fa-info-circle fa-lg text-info" aria-hidden="true" title="Informational message"></i>';
                case "Warn":
                  return '<i class="fa fa-exclamation-triangle fa-lg text-warning" aria-hidden="true" title="Warning message"></i>';
                case "Error":
                  return '<i class="fa fa-exclamation-circle fa-lg text-danger" aria-hidden="true" title="Error message"></i>';
                case "Fatal":
                  return '<i class="fa fa-frown-o fa-lg text-danger" aria-hidden="true" title="Fatal error message"></i>';
                default:
                  return messageType;
              }
            }

            function getLoaderRecordLogs(publisher, loaderRecord) {
              if (angular.isDefined(loaderRecord) && angular.isDefined(publisher)) {
                return PublisherReportService.getLoaderRecordLogsReport(publisher.short_code, loaderRecord)
                  .then(function (data) {
                    self.logs = data.rows;
                  })
                  .catch(function () {
                    $state.go('error', {}, {errorCode: 500});
                  });
              }
            }

            function getLoaderRecordLogsCsvUrl() {
              if (angular.isDefined(self.loaderRecord) && angular.isDefined(self.publisher)) {
                return PublisherReportService.getLoaderRecordLogsReportCsvUrl(self.publisher.short_code, self.loaderRecord);
              }
              return undefined;
            }

          }
        }
      }
    ]);
})(window.angular);
