; (function (angular, _) {

  'use strict';
  angular.module('kudosCards')
    .directive('kudosCardDeck', [
      '$rootScope',
      '$timeout',
      function ($rootScope, $timeout) {
        return {
          replace: true,
          link: function (scope, element) {
            var cards = [];
            var currentCardIndex = 0;

            init();

            // Moves to next card when event is received
            $rootScope.$on('cardDeck.next', nextCard);

            function init () {
              // Obtains a list of all the cards in the deck
              cards = _.map(element.find('.kudos-card'), function (card, index) {
                return angular.element(card);
              });

              // If there aren't any cards to start with, go straight to the final state
              if (!cards.length) {
                showCompletionMessage();
              }
            }

            /**
             * Display the `completed` state for a short time
             * then transition to `state-final`.
             */
            function showCompletionMessage () {
              element.addClass('completed');
            }

            function nextCard () {
              _.each(cards, function (card, index) {
                // Ensures all cards up to the current card are marked as completed
                if (index <= currentCardIndex) {
                  card.addClass('completed');
                }

                // Marks the next card as current
                if (index === (currentCardIndex + 1)) {
                  card.addClass('current');
                }
              });

              // Increments the current card index
              if (currentCardIndex <= (cards.length - 1)) {
                currentCardIndex++;
              }

              // If all the cards have been completed, adds completed class to card deck container
              if (currentCardIndex === cards.length) {
                showCompletionMessage();
              }
            }
          }
        };
      }
    ]);

}(window.angular, window._));
