(function (angular) {
  'use strict';

  angular.module('kudosShares').directive('kudosGenerateShareableLinkBox', [
    function () {
      return {
        scope: {},
        bindToController: {
          doi: '@',
          onShareUrlCreated: '=?',
          showCopyLinkButton: '=?',
          analyticsCategory: '@',
          analyticsLabel: '@',
          strictLabel: '@',
        },
        templateUrl: 'kudosShares/kudosGenerateShareableLinkBox.directive.html',
        controllerAs: 'vm',
        controller: [
          '$element',
          '$document',
          'ShareService',
          'NotificationService',
          function ($element, $document, ShareService, NotificationService) {
            var self = this;

            self.label = '';
            self.customLabel = '';
            self.shareResult = null;

            self.defaultShareConfig = Object.freeze({
              buildShareIntentUri: function (shareUrl) {
                var subject = encodeURIComponent(
                  'See my latest publication on Kudos'
                );
                var body = encodeURIComponent(
                  'See my latest publication here:\r' + shareUrl
                );

                return 'mailto:?subject=' + subject + '&body=' + body;
              },
              shareIntentText: 'Send by email\u2026',
              showCopyLinkButton: true,
            });

            self.shareConfigs = Object.freeze({
              Facebook: Object.freeze({
                buildShareIntentUri: function (shareUrl) {
                  return (
                    'https://www.facebook.com/dialog/share?app_id=382090738578650&display=page&href=' +
                    encodeURIComponent(shareUrl)
                  );
                },
                shareIntentText: 'Post on Facebook\u2026',
                showCopyLinkButton: false,
              }),
              LinkedIn: Object.freeze({
                buildShareIntentUri: function (shareUrl) {
                  return (
                    'https://www.linkedin.com/shareArticle?url=' +
                    encodeURIComponent(shareUrl)
                  );
                },
                shareIntentText: 'Post on LinkedIn\u2026',
                showCopyLinkButton: false,
              }),
              Twitter: Object.freeze({
                buildShareIntentUri: function (shareUrl) {
                  return (
                    'https://x.com/intent/post?url=' +
                    encodeURIComponent(shareUrl)
                  );
                },
                shareIntentText: 'Post on X\u2026',
                showCopyLinkButton: false,
              }),
              Weibo: Object.freeze({
                buildShareIntentUri: function (shareUrl) {
                  return (
                    'https://service.weibo.com/share/share.php?url=' +
                    encodeURIComponent(shareUrl)
                  );
                },
                shareIntentText: 'Post on Weibo\u2026',
                showCopyLinkButton: false,
              }),
              WeChat: Object.freeze({
                buildShareIntentUri: function () {
                  return 'https://web.wechat.com';
                },
                shareIntentText: 'Open WeChat',
                showCopyLinkButton: true,
              }),
              Mastodon: Object.freeze({
                buildShareIntentUri: function (shareUrl) {
                  var msg = 'Read my Story on Kudos: ' + shareUrl;
                  return '/tootpick/#text=' + encodeURIComponent(msg);
                },
                shareIntentText: 'Post on Mastodon\u2026',
                showCopyLinkButton: false,
              }),
              Bluesky: Object.freeze({
                buildShareIntentUri: function (shareUrl) {
                  return (
                    'https://bsky.app/intent/compose?text=' +
                    encodeURIComponent(shareUrl)
                  );
                },
                shareIntentText: 'Post on Bluesky\u2026',
                showCopyLinkButton: false,
              }),
              WhatsApp: Object.freeze({
                buildShareIntentUri: function (shareUrl) {
                  return 'https://wa.me/?text=' + encodeURIComponent(shareUrl);
                },
                shareIntentText: 'Send with WhatsApp\u2026',
                showCopyLinkButton: false,
              }),
            });

            self.$onInit = function () {
              self.isLoading = false;
              self.shareUrl = '';
            };

            self.getEffectiveLabel = function () {
              var label = self.label;

              if (self.strictLabel) {
                label = self.strictLabel;
              } else if (self.showCustomLabel()) {
                label = self.customLabel;
              }

              return label;
            };

            self.generateShareableLink = function () {
              var labelToSend = self.getEffectiveLabel();

              self.isLoading = true;
              ShareService.generateShareableLink(self.doi, labelToSend)
                .then(function (intervention) {
                  self.shareResult = self.buildShareResult(
                    intervention.share_url
                  );
                })
                .finally(function () {
                  self.isLoading = false;

                  if (angular.isFunction(self.onShareUrlCreated)) {
                    self.onShareUrlCreated();
                  }
                });
            };

            self.shareUrlLoaded = function () {
              return !!self.shareUrl && !self.isLoading;
            };

            self.isSelectVisible = function () {
              return !self.strictLabel;
            };

            self.buildShareResult = function (shareUrl) {
              var config =
                self.shareConfigs[self.getEffectiveLabel()] ||
                self.defaultShareConfig;

              return {
                shareUrl: shareUrl,
                shareIntentUri: config.buildShareIntentUri(shareUrl),
                shareIntentText: config.shareIntentText,
                showCopyLinkButton: config.showCopyLinkButton,
              };
            };

            self.confirmCopy = function () {
              NotificationService.success('Link copied to clipboard!');
            };

            self.showCustomLabel = function () {
              return self.label === 'custom';
            };

            self.copyToClipboard = function () {
              $element.find('input[type="text"]').select();
              $document[0].execCommand('copy');
            };
          },
        ],
      };
    },
  ]);
})(window.angular);
