; (function (angular) {

  'use strict';

  angular.module('kudosResources')
    .factory("ResourceService", [
      "$location",
      "$http",
      function ($location, $http) {

        function getResourceTypesFromResponse (response, callback) {
          if (callback !== undefined) {
            callback(response.data.resource_types);
          }

          return response.data.resource_types;
        }

        function getResourceFromResponse (response, callback) {
          if (callback !== undefined) {
            callback(response.data.resource);
          }

          return response.data.resource;
        }

        var apiBaseUrl = "/internal_api/resources";  // the PUT is handled in the web app, not API - due to CORS problems

        var pub = {
          create: function (newResource, callback) {
            return $http.post(apiBaseUrl, {resource: newResource})
              .then(function (response) {
                return getResourceFromResponse(response, callback);
              });
          },

          get: function (resourceId, callback) {
            return $http.get(apiBaseUrl + '/' + resourceId)
              .then(function (response) {
                return getResourceFromResponse(response, callback);
              });
          },

          update: function (resource, callback) {
            return $http.put(apiBaseUrl + '/' +  resource.id, {resource: resource})
              .then(function (response) {
                return getResourceFromResponse(response, callback);
              });
          },

          delete: function (resourceId, callback) {
            return $http.delete(apiBaseUrl + '/' + resourceId)
              .then(function (response) {
                return getResourceFromResponse(response, callback);
              });
          },

          // FIXME this should cache the resource types so we don't do an API call each time.
          getResourceTypes: function () {
            return $http.get(apiBaseUrl + '/types', {cache: true}).then(getResourceTypesFromResponse);
          },

          setApiBaseUrl: function (newApiBaseUrl){
            apiBaseUrl = newApiBaseUrl;
          },

          getApiBaseUrl: function (){
            return apiBaseUrl;
          }
        };

        return pub;
    }
  ]);

}(window.angular));
