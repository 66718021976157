(function (angular, _) {
  'use strict';

  angular.module('kudosPublishers').component('kudosChannelGroup', {
    templateUrl: 'kudosPublishers/components/kudosChannelGroup.component.html',
    bindings: {
      data: '<',
      maxCount: '=',
      showLabel: '=',
    },
    controller: function () {
      var self = this;

      self.$onInit = function () {
        // TODO: Consider moving max/min width to bindings and make it 'input argument'
        var maxWidth = 70;
        var minWidth = 25;
        self.channels = self._statsToChannels(
          self.data,
          maxWidth,
          minWidth,
          self.maxCount
        );
      };

      self._statsToChannels = function (stats, maxWidth, minWidth, maxCount) {
        var result = [];

        Object.keys(stats).forEach(function (key) {
          var size = null,
            count = null,
            backgroundClass = null;

          if (stats[key] === '?') {
            size = 50;
            count = '?';
            backgroundClass = getAttribute(
              'channelInactive',
              'backgroundClass'
            );
          } else if (stats[key] === 0) {
            size = minWidth;
            count = '-';
            backgroundClass = getAttribute(key, 'backgroundClass');
          } else {
            var maxValue = maxCount || Math.max.apply(null, _.values(stats));
            size = Math.sqrt(stats[key] / maxValue) * maxWidth;
            if (size < minWidth) {
              size = minWidth;
            }
            count = stats[key];
            backgroundClass = getAttribute(key, 'backgroundClass');
          }

          var margin = (maxWidth - size) / 2;
          size = size.toFixed(2) + 'px';
          margin = margin.toFixed(2) + 'px';
          result.push({
            label: getAttribute(key, 'label'),
            class: getAttribute(key, 'iconClass') + ' ' + backgroundClass,
            count: count,
            style: {
              height: size,
              width: size,
              lineHeight: size,
              margin: margin,
            },
          });
        });

        return result;
      };

      function getAttribute(key, attribute) {
        var translations = {
          link: {
            label: 'Link',
            iconClass: 'share-icon fa fa-link',
            backgroundClass: 'link-background-color',
          },
          twitter: {
            label: 'X',
            iconClass: 'share-icon fa-brands fa-x-twitter',
            backgroundClass: 'twitter-background-color',
          },
          linkedin: {
            label: 'LinkedIn',
            iconClass: 'share-icon fa fa-linkedin',
            backgroundClass: 'linkedin-background-color',
          },
          facebook: {
            label: 'Facebook',
            iconClass: 'share-icon fa fa-facebook',
            backgroundClass: 'facebook-background-color',
          },
          wechat: {
            label: 'WeChat',
            iconClass: 'share-icon fa fa-wechat',
            backgroundClass: 'wechat-background-color',
          },
          weibo: {
            label: 'Weibo',
            iconClass: 'share-icon fa fa-weibo',
            backgroundClass: 'weibo-background-color',
          },
          mastodon: {
            label: 'Mastodon',
            iconClass: 'share-icon fa-brands fa-mastodon',
            backgroundClass: 'mastodon-background-color',
          },
          bluesky: {
            label: 'Bluesky',
            iconClass: 'share-icon fa-brands fa-bluesky',
            backgroundClass: 'bluesky-background-color',
          },
          whatsapp: {
            label: 'WhatsApp',
            iconClass: 'share-icon fa-brands fa-whatsapp',
            backgroundClass: 'whatsapp-background-color',
          },

          research_gate: {
            label: 'ResearchGate',
            iconClass: 'share-icon ai ai-researchgate',
            backgroundClass: 'researchgate-background-color',
          },
          academia_edu: {
            label: 'Academia',
            iconClass: 'share-icon ai ai-academia ',
            backgroundClass: 'academia-background-color',
          },
          mendeley: {
            label: 'Mendeley',
            iconClass: 'share-icon ai ai-mendeley',
            backgroundClass: 'mendeley-background-color',
          },
          channelInactive: {
            label: 'Other',
            iconClass: 'share-icon fa fa-file-pdf-o',
            backgroundClass: 'channel-inactive-background-color',
          },
          other: {
            label: 'Other',
            iconClass: 'share-icon fa fa-file-pdf-o',
            backgroundClass: 'other-background-color',
          },
        };

        return translations[key] && translations[key][attribute];
      }
    },
  });
})(window.angular, window._);
