(function (angular, _) {
  'use strict';

  angular.module('kudosPublishers').component('kudosSharesByChannelPanel', {
    templateUrl:
      'kudosPublishers/components/kudosSharesByChannelPanel.component.html',
    controllerAs: 'vm',
    bindings: {
      data: '<',
    },
    controller: function () {
      var self = this;

      self.$onInit = function () {
        self.clicksData = {
          link: getShares(self.data, 'link', 'non_pdf'),
          twitter: getShares(self.data, 'twitter', 'non_pdf'),
          linkedin: getShares(self.data, 'linkedin', 'non_pdf'),
          facebook: getShares(self.data, 'facebook', 'non_pdf'),
          weibo: getShares(self.data, 'weibo', 'non_pdf'),
          wechat: getShares(self.data, 'wechat', 'non_pdf'),
          mastodon: getShares(self.data, 'mastodon', 'non_pdf'),
          bluesky: getShares(self.data, 'bluesky', 'non_pdf'),
          whatsapp: getShares(self.data, 'whatsapp', 'non_pdf'),
        };
        self.avgClicksData = {
          link: getAvgClicks(self.data, 'link', 'non_pdf'),
          twitter: getAvgClicks(self.data, 'twitter', 'non_pdf'),
          linkedin: getAvgClicks(self.data, 'linkedin', 'non_pdf'),
          facebook: getAvgClicks(self.data, 'facebook', 'non_pdf'),
          weibo: getAvgClicks(self.data, 'weibo', 'non_pdf'),
          wechat: getAvgClicks(self.data, 'wechat', 'non_pdf'),
          mastodon: getAvgClicks(self.data, 'mastodon', 'non_pdf'),
          bluesky: getAvgClicks(self.data, 'bluesky', 'non_pdf'),
          whatsapp: getAvgClicks(self.data, 'whatsapp', 'non_pdf'),
        };
        if (hasSPfd()) {
          self.pdfClicksData = {
            research_gate: getShares(self.data, 'research_gate', 'pdf'),
            academia_edu: getShares(self.data, 'academia_edu', 'pdf'),
            mendeley: getShares(self.data, 'mendeley', 'pdf'),
            other: getShares(self.data, 'other', 'pdf'),
          };
          self.pdfAvgClicksData = {
            research_gate: getAvgClicks(self.data, 'research_gate', 'pdf'),
            academia_edu: getAvgClicks(self.data, 'academia_edu', 'pdf'),
            mendeley: getAvgClicks(self.data, 'mendeley', 'pdf'),
            other: getAvgClicks(self.data, 'other', 'pdf'),
          };

          self.clickMaxCount = Math.max.apply(
            null,
            _.values(angular.merge({}, self.clicksData, self.pdfClicksData))
          );
          self.avgMaxCount = Math.max.apply(
            null,
            _.values(
              angular.merge({}, self.avgClicksData, self.pdfAvgClicksData)
            )
          );
        } else {
          self.pdfClicksData = {
            research_gate: '?',
            academia_edu: '?',
            mendeley: '?',
            other: '?',
          };
          self.pdfAvgClicksData = {
            research_gate: '?',
            academia_edu: '?',
            mendeley: '?',
            other: '?',
          };
        }
      };

      function hasSPfd() {
        return self.data.hasOwnProperty('pdf');
      }

      self.showSPdfUpSell = function () {
        return !hasSPfd();
      };

      function getShares(data, key, type) {
        return (
          (data && data[type] && data[type][key] && data[type][key].shares) || 0
        );
      }

      function getAvgClicks(data, key, type) {
        var shares = getShares(data, key, type);
        var clicks =
          (data && data[type] && data[type][key] && data[type][key].clicks) ||
          0;
        var avgClicks = parseFloat((clicks / shares).toFixed(2));
        return isFinite(avgClicks) ? avgClicks : 0;
      }
    },
  });
})(window.angular, window._);
