; (function (angular, _) {
    'use strict';

    var module = angular.module('kudosRegistration');

    module.controller('KudosRegistrationFormController', [
        '$scope',
        '$element',
        function (
            $scope,
            $element
        ) {
            $scope.submitted = false;
            $scope.submitting = false;

            $scope.register = function () {
                $scope.submitted = true;

                if($element.length !== 0 && $element[0].checkValidity()) {
                    $scope.submitting = true;

                    $element[0].submit();
                }
            };
        }
    ]);

}(window.angular, window._));
