; (function (angular) {
  'use strict';

  angular
  .module('kudos')
  .directive('noHtml5ValidationBubble', [
    function() {
      return {
        restrict: 'C',
        link: function (scope, element, attributes) {
          element.on('invalid', function (event) {
            // Stops native browser inline valiadtion bubble
            event.preventDefault();
          });
        }
      };
     }
   ]);

}(window.angular));
