; (function (angular) {
  'use strict';

  angular
  .module('avatarEditor')
  .directive('equalHeightThumbnails', function() {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        // Cache thumbnail selector
        var _thumbnails = element.find(".thumbnail");

        // On window resize, resize thumbnail heights
        angular.element(window).on('resize', function () {
          // Reset all thumbnail heights
          // so that max height can be ascertained
          removeThumbnailsHeight();
          // Calculate maximum height and resize thumbnail
          resizeThumbnails();
        });

        // Calculate maximum height and resize thumbnail on page load
        resizeThumbnails();

        // Function for resizing thumbnails to maximum height
        function resizeThumbnails () {
          _thumbnails.height(
            getMaximumThumbnailHeight(
              getAllThumbnailHeights()
            )
          );
        }

        // Function for reseting all thumbnail heights to auto
        function removeThumbnailsHeight () {
          _thumbnails.height('auto');
        }

        // Function obtains the hightest height of array provided
        function getMaximumThumbnailHeight (thumbnailHeights) {
          return Math.max.apply(null, thumbnailHeights);
        }

        // Returns array of heights for all found thumbnails
        function getAllThumbnailHeights () {
          return _thumbnails.map(function () {
            return $(this).height();
          });
        }
      }
    };
 });
}(window.angular));
