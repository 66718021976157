; (function (angular, _) {
  'use strict';

  angular.module('kudosPublications')
    .component(
      'showcaseYourWorkPanel', {
        bindings: {
          publication: '<',
          featuredImageUrl: '@'
        },
        templateUrl: "kudosPublications/components/showcaseYourWorkPanel/showcaseYourWorkPanel.html",
        controllerAs: 'vm',
        require: ['PublicationService'],
        controller: ["PublicationService", '$timeout', '$rootScope',
          function (PublicationService, $timeout, $rootScope) {
            var self = this;
            self.isComplete = isComplete;
            self.isWhatsItAboutEmpty = isWhatsItAboutEmpty;
            self.isWhatsItAboutComplete = isWhatsItAboutComplete;
            self.isFeaturedImageComplete = isFeaturedImageComplete;
            self.flashRecommendedAction = flashRecommendedAction;
            self.scrollToAction = scrollToAction;

            function isComplete() {
              return (self.isWhatsItAboutComplete() && self.isFeaturedImageComplete());
            }

            function isWhatsItAboutEmpty() {
              return (!self.publication.lay_summary || self.publication.lay_summary.trim().length === 0);
            }

            function isWhatsItAboutComplete() {
              return (self.publication.lay_summary && self.publication.lay_summary.trim().length >= 250);
            }

            function isFeaturedImageComplete() {
              return !!self.featuredImageUrl;
            }

            var imageEventHandler = function (evt, data) {
              self.featuredImageUrl = data.image_url;
            };

            $rootScope.$on('FeaturedImageDeleteEvent', imageEventHandler);
            $rootScope.$on('FeaturedImageUpdateEvent', imageEventHandler);
            $rootScope.$on('FeaturedImagePanelOnInitEvent', imageEventHandler);

            function flashRecommendedAction() {
              PublicationService.flashRecommendedAction = true;

              // If we didn't set this to false the flash class would remain on the
              // recommended action element, making subsequent clicks unable to flash.
              $timeout(function() {
                PublicationService.flashRecommendedAction = false;
              }, 5000);
            }

            function scrollToAction(scrollTargetId) {
              if (angular.isUndefined(scrollTargetId)) {
                return;
              }

              var scrollOffset = -60;
              var scrollTarget = angular.element('#' + scrollTargetId);

              scrollTo(scrollTarget, scrollOffset);
            }

            function scrollTo (element, offset) {
              var prefersReducedMotion = window.matchMedia('(prefers-reduced-motion)').matches;
              var body = angular.element('body,html');
              var newScrollValue = element.offset().top + offset;

              if (prefersReducedMotion) {
                body.scrollTop(newScrollValue);
              } else {
                body.animate({scrollTop: newScrollValue}, 300);
              }
            }
        }]
      }
    );
}(window.angular, window._));
