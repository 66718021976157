(function (angular, _) {
  'use strict';

  angular
    .module('kudosShares')
    .directive('kudosSharePublicationBox', [
      '$rootScope',
      '$timeout',
      'SessionService',
      'NotificationService',
      function ($rootScope, $timeout, SessionService, NotificationService) {
        return {
          scope: {},
          bindToController: {
            doi: '@',
            onShareSuccess: '=?',
            recommendation: '=',
            strictChannelName: '@?'
          },
          templateUrl: 'kudosShares/kudosSharePublicationBox.directive.html',
          controllerAs: 'vm',
          controller: ['$q', 'ShareService', function($q, ShareService) {
            var self = this;

            self.shareMessage = '';
            self.processState = 'init';
            self.progressButtonStates = getProgressButtonStates();
            self.isChannelSelected = isChannelSelected;
            self.shareToChannels = shareToChannels;
            self.canShareToChannels = canShareToChannels;
            self.hasSelectedChannels = hasSelectedChannels;
            self.calculateRemainingCharacters = calculateRemainingCharacters;
            self.shareToChannelAndNotify = shareToChannelAndNotify;
            self.$onInit = init;

            function init() {
              self.availableChannels = getAvailableChannels();
              self.strictChannel = getStrictChannel();
            }

            function getStrictChannel() {
              return _.find(ShareService.availableChannels(), function(channel) {
                return channel.name === self.strictChannelName;
              });
            }

            $rootScope.$on(SessionService.CURRENT_USER_UPDATE_EVENT_NAME, function () {
              self.availableChannels = getAvailableChannels();
            });

            function isChannelSelected (channel) {
              return channel.shareSelected && channel.enabled;
            }

            self.markChannelSharedSuccessfully = function(channel) {
              channel.shareSuccess = true;
              channel.shareFail = false;
              // De-selects databound checkbox on view if succeeds.
              channel.shareSelected = false;
              channel.shareInProgress = false;
            }

            self.markChannelSharedFailed = function(channel) {
              channel.shareSuccess = false;
              channel.shareFail = true;
              channel.shareInProgress = false;
            }

            self.markChannelShareStart = function(channel) {
              // reset statuses
              channel.shareSuccess = false;
              channel.shareFail = false;
              //mark as in progress
              channel.shareInProgress = true;
            }

            function calculateRemainingCharacters (channel) {
              if (angular.isUndefined(channel.characterLimit)) {
                return 'unlimited';
              }

              var count = channel.characterLimit - self.shareMessage.length;
              if (count < 0) {
                count = 0;
              }
              return count;
            }

            function hasSelectedChannels () {
              var channelSelected = false;
              _.each(self.availableChannels, function (channel) {
                if (channel.shareSelected && channel.enabled) {
                  channelSelected = true;
                }
              });
              return channelSelected;
            }

            function canShareToChannels () {
              // to be able to share, the user must have entered a message and selected at least one channel
              return self.shareMessage.length > 0 && self.hasSelectedChannels();
            }

            function shareToChannelAndNotify () {
              changeShareButtonState('waiting');

              ShareService.sharePublicationToChannel(self.strictChannel.name, self.doi, self.shareMessage)
              .then(function () {
                changeShareButtonState('success');
                NotificationService.success('Successfully posted to ' + self.strictChannel.title)
              })
              .then(self.callOnShareSuccess)
              .catch(function () {
                changeShareButtonState('error');
                NotificationService.error('Errors in posting to ' + self.strictChannel.title + '. Please retry.')
              })
              .finally(function () {
                $timeout(function () {
                  changeShareButtonState('init');
                }, 3000);
              });
            }

            function shareToChannels () {
              changeShareButtonState('waiting');

              ShareService.sharePublicationToChannels(
                self.availableChannels.filter(self.isChannelSelected),
                self.doi,
                self.shareMessage,
                self.shareToChannel
              ).then(function () {
                changeShareButtonState('success');
              })
              .then(self.callOnShareSuccess)
              .catch(function () {
                changeShareButtonState('error');
              })
              .finally(function () {
                $timeout(function () {
                  changeShareButtonState('init');
                }, 3000);
              });
            }

            self.callOnShareSuccess = function() {
              if (angular.isDefined(self.onShareSuccess) && angular.isFunction(self.onShareSuccess)) {
                // Run optional post share success callback (if provided)
                self.onShareSuccess();
              }
            }

            function changeShareButtonState (state) {
              if (self.processState === 'error' && state === 'success') {
                return;
              }

              self.processState = state;
            }

            self.shareToChannel = function(channel, doi, shareMessage) {
              self.markChannelShareStart(channel);

              return ShareService.sharePublicationToChannel(channel.name, doi, shareMessage, self.recommendation)
                       .then(function () {
                         self.markChannelSharedSuccessfully(channel);

                         return channel;
                       })
                       .catch(function () {
                        self.markChannelSharedFailed(channel);

                         return $q.reject(channel);
                       });
                }

            function getAvailableChannels () {
              var channels = ShareService.availableChannels();
              if (self.strictChannelName) {
                channels = _.filter(ShareService.availableChannels(), function(channel) {
                  return channel.name === self.strictChannelName;
                });
              }

              return _.map(channels, function (channel) {
                channel.shareSuccess = false;
                channel.shareFail = false;
                channel.shareInProgress = false;
                channel.shareSelected = channel.enabled;

                return channel;
              });
            }

            function getProgressButtonStates () {
              return {
                init: {
                  buttonText: 'Share'
                },
                waiting: {
                  buttonText: 'Sharing'
                },
                success: {
                  buttonText: 'Shared'
                }
              };
            }
          }
        ]
      };
    }
  ]);

} (window.angular, window._));
