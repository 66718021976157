; (function (angular) {

  "use strict";

  angular.module('kudosAdmin')
    .controller(
      'AdminAccountManagementController',
      [
        function () {
          this.getAllUnsubscribed = function () {
            var url = window.location.origin + '/subscriptions/mss_all_unsubscribed';
            var queries = [];
            if (this.publisher) {
              queries.push('publisher=' + this.publisher);
            }
            if (this.campaign) {
              queries.push('campaign=' + this.campaign);
            }
            window.location.href = url + '?' + queries.join('&');
          };
        }
      ]
    );

} (window.angular));
