; (function (angular, _) {
  'use strict';

  angular.module('kudosPublications')
    .component(      
      'geographicRegionsPanel', {
        bindings: {
          region: '=',
          publication: '<',
        },
        templateUrl: "kudosPublications/components/geographicRegionsPanel/geographicRegionsPanel.html",
        controllerAs: 'vm',
        require: ['$timeout', 'PublicationService', 'RegionComponentChangedService'],
        controller: ["$timeout", "PublicationService", "RegionComponentChangedService", function ($timeout, PublicationService, RegionComponentChangedService) {
          var self = this;
          self.selectAllCheckboxes = selectAllCheckboxes;
          self.checkboxToggle = checkboxToggle;
          self.submit = submit;
          self.getAllChanges = getAllChanges;
          self.resetSaveButton = resetSaveButton;
          self.hasChanged = hasChanged;

          this.$onInit = function() {
            this.regionButtonState = 'init';
            
            this.regionsObject ={
              Africa: false,
              Antarctica: false,
              Asia: false,
              Australasia: false,
              Europe: false,
              'North America': false,
              'South America': false
            };

            this.continentColour={
              true: {fill: '#1b5199'},
              false: {fill: '#c4d0d3'}
            };

            for(var i = 0; i < this.region.length; i++) {
              this.regionsObject[this.region[i]] = true;
            }
          };

          function selectAllCheckboxes() {
            for(var region in this.regionsObject) {
              this.regionsObject[region] = true;
            }
            self.hasChanged();
          }

          function checkboxToggle(key) {
            this.regionsObject[key] = !this.regionsObject[key];
            self.hasChanged();
          }

          function submit() {
            var changes = self.getAllChanges();
            if(_.isEqual(changes, ['noChanges'])) {
              self.resetSaveButton();
              return;
            }
            self.regionButtonState = 'waiting';
            PublicationService.updatePublicationField(self.publication, 'regions', changes)
            .then(function () {
              self.resetSaveButton();
            })
            .catch(function () {
              self.regionButtonState = 'error';
            });
          }

          function resetSaveButton() {
            self.regionButtonState = 'success';
            RegionComponentChangedService.setUnchanged();
            $timeout(function() {
              self.regionButtonState = 'init';
            }, 3000);
          }

          function hasChanged() {
            var newRegions = [];
            for(var region in this.regionsObject) {
              if(this.regionsObject[region]) {
                newRegions.push(region);
              }
            }
            if(!_.isEqual(newRegions, self.region)) {
              RegionComponentChangedService.setChanged();
              return;
            }
            RegionComponentChangedService.setUnchanged();
          }
           
          function getAllChanges() {
            var newRegions = [];
            for(var region in this.regionsObject) {
              if(this.regionsObject[region]) {
                newRegions.push(region);
              }
            }
            if(!_.isEqual(newRegions, self.region)) {
              self.region = newRegions;
              return newRegions;
            }
            return ['noChanges'];
          }
        }]
      }
    );
}(window.angular, window._));
