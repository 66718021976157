; (function (angular, _, $) {

  'use strict';
  angular.module('kudos')
    .directive('kudosNavbar', ["SessionService", function (SessionService) {
      return {
        transclude: true,
        templateUrl: 'kudos/directives/kudosNavbar.html',
        controllerAs: 'vm',
        compile: function (element, attrs) {
          // If stateless attr has been applied to directive, remove statechange
          // functionality so that traditional linking is used instead.
          // This is so that the Angular header and sidebar can be used in UX1
          // pages.
          if (angular.isDefined(attrs['stateless'])) {
            return {
              // Post-link intervention function
              post: function (scope, element, attrs) {
                // Finds all instances of 'ui-sref' in the element tree, and
                // remove the 'ui-sref' attribute
                var $anchors = $(element).find('[ui-sref]');
                if($anchors.length) {
                  _.each($anchors, function (anchor) {
                    // Ensure that anchor tag's behaviour has been properly re-defined
                    $(anchor)
                      .removeAttr('ui-sref')
                      .unbind()
                      .click(function () {
                        window.location = $(this).attr('href');
                      });
                  });
                }
              }
            };
          }
        },
        controller: ["$scope", "$location", function ($scope, $location) {
          var self = this;

          self.isSidebarShown = false;
          self.showConfirmationBanner = false;
          self.toggleSidebar = toggleSidebar;

          self.currentUser = SessionService.currentUser;
          self.userIsLoggedIn = SessionService.userIsLoggedIn;
          self.userIsVerified = SessionService.userIsVerified;

          self.showLoginForm = SessionService.showLoginForm;

          self.resendConfirmationEmail = SessionService.resendConfirmationEmail;

          self.showConfirmationBanner = function () {
            return self.userIsLoggedIn() && !self.userIsVerified()
          };

          self.isTransparentBackground = function () {
            // match a '/' or '' (empty) path
            return $location.path().match(/^\/?$/) !== null;
          };

          function toggleSidebar () {
            $scope.$broadcast('sidebar.toggleShown');
          }

          $scope.$on('sidebar.shownChange', function (event, isShown) {
            self.isSidebarShown = isShown;
          });
        }]
      };
    }]);

}(window.angular, window._, window.jQuery));
