; (function (angular, _) {
  'use strict';

  angular
    .module('kudos')
      .service(
        'kudosFormService',
        [
          '$q',
          '$rootScope',
          '$window',
          'RegionComponentChangedService',
          kudosFormService
        ]
      );

  function kudosFormService ($q, $rootScope, $window, RegionComponentChangedService) {
    var self = this;

    // Form save event broadcast name constant
    self.FormSaveStateEventName = 'kudosFormSaveState';
    self.UnsavedFormUnloadEventName = 'unsavedFormUnload';

    self.form = {};
    self.updateFunction = false;

    self.init = init;

    $window.onbeforeunload = onBeforeUnloadHandler;

    // Function for handling onBeforeUnload event and showing
    // a confirmation dialog when a user attempts
    // to close or leave the page with unsaved form changes.
    function onBeforeUnloadHandler () {
      if (_.keys(getAllChanges()).length || RegionComponentChangedService.hasChanged()) {
        // Broadcast event that signifies that the user tried to
        // close the page when the form has been changed and not saved.
        broadcastFormEvent(self.UnsavedFormUnloadEventName, 'opened');

        // unloadEvent.returnValue = getUnsavedChangeConfirmationText();
        return getUnsavedChangeConfirmationText();
      }
    }

    // Function for obtaining confirmation text to be shown when a user attempts
    // to close/leave the page with unsaved form changes.
    function getUnsavedChangeConfirmationText () {
      return 'You have unsaved edits. Before you leave this page, do you want to save your work?';
    }

    // Method to delegate form saving, and form state.
    function submitForm () {
      broadcastFormEvent(self.FormSaveStateEventName, 'waiting');

      var modelChanges = getAllChanges();

      // Run form submission function and broadcasts an event on success/failure
      getUpdateFunctions(
        modelChanges
      ).then(
        function () {
          broadcastFormEvent(self.FormSaveStateEventName, 'success');
          updateFormModel(modelChanges);
        },
        function () {
          broadcastFormEvent(self.FormSaveStateEventName, 'error');
        }
      );
    }

    // Method to return function that updates all changed fields for explain
    // fields and then returns a promise.
    function getUpdateFunctions (modelChanges) {
      return $q.all(
        _.map(
          modelChanges,
          function (changedFieldValue, changedFieldName) {
            return self.updateFunction(changedFieldName, changedFieldValue);
          }
        )
      );
    }

    // Updates the form model (used for comparing pre-save changes)
    function updateFormModel (modelChanges) {
      _.each(modelChanges, function (value, key) {
          // Assign top-level values to ensure object/array item deletions.
        self.model[key] = value;
      });
    }

    // Method for broadcasting form save activity so that other
    // components can respond appropriately to it.
    function broadcastFormEvent (eventName, state) {
      $rootScope.$broadcast(eventName, state);
    }

    // Method to compare original model with form fields and
    // returns an object detailing the field changed and the
    // new value. This can be used to selectively update changed
    // model fields.
    function getAllChanges () {
      var changes = {};

      _.each(self.form.fields, function (value, key) {
        if (value !== self.model[key]) {
          changes[key] = value;
        }
      });
      return changes;
    }

    // Service instance constructor.
    // Accepts config object:
    // e.g.
    // {
    //   model: {
    //     id: 1,
    //     name: 'Jeremy'
    //     lay_summary: "This is lay."
    //     article_id: 2,
    //     perspective: "My thoughts"
    //   },
    //   updateModelFunction: someUpdateFunction,
    // }

    function init (config) {
      self.model = config.model;
      self.updateFunction = config.updateModelFunction;

      self.form = {
        fields: _.clone(self.model),
        submit: submitForm
      };
    }
  }
}(window.angular, window._));
