; (function (angular, _) {
  'use strict';

  angular.module('kudosShares')
    .factory(
      'ShareService',
      [
        '$http',
        '$q',
        '$timeout',
        'NotificationService',
        'SessionService',
        function ($http, $q, $timeout, NotificationService, SessionService) {
          return {
            availableChannels: function () {
              var currentUser = SessionService.currentUser();

              return [
                {
                  name: 'twitter',
                  title: 'Twitter',
                  enabled: !!currentUser.twitter_login_present,
                  characterLimit: 115 // Twitter wraps URLs with t.co(); this means the maximum url length could be 23 chars (as of 5-10-2015)
                    // we also require a space between the url and the message, which implies a maximum message length of
                    // 140 - 23 - 1 = 116 characters. But for future-protecting, allow the URL to be upto 24 chars,
                    // i.e. maximum twitter message length of 115.
                    // See https://dev.twitter.com/rest/reference/get/help/configuration for current twitter url length.
                },
                {
                  name: 'linkedin',
                  title: 'LinkedIn',
                  enabled: !!currentUser.linkedin_login_present,
                  characterLimit: 675
                }
              ];
            },

            sharePublicationToChannel: function (channel, doi, shareMessage, recommendation) {
              var payload = {
                channel: channel,
                doi: doi,
                share_message: shareMessage
              };

              if (angular.isDefined(recommendation)) {
                payload.triggering_recommendation = {id: recommendation.id}; // see internal_api/app.rb
              }

              return $http.post('/internal_api/shares/share_publication_via_channel', payload).then(function (response) {
                return response.data.data.intervention;
              });
            },

            sharePublicationToChannels: function (selectedChannels, doi, shareMessage, shareToChannel) {
              var promises = _.map(selectedChannels, function (channel) {
                return shareToChannel(channel, doi, shareMessage);
              });

              return $q.allSettled(promises)
                .then(function (values) {
                  if (_.every(values, { state: 'fulfilled' })) {
                    NotificationService.success('Successfully posted to social media accounts');

                    return $q.resolve(values);
                  }

                  if (_.every(values, { state: 'rejected' })) {
                    NotificationService.error('Errors in posting to social media accounts. Please retry.');
                  } else {
                    NotificationService.warning('Posted to social media accounts with one or more errors');
                  }

                  return $q.reject(values);
                });
            },

            generateShareableLink: function (doi, label) {
              return $http.post(
                '/internal_api/shares/generate_shareable_link',
                {doi: doi, label: label}
              ).then(function (response) {
                  return response.data.data.intervention;
                })
                .catch(function () {
                  NotificationService.error('Share link could not be generated.');
                });
            }

          };
        }
      ]
    );

}(window.angular, window._));
