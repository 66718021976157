; (function (angular) {
  'use strict';
  angular
    .module('kudosInstitutions')
    .service(
      'InstitutionAffiliationService',
      [
        'ProfileService',
        'SessionService',
        InstitutionAffiliationService
      ]
    );

  function InstitutionAffiliationService (ProfileService, SessionService) {
    var self = this;

    self.saveInstitutionAssociation = saveInstitutionAssociation;

    function buildRequestPayload (institution) {
      var payload = {};

      if (angular.isObject(institution)) {
        payload = {
          institution_name: institution.displayName,
          institution_id: institution.ringgoldId
        };
      }

      if (angular.isString(institution)) {
        payload = {
          institution_name: institution,
          institution_id: null
        };
      }

      return payload;
    }

    function saveInstitutionAssociation (institution) {
      // If not logged in, should return undefined which will
      // make backend return error, which will be presented to
      // the user.
      var accountId = SessionService.currentUser().id;
      var payload = buildRequestPayload(institution);

      return ProfileService.updateAccount(accountId, payload)
        .then(function (data) {
            return {
              tokenClaimsInvalid: data.tokenClaimsInvalid
            };
        });
    }
  }

}(window.angular));
