; (function (angular) {
  'use strict';

  angular
  .module('kudos')
  .service('toggleClassService', [
    function () {
      var self = this;

      self.addToGroup = addTogglerToGroup;
      self.removeFromGroup = removeTogglerFromGroup;
      self.toggleGroup = toggleEachInGroup;

      // Allow groups structure and toggler interface to change independently of
      // each other.
      function toggleEachInGroup(groupId) {
        var group = getGroup(groupId);
        var allOn = true;
        angular.forEach(group, function (toggler, uid) {
          // This defines the toggler interface.
          if (!implementsMethods(toggler, ['on', 'off', 'isOn'])) {
            throw new Error('Toggler must have methods "on", "off", and "isOn": uid='+uid);
          }
          if (allOn && !toggler.isOn()) {
            allOn = false;
          }
        });
        angular.forEach(group, function (toggler) {
          if (allOn) {
            toggler.off();
          } else {
            toggler.on();
          }
        });
      }

      function implementsMethods(toggler, methods) {
          if (!toggler || !angular.isObject(toggler)) {
            return false;
          }
          for (var i = 0; i < methods.length; i++) {
            if (!angular.isFunction(toggler[methods[i]])) {
              return false;
            }
          }
          return true;
      }

      self._groups = {};

      function getGroup(id) {
        var group = self._groups[id];
        if (!group) {
          throw new Error('Group does not exist: groupId=' + id);
        }
        return group;
      }

      function getOrCreateGroup(id) {
        var group = self._groups[id];
        if (!group) {
          group = self._groups[id] = {};
        }
        return group;
      }

      function addTogglerToGroup(groupId, uid, toggler) {
        var group = getOrCreateGroup(groupId);
        if (group[uid]) {
          throw new Error('Toggler is already in group: groupId='+groupId+' uid='+uid);
        }
        group[uid] = toggler;
      }

      function removeTogglerFromGroup(groupId, uid) {
        if (!self._groups[groupId]) {
          return;
        }
        delete self._groups[groupId][uid];
      }
    }
  ]);

}(window.angular));
