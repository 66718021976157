; (function (angular) {

  'use strict';

  var publicationsModule = angular.module('kudosPublications');

  publicationsModule.directive('kudosPublicationExampleBox', [
      function () {
      return {
        scope: {
          helpText: '@?',
          visible: '=?'
        },
        templateUrl: 'kudosPublications/directives/kudosPublicationExampleBox/kudosPublicationExampleBox.html',
        transclude: true,
        link: function (scope) {
          if (angular.isUndefined(scope.visible)) {
            scope.visible = false;
          }

          scope.toggle = function () {
            scope.visible = !scope.visible;
          };
        }
      };
    }
  ]);

}(window.angular));
