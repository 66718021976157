; (function (angular, _) {

  'use strict';

  angular.module('kudosComponents').directive('kudosPopoverBox', [function () {
    return {
      scope: {
        displayText: '@',
        className: '@',
      },
      templateUrl: 'kudosPopoverBox/kudosPopoverBox.html',
      link: function(_, element, attrs){
        var placement = 'bottom';
        if (angular.isDefined(attrs.placement)) {
          placement = attrs.placement;
        }

        var triggerElement = element.children('a');
        triggerElement.attr('data-placement', placement);
        triggerElement.attr('data-content', attrs.helpText);

        // Use window.jQuery so it can be spied on in tests.
        window.jQuery(triggerElement).popover({trigger: 'hover focus', html: true});
      }
    };
  }]);
}(window.angular, window._));
