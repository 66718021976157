(function (angular) {
  'use strict';

  angular.module('kudosShares').component('kudosShareMultiChannel', {
    templateUrl: 'kudosShares/kudosShareMultiChannel.component.html',
    bindings: {
      doi: '@',
      onSuccess: '<',
      generatePdfUrl: '@',
      pdfDisabled: '=',
      analyticsCategory: '@',
      analyticsLabel: '@',
      startOpen: '@',
    },
    controllerAs: 'vm',
    controller: function () {
      var self = this;

      self.selected = null;

      self.channels = Object.freeze([
        {
          id: 'link',
          label: 'Link',
          displayName: 'Link',
          iconClass: 'fa fa-link',
        },
        {
          id: 'researchgate',
          label: 'ResearchGate',
          displayName: 'ResearchGate',
          iconClass: 'ai ai-researchgate',
          requiresPdfFeature: true,
        },
        {
          id: 'pdf',
          label: 'PDF',
          displayName: 'PDF',
          iconClass: 'fa fa-file-pdf-o',
          requiresPdfFeature: true,
        },
        {
          id: 'bluesky',
          label: 'Bluesky',
          displayName: 'Bluesky',
          iconClass: 'fa-brands fa-bluesky',
          usesStandardGenerateShareableLinkBox: true,
        },
        {
          id: 'facebook',
          label: 'Facebook',
          displayName: 'Facebook',
          iconClass: 'fa fa-facebook',
          usesStandardGenerateShareableLinkBox: true,
        },

        {
          id: 'linkedin',
          label: 'LinkedIn',
          displayName: 'LinkedIn',
          iconClass: 'fa fa-linkedin',
          usesStandardGenerateShareableLinkBox: true,
        },
        {
          id: 'mastodon',
          label: 'Mastodon',
          displayName: 'Mastodon',
          iconClass: 'fa-brands fa-mastodon',
          usesStandardGenerateShareableLinkBox: true,
        },
        {
          id: 'weibo',
          label: 'Weibo',
          displayName: 'Weibo',
          iconClass: 'fa fa-weibo',
          usesStandardGenerateShareableLinkBox: true,
        },
        {
          id: 'wechat',
          label: 'WeChat',
          displayName: 'WeChat',
          iconClass: 'fa fa-wechat',
          usesStandardGenerateShareableLinkBox: true,
        },
        {
          id: 'whatsapp',
          label: 'WhatsApp',
          displayName: 'WhatsApp',
          iconClass: 'fa-brands fa-whatsapp',
          usesStandardGenerateShareableLinkBox: true,
        },
        {
          id: 'twitter',
          label: 'Twitter',
          displayName: 'X',
          iconClass: 'fa-brands fa-x-twitter',
          usesStandardGenerateShareableLinkBox: true,
        },
      ]);

      self.$onInit = function () {
        if (self.startOpen) {
          self.selected = self.startOpen;
        }
      };

      self.getActiveChannels = function () {
        return self.channels.filter(function (c) {
          return !(c.requiresPdfFeature && self.pdfDisabled);
        });
      };

      self.getChannelsWithStandardShareableLinkBox = function () {
        return self.channels.filter(function (c) {
          return c.usesStandardGenerateShareableLinkBox;
        });
      };

      self.select = function (channel) {
        if (self.selected === channel) {
          self.selected = null;
        } else {
          self.selected = channel;
        }
      };
    },
  });
})(window.angular);
