; (function (angular) {
  "use strict";

  angular
    .module('kudosPublishers')
    .directive('loaderRecords', ['PublisherReportService', '$state',
      function (PublisherReportService, $state) {
        return {
          restrict: 'E',
          scope: {
            loaderBundle: '='
          },
          controllerAs: 'ctrl',
          templateUrl: 'kudosPublishers/directives/loaderRecords.directive.html',
          bindToController: {
            publisher: '=',
            loaderBundle: '='
          },
          link: function(scope, element, attrs) {
            scope.$watch('loaderBundle', function(){
              scope.ctrl.refreshDataGrid(); // cause dataGrid to be redrawn when loaderBundle changes
            });
          },
          controller: function () {
            var self = this;

            self.LOAD_LIMIT_BUTTON_VALUES = [30, 60, 100, ''];

            self.gridOptions = {
              enableSorting: true,
              columnDefs: [
                {
                  displayName: 'DOI',
                  field: 'doi',
                  headerCellClass: 'wrap-words',
                  cellClass: getCellClassForLoaderStatus
                },
                {
                  displayName: 'Email',
                  field: 'email',
                  headerCellClass: 'wrap-words',
                  cellClass: getCellClassForLoaderStatus
                },
                {
                  displayName: 'Retry count',
                  field: 'retry_count',
                  headerCellClass: 'wrap-words',
                  cellClass: getCellDangerClassForNonZero
                },
                {
                  displayName: 'Last processed at',
                  field: 'process_attempted_at',
                  cellTemplate: cellContentTemplate('{{ row.entity.process_attempted_at | date:"d-MMM-yyyy H:mm"}}'),
                  headerCellClass: 'wrap-words',
                  cellClass: getCellClassForLoaderStatus
                },
                {
                  displayName: 'Status',
                  field: 'loader_status',
                  headerCellClass: 'wrap-words',
                  cellClass: getCellClassForLoaderStatus
                },
                {
                  displayName: 'Log',
                  field: 'loader_status',
                  headerCellClass: 'wrap-words',
                  cellTemplate: cellContentTemplate('<button uib-popover-template="grid.appScope.ctrl.htmlPopoverTemplate" popover-trigger="outsideClick" popover-title="{{row.entity.doi}}" popover-append-to-body="true" popover-placement="top-right" class="btn btn-primary btn-xs"><i class="fa fa-question-circle"></i></button>'),
                  width: 60,
                  enableSorting: false
                }
              ],
              enableRowSelection: true,// we need to enable this to allow clickover popovers to work
              enableRowHeaderSelection: false,
              multiSelect: false,
              modifierKeysToMultiSelect: false,
              noUnselect: true
            };


            // publicly accessible functions used in the view
            self.setLoadLimit = setLoadLimit;
            self.refreshDataGrid = refreshDataGrid;
            self.getLoaderRecordsReportCsvUrl = getLoaderRecordsReportCsvUrl;


            // publicy exposed objects
            self.reportMetadata = {}; // this is set by a request for data
            self.loadLimit = undefined; // set by clicking on a load-limit button
            self.htmlPopoverTemplate = "kudosPublishers/directives/loaderRecords.popover.html";
            self.$onInit = init;

            function init() {
              setLoadLimit(self.LOAD_LIMIT_BUTTON_VALUES[0]);
            }

            function setLoadLimit (limit) {
              self.loadLimit = limit;
              refreshDataGrid();
            }

            function cellContentTemplate (innerTemplate) {
              return '<div class="ui-grid-cell-contents">' + innerTemplate + '</div>';
            }

            function refreshDataGrid() {
              if (angular.isDefined(self.publisher) && angular.isDefined(self.loaderBundle) && angular.isDefined(self.loadLimit)) {
                return PublisherReportService.getLoaderRecordsReport(self.publisher.short_code, self.loaderBundle, self.loadLimit)
                  .then(function (data) {
                    self.gridOptions.data = data.rows;
                    self.reportMetadata = data.metadata;
                  })
                  .catch(function () {
                    $state.go('error', {}, {errorCode: 500});
                  });
              } else {
                self.gridOptions.data = []; //clear grid
              }
            }

            function getLoaderRecordsReportCsvUrl() {
              return PublisherReportService.getLoaderRecordsReportCsvUrl(self.publisher.short_code, self.loaderBundle, self.loadLimit);
            }

            // if the current cell is > 0, return danger colour, otherwise return a colour based on the loader status
            function getCellDangerClassForNonZero(grid, row, col) {
              if (grid.getCellValue(row,col) > 0)
              {
                return "text-danger";
              } else {
                return getCellClassForLoaderStatus(grid, row, col);
              }
            }

            // return a cell class based on the loader status
            function getCellClassForLoaderStatus(grid, row, col) {
              var textClass = undefined;
              switch (row.entity.loader_status.toLowerCase()) {
                case "pending":
                  textClass = "text-muted";
                  break;
                case "processing":
                  textClass = "text-primary";
                  break;
                case "retry":
                  textClass = "text-warning";
                  break;
                case "failure":
                case "partial failure":
                case "rejected":
                  textClass = "text-danger";
                  break;
                case "success":
                  textClass = "text-success";
                  break;
                default:
                  textClass = "text-info";
                  break;
              }
              return textClass;
            }

          }
        }
      }]
    );
} (window.angular));
