; (function (angular, Humanize) {
  'use strict';

  angular.module('core')
    .filter('numberToHuman', function () {
      return function (num, precision) {
        return Humanize.compactInteger(num, precision);
      };
    })
    .filter('largeNumberToHuman', function () {
      return function (num, precision, limit) {
        // if limit is not specified, assume 1 million
        limit = typeof limit !== 'undefined' ? limit : 1000000;
        // only humanize the number if it exceeds the limit
        return num < limit ? Humanize.formatNumber(num, 0) : Humanize.compactInteger(num, precision);
      };
    });
}(window.angular, window.Humanize));
