; (function (angular, location) {

  "use strict";

  angular
    .module("kudosPublications")
    .directive("kudosPublicationClaimButton", function() {
      var directive = {
        restrict: "E",
        templateUrl: "kudosPublications/directives/kudosPublicationClaimButton/kudosPublicationClaimButton.html",
        scope: {},
        bindToController: {
          doi: "@",
          claimButtonClass: "@",
          claimText: "@"
        },
        controller: [
          'PublicationService',
          function (PublicationService) {
            var vm = this;

            vm.buttonPressed  = buttonPressed;
            vm.$onInit = init;
    
            function init() {
              vm.buttonText = valueOrDefault(vm.claimText, "Claim this publication");
              vm.buttonClasses = valueOrDefault(vm.claimButtonClass, "btn orange-background btn-block");
              vm.buttonDisabled = false;
            }

            function buttonPressed() {
              vm.buttonDisabled = true;

              PublicationService.claim(vm.doi)
                .then(onClaimSuccess)
                .finally(
                  function() {
                    vm.buttonDisabled = false;
                  }
                );
            }

            function onClaimSuccess () {
              location.href = '/publications/' + PublicationService.encodeDOI(vm.doi);
            }
          }
        ],
        controllerAs: "vm"
      };

      return directive;
    });

  /**
   * Helper function to simplify the setting of default values.
   * @param  value        A (possibly undefined) value
   * @param  defaultValue The value to return if `value` isn't present
   * @return              `value` if it is defined, else `defaultValue`
   */
  function valueOrDefault (value, defaultValue) {
    return (
      angular.isUndefined(value) ?
        defaultValue :
        value
    );
  }

}(window.angular, window.location));
