; (function (angular) {
  'use strict';

  angular.module('kudosPublications')
    .directive(
      'publicationResourcesEdit',
      [
        'ResourceService',
        function (ResourceService) {
          return {
            scope: {
              resources: '=?',
            },
            templateUrl: 'kudosPublications/publicationResourcesEdit.directive.html',
            require: '^^publicationResources',
            link: function (scope, element, attrs, publicationResourcesController) {
              scope.resourceTypes = [];

              publicationResourcesController.state.formInstance = scope.formInstance;

              scope.gotoListResourcesState = publicationResourcesController.gotoListResourcesState;
              scope.model = publicationResourcesController.state.model;
              scope.resourceTypesStillLoading = resourceTypesStillLoading;

              init();

              function init () {
                obtainResourceTypes();
              }

              function resourceTypesStillLoading () {
                return !scope.resourceTypes.length;
              }

              function obtainResourceTypes () {
                ResourceService.getResourceTypes().then(function (resourceTypes) {
                  scope.resourceTypes = resourceTypes;
                });
              }

            }
          };
        }
      ]);

}(window.angular));
