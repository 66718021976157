; (function (angular) {

  'use strict';

  angular.module('kudosHub').component('passwordField', {
      templateUrl: 'kudosHub/settings/kudosPasswordField.html',
      bindings: {
        name: '@',
        autocomplete: '@',
        placeholder: '@'
      },
      controllerAs: 'vm',
      controller: function () {
        this.hidden = true;
        this.toggleType = toggleType;
        this.displayText = displayText;
        this.inputType = inputType;

        function toggleType () {
          this.hidden = !this.hidden;
        }

        function displayText () {
          if (this.hidden) {
            return 'show';
          }
          return 'hide';
        }

        function inputType () {
          if (this.hidden) {
            return 'password';
          }
          return 'text';
        }
      }
  });

} (window.angular));
