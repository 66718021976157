(function (angular) {
  'use strict';

  angular.module('facebook').service('FacebookService', [
    '$q', '$window',
    function ($q, $window) {
      var self = this;

      var sdk;
      initsdk();

      self.ERROR_UNAVAILABLE = 'Facebook SDK not available';
      self.UI_CLOSED = 'Facebook UI was closed';

      self.share = function (url) {
        if (!sdk) {
          return $q.reject(self.ERROR_UNAVAILABLE);
        }

        var opts = {
          method: 'share',
          mobile_iframe: true,
          href: url
        };
        var deferred = $q.defer();
        sdk.ui(opts, function (response) {
          if (!response) {
            deferred.reject(self.UI_CLOSED);
            return;
          }
          deferred.resolve(response);
        });
        return deferred.promise;
      };

      function initsdk() {
        $window.fbAsyncInit = function() {
          sdk = $window.FB;
          sdk.init({
            appId            : $window.FACEBOOK_APP_ID,
            autoLogAppEvents : false,
            xfbml            : false,
            version          : 'v3.1'
          });
        };
        // Snippet that creates window.FB object.
        (function(d, s, id){
           var js, fjs = d.getElementsByTagName(s)[0];
           if (d.getElementById(id)) {return;}
           js = d.createElement(s); js.id = id;
           js.src = "https://connect.facebook.net/en_US/sdk.js";
           fjs.parentNode.insertBefore(js, fjs);
         }(document, 'script', 'facebook-jssdk'));
      }
    }
  ]);

}(window.angular));
