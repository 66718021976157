; (function (angular) {
  'use strict';

  angular
  .module('kudos')
  .directive('toggleClassGroup', [
    'toggleClassService',
    function (
      toggleClassService
    ) {
      return {
        scope: {
          group: '@'
        },
        link: function (scope, element) {
          element.on('click', toggleGroup);

          function toggleGroup () {
            toggleClassService.toggleGroup(scope.group);
          }
        }
      };
    }
  ]);

}(window.angular));
