; (function (angular) {
  "use strict";

  angular.module('kudosAdmin')
    .config([
      '$stateProvider',
      function ($stateProvider) {
        $stateProvider
          .state('admin', {
            url: '/admin',
            abstract: true,
            templateUrl: 'kudosAdmin/admin.html',
            controller: 'AdminController as vm',
            resolve: {
              categoryOverview: ['$state', 'AdminService', function ($state, AdminService) {
                return AdminService.one('admin').one('reports', 'category_overview').get()
                  .then(function (categoryOverview) {
                    return categoryOverview.rows;
                  })
                  .catch(function () {
                    $state.go('error', {}, {errorCode: 500});
                  });
              }],
              exampleExplainedPublications: ['$state', 'AdminService', function ($state, AdminService) {
                return AdminService.one('admin').one('reports', 'explained_publications_examples').get()
                  .then(function (explained_publications_examples) {
                    return explained_publications_examples.rows;
                  })
                  .catch(function () {
                    $state.go('error', {}, {errorCode: 500});
                  });
              }]
            }
          })
          .state('admin.dashboard', {
            url: '',
            templateUrl: 'kudosAdmin/admin.dashboard.html',
            controller: 'AdminDashboardController as adminDashboard'
          })
          .state('admin.manage_institutions', {
            url: '/manage_institutions',
            abstract: true,
            templateUrl: 'kudosAdmin/admin.manage-institutions.html'
          })
          .state('admin.manage_institutions.search', {
            url: '/search',
            templateUrl: 'kudosAdmin/admin.manage-institutions.search.html',
            controller: 'AdminInstitutionSearchController as adminInstitutionSearch'
          })
          .state('admin.reports', {
            url: '/reports',
            templateUrl: 'kudosAdmin/admin.reports.html'
          })
          .state('admin.institutions', {
            url: '/institutions',
            templateUrl: 'kudosAdmin/admin.institutions.html',
            controller: 'AdminInstitutionsController as adminInstitutions',
            resolve: {
              institutions: ['InstitutionService', '$state', function (InstitutionService, $state) {
                var states = InstitutionService.institutionSubscriptionStates.join(',');
                return InstitutionService.getCustomerInstitutions(states)
                  .then(function (response) {
                    return response.data.data.institutions;
                  })
                  .catch(function () {
                    $state.go('error', {}, {errorCode: 500});
                  });
              }]
            }
          })
          .state('admin.publishers', {
            url: '/publishers',
            templateUrl: 'kudosAdmin/admin.publishers.html',
            controller: 'AdminPublisherController as adminPublishers',
            resolve: {
              publishers: ['PublisherService', function (PublisherService) {
                var states = PublisherService.STATES.CUSTOMER; // start with customers to keep list small
                return PublisherService.getCustomerPublishers(states);
              }]
            }
          })
          .state('admin.organisations', {
            url: '/organisations',
            templateUrl: 'kudosAdmin/admin.organisations.html',
            controller: 'AdminOrganisationController as adminOrganisations',
            resolve: {
              organisations: ['OrganisationService', function (OrganisationService) {
                return OrganisationService.getCustomerOrganisations();
              }]
            }
          })
          .state('admin.search', {
            url: '/search',
            templateUrl: 'kudosAdmin/admin.search.html',
            controller: 'AdminSearchController as adminSearch'
          })
          .state('admin.account-management', {
            url: '/account-management',
            templateUrl: 'kudosAdmin/admin.account-management.html',
            controller: 'AdminAccountManagementController as adminAccountManagement'
          });
      }
    ]);

} (window.angular));
