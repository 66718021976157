; (function (angular) {

  'use strict';
  angular.module('kudosCards')
    .directive('kudosCard', [
      function () {
        return {
          transclude: true,
          replace: true,
          templateUrl: 'kudosCards/kudosCard.directive.html',
          controllerAs: 'vm',
          scope: {},
          bindToController: {
            recommendation: '=',
            hideSkip: '=?'
          },
          controller: [
            'CardDeckService',
            'RecommendationService',
            function (CardDeckService, RecommendationService) {
              var self = this;

              // initialise the controller
              init();

              // public API
              self.skip = skip;
              self.nextCard = CardDeckService.nextCard;

              function init () {
                self.skipped = false;
              }

              function skip () {
                RecommendationService.skipRecommendation(self.recommendation.id)
                  .finally(function () {
                    CardDeckService.nextCard();
                  });
              }
            }
          ]
        };
      }
    ]);

}(window.angular));

