; (function (angular) {
  'use strict';

  angular.module('core')
    .run(["$rootScope", function ($rootScope) {
      var scrollto = function (element, offset) {
        var body = angular.element('body, html');
        body.animate({
          scrollTop: element.offset().top + offset
        }, 100);
      };

      $rootScope.scrollToSelector = function (selector) {
        // we need to offset the scroll by the static header size.
        // and we add 10px extra so you can see a glimpse of where you came from.
        var scrollOffset = -60;
        var scrollTarget = angular.element(selector);
        scrollto(scrollTarget, scrollOffset);
      };
    }]);
}(window.angular));
