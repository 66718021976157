(function (angular) {
  'use strict';

  angular.module('kudosAbout').component('aboutResearchGroupForm', {
    templateUrl: 'kudosAbout/aboutResearchGroupForm.component.html',
    controllerAs: 'vm',
    controller: [
      function () {
        var self = this;

        self.toggleSelected = function (boxName) {
          if (self.selected == boxName) {
            self.selected = '';
          } else {
            self.selected = boxName;
          }
        };
      }
    ]
  }
);}(window.angular));