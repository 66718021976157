; (function (angular) {
  'use strict';
  angular
    .module("kudosNotifications")
    .factory("NotificationService", ["toastr", function(toastr){

      var pub = {};

      pub.success = function(message, title){
        toastr.success(message, title, {timeOut: 8000});
      };

      pub.info = function(message, title){
        toastr.info(message, title, {timeOut: 0});
      };

      pub.warning = function(message, title){
        toastr.warning(message, title, {timeOut: 0});
      };

      pub.error = function(message, title){
        toastr.error(message, title, {timeOut: 0});
      };

      pub.clear = function(toast){
        if(angular.isDefined(toast)) {
          toastr.clear([toast]);
        } else {
          toastr.clear();
        }
      };

      return pub;
    }]);

}(window.angular));
