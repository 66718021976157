; (function (angular) {
  'use strict';

  angular.module('core')
    .config([
      '$provide',
      '$stateProvider',
      '$urlRouterProvider',
      '$urlMatcherFactoryProvider',
      '$locationProvider',
      function($provide, $stateProvider, $urlRouterProvider, $urlMatcherFactoryProvider, $locationProvider) {

        /**
          Auto-scroll to the top of the window on page transition. By default ui-router just replaces content,
          so it's not obvious to users that the page has changed. `autoscroll='true'` helps, but cuts off
          the site title.

          See:
          * http://stackoverflow.com/questions/22290570/angular-ui-router-scroll-to-top-not-to-ui-view
          * http://angular-ui.github.io/ui-router/site/#/api/ui.router.state.directive:ui-view
          * http://angular-ui.github.io/ui-router/site/#/api/ui.router.state.$uiViewScroll
         **/
        $provide.decorator('$uiViewScroll', ['$window', function ($window) {
          return function () {
            $window.scrollTo(0, 0);
          };
        }]);

        /**
          Routing!
        **/

        // Allow optional trailing slash on URLs
        $urlMatcherFactoryProvider.strictMode(false);

        // For any unmatched url, show a 404 error but don't change the URL
        $urlRouterProvider.otherwise(function($injector, $location){
           var $state = $injector.get('$state');
           $state.go('error',  {errorCode: 404});
           return $location.path();
        });

        // Generic application states
        $stateProvider
          .state('error', {
            controller: "ErrorController as vm",
            templateUrl: "partials/error.html",
            params: {
              errorCode: "500", //default to 500 error
              errorMessage: undefined
            },
            resolve: {
              error: ['$q', '$stateParams', function ($q, $stateParams) {
                return $q.when({
                  errorCode: $stateParams.errorCode,
                  message: $stateParams.errorMessage
                });
              }]
            }
          });

        // Use the HTML5 History API
        // This gives us proper (non-#-based) routing,
        // providing we have <base> set in our HTML.
        // If not, then turn off client side routing.
        var baseHrefExists = !!document.querySelectorAll('base[href]').length
        $locationProvider.html5Mode(baseHrefExists);

    }]);

}(window.angular));
