; (function (angular) {
  'use strict';

  angular.module('kudosPublications')
    .directive(
      'publicationResourcesList',
      [
        function () {
          return {
            scope: {
              resources: '=?',
            },
            templateUrl: 'kudosPublications/publicationResourcesList.directive.html',
            require: '^^publicationResources',
            link: function (scope, element, attrs, publicationResourcesController) {
              scope.gotoEditResourceState = publicationResourcesController.gotoEditResourceState;
              scope.deleteResource = publicationResourcesController.deleteResource;
            }
          };
        }
      ]);

}(window.angular));
