; (function (angular) {

  "use strict";

  angular.module('kudosAdmin')
    .controller(
      'AdminOrganisationController',
      [
        'organisations',
        'AdminReportService',
        function (organisations, AdminReportService) {
          var self = this;

          self.organisations = organisations;
          self.gridOptions = AdminReportService.compileGridOptions.organisations_report(self.organisations);
        }
      ]
    );

} (window.angular));
