; (function (angular) {
  'use strict';

  angular.module('kudosAbout')
    .controller(
      'AboutController',
      [
        'extraInfoBoxService',
        function (extraInfoBoxService) {
          var self = this;

          self.showExtraInfoBoxes = extraInfoBoxService.showExtraInfoBoxes = {
            mobilize: false,
            why_kudos: false,
            measure: false,
            case_studies: false,
            support: false,
            videos: false
          };

          self.toggleExtraInfoBox = extraInfoBoxService.toggleExtraInfoBox;
          self.getExtraInfoBoxIndicatorClass = extraInfoBoxService.getExtraInfoBoxIndicatorClass;

        }
      ]);
})(window.angular);
