(function (angular) {
  'use strict';

  angular
    .module('kudosPublications')
    .directive('publicationInfographicView', [
      function () {
        return {
          scope: {},
          bindToController: {
            publication: '='
          },
          replace: true,
          templateUrl: 'kudosPublications/publication-infographic/publication-infographic-view.directive.html',
          controllerAs: 'vm',
          controller: [
            '$uibModal',
            '$rootScope',
            function ($uibModal, $rootScope) {

              var self = this;
              self.showInfographicModal = showInfographicModal;
              self.$onInit = init;

              function init() {
                // Ensure that there self.publication.infographic is never undefined
                self.publication.infographic = self.publication.infographic || {};
              }

              function showInfographicModal () {
                var modalInstance;
                var modalScope = $rootScope.$new();

                modalScope.close = function () {
                  modalInstance.dismiss('cancel');
                };

                modalScope.infographic_url = self.publication.infographic.infographic;

                modalInstance = $uibModal.open({
                  animation: true,
                  templateUrl: 'kudosPublications/publication-infographic/publication-infographic-modal.html',
                  size: 'lg',
                  scope: modalScope
                });
              }
            }
          ]
        };
      }
    ]);

} (window.angular));
