; (function (angular) {
  "use strict";

  angular
    .module('kudosPublishers')
    .directive('loaderBundleLogs', ['PublisherReportService', '$state',
      function (PublisherReportService, $state) {
        return {
          restrict: 'E',
          scope: {},
          bindToController: {
            publisher: '=',
            loaderBundle: '='
          },
          templateUrl: 'kudosPublishers/directives/loaderBundleLogs.directive.html',
          controllerAs: 'ctrl',
          controller: function () {
            var self = this;
            self.logs = [];
            self.getMessageTypeIcon = getMessageTypeIcon;
            self.getLoaderBundleLogsCsvUrl = getLoaderBundleLogsCsvUrl;
            self.$onInit = init;

            function init() {
              getLoaderBundleLogs(self.publisher, self.loaderBundle);
            }

            function getMessageTypeIcon(messageType) {
              switch (messageType.toLowerCase()) {
                case "debug":
                  return '<i class="fa fa-stethoscope fa-lg text-muted" aria-hidden="true" title="Debug message"></i>';
                case "info":
                  return '<i class="fa fa-info-circle fa-lg text-info" aria-hidden="true" title="Informational message"></i>';
                case "warn":
                  return '<i class="fa fa-exclamation-triangle fa-lg text-warning" aria-hidden="true" title="Warning message"></i>';
                case "error":
                  return '<i class="fa fa-exclamation-circle fa-lg text-danger" aria-hidden="true" title="Error message"></i>';
                case "fatal":
                  return '<i class="fa fa-frown-o fa-lg text-danger" aria-hidden="true" title="Fatal error message"></i>';
                default:
                  return messageType;
              }
            }

            function getLoaderBundleLogs(publisher, loaderBundle) {
              if (angular.isDefined(loaderBundle) && angular.isDefined(publisher)) {
                return PublisherReportService.getLoaderBundleLogsReport(publisher.short_code, loaderBundle)
                  .then(function (data) {
                    self.logs = data.rows;
                  })
                  .catch(function () {
                    $state.go('error', {}, {errorCode: 500});
                  });
              }
            }

            function getLoaderBundleLogsCsvUrl() {
              if (angular.isDefined(self.loaderBundle) && angular.isDefined(self.publisher)) {
                return PublisherReportService.getLoaderBundleLogsReportCsvUrl(self.publisher.short_code, self.loaderBundle);
              }
              return undefined;
            }
          }
        }
      }
    ]);
})(window.angular);
