; (function (angular) {

  'use strict';

  angular.module('kudosCharts')
    .directive(
      'kudosPercentageCircle',
      [
        'kudosPercentageCircleService',
        function (kudosPercentageCircleService) {
          return {
            scope: {
              percent: '='
            },
            link: function (scope, element) {
              var service = kudosPercentageCircleService.new(element);
              // Watch for changes on the scope, and update
              // percentage in real time.
              scope.$watch('percent', function (newPercentage) {
                service.update(newPercentage);
              });

              // Set initial percentage value.
              service.update(scope.percent);
            }
          };
        }
      ]
    );
}(window.angular));
