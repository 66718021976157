; (function (angular) {
  'use strict';
  angular
    .module('kudosPublishers')
    .factory(
      'PublisherAdminService',
      [
      'Restangular',
      function (Restangular) {
        var publisherAdmins = Restangular.service('publisher_admins');

        publisherAdmins.getIfUserIsPublisherAdmin = function (userId) {
          return publisherAdmins.one(userId).get()
            .then(function (response) {
              return response.publisher_admin;
            });
        };

        publisherAdmins.addPublisherAdmin = function (id, shortCode) {
          var payload = {
            id: id
          };
          return Restangular.service('publishers').one(shortCode).one('admins').customPOST(payload);
        };

        publisherAdmins.deletePublisherAdmin = function (id, shortCode) {
          return Restangular.service('publishers').one(shortCode).one('admins').one(id.toString()).customDELETE();
        };

        return publisherAdmins;
      }]);

}(window.angular));
