; (function (angular) {

  'use strict';
  angular.module('kudosCards')
    .directive('kudosUserManagementCard', [
      function () {
        return {
          templateUrl: 'kudosCards/kudosUserManagementCard.directive.html',
          controllerAs: 'vm',
          scope: {},
          bindToController: {
            organisation: '=',
            recommendation: '='
          },
          controller: [
            function () {
              var self = this;
              self.$onInit = init;

              var AUTHORISE_TWITTER = 6;
              var AUTHORISE_FACEBOOK = 7;
              var AUTHORISE_LINKEDIN = 8;


              self.cardState = {
                completed: false
              };

              function init () {
                if (self.recommendation) {
                  switch (self.recommendation.action) {

                    case AUTHORISE_TWITTER:
                      self.social_media_name = "twitter";
                      self.social_media_label = "Twitter";
                      break;

                    case AUTHORISE_FACEBOOK:
                      self.social_media_name = "facebook";
                      self.social_media_label = "Facebook";
                      break;

                    case AUTHORISE_LINKEDIN:
                      self.social_media_name = "linkedin";
                      self.social_media_label = "LinkedIn";
                      break;

                    default:
                      self.social_media_name = "unknown";
                      self.social_media_label = "Unknown";
                  }
                } else {
                  self.social_media_name = "github"; // for the benefit of the showcase
                  self.social_media_label = "Github";
                }
              }


            }
          ]
        };
      }
    ]);

}(window.angular));
