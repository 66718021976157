; (function (angular) {

  "use strict";

  angular.module('kudosAdmin')
    .controller(
      'AdminInstitutionsController',
      [
        'institutions',
        'AdminReportService',
        function (institutions, AdminReportService) {
          var self = this;

          self.institutions = institutions;
          self.gridOptions = AdminReportService.compileGridOptions.institution_report(self.institutions);

        }
      ]
    );

} (window.angular));
