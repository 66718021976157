; (function (angular) {
  'use strict';

  angular.module('core')
    .config(['toastrConfig', function (toastrConfig) {
      angular.extend(toastrConfig, {
        allowHtml: true,
        closeButton: true
      });
    }]);

}(window.angular));
