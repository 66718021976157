; (function (angular) {

  'use strict';
  angular.module('kudosCards')
    .directive('kudosActionClaimCard', [
      function () {
        return {
          templateUrl: 'kudosCards/kudosActionClaimCard.directive.html',
          controllerAs: 'vm',
          scope: {},
          bindToController: {
            organisation: '=',
            publication: '=',
            recommendation: '='
          },
          controller: [
            '$element',
            'NotificationService',
            'OrganisationInviteService',
            'CardDeckService',
            function ($element, NotificationService, OrganisationInviteService, CardDeckService) {
              var self = this;

              self.cardState = {
                completed: false,
                emailStep: true,
                nameMessageStep: false
              };

              self.form = {
                email: '',
                message: '',
                submitted: false
              };

              self.createClaimInvite = createClaimInvite;
              self.nextCard = CardDeckService.nextCard;

              self.isEmailStep = isEmailStep;
              self.isNameMessageStep = isNameMessageStep;
              self.proceedToNameMessageStep = proceedToNameMessageStep;

              function createClaimInvite () {
                self.form.submitted = true;

                if (!self.form.email) {
                  return;
                }

                OrganisationInviteService.createInvite(
                  self.organisation.short_code,
                  self.publication.kudos_internal_id,
                  self.form.email,
                  self.form.name,
                  self.form.message,
                  self.recommendation
                ).then(function (response) {
                  self.cardState.completed = angular.isDefined(response);
                });
              }

              function isEmailStep () {
                return self.cardState.emailStep && !self.cardState.completed;
              }

              function isNameMessageStep () {
                return self.cardState.nameMessageStep && !self.cardState.completed;
              }

              function proceedToNameMessageStep () {
                self.cardState.emailStep = false;
                self.cardState.nameMessageStep = true;
              }
            }
          ]
        };
      }
  ]);

}(window.angular));
