; (function (angular, _) {
  'use strict';
  angular
    .module('kudosInstitutions')
    .factory(
      'InstitutionReportService',
      [
        '$http', 'NotificationService', '$state', 'PublicationService',
        function ($http, NotificationService, $state, PublicationService) {

          function addReportToFavorites(shortCode, reportName) {
            return $http.post('/internal_api/institutions/' + shortCode + '/favorites/' + reportName)
              .then(function (response) {
                NotificationService.success('This report has been added to your favourites.');
                return response;
              })
              .catch(function () {
                $state.go('error', {}, {errorCode: 500});
              });
          };

          function removeReportFromFavorites(shortCode, reportName) {
            return $http.delete('/internal_api/institutions/' + shortCode + '/favorites/' + reportName)
              .then(function (response) {
                NotificationService.success('This report has been removed from your favorites.');
                return response;

              })
              .catch(function () {
                $state.go('error', {}, {errorCode: 500});
              });
          };

          var cellContentTemplate = function (innerTemplate) {
            return '<div class="ui-grid-cell-contents">' + innerTemplate + '</div>';
          };

          /*
           * Bespoke sorting algorithm for date fields.
           * This is required so that blank dates can be sorted to the bottom, the default
           * date sorting algorithm places blanks at the top.
           */
          var dateSortingAlgorithm = function (a, b) {
            // If both a and b are not blank - sort normally.
            if (!!a && !!b) {
              if (a === b) {
                return 0;
              } else if (a > b) {
                return 1;
              } else {
                return -1;
              }
            // If b is blank, place it after a.
            } else if (!!a && !b) {
              return 1;
            // If a is blank, place it after b.
            } else if (!a && !!b) {
              return -1;
            // If both are blank, place them at the same level.
            } else {
              return 0;
            }
          };

          return {

            addReportToFavorites: addReportToFavorites,
            removeReportFromFavorites: removeReportFromFavorites,

            getFavoritedReports: function(shortCode) {
              return $http.get('/internal_api/institutions/' + shortCode + '/favorites');
            },

            getFavoritedReport: function(shortCode, reportName) {
              return $http.get('/internal_api/institutions/' + shortCode + '/favorites/' + reportName);
            },

            getReport: function (shortCode, reportName) {
              return $http.get('/internal_api/institutions/' + shortCode + '/reports/' + reportName);
            },

            getReportUrl: function (shortCode, reportName) {
              return ('/internal_api/institutions/' + shortCode + '/reports/' + reportName);
            },

            getReportCsvUrl: function (shortCode, reportName) {
              return (this.getReportUrl(shortCode, reportName) + '.csv');
            },

            compileChartOptions: {
              go_url_views: {
                lineChart: function (report) {
                  return {
                    metadata: {
                      yAxis: {
                        axisLabel: "Cumulative number of clicks on the invitation link by this date"
                      }
                    },
                    datasets: [
                      {
                        x: {
                          name: "date_with_views",
                          label: "Date"
                        },
                        y: {
                          name: "number_before_date",
                          label: "Cumulative number of clicks"
                        },
                        data: _.map(report, function (row) {
                          return {
                            x: Date.parse(row.date_with_views),
                            y: row.number_before_date
                          };
                        })
                      }
                    ]
                  };
                }
              },

              researchers_registrations: {
                lineChart: function (report) {
                  return {
                    metadata: {
                      yAxis: {
                        axisLabel: "Cumulative number of researchers registered with Kudos by this date"
                      }
                    },
                    datasets: [
                      {
                        x: {
                          name: "date_with_registrations",
                          label: "Date"
                        },
                        y: {
                          name: "number_before_date",
                          label: "Cumulative number of researchers"
                        },
                        data: _.map(report, function (row) {
                          return {
                            x: Date.parse(row.date_with_registrations),
                            y: row.number_before_date
                          };
                        })
                      }
                    ]
                  };
                }
              },

              publications_views: {
                lineChart:  function (report) {
                  return {
                    metadata: {
                      yAxis: {
                        axisLabel: "Cumulative number of views of publications by this date"
                      },
                    },
                    datasets: [
                      {
                        x: {
                          name: "date",
                          label: "Date"
                        },
                        y: {
                          name: "cumulative_views",
                          label: "Cumulative number of views"
                        },
                        data: _.map(report, function (row) {
                          return {
                            x: Date.parse(row.date),
                            y: row.cumulative_views
                          };
                        })
                      }
                    ]
                  };
                }
              },
            },

            compileGridOptions: {

              all_publications: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'publicationDate',
                      cellTemplate: cellContentTemplate('{{ row.entity.publicationDate | date:"MMM yyyy"}}'),
                      maxWidth: 110,
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'publicationTitle',
                      cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                    },
                    {
                      field: 'DOI',
                      headerCellClass: 'uppercase',
                      maxWidth: 250
                    }
                  ],
                  data: _.map(report, function (article) {

                    return {
                      publicationDate: article.date,
                      publicationTitle: article.title,
                      DOI: article.doi,
                      encodedDOI: article.encoded_doi
                    };
                  })
                };
              },

              explained_excluding_resources_publications: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'publicationDate',
                      cellTemplate: cellContentTemplate('{{ row.entity.publicationDate | date:"MMM yyyy"}}'),
                      headerCellClass: 'wrap-words',
                      maxWidth: 110,
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'publicationTitle',
                      cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                    },
                    {
                      field: 'DOI',
                      headerCellClass: 'uppercase',
                      maxWidth: 250
                    }
                  ],
                  data: _.map(report, function (article) {

                    return {
                      publicationDate: article.date,
                      publicationTitle: article.title,
                      DOI: article.doi,
                      encodedDOI: article.encoded_doi
                    };
                  })
                };
              },

              publications_with_resources: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'publicationDate',
                      cellTemplate: cellContentTemplate('{{ row.entity.publicationDate | date:"MMM yyyy"}}'),
                      maxWidth: 110,
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'publicationTitle',
                      cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                    },
                    {
                      field: 'DOI',
                      headerCellClass: 'uppercase',
                      maxWidth: 250
                    }
                  ],
                  data: _.map(report, function (article) {

                    return {
                      publicationDate: article.date,
                      publicationTitle: article.title,
                      DOI: article.doi,
                      encodedDOI: article.encoded_doi
                    };
                  })
                };
              },

              explained_publications: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'publicationDate',
                      cellTemplate: cellContentTemplate('{{ row.entity.publicationDate | date:"MMM yyyy"}}'),
                      maxWidth: 110,
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'publicationTitle',
                      cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                    },
                    {
                      field: 'DOI',
                      headerCellClass: 'uppercase',
                      maxWidth: 250
                    }
                  ],
                  data: _.map(report, function (article) {

                    return {
                      publicationDate: article.date,
                      publicationTitle: article.title,
                      DOI: article.doi,
                      encodedDOI: article.encoded_doi
                    };
                  })
                };
              },

              linked_resources: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'publicationDate',
                      cellTemplate: cellContentTemplate('{{ row.entity.publicationDate | date:"MMM yyyy"}}'),
                      maxWidth: 100,
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'desc',
                        priority: 0
                      }
                    },
                    {
                      field: 'publicationTitle',
                      cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                    },
                    {
                      field: 'resourceTitle'
                    },
                    {
                      field: 'resourceDescription'
                    },
                    {
                      field: 'resourceType',
                      maxWidth: 90,
                      headerCellClass: 'wrap-words'
                    },
                    {
                      field: 'resourceLink',
                      cellTemplate: cellContentTemplate('<a href="{{ row.entity.resourceLink }}" target="_blank">{{ row.entity.resourceLink }}</a>')
                    }
                  ],
                  data: _.map(report, function (resource) {

                    return {
                      publicationDoi: resource.publication_doi,
                      publicationDate: resource.publication_date,
                      publicationTitle: resource.publication_title,
                      resourceTitle: resource.resource_title,
                      resourceDescription: resource.resource_description,
                      resourceType: resource.resource_type,
                      resourceLink: resource.resource_link,
                      encodedDOI: PublicationService.encodeDOI(resource.publication_doi)
                    };
                  })
                };
              },

              shared_publications: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'publicationDate',
                      cellTemplate: cellContentTemplate('{{ row.entity.publicationDate | date:"MMM yyyy"}}'),
                      maxWidth: 110,
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'publicationTitle',
                      cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                    },
                    {
                      field: 'DOI',
                      headerCellClass: 'uppercase',
                      maxWidth: 250
                    }
                  ],
                  data: _.map(report, function (article) {

                    return {
                      publicationDate: article.date,
                      publicationTitle: article.title,
                      DOI: article.doi,
                      encodedDOI: article.encoded_doi
                    };
                  })
                };
              },

              explain_share_activity: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'date',
                      cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                      maxWidth: 130,
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'friendlyNote',
                      displayName: 'Activity',
                      cellTemplate: cellContentTemplate('{{ row.entity.friendlyNote }} <a ng-show="!!row.entity.readTweetUrl" href="{{row.entity.readTweetUrl}}" title="read tweet" target="_blank">read tweet</a>')
                    },
                    {
                      field: 'accountName',
                      displayName: 'Author',
                      cellTemplate: cellContentTemplate('<a href="/profile/{{row.entity.customUsername}}" target="_blank" ng-bind-html="row.entity.accountName"></a>')
                    },
                    {
                      field: 'publicationTitle',
                      cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                    },
                    {
                      field: 'publicationDOI',
                      displayName: 'DOI of Publication',
                      maxWidth: 250,
                      cellTemplate: cellContentTemplate('{{ row.entity.publicationDOI }}')
                    }
                  ],
                  data: _.map(report, function (activity) {

                    return {
                      date: activity.date,
                      friendlyNote: activity.friendly_note,
                      accountName: activity.account_name,
                      customUsername: activity.custom_username,
                      publicationTitle: activity.publication_title,
                      publicationDOI: activity.publication_doi,
                      readTweetUrl: activity.read_tweet_url,
                      encodedDOI: PublicationService.encodeDOI(activity.publication_doi)
                    };
                  })
                };
              },

              explain_excluding_resources_activity: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'date',
                      cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                      maxWidth: 130,
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'friendlyNote',
                      displayName: 'Activity',
                      cellTemplate: cellContentTemplate('{{ row.entity.friendlyNote }} <a ng-show="!!row.entity.readTweetUrl" href="{{row.entity.readTweetUrl}}" title="read tweet" target="_blank">read tweet</a>')
                    },
                    {
                      field: 'accountName',
                      displayName: 'Author',
                      cellTemplate: cellContentTemplate('<a href="/profile/{{row.entity.customUsername}}" target="_blank" ng-bind-html="row.entity.accountName"></a>')
                    },
                    {
                      field: 'publicationTitle',
                      cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                    },
                    {
                      field: 'publicationDOI',
                      displayName: 'DOI of Publication',
                      maxWidth: 250,
                      cellTemplate: cellContentTemplate('{{ row.entity.publicationDOI }}')
                    }
                  ],
                  data: _.map(report, function (activity) {

                    return {
                      date: activity.date,
                      friendlyNote: activity.friendly_note,
                      accountName: activity.account_name,
                      customUsername: activity.custom_username,
                      publicationTitle: activity.publication_title,
                      publicationDOI: activity.publication_doi,
                      readTweetUrl: activity.read_tweet_url,
                      encodedDOI: PublicationService.encodeDOI(activity.publication_doi)
                    };
                  })
                };
              },

              resource_activity: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'date',
                      cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                      maxWidth: 130,
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'friendlyNote',
                      displayName: 'Activity',
                      cellTemplate: cellContentTemplate('{{ row.entity.friendlyNote }} <a ng-show="!!row.entity.readTweetUrl" href="{{row.entity.readTweetUrl}}" title="read tweet" target="_blank">read tweet</a>')
                    },
                    {
                      field: 'accountName',
                      displayName: 'Author',
                      cellTemplate: cellContentTemplate('<a href="/profile/{{row.entity.customUsername}}" target="_blank" ng-bind-html="row.entity.accountName"></a>')
                    },
                    {
                      field: 'publicationTitle',
                      cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                    },
                    {
                      field: 'publicationDOI',
                      displayName: 'DOI of Publication',
                      maxWidth: 250,
                      cellTemplate: cellContentTemplate('{{ row.entity.publicationDOI }}')
                    }
                  ],
                  data: _.map(report, function (activity) {

                    return {
                      date: activity.date,
                      friendlyNote: activity.friendly_note,
                      accountName: activity.account_name,
                      customUsername: activity.custom_username,
                      publicationTitle: activity.publication_title,
                      publicationDOI: activity.publication_doi,
                      readTweetUrl: activity.read_tweet_url,
                      encodedDOI: PublicationService.encodeDOI(activity.publication_doi)
                    };
                  })
                };
              },

              share_activity: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'date',
                      cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                      maxWidth: 130,
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'friendlyNote',
                      displayName: 'Activity',
                      cellTemplate: cellContentTemplate('{{ row.entity.friendlyNote }} <a ng-show="!!row.entity.readTweetUrl" href="{{row.entity.readTweetUrl}}" title="read tweet" target="_blank">read tweet</a>')
                    },
                    {
                      field: 'accountName',
                      displayName: 'Author',
                      cellTemplate: cellContentTemplate('<a href="/profile/{{row.entity.customUsername}}" target="_blank" ng-bind-html="row.entity.accountName"></a>')
                    },
                    {
                      field: 'publicationTitle',
                      cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                    },
                    {
                      field: 'publicationDOI',
                      displayName: 'DOI of Publication',
                      maxWidth: 250,
                      cellTemplate: cellContentTemplate('{{ row.entity.publicationDOI }}')
                    }
                  ],
                  data: _.map(report, function (activity) {

                    return {
                      date: activity.date,
                      friendlyNote: activity.friendly_note,
                      accountName: activity.account_name,
                      customUsername: activity.custom_username,
                      publicationTitle: activity.publication_title,
                      publicationDOI: activity.publication_doi,
                      readTweetUrl: activity.read_tweet_url,
                      encodedDOI: PublicationService.encodeDOI(activity.publication_doi)
                    };
                  })
                };
              },

              tweet_activity: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'date',
                      cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                      maxWidth: 130,
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'friendlyNote',
                      displayName: 'Activity',
                      cellTemplate: cellContentTemplate('{{ row.entity.friendlyNote }}')
                    },
                    {
                      field: 'accountName',
                      displayName: 'Author',
                      cellTemplate: cellContentTemplate('<a href="/profile/{{row.entity.customUsername}}" target="_blank" ng-bind-html="row.entity.accountName"></a>')
                    },
                    {
                      field: 'publicationTitle',
                      cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                    },
                    {
                      field: 'publicationDOI',
                      displayName: 'DOI of Publication',
                      maxWidth: 250,
                      cellTemplate: cellContentTemplate('{{ row.entity.publicationDOI }}')
                    },
                    {
                      field: 'tweet',
                      name: 'Read Tweet',
                      cellTemplate: cellContentTemplate('<a ng-show="!!row.entity.readTweetUrl" href="{{row.entity.readTweetUrl}}" title="read tweet" target="_blank">read tweet</a>')
                    }
                  ],
                  data: _.map(report, function (activity) {

                    return {
                      date: activity.date,
                      friendlyNote: activity.friendly_note,
                      accountName: activity.account_name,
                      customUsername: activity.custom_username,
                      publicationTitle: activity.publication_title,
                      publicationDOI: activity.publication_doi,
                      readTweetUrl: activity.read_tweet_url,
                      encodedDOI: PublicationService.encodeDOI(activity.publication_doi)
                    };
                  })
                };
              },

              researchers_claims: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'number_of_claims',
                      displayName: 'Number of publications claimed',
                      cellTemplate: cellContentTemplate('{{ row.entity.number_of_claims }}'),
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'desc',
                        priority: 0
                      }
                    },
                    {
                      field: 'researchers_claimed',
                      displayName: 'Number of Researchers that have claimed this number of publications',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.researchers_claimed }}')
                    }
                  ],
                  data: _.map(report, function (row) {

                    return {
                      number_of_claims: row.number_of_claims,
                      researchers_claimed: row.researchers_claimed
                    };
                  })
                };
              },

              researchers_registrations: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'date',
                      cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                      headerCellClass: 'wrap-words',
                      width: 130,
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'number_on_date',
                      displayName: 'Number of researchers registered with Kudos on this date',
                      cellTemplate: cellContentTemplate('{{ row.entity.number_on_date }}'),
                      headerCellClass: 'wrap-words'
                    },
                    {
                      field: 'number_before_date',
                      displayName: 'Cumulative number of researchers registered with Kudos by this date',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.number_before_date }}')
                    }
                  ],
                  data: _.map(report, function (row) {

                    return {
                      date: row.date_with_registrations,
                      number_on_date: row.number_on_date,
                      number_before_date: row.number_before_date
                    };
                  })
                };
              },

              researchers_by_career_stage: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'stage',
                      displayName: 'Career stage',
                      cellTemplate: cellContentTemplate('{{ row.entity.stage || "(Not Specified)" }}'),
                      headerCellClass: 'wrap-words',
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'acc_number',
                      displayName: 'Number of Researchers',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.acc_number }}'),
                      sort: {
                        direction: 'desc',
                        priority: 0
                      }
                    }
                  ],
                  data: _.map(report, function (row) {

                    return {
                      stage: row.stage,
                      acc_number: row.acc_number
                    };
                  })
                };
              },

              researchers_by_country: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'country',
                      displayName: 'Country',
                      cellTemplate: cellContentTemplate('{{ row.entity.country || "(Not Specified)" }}'),
                      headerCellClass: 'wrap-words'
                    },
                    {
                      field: 'acc_number',
                      displayName: 'Number of Researchers',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.acc_number }}'),
                      sort: {
                        direction: 'desc',
                        priority: 0
                      }
                    }
                  ],
                  data: _.map(report, function (row) {

                    return {
                      country: row.country_name,
                      acc_number: row.acc_number
                    };
                  })
                };
              },

              researchers_by_subject: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'subject',
                      displayName: 'Subject area',
                      cellTemplate: cellContentTemplate('{{ row.entity.subject || "(Not Specified)" }}'),
                      headerCellClass: 'wrap-words',
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'acc_number',
                      displayName: 'Number of Researchers',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.acc_number }}'),
                      sort: {
                        direction: 'desc',
                        priority: 0
                      }
                    }
                  ],
                  data: _.map(report, function (row) {

                    return {
                      subject: row.subject,
                      acc_number: row.acc_number
                    };
                  })
                };
              },

              go_url_views: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'date',
                      cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                      headerCellClass: 'wrap-words',
                      width: 130,
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'number_on_date',
                      displayName: 'Number of clicks on the invitation link on this date',
                      cellTemplate: cellContentTemplate('{{ row.entity.number_on_date }}'),
                      headerCellClass: 'wrap-words',
                      width: 350,
                      sort: {
                        direction: 'desc',
                        priority: 0
                      }
                    },
                    {
                      field: 'number_before_date',
                      displayName: 'Cumulative number of clicks on the invitation link by this date',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.number_before_date }}')
                    }
                  ],
                  data: _.map(report, function (row) {

                    return {
                      date: row.date_with_views,
                      number_on_date: row.number_on_date,
                      number_before_date: row.number_before_date
                    };
                  })
                };
              },

              all_researchers: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'registered',
                      cellTemplate: cellContentTemplate('{{ row.entity.registered | date:"d MMM yyyy"}}'),
                      displayName: 'Registration date',
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'name',
                      displayName: "User's name",
                      cellTemplate: cellContentTemplate('<a href="/profile/{{row.entity.customUsername}}" target="_blank" ng-bind-html="row.entity.name"></a>'),
                      headerCellClass: 'wrap-words'
                    },
                    {
                      field: 'email',
                      displayName: "User's email address",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.email }}')
                    },
                    {
                      field: 'subject',
                      displayName: 'Subject area',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.subject || "-" }}')
                    },
                    {
                      field: 'career_stage',
                      displayName: 'Career stage',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.career_stage || "-" }}')
                    },
                    {
                      field: 'country',
                      displayName: 'Country',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.country || "-" }}')
                    }
                  ],
                  data: _.map(report, function (account) {

                    return {
                      name: account.name,
                      registered: account.registered,
                      email: account.email,
                      career_stage: account.career_stage,
                      subject: account.subject,
                      country: account.country,
                      customUsername: account.custom_username,
                    };
                  })
                };
              },

              publications_views: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'date',
                      cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                      displayName: 'Date',
                      headerCellClass: 'wrap-words',
                      width: 100,
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'views',
                      displayName: 'Number of views of publications on this date',
                      cellTemplate: cellContentTemplate('{{ row.entity.views }}'),
                      headerCellClass: 'wrap-words'
                    },
                    {
                      field: 'cumulative_views',
                      displayName: "Cumulative number of view of publications by this date",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.cumulative_views }}')
                    }
                  ],
                  data: _.map(report, function (record) {

                    return {
                      date: record.date,
                      views: record.views,
                      cumulative_views: record.cumulative_views
                    };
                  })
                };
              },

              publications_most_viewed: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'rank',
                      cellTemplate: cellContentTemplate('{{ row.entity.rank }}'),
                      displayName: 'Rank',
                      headerCellClass: 'wrap-words',
                      width: 100
                    },
                    {
                      field: 'number_of_views',
                      displayName: 'Number of views of the publication page',
                      width: 100,
                      cellTemplate: cellContentTemplate('{{ row.entity.number_of_views }}'),
                      headerCellClass: 'wrap-words'
                    },
                    {
                      field: 'title',
                      displayName: "Publication title",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.title"></a>')
                    },
                    {
                      field: 'doi',
                      displayName: "DOI",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="http://dx.doi.org/{{row.entity.doi}}" target="_blank" ng-bind-html="row.entity.doi"></a>')
                    }
                  ],
                  data: _.map(report, function (publication) {

                    return {
                      rank: publication.rank,
                      number_of_views: publication.number_of_views,
                      title: publication.title,
                      doi: publication.doi,
                      encodedDOI: PublicationService.encodeDOI(publication.doi)
                    };
                  })
                };
              },

              publications_share_referrals: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'date_with_share_referrals',
                      cellTemplate: cellContentTemplate('{{ row.entity.date_with_share_referrals | date:"d MMM yyyy"}}'),
                      displayName: 'Date',
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'sum_referrals_on_date',
                      displayName: 'Total number of share referrals on this date',
                      cellTemplate: cellContentTemplate('{{ row.entity.sum_referrals_on_date }}'),
                      headerCellClass: 'wrap-words'
                    }
                  ],
                  data: _.map(report, function (record) {

                    return {
                      date_with_share_referrals: record.date_with_share_referrals,
                      sum_referrals_on_date: record.sum_referrals_on_date
                    };
                  })
                };
              },

              publications_highest_share_referrals: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'rank',
                      cellTemplate: cellContentTemplate('{{ row.entity.rank }}'),
                      displayName: 'Rank',
                      headerCellClass: 'wrap-words',
                      width: 100
                    },
                    {
                      field: 'number_share_referrals',
                      displayName: 'Number of Share Referrals',
                      width: 100,
                      cellTemplate: cellContentTemplate('{{ row.entity.number_share_referrals }}'),
                      headerCellClass: 'wrap-words'
                    },
                    {
                      field: 'type_share',
                      displayName: 'Type of share',
                      width: 150,
                      cellTemplate: cellContentTemplate('{{ row.entity.type_share }} <a ng-show="!!row.entity.readTweetUrl" href="{{row.entity.readTweetUrl}}" title="read tweet" target="_blank">read tweet</a>'),
                      headerCellClass: 'wrap-words'
                    },
                    {
                      field: 'publication_title',
                      displayName: "Publication title",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.title"></a>')
                    },
                    {
                      field: 'publication_doi',
                      displayName: "DOI",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="http://dx.doi.org/{{row.entity.doi}}" target="_blank" ng-bind-html="row.entity.doi"></a>')
                    }
                  ],
                  data: _.map(report, function (publication) {

                    return {
                      rank: publication.rank,
                      number_share_referrals: publication.number_share_referrals,
                      type_share: publication.type_share,
                      readTweetUrl: publication.read_tweet_url,
                      title: publication.publication_title,
                      doi: publication.publication_doi,
                      encodedDOI: PublicationService.encodeDOI(publication.publication_doi)
                    };
                  })
                };
              },

              showcase_page_view_count: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'date',
                      cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                      maxWidth: 110,
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'desc',
                        priority: 0
                      },
                      sortingAlgorithm: dateSortingAlgorithm
                    },
                    {
                      field: 'pageViews',
                      displayName: 'Number of views of showcase on this date'
                    },
                    {
                      field: 'cumulativePageViews',
                      displayName: 'Cumulative number of views of showcase by this date'
                    }
                  ],
                  data: _.map(report, function (pageTracker) {
                    return {
                      date: pageTracker.date,
                      pageViews: pageTracker.page_views,
                      cumulativePageViews: pageTracker.cumulative_page_views
                    };
                  })
                };
              },

              altmetric_scores_top_all_publications: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'rank',
                      displayName: 'Rank',
                      cellTemplate: cellContentTemplate('{{ row.entity.rank }}'),
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'asc',
                        priority: 0
                      },
                      maxWidth: 80 // make the rank column a bit narrower
                    },
                    {
                      field: 'scoreLatest',
                      displayName: 'Current Altmetric score',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.scoreLatest }}'),
                      maxWidth: 80 // make the score column a bit narrower
                    },
                    {
                      field: 'title',
                      displayName: "Publication title",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="/articles/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.title"></a>'),
                    },
                    {
                      field: 'title',
                      displayName: "Kudos detailed metrics page",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="/statistics/article/{{row.entity.id}}" target="_blank">More details</a>'),
                      maxWidth: 100
                    },
                    {
                      field: 'title',
                      displayName: "Altmetrics detailed metrics page",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="http://www.altmetric.com/details.php?doi={{row.entity.decodedDOI}}" target="_blank">More details</a>'),
                      maxWidth: 110
                    },
                    {
                      field: 'decodedDOI',
                      displayName: "DOI",
                      maxWidth: 190
                    }
                  ],
                  data: report // no map function required as JSON is in the right format
                };
              },

              altmetric_scores_top_explained_publications: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'rank',
                      displayName: 'Rank',
                      cellTemplate: cellContentTemplate('{{ row.entity.rank }}'),
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'asc',
                        priority: 0
                      },
                      maxWidth: 80 // make the rank column a bit narrower
                    },
                    {
                      field: 'scoreLatest',
                      displayName: 'Current Altmetric score',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.scoreLatest }}'),
                      maxWidth: 80 // make the score column a bit narrower
                    },
                    {
                      field: 'title',
                      displayName: "Publication title",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="/articles/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.title"></a>'),
                    },
                    {
                      field: 'title',
                      displayName: "Kudos detailed metrics page",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="/statistics/article/{{row.entity.id}}" target="_blank">More details</a>'),
                      maxWidth: 100
                    },
                    {
                      field: 'title',
                      displayName: "Altmetrics detailed metrics page",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="http://www.altmetric.com/details.php?doi={{row.entity.decodedDOI}}" target="_blank">More details</a>'),
                      maxWidth: 110
                    },
                    {
                      field: 'decodedDOI',
                      displayName: "DOI",
                      maxWidth: 190
                    }
                  ],
                  data: report // no map function required as JSON is in the right format
                };
              },

              altmetric_scores_climbing_all_publications: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'rank',
                      displayName: 'Rank',
                      cellTemplate: cellContentTemplate('{{ row.entity.rank }}'),
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'asc',
                        priority: 0
                      },
                      maxWidth: 80 // make the rank column a bit narrower
                    },
                    {
                      field: 'scorePercentageChange',
                      displayName: '% increase over the last 7 days',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.scorePercentageChange }}%'),
                      maxWidth: 80 // make the score column a bit narrower
                    },
                    {
                      field: 'scoreLatest',
                      displayName: 'Current Altmetric score',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.scoreLatest }}'),
                      maxWidth: 80 // make the score column a bit narrower
                    },
                    {
                      field: 'scoreLatest',
                      displayName: 'Altmetric score 7 days ago',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.scoreLastWeek }}'),
                      maxWidth: 80 // make the score column a bit narrower
                    },
                    {
                      field: 'title',
                      displayName: "Publication title",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="/articles/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.title"></a>'),
                    },
                    {
                      field: 'title',
                      displayName: "Kudos detailed metrics page",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="/statistics/article/{{row.entity.id}}" target="_blank">More details</a>'),
                      maxWidth: 100
                    },
                    {
                      field: 'title',
                      displayName: "Altmetrics detailed metrics page",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="http://www.altmetric.com/details.php?doi={{row.entity.decodedDOI}}" target="_blank">More details</a>'),
                      maxWidth: 110
                    },
                    {
                      field: 'decodedDOI',
                      displayName: "DOI",
                      maxWidth: 190
                    }
                  ],
                  data: report // no map function required as JSON is in the right format
                };
              },

              altmetric_scores_climbing_explained_publications: function (report) {
                return {
                  enableSorting: true,
                  minRowsToShow: 20,
                  columnDefs: [
                    {
                      field: 'rank',
                      displayName: 'Rank',
                      cellTemplate: cellContentTemplate('{{ row.entity.rank }}'),
                      headerCellClass: 'wrap-words',
                      sort: {
                        direction: 'asc',
                        priority: 0
                      },
                      maxWidth: 80 // make the rank column a bit narrower
                    },
                    {
                      field: 'scorePercentageChange',
                      displayName: '% increase over the last 7 days',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.scorePercentageChange }}%'),
                      maxWidth: 80 // make the score column a bit narrower
                    },
                    {
                      field: 'scoreLatest',
                      displayName: 'Current Altmetric score',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.scoreLatest }}'),
                      maxWidth: 80 // make the score column a bit narrower
                    },
                    {
                      field: 'scoreLatest',
                      displayName: 'Altmetric score 7 days ago',
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('{{ row.entity.scoreLastWeek }}'),
                      maxWidth: 80 // make the score column a bit narrower
                    },
                    {
                      field: 'title',
                      displayName: "Publication title",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="/articles/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.title"></a>'),
                    },
                    {
                      field: 'title',
                      displayName: "Kudos detailed metrics page",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="/statistics/article/{{row.entity.id}}" target="_blank">More details</a>'),
                      maxWidth: 100
                    },
                    {
                      field: 'title',
                      displayName: "Altmetrics detailed metrics page",
                      headerCellClass: 'wrap-words',
                      cellTemplate: cellContentTemplate('<a href="http://www.altmetric.com/details.php?doi={{row.entity.decodedDOI}}" target="_blank">More details</a>'),
                      maxWidth: 110
                    },
                    {
                      field: 'decodedDOI',
                      displayName: "DOI",
                      maxWidth: 190
                    }
                  ],
                  data: report // no map function required as JSON is in the right format
                };
              }
            }
          };
        }
      ]
    );

}(window.angular, window._));

