(function (angular) {
  'use strict';

  angular
    .module('kudosShares')
    .directive('kudosPdfShareLabel', [
      function () {
        return {
          scope: {},
          bindToController: {
            link: '@',
            strictLabel: '@'
          },
          templateUrl: 'kudosShares/kudosPdfShareLabel.directive.html',
          controllerAs: 'vm',
          controller: [function() {
            this.$onInit = function() {
              this.selectedLabel = this.strictLabel;
              this.userFreeTextLabel = '';
            };

            this.isCustomLabel = function() {
              return this.selectedLabel === "custom";
            };

            this.isSelectVisible = function() {
              return !this.strictLabel;
            };

            this.PDFLink = function() {
              var labelToSend = this.selectedLabel;

              if (this.isCustomLabel()) {
                labelToSend = encodeURIComponent(this.userFreeTextLabel);
              }

              if (!labelToSend) {
                return this.link;
              }

              return this.link + '?label=' + labelToSend;
            };
          }]
        };
      }
     ]);
} (window.angular));
