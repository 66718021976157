; (function (angular, location) {

  "use strict";

  angular
    .module("kudosPublications")
    .directive("kudosPublicationUnclaimButton", function() {
      var directive = {
        restrict: "E",
        templateUrl: "kudosPublications/directives/kudosPublicationUnclaimButton/kudosPublicationUnclaimButton.html",
        scope: {},
        bindToController: {
          doi: "@",
          unclaimButtonClass: "@",
          unclaimText: "@",
          reloadPage: '=?'
        },
        controllerAs: "vm",
        controller: [
          'PublicationService',
          'SessionService',
          function (PublicationService, SessionService) {
            var vm = this;
            vm.buttonPressed = buttonPressed;
            vm.$onInit = init;

            function init() {
              vm.buttonText = valueOrDefault(vm.unclaimText, "Unclaim this publication");
              vm.buttonClasses = valueOrDefault(vm.unclaimButtonClass, "btn btn-danger btn-block");
              vm.buttonDisabled = false;
            }

            function buttonPressed() {
              vm.buttonDisabled = true;

              PublicationService.unclaim(vm.doi)
                .then(onUnclaimSuccess)
                .finally(
                  function() {
                    vm.buttonDisabled = false;
                  }
                );
            }

            function onUnclaimSuccess () {
              if (!!vm.reloadPage) {
                location.reload();
              } else if (/\/author/.test(location.pathname)) {
                // ux3 feature
                location.href = location.pathname.replace('/author', '?ux3=')
              } else {
                // reload the session so we pick up the new publication
                SessionService.getSession();
              }
            }
          }
        ]
      };

      return directive;
    });

  /**
   * Helper function to simplify the setting of default values.
   * @param  value        A (possibly undefined) value
   * @param  defaultValue The value to return if `value` isn't present
   * @return              `value` if it is defined, else `defaultValue`
   */
  function valueOrDefault (value, defaultValue) {
    return (
      angular.isUndefined(value) ?
        defaultValue :
        value
    );
  }

}(window.angular, window.location));
