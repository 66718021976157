; (function (angular, _) {
  'use strict';
  angular
    .module('kudosInstitutions')
    .factory(
      'InstitutionService',
      [
        '$http',
        '$analytics',
        '$q',
        'NotificationService',
        function ($http, $analytics, $q, NotificationService) {

          function compileUpdatedPublicationShowcaseArray(publications) {
            return _.map(publications, function (publication) {
              return {
                doi: publication.id
              };
            });
          }

          function compileUpdatedProfileShowcaseArray(profiles) {
            return _.map(profiles, function (profile) {
              return {
                id: profile.id
              };
            });
          }

          function updateInstitutionPublicationShowcase(institution, updatedShowcase) {
            return InstitutionService.updateInstitutionField(institution, 'showcasePublications', updatedShowcase);
          }

          function updateInstitutionProfileShowcase(institution, updatedShowcase) {
            return InstitutionService.updateInstitutionField(institution, 'showcaseProfiles', updatedShowcase);
          }

          var InstitutionService = {

            getInstitution: function (shortCode) {
              return $http.get('/internal_api/institutions/' + shortCode);
            },

            getInstitutionsLatestTweets: function (shortCode) {
              return $http.get('/internal_api/institutions/' + shortCode + '/latest_tweets');
            },

            getInstitutionsShowcasePublications: function (shortCode) {
              return $http.get('/internal_api/institutions/' + shortCode + '/showcase/publications');
            },

            getInstitutionsShowcaseProfiles: function (shortCode) {
              return $http.get('/internal_api/institutions/' + shortCode + '/showcase/profiles');
            },

            addPublicationToInstitutionShowcase: function (institution, publicationDOI) {
              var unableToAddErrorMessage = 'The institution showcase publication could not be added, please try again.';

              return InstitutionService.getInstitution(institution.code)
                .then(function (response) {
                  institution = response.data.data.institution;

                  var addedPublication = {
                    id: publicationDOI
                  };

                  var updatedShowcaseArray = compileUpdatedPublicationShowcaseArray(
                    institution.showcasePublications.concat(addedPublication)
                  );

                  return updateInstitutionPublicationShowcase(institution, updatedShowcaseArray)
                    .then(function (response) {
                      $analytics.eventTrack('Added publication to institution showcase', {  category: 'institution showcase' });
                      return response.data;
                    })
                    .catch(function () {
                      NotificationService.error(unableToAddErrorMessage);
                    });
                  })
                  .catch(function () {
                    NotificationService.error(unableToAddErrorMessage);
                  });
            },

            addProfileToInstitutionShowcase: function (institution, profileId) {
              var unableToAddErrorMessage = 'The institution showcase profile could not be added, please try again.';

              var addedProfile = {
                id: profileId
              };

              var updatedShowcaseArray = compileUpdatedProfileShowcaseArray(
                institution.showcaseProfiles.concat(addedProfile)
              );

              return updateInstitutionProfileShowcase(institution, updatedShowcaseArray)
                .then(function (response) {
                  $analytics.eventTrack('Added profile to institution showcase', {  category: 'institution showcase' });
                  return response.data;
                })
                .catch(function () {
                  NotificationService.error(unableToAddErrorMessage);
                });
            },

            removePublicationFromInstitutionShowcase: function (institution, publicationDOI) {
              var unableToRemoveErrorMessage = 'The institution showcase publication could not be removed, please try again.';

              return InstitutionService.getInstitution(institution.code)
                .then(function (response) {
                  institution = response.data.data.institution;

                  var updatedShowcaseArray = compileUpdatedPublicationShowcaseArray(institution.showcasePublications);

                  _.remove(updatedShowcaseArray, function (publication) {
                    return (publication.doi === publicationDOI);
                  });

                  return updateInstitutionPublicationShowcase(institution, updatedShowcaseArray)
                    .then(function (response) {
                      $analytics.eventTrack('Removed publication from institution showcase', {  category: 'institution showcase' });
                      return response.data;
                    })
                    .catch(function () {
                      NotificationService.error(unableToRemoveErrorMessage);
                    });
                })
                .catch(function () {
                  NotificationService.error(unableToRemoveErrorMessage);
                });
            },

            removeProfileFromInstitutionShowcase: function (institution, profileId) {
              var unableToRemoveErrorMessage = 'The institution showcase profile could not be removed, please try again.';

              var updatedShowcaseArray = compileUpdatedProfileShowcaseArray(institution.showcaseProfiles);

              _.remove(updatedShowcaseArray, function (profile) {
                return (profile.id === profileId);
              });

              return updateInstitutionProfileShowcase(institution, updatedShowcaseArray)
                .then(function (response) {
                  $analytics.eventTrack('Removed profile from institution showcase', {  category: 'institution showcase' });
                  return response.data;
                })
                .catch(function () {
                  NotificationService.error(unableToRemoveErrorMessage);
                });
            },

            reOrderInstitutionShowcasePublications: function (institution, reOrderedPublications) {
              var updatedShowcaseArray = compileUpdatedPublicationShowcaseArray(reOrderedPublications);

              return updateInstitutionPublicationShowcase(institution, updatedShowcaseArray)
                .then(function (response) {
                  $analytics.eventTrack('Re-ordered institution showcase publications', {  category: 'institution showcase' });
                  return response.data;
                })
                .catch(function () {
                  NotificationService.error('The institution showcase publications could not be re-ordered, please try again.');
                });
            },

            reOrderInstitutionShowcaseProfiles: function (institution, reOrderedProfiles) {
              var updatedShowcaseArray = compileUpdatedProfileShowcaseArray(reOrderedProfiles);

              return updateInstitutionProfileShowcase(institution, updatedShowcaseArray)
                .then(function (response) {
                  $analytics.eventTrack('Re-ordered institution showcase profiles', {  category: 'institution showcase' });
                  return response.data;
                })
                .catch(function () {
                  NotificationService.error('The institution showcase profiles could not be re-ordered, please try again.');
                });
            },

            updateInstitutionField: function(institution, fieldName, newValue) {
              var updatePacket = {};
              updatePacket[fieldName] = newValue;

              return $http.put('/internal_api/institutions/' + institution.code, updatePacket)
                .then(function (response) {
                  $analytics.eventTrack("edit " + fieldName, {  category: 'institution edits' });
                  return response.data;
                })
                .catch(function () {
                  NotificationService.error('The ' + fieldName.replace('_', ' ') + ' could not be updated, please try again.');
                });
            },

            findInstitutionsByPartialName: function (searchString, limit, parentId, type) {
              var params = {
                query: searchString
              };

              if (!!limit) {
                params.limit = limit;
              }

              if (angular.isDefined(parentId)) {
                params.parent = parentId;
              }

              if (angular.isDefined(type)) {
                params.type = type;
              }

              return $http({url: '/internal_api/institutions/search', method: "GET", params: params})
                .then(function (response) {
                  return response.data.data.search_results;
                });
            },

            getIfUserIsInstitutionAdmin: function (userId) {
              return $http.get('/internal_api/institution_admins/' + userId)
                .then(function (response) {
                  return response.data.data.institution_admin;
                })
                .catch(function () {});
            },

            addInstitutionAdmin: function (new_admin_email, institution) {
              var errormsg = 'Failed to add institutional admin privileges for ' + new_admin_email.name;

              return $http.put('/internal_api/institution_admins/' + new_admin_email + '/for_institution/' + institution.code)
                .then(function (response) {
                  if (response.status === 200) {
                    NotificationService.success('Institutional admin privileges added for ' + new_admin_email);
                  }
                  return response.data.data.institution_admin;
                })
                .catch(function (error) {
                  NotificationService.error(errormsg + '. The error was ' + error.data.errors[0]);
                });
            },

            removeInstitutionAdmin: function (admin_to_remove, institution) {
              var errormsg = 'Failed to revoke institutional admin privileges for ' + admin_to_remove.name;

              return $http.delete('/internal_api/institution_admins/' + admin_to_remove.id + '/for_institution/' + institution.code)
                .then(function (response) {
                  if (response.status === 204) {
                    NotificationService.success('Institutional admin privileges revoked for ' + admin_to_remove.name);
                  }
                  return response;
                })
                .catch(function (error) {
                  NotificationService.error(errormsg + '. The error was ' + error.data.errors[0]);
                });
            },

            removeInstitutionLogo: function (shortCode) {
              return $http.get('/internal_api/institutions/' + shortCode + '/logo/remove');
            },

            removeInstitutionBanner: function (shortCode) {
              return $http.get('/internal_api/institutions/' + shortCode + '/banner/remove');
            },

            searchInstitutionUsers: function (institution, email, name) {
              var query = [];

              if (!!email) {
                query.push('email=' + email);
              }

              if (!!name) {
                query.push('name=' + name);
              }

              query = '?' + query.join('&');

              return $http.get('/internal_api/institutions/' + institution.code + '/profiles' + query);
            },

            getCustomerInstitutions: function (states) {
              return $http.get('/internal_api/institutions/?state=' + states);
            },

            institutionSubscriptionStates: ['customer', 'trial']

          };

          return InstitutionService;
        }
      ]
    );

}(window.angular, window._));
