; (function (angular) {

  "use strict";

  angular.module('kudosAdmin')
    .controller(
      'AdminSearchController',
      [
        '$window',
        'AdminReportService',
        'PublisherService',
        'PublicationService',
        'NotificationService',
        function ($window, AdminReportService, PublisherService, PublicationService, NotificationService) {
          var self = this;

          self.profileSearch = profileSearch;
          self.publisherSearch = publisherSearch;
          self.contributorPublicationsSearch = contributorPublicationsSearch;
          self.showGrid = false;

          var cellContentTemplate = function (innerTemplate) {
            return '<div class="ui-grid-cell-contents">' + innerTemplate + '</div>';
          };

          function profileSearch (searchString) {
            $window.location.href = '/admin/user_search?q=' + searchString;
          }

          self.adminResultsGridOptions = {};


          function publisherSearch (searchString) {
            PublisherService.findPublisherByPartialName(searchString)
              .then(function(publisherList){
                self.adminResultsGridOptions = AdminReportService.compileGridOptions.publisher_report(publisherList);
                self.showGrid = true;
              })
              .catch(function () {
                NotificationService.error('Couldn\'t perform search');
              });
          }


          function contributorPublicationsSearch (email) {
            PublicationService.getPublicationsByContributor(email)
              .then(function(response){
                self.adminResultsGridOptions = AdminReportService.compileGridOptions.publications_by_contributor_list(response.data.data.publications);
                self.showGrid = true;
              })
              .catch(function (response) {
                console.log(response);
                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('errors')) {
                  NotificationService.error(response.data.errors.join("<br>"));
                } else {
                  NotificationService.error('Cannot perform search.')
                }
              });
          }

        }
      ]
    );

} (window.angular));
