; (function (angular, _) {

  'use strict';

  angular.module('kudosOrganisations')
    .factory('OrganisationInviteService', [
      '$http',
      'NotificationService',
      function ($http, NotificationService) {
        return {
          createInvite: createInvite
        };

        function createInvite (shortCode, articleId, emailAddress, name, message, recommendation) {
          var payload = {
            email: emailAddress,
            article_id: articleId,
            message: message,
            name: name
          };

          if (angular.isDefined(recommendation)) {
            payload.triggering_recommendation = {id: recommendation.id}; // see internal_api/app.rb
          }

          return $http.post('/internal_api/organisations/' + shortCode + '/invites', payload).catch(function (response) {
            _.each(response.data.errors, function (error) {
              NotificationService.error(error);
            });
          });
        }
      }
    ]
  );

}(window.angular, window._));

