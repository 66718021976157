; (function (angular) {

  'use strict';

  angular.module('altmetric')
    .directive('altmetricBadge', function () {
      return {
        scope: {},
        bindToController: {
          doi: '@'
        },
        templateUrl: 'altmetric/altmetric-badge.directive.html',
        controllerAs: 'vm',
        controller: ['$timeout', function ($timeout) {
          this.$onInit = function () {
            $timeout(window._altmetric_embed_init, 500);
          };
        }]
      };
    }
  );
}(window.angular));
