/**
 * Claim/Unclaim button for a publication.
 *
 * This is a lightweight directive which only requires a DOI, not a full publication object.
 *
 * This directive has the following dependencies:
 *
 *   1. `SessionService.currentUser`, which it expects to return a user with an `articles` attribute.
 *        `articles` is an array of objects which must each have an `id` attribute which is the
 *        un-prefixed DOI (e.g. "10.5555/2014-04-01").
 *   2. `SessionService.getSession` to trigger the update of the user's list of owned publications.
 *   3. `PublicationService.claim`, which is responsible for the confirmation of the claim.
 *   4. `PublicationService.unclaim`, which is responsible for the confirmation of the unclaim.
 *
 */
; (function (angular) {

  "use strict";

  angular
    .module("kudosPublications")
    .directive("kudosPublicationClaimUnclaimButton", function() {
      var directive = {
        restrict: "E",
        replace: true,
        templateUrl: "kudosPublications/directives/kudosPublicationClaimUnclaimButton/kudosPublicationClaimUnclaimButton.html",
        scope: {},
        bindToController: {
          doi: "@",
          claimButtonClass: "@",
          unclaimButtonClass: "@",
          claimText: "@",
          unclaimText: "@"
        },
        controller: kudosPublicationClaimButtonController,
        controllerAs: "vm"
      };

      return directive;
    });

  kudosPublicationClaimButtonController.$inject = ["PublicationService", "SessionService"];
  function kudosPublicationClaimButtonController(PublicationService, SessionService) {

    var vm = this;
    vm.$onInit = init;
    vm.publicationIsClaimed = publicationIsClaimed;

    function init() {
      vm.claimButtonClass = valueOrDefault(vm.claimButtonClass, "btn orange-background btn-block");
      vm.unclaimButtonClass = valueOrDefault(vm.unclaimButtonClass, "btn btn-danger btn-block");

      vm.claimText = valueOrDefault(vm.claimText, "Claim this publication");
      vm.unclaimText = valueOrDefault(vm.unclaimText, "Unclaim this publication");
    }

    function publicationIsClaimed () {
      return SessionService.userIsAuthorFor(vm.doi);
    }
  }

  /**
   * Helper function to simplify the setting of default values.
   * @param  value        A (possibly undefined) value
   * @param  defaultValue The value to return if `value` isn't present
   * @return              `value` if it is defined, else `defaultValue`
   */
  function valueOrDefault (value, defaultValue) {
    return (
      angular.isUndefined(value) ?
        defaultValue :
        value
    );
  }

}(window.angular));
