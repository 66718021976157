; (function (angular, trendmd_widget, trendmd_journal_id, trendmd_track_id) {
  // dependency injection of window.x variables doesn't seem to work
  // so using the vars directly, which does work (check XHR reqs being made)

  "use strict";

  angular
    .module('trendmd')
    .directive('trendmd', [function () {
      return {
        templateUrl: 'trendmd/trendmd.html',
        link: function (scope, element) {
          scope.trendmd_div = element.find('#trendmd-suggestions');
          if(trendmd_widget){
            trendmd_widget.register({journal_id: trendmd_journal_id, element: '#trendmd-suggestions', track_id: trendmd_track_id});  // this definitely sends a request to TrendMD, but div stays empty
          }
        },

        controllerAs: 'vm',
        controller: function() {

        }
      };
    }]);
}(window.angular, window.TrendMD, window.TRENDMD_JOURNAL_ID, window.TRENDMD_TRACK_ID));
