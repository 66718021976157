(function(angular, _) {
  'use strict';
  angular
    .module('kudos')
      .directive('listSortOrder', [
        function () {
          return {
            scope: {
              ngModel: '=',
              onSave: '=',
              templateFn: '=?'
            },
            templateUrl: 'kudos/directives/listSortOrder.html',
            link: function (scope) {

              scope.editing = false;

              // Verbose function that returns whether the list is being re-arranged or not.
              scope.isEditing = function () {
                return (scope.editing !== false);
              };

              // Verbose function that returns whether an array element at the index provided
              // is the item being moved.
              scope.isEditingIndex = function (index) {
                return (scope.editing === index);
              };

              // Enter "editing" re-arrange mode for item at provided index
              scope.edit = function (index) {
                scope.editing = index;
              };

              // Leave "editing" mode
              scope.cancel = function () {
                scope.editing = false;
              };

              scope.show = {};

              // Checks if the first move to row should be shown, this is to ensure you can only move items to
              // appropriate places.
              scope.show.firstMoveToRow = function () {
                return !scope.isEditing() || scope.isEditingIndex(0);
              };

              // Checks if the move to row at a provided index should be shown, this is to ensure you can only
              // move items to appropriate places.
              scope.show.indexMoveToRow = function (index) {
                return !scope.isEditing() || (scope.isEditingIndex(index + 1) || scope.isEditingIndex(index));
              };

              // Moves currently edited item to provided index
              scope.moveTo = function (moveToIndex) {

                // Only shoots web when it should
                if (scope.editing !== false) {

                  // Create a new array from scratch
                  var tempArray = [];

                  // Get element of the array that is being moved
                  var movedItem = scope.ngModel[scope.editing];

                  _.each(scope.ngModel, function (listItem, index) {

                    // Push moved item to array if at desired index
                    if (index === moveToIndex) {
                      tempArray.push(movedItem);
                    }

                    // If listItem is not currently moved item, push it to the array.
                    if (index !== scope.editing) {
                      tempArray.push(listItem);
                    }

                  });

                  // This caters for moving an item to the end of the array (moveToIndex is never reached
                  // because array is not long enough, so without the item being moved goes missing).
                  if (moveToIndex === scope.ngModel.length) {
                    tempArray.push(movedItem);
                  }

                  scope.ngModel = tempArray;

                  // Call provided onSave scope function with newly arranged array
                  scope.onSave(scope.ngModel);

                  // Cancel the moving state
                  scope.cancel();
                }
              };

            }
          };
        }
    ])
    .directive('compile', ["$compile", function($compile) {
      return function(scope, element, attrs) {
        require: '^listSortOrder',
        scope.$watch(
          function(scope) {
            return scope.$eval(attrs.compile);
          },
          function(value) {
            element.html(value);
            $compile(element.contents())(scope);
          }
        );
      };
    }]);

})(window.angular, window._);

