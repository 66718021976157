(function (angular) {
  'use strict';

  angular.module('unsplash').service('UnsplashService', [
    '$http',
    function (
      $http
    ) {
      var self = this;

      var BASE = '/internal_api/publication_featured_image/search_unsplash';

      self.OPTIONS = {
        ORIENTATION: {
          LANDSCAPE_ONLY: 'landscape',
          PORTRAIT_ONLY: 'portrait',
          ANY: null,
          SQUARE: 'squarish'
        }
      };

      self.getPhotos = function (query, options, page) {
        var params = {};
        if (options.orientation) {
          params.orientation = options.orientation;
        }

        params.page = page || 1;

        return self.get(query, params)
          .then(function (response) {
            var data = response.data.data;
            var items = data.hits;
            var totalPages = data.total_pages;

            var urls = [];
            angular.forEach(items, function (item, i) {
              urls[i] = {
                url: item.urls.regular,
                preview: item.urls.thumb,
                creditUserLink: item.user.links.html,
                creditUserName: item.user.name,
                downloadLocation: item.links.download_location
              };
            });

            return {urls: urls, totalPages: totalPages};
          });
      };

      self.get = function (query, params) {
        return $http.get(self.buildURL(query, params), {cache: true});
      };

      self.defaultParams = {
        per_page: 20
      };

      self.buildURL = function (query, params) {
        var totalParams = angular.extend({q: query}, self.defaultParams, params);

        var queryStrings = [];
        angular.forEach(totalParams, function (val, key) {
          queryStrings.push(key + '=' + encodeURIComponent(val));
        });

        return BASE + '?' + queryStrings.join('&');
      };
    }
  ]);

}(window.angular));
