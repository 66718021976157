; (function (angular) {
  'use strict';
  angular
    .module('kudosPublishers')
    .factory(
      'PublisherReportService',
      [
        '$http', '$state', '$httpParamSerializer', 'NotificationService', 'PublisherService',
        function ($http, $state, $httpParamSerializer, NotificationService, PublisherService) {

          function addReportToFavorites(shortCode, reportName) {
            return $http.post('/internal_api/publishers/' + shortCode + '/favorites/' + reportName)
              .then(function (response) {
                NotificationService.success('This report has been added to your favourites.');
                return response;
              })
              .catch(function () {
                $state.go('error', {}, {errorCode: 500});
              });
          }

          function removeReportFromFavorites(shortCode, reportName) {
            return $http.delete('/internal_api/publishers/' + shortCode + '/favorites/' + reportName)
              .then(function (response) {
                NotificationService.success('This report has been removed from your favorites.');
                return response;

              })
              .catch(function () {
                $state.go('error', {}, {errorCode: 500});
              });
          }

          function getFavoritedReports(shortCode) {
            return $http.get('/internal_api/publishers/' + shortCode + '/favorites');
          }

          function getFavoritedReport(shortCode, reportName) {
            return $http.get('/internal_api/publishers/' + shortCode + '/favorites/' + reportName);
          }

          function getLoaderBundlesReport(shortCode, query, limit) {
            var params = {};
            if (angular.isDefined(query) && query != null && query !== '') {
              params.filters = {query: query};
            }
            if (angular.isDefined(limit)) {
              params.limit = limit;
            }
            return PublisherService.one(shortCode).one('reports/loader_bundles?' + $httpParamSerializer(params)).get();
          }

          function getLoaderBundlesReportCsvUrl(shortCode, query, limit) {
            var params = {};
            if (angular.isDefined(query) && query != null && query !== '') {
              params.filters = {query: query};
            }
            if (angular.isDefined(limit)) {
              params.limit = limit;
            }
            return '/internal_api/publishers/' + shortCode + '/reports/loader_bundles.csv?' + $httpParamSerializer(params);
          }

          function getLoaderBundleLogsReport(shortCode, loaderBundle, limit) {
            var params = {};
            if (angular.isDefined(loaderBundle)) {
              params.filters = {loader_bundle_id: loaderBundle.id};
            }
            if (angular.isDefined(limit)) {
              params.limit = limit;
            }
            return PublisherService.one(shortCode).one('reports/loader_bundle_logs?' + $httpParamSerializer(params)).get();
          }

          function getLoaderBundleLogsReportCsvUrl(shortCode, loaderBundle, limit) {
            var params = {};
            if (angular.isDefined(loaderBundle)) {
              params.filters = {loader_bundle_id: loaderBundle.id};
            }
            if (angular.isDefined(limit)) {
              params.limit = limit;
            }
            return '/internal_api/publishers/' + shortCode + '/reports/loader_bundle_logs.csv?' + $httpParamSerializer(params);
          }

          function getLoaderRecordsReport(shortCode, loaderBundle, limit) {
            var params = {};
            if (angular.isDefined(loaderBundle)) {
              params.filters = {loader_bundle_id: loaderBundle.id};
            }
            if (angular.isDefined(limit)) {
              params.limit = limit;
            }
            return PublisherService.one(shortCode).one('reports/loader_records?' + $httpParamSerializer(params)).get();
          }

          function getLoaderRecordsReportCsvUrl(shortCode, loaderBundle, limit) {
            var params = {};
            if (angular.isDefined(loaderBundle)) {
              params.filters = {loader_bundle_id: loaderBundle.id};
            }
            if (angular.isDefined(limit)) {
              params.limit = limit;
            }
            return '/internal_api/publishers/' + shortCode + '/reports/loader_records.csv?' + $httpParamSerializer(params);
          }

          function getLoaderRecordLogsReport(shortCode, loaderQueue, limit) {
            var params = {};
            if (angular.isDefined(loaderQueue)) {
              params.filters = {loader_record_id: loaderQueue.id};
            }
            if (angular.isDefined(limit)) {
              params.limit = limit;
            }
            return PublisherService.one(shortCode).one('reports/loader_record_logs?' + $httpParamSerializer(params)).get();
          }

          function getLoaderRecordLogsReportCsvUrl(shortCode, loaderQueue, limit) {
            var params = {};
            if (angular.isDefined(loaderQueue)) {
              params.filters = {loader_record_id: loaderQueue.id};
            }
            if (angular.isDefined(limit)) {
              params.limit = limit;
            }
            return '/internal_api/publishers/' + shortCode + '/reports/loader_record_logs.csv?' + $httpParamSerializer(params);
          }

          return {
            addReportToFavorites: addReportToFavorites,
            removeReportFromFavorites: removeReportFromFavorites,
            getFavoritedReports: getFavoritedReports,
            getFavoritedReport: getFavoritedReport,
            getLoaderBundlesReport: getLoaderBundlesReport,
            getLoaderBundlesReportCsvUrl: getLoaderBundlesReportCsvUrl,
            getLoaderBundleLogsReport: getLoaderBundleLogsReport,
            getLoaderBundleLogsReportCsvUrl: getLoaderBundleLogsReportCsvUrl,
            getLoaderRecordsReport: getLoaderRecordsReport,
            getLoaderRecordsReportCsvUrl: getLoaderRecordsReportCsvUrl,
            getLoaderRecordLogsReport: getLoaderRecordLogsReport,
            getLoaderRecordLogsReportCsvUrl: getLoaderRecordLogsReportCsvUrl

          }
        }
      ]
    )
}(window.angular));
