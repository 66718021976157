(function(angular) {
  'use strict';

  angular
    .module('showcase')
    .controller('ShowcaseCardController', [
      'publication',
      'organisation',
      '$timeout',
      function (publication, organisation, $timeout) {
        var self = this;

        self.publication = publication;
        self.organisation = organisation;
      }
    ]);

}(window.angular));
