; (function (angular) {
  'use strict';

  angular.module('kudos').component('kudosDefaultAvatar', {
    templateUrl: 'kudos/components/kudosDefaultAvatar.html',
    bindings: {
      colour: '@',
      text: '@',
      size: '@'
    },
    controllerAs: 'vm',
    controller: function () {
        var self = this;

        self.sizes = {
          small: { side: 30, fontSize: "12px" },
          medium: { side: 60, fontSize: "24px" },
          large: { side: 120, fontSize: "48px" },
          xlarge: { side: 175, fontSize: "80px" }
        };

        self.$onInit = function() {
          self.ensureValidSize();
        };

        self.cx = function() {
          return self.side() / 2;
        };

        self.cy = function() {
          return self.side() / 2;
        };

        self.r = function() {
          return self.side() / 2;
        };

        self.side = function() {
          return self.sizes[self.size].side;
        };

        self.fontSize = function() {
          return self.sizes[self.size].fontSize;
        };

        self.ensureValidSize = function() {
          if (!self.sizes.hasOwnProperty(self.size)) {
            self.size = 'medium';
          }
        };
    }
  }

);}(window.angular));
