; (function (angular, _) {
  "use strict";

  angular.module('kudosPublishers')
    .controller(
      'PublisherReportController',
      [ 'reportMetadata',
        'PublisherService',
        'PublisherReportService',
        'SessionService',
        'favoritedReport',
        'publisher',
        '$stateParams',
        '$state',
        '$interpolate',
        function (reportMetadata, PublisherService, PublisherReportService, SessionService, favoritedReport, publisher, $stateParams, $state, $interpolate) {
          var self = this;
          self.favorited = favoritedReport;

          self.showEmailButtons = true;
          self.report = {
            name: reportMetadata.name,
            title: reportMetadata.title,
            description: $interpolate(reportMetadata.description)({ publisher: publisher }),
            columns: reportMetadata.columns,
          }

          self.removeReportFromFavorites = function(reportName) {
            PublisherReportService.removeReportFromFavorites($stateParams.shortcode, reportName)
              .then(function(response) {
                self.favorited = response.data.data;
              });
          };

          self.addReportToFavorites = function(reportName ){
            PublisherReportService.addReportToFavorites($stateParams.shortcode, reportName)
              .then(function(response) {
                self.favorited = response.data.data;
              });
          };

          self.currentUserEmail = function() {
            return SessionService.currentUser().email;
          };

          self.requestEmailReport = function (reportName){
            return PublisherService.one($stateParams.shortcode).one('email_reports', reportName).get()
              .then(function () {
                self.showEmailButtons = false;
              })
              .catch(function () {
                $state.go('error', {}, {errorCode: 500});
              });
          };
        }
      ]
    );

} (window.angular, window._));
