; (function (angular) {
  'use strict';
  angular
    .module('kudosOrganisations')
    .factory(
      'OrganisationService',
      [
      '$http',
      '$state',
      function ($http, $state) {
        var self = this;

        self.getCustomerOrganisations = function () {
          return $http.get('/internal_api/organisations')
                  .then(function (response) {
                    return response.data.data.organisations;
                  })
                  .catch(function () {
                    $state.go('error', {}, {errorCode: 500});
                  });
        };

        return self;
      }
    ]
  );

}(window.angular));
