; (function (angular) {
  'use strict';

  angular.module('kudos').component('kudosAvatar', {
    templateUrl: "kudos/components/kudosAvatar.html",
    controllerAs: 'vm',
    bindings: {
      size: '@',
      avatar: '<',
      withShadow: '@'
    },
    controller: function () {
      var self = this;
      self.sizes = {
        small: { '1x': '_30x30', '2x': '_60x60' },
        medium: { '1x': '_60x60', '2x': '_120x120' },
        large: { '1x': '_120x120', '2x': '_240x240' },
        xlarge: { '1x': '_240x240'}
      };

      self.$onInit = function() {
        self.ensureValidSize();
      };

      self.hasShadow = function () {
        return self.withShadow === 'true';
      };

      self.ensureValidSize = function() {
        if (!self.sizes.hasOwnProperty(self.size)) {
          self.size = 'medium';
        }
      };

      self.getAccountAvatarThumb = function() {
        return getAccountAvatarURL(self.sizes[self.size]['1x']);
      };

      self.getAccountAvatarThumbSet = function() {
        var thumb1x = getAccountAvatarURL(self.sizes[self.size]['1x']);
        var thumb2x = getAccountAvatarURL(self.sizes[self.size]['2x']);
        if (thumb1x !== '') {
          if (thumb2x !== undefined) {
            return thumb1x + " 1x, " + thumb2x + " 2x";
          }
          return thumb1x + " 1x";
        }
        return '';  //don't return null, as this will be interpretted as the url "/null"
      };

      function getAccountAvatarURL(size) {
        return self.avatar && self.avatar[size];
      }

      self.hasDefautAvatar = function() {
        return !!(self.avatar && self.avatar.default_avatar);
      };

      function getDefaultAvatar() {
        if(self.hasDefautAvatar()) {
          return self.avatar.default_avatar;
        }
        return {};
      }

      self.defautAvatarColour = function() {
        return getDefaultAvatar().colour;
      };

      self.defautAvatarInitials = function() {
        return getDefaultAvatar().initials;
      };
    }
  }

);}(window.angular));
