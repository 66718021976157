; (function (angular, _) {
  'use strict';

  angular.module('kudosPublications')
    .directive('kudosAdminRefreshPublication', function () {
      return {
        scope: {},
        bindToController: {
          doi: '@',
          onReloadSuccess: '=?'
        },
        templateUrl: 'kudosPublications/kudosAdminRefreshPublication.directive.html',
        controllerAs: 'vm',
        controller: directiveControllerDependencies()
      };
    });

  function directiveControllerDependencies () {
    return [
      '$q',
      'PublicationService',
      directiveController
    ];
  }

  function directiveController ($q, PublicationService) {
    var self = this;

    self.refreshMetadata = refreshMetadata;
    self.buttonState = 'init';
    self.buttonStates = getButtonStates();

    function refreshMetadata () {
      self.buttonState = 'waiting';

      PublicationService
        .refreshMetadata(buildRefreshMetdadataPayload())
        .then(onReloadSuccessCallback)
        .catch(onReloadFailCallback);
    }

    function buildRefreshMetdadataPayload () {
      return {
        id: self.doi
      };
    }

    function onReloadFailCallback () {
      self.buttonState = 'error';
    }

    function onReloadSuccessCallback (response) {
      self.buttonState = 'success';

      if (_.isFunction(self.onReloadSuccess)) {
        return self.onReloadSuccess(response);
      }
    }

    function getButtonStates () {
      return {
        init: {
          name: 'init',
          buttonText: 'Synchronise with Crossref',
          buttonClass: 'btn-default'
        },
        waiting: {
          buttonText: 'Synchronising...'
        },
        success: {
          buttonText: 'Synchronised successfully'
        },
        error: {
          buttonText: 'Synchronisation failed'
        }
      };
    }
  }
}(window.angular, window._));
