(function (angular, _) {
  "use strict";
  angular.module("sessions").factory("SessionService", [
    "$http",
    "$location",
    "$window",
    "$q",
    "$rootScope",
    "NotificationService",
    "$cookies",
    function (
      $http,
      $location,
      $window,
      $q,
      $rootScope,
      NotificationService,
      $cookies
    ) {
      // local variables
      var locals = {
        currentUserObject: {},
      };

      var sessionService = {
        CURRENT_USER_UPDATE_EVENT_NAME: "currentUserUpdate",

        firstTime: false,

        showLoginForm: showLoginForm,

        signOut: function () {
          $http
            .delete("/sessions/destroy", { params: { do_not_redirect: true } })
            .then(function () {
              $window.location.href = "/authentication";
            })
            .catch(function () {
              NotificationService.error(
                "There was an error signing out, please try again"
              );
            });
        },

        getSession: function () {
          var growkudosSessionCheck = "growkudosSessionCheck";

          return $q(function (resolve, reject) {
            if (angular.isDefined($cookies.getObject(growkudosSessionCheck))) {
              // user has a growkudosSessionCheck cookie, so *probably* has a growkudos session
              resolve(
                $http
                  .get("/internal_api/sessions/")
                  .then(function (response) {
                    var current_user = response.data.data.current_user;
                    if (current_user) {
                      sessionService.currentUser(current_user);
                      sessionService.userIsVerified(!!current_user.confirmed);
                      sessionService.userIsAdmin(!!current_user.admin);
                      $cookies.put(growkudosSessionCheck, 1);
                    } else {
                      $cookies.remove(growkudosSessionCheck);
                    }
                    return current_user;
                  })
                  .catch(function (response) {
                    $cookies.remove(growkudosSessionCheck);
                    return response.data;
                  })
              );
            } else {
              // user does not have a growkudosSessionCheck cookie, so no need to call /internal_api/sessions/
              reject({});
            }
          });
        },

        currentUser: function (user) {
          if (user === undefined) {
            return locals.currentUserObject || {};
          } else {
            locals.currentUserObject = user;
          }

          $rootScope.$broadcast(this.CURRENT_USER_UPDATE_EVENT_NAME, user);
        },

        userIsVerified: function () {
          return (
            locals.currentUserObject !== undefined &&
            !!locals.currentUserObject.confirmed
          );
        },

        userIsAdmin: function () {
          return (
            locals.currentUserObject !== undefined &&
            !!locals.currentUserObject.admin
          );
        },

        userIsAdminFor: function (user, shortCode) {
          if (user === undefined) {
            return false;
          }
          var isAdmin = false;

          if (angular.isDefined(user.institutions_administered)) {
            _.each(user.institutions_administered, function (institution) {
              if (institution.shortCode === shortCode) {
                isAdmin = true;
              }
            });
          }

          if (angular.isDefined(user.publishers_administered)) {
            _.each(user.publishers_administered, function (publisher) {
              if (publisher.short_code === shortCode) {
                isAdmin = true;
              }
            });
          }
          return isAdmin;
        },

        userIsLoggedIn: function () {
          return (
            locals.currentUserObject !== undefined &&
            Object.keys(locals.currentUserObject).length !== 0
          );
        },

        resendConfirmationEmail: function () {
          return $http
            .post("/internal_api/accounts/resend_confirmation")
            .then(function () {
              NotificationService.success("Confirmation Link Sent.");
            })
            .catch(function () {
              NotificationService.error("Could not send confirmation email.");
            });
        },

        userIsAuthorFor: function (doi) {
          var user = this.currentUser();

          if (angular.isUndefined(user)) {
            return false;
          }

          return angular.isDefined(_.find(user.articles, { id: doi }));
        },
      };

      return sessionService;

      function showLoginForm() {
        var loginUrl =
          "/sessions/new?goto=" + encodeURIComponent($window.location.href);
        $window.location.href = loginUrl;
      }
    },
  ]);
})(window.angular, window._);
