; (function (angular) {

  'use strict';

  angular.module('kudos')
    .directive('kudosFooter', [function () {
      return {
        templateUrl: 'kudos/directives/kudosFooter.html',
        replace: true,
        link: function (scope) {
          scope.currentYear = new Date().getFullYear();
        }
      };
    }]);

}(window.angular));

