; (function (angular, d3, _) {
  'use strict';

  angular
    .module('kudosCharts')
      .factory(
        'kudosPercentageCircleService',
        [
          function () {
            return {
              new: function (element) {

                var self = {};

                self.update = update;

                self._arcTween                    = _arcTween;
                self._colorTween                  = _colorTween;
                self._getTargetColorForPercentage = _getTargetColorForPercentage;
                self._createBackground            = _createBackground;
                self._createForeground            = _createForeground;
                self._createText                  = _createText;
                self._createSVG                   = _createSVG;
                self._createArc                   = _createArc;
                self._withProportions             = _withProportions;
                self._getProportions              = _getProportions;
                self._sanitizePercentage          = _sanitizePercentage;
                self._transitionPercentageCircle  = _transitionPercentageCircle;

                init(element);

                return self;

                function init (element) {
                  self.element    = element;
                  self.arc        = _createArc();
                  self.svg        = _createSVG();
                  self.text       = _createText();
                  self.background = _createBackground();
                  self.foreground = _createForeground();
                }

                function update (percentage) {
                  self._sanitizePercentage(percentage, self._transitionPercentageCircle);
                }

                function _transitionPercentageCircle (percentage) {
                  self.foreground
                      .transition()
                      .duration(750)
                      .call(self._arcTween, percentage)
                      .call(self._colorTween, 'fill', self._getTargetColorForPercentage(percentage));

                  self.background
                      .transition()
                      .duration(750)
                      .call(self._colorTween, 'stroke', self._getTargetColorForPercentage(percentage, 0.25));
                }

                function _sanitizePercentage (percentage, cb) {
                  if (_.isUndefined(percentage))        { return cb(0);   }
                  if (_.isNaN(parseInt(percentage)))    { return cb(0);   }
                  if (percentage < 0)                   { return cb(0);   }
                  if (percentage > 100)                 { return cb(100); }

                  return cb(parseInt(percentage, 10));
                }

                function _getProportions () {
                  var props = {};

                  props.width       = 130;
                  props.height      = 130;

                  props.padding     = 5;
                  props.guageWidth  = 6;

                  props.outerRadius = (Math.min(props.width, props.height) / 2) - (props.padding * 2);
                  props.innerRadius = (props.outerRadius - props.guageWidth);

                  props.fontSize    = (Math.min(props.width, props.height) / 4);

                  return props;
                }

                function _withProportions (cb) {
                  return cb(self._getProportions());
                }

                function _createArc () {
                  return self._withProportions(function (proportions) {
                    return d3
                      .svg
                      .arc()
                      .innerRadius(proportions.innerRadius)
                      .outerRadius(proportions.outerRadius)
                      .startAngle(0);
                  });
                }

                function _createSVG () {
                  return self._withProportions(function (proportions) {
                    return d3.select(self.element.get(0))
                             .append('svg')
                             .attr('width', '100%')
                             .attr('height', '100%')
                             .attr('viewBox','0 0 '+Math.min(proportions.width,proportions.height) +' '+Math.min(proportions.width,proportions.height) )
                             .attr('preserveAspectRatio','xMinYMin')
                             .append('g')
                             .attr('transform', 'translate(' + Math.min(proportions.width,proportions.height) / 2 + ',' + Math.min(proportions.width,proportions.height) / 2 + ')');
                  });
                }

                function _createText () {
                  return self._withProportions(function (proportions) {
                    // subtext
                    self.svg.append('text')
                            .text('complete')
                            .attr('class', 'text-complete')
                            .attr('text-anchor', 'middle')
                            .style('font-size', (proportions.fontSize /2)+'px')
                            .style('fill', '#8b8f8b')
                            .attr('dy', (proportions.fontSize * 0.75))
                            .attr('dx', 2);

                    return self.svg.append('text')
                                   .text('0%')
                                   .attr('class', 'text-percentage')
                                   .attr('text-anchor', 'middle')
                                   .style('font-size', proportions.fontSize+'px')
                                   .style('fill', '#8b8f8b')
                                   .attr('dy', proportions.fontSize/4)
                                   .attr('dx', 2);
                  });
                }

                function _createForeground () {
                  return self.svg.append('path')
                                 .datum({
                                   endAngle: 0
                                 })
                                 .style('fill', self._getTargetColorForPercentage(0))
                                 .attr('d', self.arc);
                }

                function _createBackground () {
                  return self._withProportions(function (proportions) {
                    return self.svg.append('circle')
                                   .attr('cx', 0)
                                   .attr('cy', 0)
                                   .attr('r', proportions.innerRadius + 3)
                                   .style('stroke', self._getTargetColorForPercentage(0, 0.25))
                                   .style('fill', 'none');
                  });
                }

                function _getTargetColorForPercentage (percentage, darken) {
                  var colorIndex  = (Math.floor(percentage / 10) - 1);
                  var darkenBy    = darken || 0;

                  // Ensure color index is always in bounds.
                  colorIndex      = (colorIndex < 0 ? 0 : colorIndex);
                  colorIndex      = (colorIndex > 9 ? 9 : colorIndex);

                  var targetColors = [
                    d3.rgb(229, 40, 34),  // <10%
                    d3.rgb(229, 82, 36),  // <20%
                    d3.rgb(226, 104, 38), // <30%
                    d3.rgb(224, 118, 38), // <40%
                    d3.rgb(239, 160, 20), // <50%
                    d3.rgb(248, 170, 18), // <60%
                    d3.rgb(250, 234, 37), // <70%
                    d3.rgb(170, 201, 17), // <80%
                    d3.rgb(118, 184, 42), // <90%
                    d3.rgb(105, 180, 45)  // <100%
                  ];

                  var targetColor = targetColors[colorIndex];

                  return targetColor.darker(darkenBy);
                }

                function _colorTween(transition, styleProperty, targetColor) {
                  transition.styleTween(styleProperty, function () {
                    return d3.interpolateRgb(this.style[styleProperty], targetColor);
                  });
                }

                function _arcTween(transition, percentage) {
                  transition.attrTween('d', function (d) {
                    var newAngle    = ((percentage / 100) * (2 * Math.PI));
                    var interpolate = d3.interpolate(d.endAngle, newAngle);

                    return function (tick) {
                      d.endAngle = interpolate(tick);

                      // Get the percentage mid tween to so that the number counts up/down to target.
                      var midTransitionPercentage = Math.round((d.endAngle/ (2 * Math.PI)) * 100);

                      self.text.text(midTransitionPercentage + '%');

                      return self.arc(d);
                    };
                  });
                }

              }
            };
          }
        ]
      );
}(window.angular, window.d3, window._));
