; (function (angular, _) {

  'use strict';

  angular.module('kudosPublications')
    .factory('AuthorPerspectiveService', [
      '$http',
      '$q',
      'NotificationService',
      function ($http, $q, NotificationService) {

        var apiBaseUrl = '/author_perspectives';  // the PUT is handled in the web app, not API - due to CORS problemss

        var pub = {

          create: function (newPerspective) {
            return $http.post(apiBaseUrl, {author_perspective: newPerspective}).catch(handleCatch);
          },

          get: function (perspectiveId) {
            return $http.get(apiBaseUrl + '/' + perspectiveId);
          },

          update: function (perspective) {
            return $http.put(apiBaseUrl + '/' +  perspective.id, {author_perspective: perspective}).catch(handleCatch);
          },

          delete: function (perspectiveId) {
            return $http.delete(apiBaseUrl + '/' + perspectiveId);
          },

          setApiBaseUrl: function (newApiBaseUrl){
            apiBaseUrl = newApiBaseUrl;
          },

          getApiBaseUrl: function (){
            return apiBaseUrl;
          }
        };

        function handleCatch (response) {
          _.each(response.data.message, function (error) {
            NotificationService.error(error);
          });

          return $q.reject(response);
        }

        return pub;
    }
  ]);

}(window.angular, window._));
