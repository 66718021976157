; (function (angular, _) {
  "use strict";

  angular.module('kudosPublishers')
    .config([
      '$stateProvider',
      'publisherReports',
      function ($stateProvider, publisherReports) {

          $stateProvider
            .state('publisher', {
              url: '/publishers/{shortcode}',
              abstract: true,
              templateUrl: "kudosPublishers/publisher.html",
              controller: 'PublisherController as vm',
              resolve: {
                publisher: ['$state', '$stateParams', 'PublisherService', function ($state, $stateParams, PublisherService) {
                  return PublisherService.one($stateParams.shortcode).get()
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
                }]
              }
            })
            .state('publisher.dashboard', {
              url: '?feature',
              controller: 'PublisherDashboardController as vm',
              templateUrl: "kudosPublishers/publisher.dashboard.html",
              resolve: {
                latestTweets: ['$stateParams', '$state', 'PublisherService', function ($stateParams, $state, PublisherService) {
                  return PublisherService.one($stateParams.shortcode).one('tweets').get()
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
                }],
                categoryOverview: ['$state', '$stateParams', 'PublisherService', function ($state, $stateParams, PublisherService) {
                  return PublisherService.one($stateParams.shortcode).one('reports', 'category_overview').get()
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
                }],
                sharesByChannel: ['$state', '$stateParams', 'PublisherService', function ($state, $stateParams, PublisherService) {
                  return PublisherService.one($stateParams.shortcode).one('reports', 'share_channels_stats').get()
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
                }],
                recentlyExplainedPublications: ['$state', '$stateParams', 'PublisherService', function ($state, $stateParams, PublisherService) {
                  return PublisherService.one($stateParams.shortcode).one('reports/recently_explained_publications?limit=20').get()
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
                }],
                recentlyActiveAuthors: ['$state', '$stateParams', 'PublisherService', function ($state, $stateParams, PublisherService) {
                  return PublisherService.one($stateParams.shortcode).one('reports/recently_active_authors?limit=20').get()
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
                }],
                exampleExplainedPublications: ['$state', '$stateParams', 'PublisherService', function ($state, $stateParams, PublisherService) {
                  return PublisherService.one($stateParams.shortcode).one('reports', 'explained_publications_examples').get()
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
                }],
                favoriteReports: ['$state', '$stateParams', 'PublisherReportService', function ($state, $stateParams, PublisherReportService) {
                  return PublisherReportService
                    .getFavoritedReports($stateParams.shortcode)
                    .then(function(response) {
                      return _.map(response.data.data, function(value) {
                        return _.find(publisherReports, {name: value.report_name});
                      });
                    })
                    .catch(function (error) {
                      $state.go('error', {}, {errorCode: 500, errorMessage: error});
                    });
                }],
                shareActivityCountQuery: ['$state', '$stateParams', 'PublisherQueryService', function ($state, $stateParams, PublisherQueryService) {
                  return PublisherQueryService.getQuery($stateParams.shortcode, 1)
                    .then(function(response) {
                      return response.data.data.query;
                    })
                    .catch(function (error) {
                      $state.go('error', {}, {errorCode: 500, errorMessage: error});
                    });
                }],
                explainActivityCountQuery: ['$state', '$stateParams', 'PublisherQueryService', function ($state, $stateParams, PublisherQueryService) {
                  return PublisherQueryService.getQuery($stateParams.shortcode, 2)
                    .then(function(response) {
                      return response.data.data.query;
                    })
                    .catch(function (error) {
                      $state.go('error', {}, {errorCode: 500, errorMessage: error});
                    });
                }],
                registeredAuthorActivityCountQuery: ['$state', '$stateParams', 'PublisherQueryService', function ($state, $stateParams, PublisherQueryService) {
                  return PublisherQueryService.getQuery($stateParams.shortcode, 3)
                    .then(function(response) {
                      return response.data.data.query;
                    })
                    .catch(function (error) {
                      $state.go('error', {}, {errorCode: 500, errorMessage: error});
                    });
                }]
              }
            })

            .state('publisher.search', {
              url: '/search',
              controller: 'PublisherSearchController as search',
              templateUrl: "kudosPublishers/publisher.search.html"
            })
            .state('publisher.benchmarking', {
              url: '/benchmarking',
              templateUrl: "kudosPublishers/publisher.benchmarking.html"
            })
            .state('publisher.showcase', {
              url: '/showcase',
              templateUrl: "kudosPublishers/publisher.showcase.html"
            })
            .state('publisher.loading', {
              url: '/loading',
              templateUrl: "kudosPublishers/publisherLoading.html"
            })
            .state('publisher.branding', {
              url: '/branding',
              templateUrl: "kudosPublishers/publisher.branding.html"
            })
            .state('publisher.account', {
              url: '/account',
              templateUrl: "kudosPublishers/publisher.account.html",
              controller: 'PublisherAccountController as account',
              resolve: {
                publisherAdmins: ['$state', '$stateParams', 'PublisherService', function ($state, $stateParams, PublisherService) {
                  return PublisherService.one($stateParams.shortcode).one('admins').get()
                    .then(function (response) {
                      return response.admins;
                    })
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
                }]
              }
            })

            .state('publisher.reports', {
              url: '/reports',
              abstract: true,
              template: "<ui-view></ui-view>"
            })

            .state('publisher.reports.list', {
              url: '',
              templateUrl: "kudosPublishers/publisher.reports.list.html"
            });

            publisherReports.forEach(function (report) {
              $stateProvider.state('publisher/reports/' + report.name, {
                parent: 'publisher.reports',
                templateUrl: "kudosPublishers/publisher.reports.detail.html",
                controller: 'PublisherReportController',
                controllerAs: 'vm',
                url: '/' + report.name,
                resolve: {
                  reportMetadata: function () {
                    return report;
                  },
                  favoritedReport: ['$state', '$stateParams', 'PublisherReportService', 'reportMetadata', function($state, $stateParams, PublisherReportService, reportMetadata) {
                    return PublisherReportService.getFavoritedReport($stateParams.shortcode, reportMetadata.name)
                      .then(function (response) {
                        return response.data.data;
                      })
                      .catch(function () {
                        $state.go('500');
                      });
                  }]
                }
              });
            });
      }
    ]);

} (window.angular, window._));
