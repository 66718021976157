; (function (angular) {

  "use strict";

  angular.module('kudosPublishers')
    .controller(
    'PublisherSearchController',
    [
      'publisher',
      'PublisherService',
      'NotificationService',
      function (publisher, PublisherService, NotificationService) {
        var self = this;

        self.publisher = publisher;

        self.searchForm = {};
        self.searching = false;
        self.searchResults = false;

        self.submitSearch = function () {
          self.searching = true;
          PublisherService.searchPublisherUsers(publisher, self.searchForm.email, self.searchForm.name)
            .then(function (response) {
              self.searchResults = response.profiles;
            })
            .catch(function () {
              NotificationService.error('User search failed, please try again');
            })
            .finally(function () {
              self.searching = false;
            });
        };

        self.canSubmit = function () {
          return !!self.searchForm.email || !!self.searchForm.name;
        };

      }
    ]
  );

} (window.angular));


