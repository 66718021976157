(function (angular) {
  'use strict';

  angular.module('angulartics.kudos.analytics')
    .directive(
      'recordReadReferralEvent',
      [
        '$analytics',
        function ($analytics) {
          return {
            scope: {
              doi: '@'
            },
            link: function (scope, element) {
              element.on('click', function () {
                $analytics.eventTrack(
                  'Referral',
                  {
                    category: 'Article',
                    label: scope.doi
                  }
                );
              });
            }
          };
        }
      ]
    );


})(window.angular);

