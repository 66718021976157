; (function (angular, _) {
  'use strict';

  angular
    .module('kudos')
      .service(
        'extraInfoBoxService',
        [
        function () {
          var self = this;

          self.showExtraInfoBoxes = {};

          self.toggleExtraInfoBox = function (boxName) {
            _.forOwn(self.showExtraInfoBoxes, function (value, key) {
              if (key !== boxName) {
                self.showExtraInfoBoxes[key] = false;
              }
            });

            if (angular.isDefined(self.showExtraInfoBoxes[boxName])) {
              self.showExtraInfoBoxes[boxName] = !self.showExtraInfoBoxes[boxName];
            }
          };

          self.getExtraInfoBoxIndicatorClass = function (who) {
            var showing = !!self.showExtraInfoBoxes[who];

            return {
              'fa-angle-up': showing,
              'fa-angle-down': !showing
            };
          };
        }]
      );
}(window.angular, window._));
