; (function (angular, _) {
  'use strict';

  var module = angular.module('kudosRegistration');

  module.controller('KudosRegisterSubscriptionsController', [
    '$q',
    function (
      $q
    ) {
      this.valid = function (form, model) {
        if (!form || !model) {
          return false;
        }
        var modelValid = _.every(model, function (value) {
          return angular.isDefined(value);
        });
        return form.$valid && modelValid;
      };

      this.trackAndSubmit = function ($event, form, model) {
        if ($event.target.tagName !== 'FORM') {
          return;
        }
        if (!this.valid(form, model)) {
          return;
        }

        $event.preventDefault();

        // Only submit once all analytics requests are done.

        var submit = function () {
          $event.target.submit();
        };
        var track = this._ga;

        var submitAnalyticsPromises = _.map(model, function (value, key) {
          var deferred = $q.defer();
          // TODO: upgrade angulartics so it can be used with hitCallback.
          track('send', {
            hitType: 'event',
            eventAction: (value === true || value === 'true') ? 'opt-in' : 'opt-out',
            eventCategory: 'subscription ' + _.startCase(key).toLowerCase(),
            eventLabel: 'registration flow',
            hitCallback: deferred.resolve
          });
          return deferred.promise;
        });

        $q.all(submitAnalyticsPromises).then(submit);
      };

      this._ga = function () {
        if (window.ga) {
          window.ga.apply(null, arguments);
        } else if (arguments[1] && arguments[1].hitCallback) {
          // If GA is not available, we need to ensure any hitCallbacks are still
          // called to avoid the UI not responding
          arguments[1].hitCallback();
        }
      };
    }
  ]);

}(window.angular, window._));
