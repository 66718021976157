; (function (angular, _) {

  'use strict';

  angular.module('kudosOrganisations')
    .factory('OrganisationShowcaseService', [
      '$http',
      'NotificationService',
      function ($http, NotificationService) {
        return {
          addPublicationToShowcase: addPublicationToShowcase
        };

        function addPublicationToShowcase (shortCode, articleId, recommendation) {
          var payload = {
            article_id: articleId
          };

          if (angular.isDefined(recommendation)) {
            payload.triggering_recommendation = {id: recommendation.id}; // see internal_api/app.rb
          }

          return $http.post('/internal_api/organisations/' + shortCode + '/showcase', payload).catch(function (response) {
            _.each(response.data.errors, function (error) {
              NotificationService.error(error);
            });
          });
        }
      }
    ]
  );

}(window.angular, window._));


