; (function (angular) {
  'use strict';

  angular
    .module('kudos')
    .directive('progressButton', [
      function () {
        return {
          replace: true,
          templateUrl: 'kudos/progressButton.directive.html',
          scope: {},
          bindToController: {
            processState: '&',
            disabled: '&?',
            onClick: '=',
            buttonStates: '&?'
          },
          link: function (scope, element, attrs, controllers) {
            element.on('click', function (event) {
              event.preventDefault();

              scope.$apply(function () {
                if (controllers.currentState !== 'waiting') {
                  scope.button.onClick();
                }
              });
            });
          },
          controllerAs: 'button',
          controller: [
            '$scope',
            function ($scope) {

              var self = this;

              // Button state property definitions
              var ButtonStates = getButtonStates();

              self.currentState = ButtonStates.init.name;

              self.getButtonText = getButtonText;
              self.getButtonClass = getButtonClass;
              self.getButtonIconClass = getButtonIconClass;
              self.getButtonDisabled = getButtonDisabled;

              self.$onInit = function() {
                // Apply override to button states when/if available
                $scope.$watch(
                  function () {
                    return angular.isFunction(self.buttonStates);
                  },
                  function (buttonStatesDefined) {
                    if (buttonStatesDefined) {
                      ButtonStates = getButtonStates();
                    }
                  }
                );

                // Watch for changes in processState element attribute
                $scope.$watch(
                  function () {
                    return self.processState();
                  },
                  function (newState, oldState) {
                    if (newState !== oldState) {
                      changeCurrentState (newState);
                    }
                  }
                );

                // Initialise initial state
                changeCurrentState(self.processState());
              };


              function changeCurrentState (newState) {
                if (angular.isUndefined(newState)) {
                  newState = 'initial';
                }

                self.currentState = newState;
              }

              function getButtonText () {
                return ButtonStates[self.currentState].buttonText;
              }

              function getButtonIconClass () {
                return ButtonStates[self.currentState].buttonIconClass;
              }

              function getButtonClass () {
                return ButtonStates[self.currentState].buttonClass;
              }

              function getButtonDisabled () {
                return !!self.disabled && self.disabled();
              }

              function getButtonStates () {
                return angular.merge({}, getBaseButtonStates(), getOverridenButtonStates());
              }

              function getOverridenButtonStates () {
                return (angular.isFunction(self.buttonStates) ? self.buttonStates() : {});
              }

              function getBaseButtonStates () {
                return {
                  init: {
                    name: 'init',
                    buttonText: 'Save',
                    buttonClass: 'init'
                  },
                  waiting: {
                    name: 'waiting',
                    buttonText: 'Saving',
                    buttonIconClass: 'fa-circle-o-notch fa-spin',
                    buttonClass: 'waiting'
                  },
                  success: {
                    name: 'success',
                    buttonText: 'Saved',
                    buttonIconClass: 'fa-thumbs-o-up throb-in slow',
                    buttonClass: 'success'
                  },
                  error: {
                    name: 'error',
                    buttonText: 'Error',
                    buttonIconClass: 'fa-exclamation-circle throb-in slow',
                    buttonClass: 'error'
                  }
                };
              }
            }
          ]
      };
    }
  ]);

}(window.angular));
