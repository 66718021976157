;(function (angular) {
  "use strict";

  // TODO: move this to the server side so we can do a lookup once.
  // All attributes except `compileGridOptions` should be copied verbatim from
  // `/internal_api/publishers/PUB_SHORT_CODE/reports/` until that happens.
  // This is only used for the headers; no data is shown.
  angular.module('kudosPublishers').constant('publisherReports', [
    {
      name: 'all_activities_by_date',
      visible: true,
      type: 'activity_report',
      title: 'Activity report: all activities by date (explain and share)',
      description: 'This report lists all activities undertaken on Kudos by authors that indicated that they have published with {{ publisher.name }} to help increase the impact of their publications. This included adding or editing a short title, the "What\'s it about?" text, the "Why is it important?" text, an Author Perspective, a Resource or by sharing through Twitter, Facebook, LinkedIn, Weibo, WeChat or email/online.',
      columns: ['Date', 'Activity', 'Author', 'Publication title', 'Journal title', 'DOI of publication']
    },
    {
      name: 'all_explained_activities',
      visible: true,
      type: 'activity_report',
      title: 'Activity report: all explained activities (excluding adding resources) by date',
      description: 'This report lists all explain activities undertaken on Kudos by authors that indicated that they have published with {{ publisher.name }} to help increase the impact of their publications. This included adding or editing a short title, the "What\'s it about?" text, the "Why is it important?" text or an Author Perspective.',
      columns: ['Date', 'Activity', 'Author', 'Publication title', 'DOI of publication']
    },
    {
      name: 'all_resource_activities_by_date',
      visible: true,
      type: 'activity_report',
      title: 'Activity report: all resource activities by date',
      description: 'This report lists all resource activities undertaken on Kudos by authors that indicated that they have published with {{ publisher.name }} to help increase the impact of their publications. This includes both adding or editing a Resource.',
      columns: ['Date', 'Activity', 'Author', 'Publication title', 'DOI of publication']
    },
    {
      name: 'all_share_activities_by_date',
      visible: true,
      type: 'activity_report',
      title: 'Activity report: all share activities by date',
      description: 'This report lists all share undertaken on Kudos by authors that indicated that they have published with {{ publisher.name }} to help increase the impact of their publications. This included sharing through Twitter, Facebook, LinkedIn, Weibo, WeChat or email/online.',
      columns: ['Date', 'Activity', 'Author', 'Publication title','Journal title', 'DOI of publication']
    },
    {
      name: 'all_tweets_by_date',
      visible: false,
      type: 'activity_report',
      title: 'Activity report: all tweets by date',
      description: 'This report lists all tweets sent from Kudos by authors that indicated that they have published with {{ publisher.name }} to help increase the impact of their publications.',
      columns: ['', '', '', '', '']
    },
    {
      name: 'publication_page_views_by_date',
      visible: false,
      type: 'performance_report',
      title: 'Performance report: publication page views',
      description: 'This report shows the number of views of publications by date and the cumulative number of views of publications on Kudos for publications where one of more author has indicated that they have published with {{ publisher.name }} ',
      columns: ['Date', 'Number of views of the publication page on this date', 'Cumulative number of views of the publication page by this date']
    },
    {
      name: 'detailed_explain_activity_report',
      visible: true,
      type: 'publication_report',
      title: 'Publication report: detailed explain activity report',
      description: 'This report is a detailed list of all publications on Kudos where at least one author has indicated that they have published with {{ publisher.name }} and that have been explained by one or more of the authors. This includes adding or editing a Short Title, adding or editing text explaining "What\'s it about?", "Why is it important?" or the Author Perspective or adding a Resource.',
      columns: ['Publication Date', 'Most Recent Explain Date', 'Publication Title', 'Journal Title', 'Short Title?', 'What\'s it about?', 'Why is it important?', 'Number of perspectives', 'Number of resources']
    },
    {
      name: 'most_viewed',
      visible: true,
      type: 'performance_report',
      title: 'Performance report: publication page - most viewed',
      description: 'This report shows the publication page with the highest number of views on Kudos for publications where one of more author has indicated that they are from {{ publisher.name }}.',
      columns: ['Rank','Number of views of the publication page', 'Publication title', 'DOI']
    },
    {
      name: 'publisher_showcase_total_views_by_date',
      visible: false,
      type: 'performance_report',
      title: 'Performance report: Publisher showcase page - total views by date',
      description: 'This report shows the number of view of the Publisher Showcase page for {{ publisher.name }}.',
      columns: []
    },
    {
      name: 'publisher_showcase_cumulative_views_by_date',
      visible: false,
      type: 'performance_report',
      title: 'Performance report: Publisher showcase page - cumulative views by date',
      description: 'This report shows the cumulative number of view of the Publisher Showcase page by date on Kudos for publications where one of more author has indicated that they are from {{ publisher.name }}.',
      columns: []
    },
    {
      name: 'share_referrals_total_by_date',
      visible: false,
      type: 'performance_report',
      title: 'Performance report: share referrals - total by date',
      description: 'This report shows the number of share referrals by date to publications when one or more author has indicated that they were from {{ publisher.name }}. Share referrals are clicks on coded links in tweets, Facebook post, emails or online resulting from authors using the sharing tools on Kudos.',
      columns: ['Date', 'Total number of share referrals on this date', 'Total cumulative number of share referrals on this date']
    },
    {
      name: 'share_referrals_highest',
      visible: true,
      type: 'performance_report',
      title: 'Performance report: shares resulting in highest share referrals',
      description: 'This report shows the shares that resulted in the highest number of share referrals for publications where one of more author has indicated that they are from {{ publisher.name }}.',
      columns: ['Rank', 'Number of Share Referrals', 'Type of share', 'Publication title', 'Journal title', 'DOI']
    },
    {
      name: 'doi_click_throughs_cumulative_by_date',
      visible: false,
      type: 'performance_report',
      title: 'Performance report: DOI click-throughs - cumulative by date',
      description: 'This report shows the cumulative number of DOI click-throughs by date to publications when one or more author has indicated that they were from {{ publisher.name }}. DOI click-throughs are where a reader has click through from Kudos or from a shared link to read the publication.',
      columns: ['Date', 'Cumulative number of DOI click-throughs by this date']
    },
    {
      name: 'doi_click_throughs_total_by_date',
      visible: false,
      type: 'performance_report',
      title: 'Performance report: DOI click-throughs - total by date',
      description: 'This report shows the total number of DOI click-throughs by date to publications when one or more author has indicated that they were from {{ publisher.name }}. DOI click-throughs are where a reader has click through from Kudos or from a shared link to read the publication.',
      columns: ['Date', 'Number of DOI click-throughs on this date']
    },
    {
      name: 'altmetric_scores_top_all_publications',
      visible: true,
      type: 'performance_report',
      title: 'Performance report: top altmetric scores (all publications)',
      description: 'This report lists the publications from {{ publisher.name }} on Kudos that have the highest current altmetric scores. The Top 100 are shown and altmetric data is provided by <a href="https://www.altmetric.com" target="_blank">Altmetric.com</a>',
      columns: ['Rank', 'Current Altmetric score', 'Publication title', 'Kudos detailed metrics page', 'Altmetrics detailed metrics page', 'DOI']
    },
    {
      name: 'altmetric_scores_top_explained_publications',
      visible: true,
      type: 'performance_report',
      title: 'Performance report: top altmetric scores (explained publications)',
      description: 'This report lists the explained publications from {{ publisher.name }} on Kudos that have the highest current altmetric scores. The Top 100 are shown and altmetric data is provided by <a href="https://www.altmetric.com" target="_blank">Altmetric.com</a>',
      columns: ['Rank', 'Current Altmetric score', 'Publication title', 'Kudos detailed metrics page', 'Altmetrics detailed metrics page', 'DOI']
    },
    {
      name: 'altmetric_scores_climbing_all_publications',
      visible: true,
      type: 'performance_report',
      title: 'Performance report: highest climbing altmetric scores (all publications)',
      description: 'This report lists the publications from {{ publisher.name }} on Kudos that have the highest recent growth altmetric scores. The Top 100 are shown and altmetric data is provided by <a href="https://www.altmetric.com" target="_blank">Altmetric.com</a>',
      columns: ['Rank', '% increase over the last 7 days', 'Current Altmetric score', 'Altmetric score 7 days ago', 'Publication title', 'Kudos detailed metrics page', 'Altmetrics detailed metrics page', 'DOI']
    },
    {
      name: 'altmetric_scores_climbing_explained_publications',
      visible: true,
      type: 'performance_report',
      title: 'Performance report: highest climbing altmetric scores (explained publications)',
      description: 'This report lists the explained publications from {{ publisher.name }} on Kudos that have the highest recent growth altmetric scores. The Top 100 are shown and altmetric data is provided by <a href="https://www.altmetric.com" target="_blank">Altmetric.com</a>',
      columns: ['Rank','% increase over the last 7 days', 'Current Altmetric score', 'Altmetric score 7 days ago', 'Publication title', 'Kudos detailed metrics page', 'Altmetrics detailed metrics page', 'DOI'
  ]
    },
    {
      name: 'all_publications',
      visible: false,
      type: 'publication_report',
      title: 'Publications report: all publications on Kudos',
      description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ publisher.name }}.',
      columns: ['Publication Date', 'Publication Title', 'DOI']
    },
    {
      name: 'all_publications_explained',
      visible: true,
      type: 'publication_report',
      title: 'Publications report: all publications on Kudos that have been explained',
      description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ publisher.name }} and that have been explained by one or more of the authors. This means that the publication has at least one of the following: a Short Title, text explaining "What\'s it about?", text explaining "Why is it important?", an Author Perspective or a Resource.',
      columns: ['Publication Date', 'Publication Title', 'DOI']
    },
    {
      name: 'all_publications_shared',
      visible: true,
      type: 'publication_report',
      title: 'Publications report: all publications on Kudos that have been shared',
      description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ publisher.name }} and the publication has been shared by one or more of the authors. Shares means that one of more of the authors has used Kudos to send a tweet, add a Facebook post, send an email or post online. When the author shares, a coded link is included in the share which allows readers to link to the publication.',
      columns: ['Publication Date', 'Publication Title', 'DOI']
    },
    {
      name: 'all_publications_with_resources',
      visible: true,
      type: 'publication_report',
      title: 'Publications report: all publications on Kudos that have one or more resource',
      description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{publisher.name}} and have added one or more resource links. This includes links to data sets, videos, presentations or news stories.',
      columns: ['Publication Date', 'Publication Title', 'DOI']
    },
    {
      name: 'all_resources_linked_to_publications',
      visible: true,
      type: 'publication_report',
      title: 'Publications report: all resources linked to publications',
      description: 'This report lists all resources linked to publications on Kudos where at least one author has indicated that they are from {{ publisher.name }. A resource includes data sets, videos, presentations or news stories.',
      columns: ['Publication Date', 'Publication Title', 'Resource Title', 'Resource Description', 'Resource Type', 'Resource Link']
    },
    {
      name: 'recently_explained_publications',
      visible: true,
      type: 'publication_report',
      title: 'Publication report: recently explained publications',
      description: 'This report lists publications on Kudos where at least one author has indicated that they have published with  {{ publisher.name }} and that have recently been explained by one or more of the authors. This means that the publication has recently had at least one of the following added or edited: a Short Title, text explaining "What\'s it about?", text explaining "Why is it important?", an Author Perspective or a Resource.',
      columns: ['Date', 'Time', 'Publication title', 'DOI']
    },
    {
      name: 'key_metrics_by_journal',
      visible: true,
      type: 'publication_report',
      title: 'Publications report: key metrics by journal',
      description: 'This report lists all journals on Kudos that have been published by {{publisher.name}}. Key metrics are shown for each journal.',
      columns: ['Journal', 'ISSNs', 'Publications on Kudos', 'Publications linked to a registered user', 'Publications explained', 'Publications shared', 'Kudos views', 'Clicks on shared links', 'Clicks from Kudos to the DOI']
    },
    {
      name: 'all_authors',
      type: 'author_report',
      title: 'Author report: export of all registered users',
      description: 'This report lists all authors using Kudos who have published with {{ publisher.name }}. It includes their name, email address, self-selected subject area and self-selected career stage. Please note that you are bound by Kudos\' terms and conditions regarding the use of this personal data.',
      columns: ['Registration date', 'User\'s name', 'User\'s email address', 'Subject area', 'Career stage', 'Country']
    },
    {
      name: 'authors_by_country',
      type: 'author_report',
      title: 'Author report: breakdown by geographic location',
      description: 'This report lists all authors using Kudos who have published with {{ publisher.name }}. It shows the distribution of countries where the authors are primarily based. These countries are selected by the author when they register with Kudos. This field is optional, so some authors may not have made a selection.',
      columns: ['Country', 'Number of Authors']
    },
    {
      name: 'authors_claims',
      type: 'author_report',
      title: 'Author report: breakdown by number of publications',
      description: 'This report lists all authors using Kudos who have published with {{ publisher.name }}. It shows the distribution of the number of publications each author has claimed. A author claims a publication to indicate that they are an author of that publication. Some authors will have claimed multiple publications.',
      columns: ['Number of publications claimed', 'Number of Authors who have claimed this number of publications' ]
    },
    {
      name: 'authors_by_career_stage',
      type: 'author_report',
      title: 'Author report: breakdown by career stage',
      description: 'This report lists all authors using Kudos who have published with {{ publisher.name }}. It shows the distribution of authors across career stages. These career stages are selected by the author when they register with Kudos. This field is optional, so some authors may not have made a selection.',
      columns: ['Career stage', 'Number of Authors']
    },
    {
      name: 'authors_by_subject',
      type: 'author_report',
      title: 'Author report: breakdown by subject area',
      description: 'This report lists all authors using Kudos who have published with {{ publisher.name }}. It shows the distribution of authors across subject areas. These subject areas are selected by the author when they register with Kudos. This field is optional, so some authors may not have made a selection.',
      columns: ['Subject area', 'Number of Authors']
    },
    {
      name: 'authors_most_publications',
      type: 'author_report',
      title: 'Author report: authors with the most publications',
      description: 'This report lists authors using Kudos who have published with {{ publisher.name }}. It ranks authors to show which authors have the most {{ publisher.name }} publications claimed in Kudos.',
      columns: ['Rank', 'Number of claimed publications', 'Author\'s name', 'Author\'s email']
    },
    {
      name: 'authors_most_explained_publications',
      type: 'author_report',
      title: 'Author report: authors who have explained the most publications',
      description: 'This report lists authors using Kudos who have published with {{ publisher.name }}. It ranks authors to show which authors have explained the most {{ publisher.name }} publications in Kudos.',
      columns: ['Rank', 'Number of explained publications', 'Author\'s name', 'Author\'s email']
    },
    {
      name: 'authors_most_shared_publications',
      type: 'author_report',
      title: 'Author report: authors who have shared the most',
      description: 'This report lists authors using Kudos who have published with {{ publisher.name }}. It ranks authors to show which authors have shared the most {{ publisher.name }} publications in Kudos. It shows the number of different publications shared at least once.',
      columns: ['Rank', 'Number of different shared publications', 'Author\'s name', 'Author\'s email']
    },
    {
      name: 'authors_most_share_referrals',
      type: 'author_report',
      title: 'Author report: authors who have had the highest share referrals',
      description: 'This report lists authors using Kudos who have published with {{ publisher.name }}.  It ranks authors to show which authors have had the highest total number of share referrals for {{ publisher.name }} publications in Kudos.',
      columns: ['Total number of share referrals', 'Author\'s name', 'Author\'s email']
    },
    {
      name: 'recently_active_authors',
      type: 'author_report',
      title: 'Author report: recently active authors',
      description: 'This report lists authors using Kudos that have published with {{ publisher.name }} and that been recently active on Kudos.',
      columns: ['Date of Activity', 'Time of Activity', 'Author\'s name']
    }
  ]);
}(window.angular));
