; (function (angular) {
  'use strict';

  angular.module( 'kudosPageTracker')
    .factory(
      'PageTrackerService',
      [
        '$http',
        function ($http) {
          return {
            trackPageView: function (url) {
              return $http.post(
                '/internal_api/page_trackers/track',
                {
                  url: url
                }
              );
            }
          };
        }
      ]
    );

}(window.angular));

