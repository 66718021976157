(function (angular) {
  'use strict';

  angular.module('angulartics.kudos.analytics')
    .directive(
      'eventTrack',
      [
        '$analytics',
        function ($analytics) {
          return {
            link: function (scope, element, attrs) {
              if (!!attrs.eventTrack && !!attrs.category && !!attrs.label) {
                addClickListener();
              }

              function addClickListener () {
                element.on('click', function () {
                  $analytics.eventTrack(
                    attrs.eventTrack,
                    {
                      category: attrs.category,
                      label: attrs.label
                    }
                  );
                });
              }
            }
          };
        }
      ]
    );
})(window.angular);
