(function (angular) {
  'use strict';

  angular.module('kudosHub.featuredPublication').component('kudosHubFeaturedPublication', {
    templateUrl: 'kudosHub/featuredPublication/kudosHubFeaturedPublication.component.html',
    bindings: {
      doi: '@',
      featured: '@'
    },
    controllerAs: 'vm',
    controller: [
      '$q',
      '$http',
      'NotificationService',
      'PublicationService',
      function (
        $q,
        $http,
        NotificationService,
        PublicationService
      ) {
        var self = this;

        var labelSelected = 'Featured publication: select to remove from your featured publications';
        var labelUnselected = 'Not a featured publication: select to add to your featured publications';

        self.$onInit = function () {
          self._setState(self.featured === 'true', false);
        };

        self._setState = function (isFeatured, isWaiting) {
          self.isFeatured = isFeatured === true;
          self.isWaiting = isWaiting === true;
          updateState(isFeatured, isWaiting);
        };

        self.setStateWaiting = function (isFeatured) {
          self._setState(isFeatured, true);
        };
        self.setStateDone = function (isFeatured) {
          self._setState(isFeatured, false);
        };

        self.onClick = function () {
          if (self.isWaiting) {
            return;
          }

          var oldValue = self.isFeatured;
          var newValue = !oldValue;
          self.setStateWaiting(newValue);
          self.updatePublication(self.doi, newValue)
            .then(function (added) {
              if (added) {
                NotificationService.success('Publication added to featured publications. Go to your Public Profile to see this change.');
              } else {
                NotificationService.success('Publication removed from featured publications list.');
              }

              self.setStateDone(newValue);
            })
            .catch(function () {
              // TODO: monitoring, we should log the rejection reason
              NotificationService.error('Unable to change publication. Please try again or contact support.');

              self.setStateDone(oldValue);
            });
        };

        self.updatePublication = function (doi, submitValue) {
          if (typeof submitValue !== 'boolean') {
            return $q.reject('Value must be a boolean, but is: ' + JSON.stringify(submitValue));
          }

          var url = '/internal_api/publications_featured/' + PublicationService.encodeDOI(doi);
          if (submitValue) {
            return $http.put(url).then(function () {
              return true;
            });
          }
          return $http.delete(url).then(function () {
            return false;
          });
        };

        function updateState(isFeatured, isWaiting) {
          self.classname = isFeatured ? 'selected' : 'unselected';
          if (isWaiting) {
            self.classname += ' waiting';
          }
          self.iconClassname = isFeatured ? 'fa fa-star' : 'fa fa-star-o';
          self.label = isFeatured ? labelSelected : labelUnselected;
        }
      }
    ]
  });

}(window.angular));
