; (function (angular) {
  'use strict';
  angular.module('kudosPublications')
    .factory('InfographicService', [
      '$http',
      function ($http) {

        function buildUrl(doi) {
          var apiBaseUrl =  '/internal_api/publications';
          var resource = 'infographic';
          //  Can't use URL constructor here as not supported by PhantomJS
          var url = [apiBaseUrl, encodeURIComponent(doi), resource].join('/');
          return encodeURI(url);
        }

        function buildErrorResponse(response, action) {
          var error;

          switch (response.status) {
            case 401:
              error = 'unauthenticated';
              break;
            case 400:
              if (response.data && response.data.errors && response.data.errors.length > 0) {
                error = response.data.errors[0];
                break;
              }
              error = 'Unable to ' + action + ' infographic, please try again';
              break;
            default:
              error = 'Unable to ' + action + ' infographic, please try again';
          }

          return new Error(error, { cause : new Error('Got ' + response.status + ' from infographic API')});
        }

        return {
          delete: function(doi) {
            return $http.delete(buildUrl(doi)).catch(function(response) {
              throw buildErrorResponse(response, 'delete');
            });
          },
          update: function(doi, infographicFile) {
            var formData = new FormData();
            formData.append('file', infographicFile);

            return $http.put(
              buildUrl(doi),
              formData,
              // Required to ensure the XHR API sets the content-type
              // header with the boundary present
              { headers: { 'Content-Type': undefined } }
            ).then(function (response) {
              return response.data.data;
            }).catch(function(response){
              throw buildErrorResponse(response, 'update');
            });
          }
        };
      }
    ]);

  }(window.angular, window._));
