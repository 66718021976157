; (function (angular) {
  'use strict';

  angular
    .module('kudos')
    .directive('kudosEditableText', function() {
      return {
        scope: {
          ngModel: '=',
          isEditable: '=',
          onEdit: '&',
          onAfterSave: '&',
          editing: '=',
          formPlaceholder: '@',
          pristineText: '@',
          truncateAt: '@'
        },
        templateUrl: 'kudos/directives/kudosEditableText.html',
        replace: true,
        link: function (scope) {

          scope.cancel = function () {
            scope.editing = false;
          };

          scope.show = function () {
            scope.editing = true;
          };

          scope.$watch('editing', function (newValue) {
            if (newValue !== undefined) {
              if (newValue) {
                scope.editableThing.$show();
              } else {
                scope.editableThing.$hide();
              }
            }
          });
        }
      };
    });
}(window.angular));

