; (function (angular) {
  'use strict';

  angular
  .module('kudos')
  .directive('styleFormValidation', [
    '$rootScope',
    function () {
      return {
        restrict: 'A',
        link: function (scope, element) {
          /* If child input element adds/removes HTML5 :invalid
           * pseudo form validation element, add or remove .invalid
           * class to container so that the error state can be shared.
           */
          element.on('keyup paste', changeHandler);

          function changeHandler () {
            if (!!element.find('input:invalid').length) {
              element.addClass('invalid');
            } else {
              element.removeClass('invalid');
            }
          }

          changeHandler();
        }
      };
    }
  ]);

}(window.angular));

