; (function (angular, google) {
  'use strict';

  angular
    .module('kudosCharts')
      .service(
        'googleChartService',
        [
          '$window',
          googleChartFactory
        ]
      );

  function googleChartFactory ($window) {

    function googleChartService () {
      var self = this;

      self.loadingChartData   = false;

      self.element            = false;
      self.chartOptions       = {};
      self.getChartDataFn     = false;

      self.init               = init;
      self.getChartData       = getChartData;
      self.initChart          = initChart;
      self.updateChart        = updateChart;
      self.resizeChart        = resizeChart;
      self.getElement         = getElement;
      self.buildChart         = buildChart;
      self.drawChart          = drawChart;
      self.buildChartInstance = buildChartInstance;
      self.buildChartData     = buildChartData;
      self.getChartOptions    = getChartOptions;
      self.loadChartPackage   = loadChartPackage;

      function init (element, chartOptions, getChartDataFn) {
        self.element        = element;
        self.chartOptions   = chartOptions;
        self.getChartDataFn = getChartDataFn;

        loadChartPackage(initChart);
      }

      function getChartData () {
        if (!self.getChartDataFn) { return; }

        return self.getChartDataFn();
      }

      function initChart () {
        getChartData()
          .then(function (response) {
            self.currentBuiltChart = buildChart(getElement(), response.data);

            drawChart(self.currentBuiltChart);
          })
          .then(function () {
            // Redraw reized chart on window resize event
            angular.element($window).bind('resize', resizeChart);
          });
      }

      function updateChart () {
        self.loadingChartData = true;

        if (!getChartData()) { return; }

        getChartData()
          .then(function (response) {
            self.currentBuiltChart = buildChart(getElement(), response.data);
            drawChart(self.currentBuiltChart);
          })
          .finally(function () {
            self.loadingChartData = false;
          });
      }

      function resizeChart () {
        self.currentBuiltChart.options = getChartOptions();

        drawChart(self.currentBuiltChart);
      }

      function getElement () {
        return self.element;
      }

      function buildChart (element, statistics) {
        return {
          instance: buildChartInstance(element),
          data:     buildChartData(statistics),
          options:  getChartOptions()
        };
      }

      function drawChart (googleChart) {
        return googleChart.instance.draw(googleChart.data, googleChart.options);
      }

      function buildChartInstance (element) {
        return new google.visualization.LineChart(element);
      }

      function buildChartData (statistics) {
        return new google.visualization.DataTable(statistics);
      }

      function getChartOptions () {
        return self.chartOptions;
      }

      function loadChartPackage (callback) {
        google.charts.load('current', { packages: ['corechart'] });

        google.charts.setOnLoadCallback(callback);
      }
    }

    return googleChartService;

  }

}(window.angular, window.google));
