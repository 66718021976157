; (function (angular) {

  'use strict';

  angular.module('kudosCharts')
    .directive(
      'kudosPublicationActivityChart',
      [
        '$http',
        '$timeout',
        'googleChartService',
        function ($http, $timeout, googleChartService) {
          return {
            scope: {
              publicationId: '@'
            },
            templateUrl: 'kudosCharts/kudosPublicationActivity.directive.html',
            link: function (scope, element) {
              scope._googleChartService = new googleChartService();

              scope.dateRangeForm = {
                options: [
                  { value: '30',  label: '30 days'  },
                  { value: '90',  label: '90 days'  },
                  { value: '180', label: '180 days' },
                  { value: 'all', label: 'All time' },
                ],
                selectOption:   selectDateRangeFormOption,
                getOptionClass: getDateRangeFormOptionClass,
                getFormLabel:   getFormLabel,
                selected:       '30'
              };

              scope.$watch('dateRangeForm.selected', handleDateRangeFormSelected);

              $timeout(init, 0);

              function init () {
                scope._googleChartService.init(getElement(), getChartOptions(), getStatistics);
              }

              function handleDateRangeFormSelected (newValue, oldValue) {
                if (angular.isUndefined(newValue))  { return; }
                if (newValue === oldValue)          { return; }

                scope._googleChartService.updateChart();
              }

              function selectDateRangeFormOption (option) {
                scope.dateRangeForm.selected = option.value;
              }

              function getFormLabel () {
                var verb = 'Show';

                if (scope._googleChartService.loadingChartData) {
                  verb = 'Loading';
                }

                return verb + ' data for last:';
              }

              function getDateRangeFormOptionClass (option) {
                if (option.value === scope.dateRangeForm.selected) {
                  return 'btn-primary';
                }

                return 'btn-default';
              }

              function getElement () {
                return element.find('#chart-' + scope.publicationId)[0];
              }

              function getChartOptions () {
                return {
                  height: '400',
                  width: angular.element(getElement()).width(),
                  chartArea: {
                    top: 20,
                    height: '65%',
                    width: '85%'
                  },
                  colors: [
                    '#f0733a',
                    '#7f9818',
                    '#3486be',
                    '#8234be'
                  ],
                  legend: {
                    position: 'none'
                  },
                  hAxis: {
                    slantedText: true,
                    slantedTextAngle: 45
                  },
                  vAxis: {
                    viewWindow: {
                      min: 0
                    }
                  },
                  tooltip: {
                    isHtml: true
                  }
                };
              }

              function getStatistics () {
                var id        = scope.publicationId;
                var dateRange = scope.dateRangeForm.selected;

                return $http.get('/article_metrics/statistics/' + id + '?date_range=' + dateRange);
              }

            }
          };
        }
      ]
    );

}(window.angular));
