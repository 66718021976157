; (function (angular, _) {
  'use strict';

  angular.module('kudosPublications')
    .controller(
      'PublicationExplainFormController',
      [
        'AuthorPerspectiveService',
        'PublicationService',
        'SessionService',
        'kudosFormService',
        '$rootScope',
        '$timeout',
        function (AuthorPerspectiveService, PublicationService, SessionService, kudosFormService, $rootScope, $timeout) {
          var self = this;

          self.saveButtonState = 'init';

          // Once listens for form save success/failure and changes button state appropriately
          $rootScope.$on(kudosFormService.FormSaveStateEventName, function (event, data) {
            changeSaveButtonState(data);
          });

          self.formService         = kudosFormService;
          self.initController      = initController;
          self.getUpdateFunction   = getUpdateFunction;
          self.getCharacterCount   = getCharacterCount;
          self.hasEnoughCharacters = hasEnoughCharacters;
          // minCharacterLength is shown on the character count partial, as well as being used to check the number
          // of characters against, so it only needs to be changed in one place.
          self.minCharacterLength  = 250;

          function getCharacterCount(text) {
            if(text) {
              return text.trim().length;
            }

            return 0;
          }

          function hasEnoughCharacters(text) {
            return getCharacterCount(text) >= self.minCharacterLength;
          }

          // Resets the save button state to default
          function resetSaveButtonState () {
            changeSaveButtonState('init');
          }

          // Sets the button state to a specified state
          function changeSaveButtonState (newState) {
            self.saveButtonState = newState;

            // If the state is success, this resets it after 3 seconds
            if (newState === 'success') {
              $timeout(resetSaveButtonState, 3000);
            }
          }

          // Descide whether to create, update, or delete an author perspective.
          function delegatePerspectiveRequest (fieldName, newValue) {
            var currentUserId   = SessionService.currentUser().id;
            var publicationDOI  = self.publication.id;

            // Build a payload for create/update functions.
            var payload = buildPerspectivePayload(currentUserId, publicationDOI, fieldName, newValue);

            // If perspective has no ID, then its new and needs to be created.
            if (_.isUndefined(self.perspective.id)) {
              return perspectiveCreateRequest(payload);
            }

            // If perspective text is blank, then it should be deleted.
            if (_.trim(newValue) === '') {
              return perspectiveDeleteRequest(self.perspective.id);
            }

            // At this point, assume that it is present and will be updated.
            return perspectiveUpdateRequest(payload);
          }

          // Create a payload for create/update author perspective request
          function buildPerspectivePayload (currentUserId, publicationDOI, fieldName, newValue) {
            var basePayload = {};

            basePayload.account_id      = currentUserId;
            basePayload.publication_doi = publicationDOI;

            basePayload[fieldName]      = newValue;

            return _.merge(self.perspective, basePayload);
          }

          // Update author perspective with provided payload
          function perspectiveUpdateRequest (payload) {
            return AuthorPerspectiveService.update(payload).then(updatePerspectiveFromResponse);
          }

          // Create author perspective with provided payload
          function perspectiveCreateRequest (payload) {
            return AuthorPerspectiveService.create(payload).then(updatePerspectiveFromResponse);
          }

          // Delete author perspective with perspective id
          function perspectiveDeleteRequest (perspectiveId) {
            return AuthorPerspectiveService.delete(perspectiveId).then(removePerspective);
          }

          // From create/update request, update locally stored author perspective
          function updatePerspectiveFromResponse (response) {
            self.perspective = response.data.author_perspective;
          }

          // From delete request, delete locally stored author perspective
          function removePerspective () {
            self.perspective = {};
          }

          // Updates the publication with the provided field and value
          function publicationUpdate (fieldName, newValue) {
            return PublicationService.updatePublicationField(self.publication, fieldName, newValue);
          }

          // Delegate between making a publication request or
          // an author perspective request.
          function getUpdateFunction (fieldName, newValue) {
            if (fieldName !== 'perspective') {
              return publicationUpdate(fieldName, newValue);
            }

            return delegatePerspectiveRequest(fieldName, newValue);
          }

          // Create a form model comprising of both publication and perspective
          // for the form service to be modelled around.
          function buildFormServiceModel (publication, perspective) {
            return _.merge({}, publication, perspective);
          }

          // Build form service config object
          function buildFormServiceConfig (publication, perspective) {
            return {
              model:                buildFormServiceModel(publication, perspective),
              updateModelFunction:  getUpdateFunction
            };
          }

          // Boot explain form controller
          function initController (publication, perspective) {
            var formServiceConfig = buildFormServiceConfig(publication, perspective);

            self.publication      = publication;
            self.perspective      = perspective;

            self.formService.init(formServiceConfig);
          }
        }
      ]
    );
})(window.angular, window._);
