; (function (angular) {
  'use strict';

  angular.module('kudosPublications')
    .controller(
      'PublicationAuthorPageController',
      [
        '$rootScope',
        '$q',
        'PublicationService',
        'kudosFormService',
        function ($rootScope, $q, PublicationService, kudosFormService) {
          var self = this;

          $rootScope.$on('publicationResourcesUpdated', updateModels);
          $rootScope.$on(kudosFormService.FormSaveStateEventName, function (event, data) {
            if (data === 'success' && self.updating === false) {
              updateModels();
            }
          });

          self.initController         = initController;
          self.updateModels           = updateModels;
          self.getModel               = getModel;
          self.updating               = false;
          self.flashRecommendedAction = flashRecommendedAction;

          self.modelStore = {};

          function initController (models) {
            self.modelStore = models;
          }

          function getModel (modelName) {
            return self.modelStore[modelName];
          }

          function updateModels () {
            self.updating = true;

            updatePublication();
          }

          function updatePublication () {
            return PublicationService.getPublication(self.modelStore.publication.id)
              .then(
                function (response) {
                  self.modelStore.publication = response.data.data.article;
                  self.updating = false;
                },
                function () {
                  self.updating = false;
                }
              );
          }

          function flashRecommendedAction() {
            return PublicationService.flashRecommendedAction;
          }
        }
      ]
    );
})(window.angular);
