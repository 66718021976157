(function(angular) {
  'use strict';

  angular
    .module("showcase")
    .controller("ShowcaseController",
        ["$scope", "$http", "$location", "PublicationService", "NotificationService",
        function ($scope, $http, $location, PublicationService, NotificationService) {

      $scope.NotificationService = NotificationService;

      $scope.kudosEditableText = {
        text: 'test text',
        isEditable: true,
        editEvent: function(newText) { alert("Text changed to '" + newText + "'"); }
      };

        $scope.kudosPublicationOfficialMetadata = {};

        $scope.kudosResourceList = {
            editable: true,
            publication: {
                resources: [{
                    id: 12,
                    title: "Rosetta ESA page",
                    url: "http://sci.esa.int/rosetta/",
                    description: "The ESA's page on Rosetta, including links to news and updates."
                },{
                    id:223,
                    title: "Rosetta Mission: can you land on a comet?",
                    url: "http://www.bbc.co.uk/news/science-environment-29746430",
                    description: "The European Space Agency has made history by successfully landing a robot on a comet in deep space. Can you put the Philae lander in the right spot on a spinning comet in this interactive game? In real life, the landing took about seven hours, so we've sped things up for you. For a more in-depth look at the comet, the lander and the mission, read on below the game."
              }]
            },

            create: function(resource) { alert("Resource created: " + JSON.stringify( resource )); },
            update: function(resource) { alert("An update occurred: " + JSON.stringify( resource )); },
            delete: function(id) { alert("Resource " + id + " has been deleted"); },
            resourceTypes: [
              { "name": "Type 1", "type": "resource_type_1" },
              { "name": "Type 2", "type": "resource_type_2"}
            ],
            publicationDoi: "1234/doi"
        };

        $scope.kudosResource = {
            editable: true,
            beingEdited: false,
            resource: {
                id: 12,
                title: "Rosetta ESA page",
                url: "http://sci.esa.int/rosetta/",
                description: "The ESA's page on Rosetta, including links to news and updates."
            },
            editEvent: function() { alert("An edit event happened, new value: " + JSON.stringify( $scope.kudosResource.resource )); },
            deleteEvent: function() { alert("Resource delete event occurred"); },
            resourceTypes: [
              { "name": "Type 1", "type": "resource_type_1" },
              { "name": "Type 2", "type": "resource_type_2"}
            ]
        };


        $scope.editableTextareaToggled = {
            editable: true,
            textToEdit: "Here's some sample text",
            editEvent: function(newValue) { alert("An edit event happened, new value: " + newValue); }
        };

        //this is a stub function for the Kudos official publication metadata directive
        $scope.recordReadEvent = function(){};

        $scope.init = function() {

            PublicationService.getPublication('10.1087/20120103')
                .then(function(response) {
                    $scope.kudosPublicationOfficialMetadata.publication = response.data.data.article;
                    //$scope.editableResourceToggled.resource = publication.resources[0];
                })
                .catch(function(response) {
                    console.error(response.status);
                });
        };

        // edit_in_place_options
        $scope.eip = {
          makeEditable: function (event) {
            angular.element(event.target).attr('contenteditable', true);
            angular.element(event.target).focus();
          },
          toggleEditWithIcon: function () {
            angular.element('#toggleEditWithIcon').attr('contenteditable', true);
            angular.element('#toggleEditWithIcon').focus();
          },
          account: {
            display_name: 'Miss Jessica S Trumbleton',
            title: 'Miss',
            name: 'Jessica',
            middle_name: 'Susan',
            surname: 'Trumbleton'
          }
        };

        $scope.reportChartOptions = {
          metadata: {
            xAxis: {
              axisLabel: "X Axis Label"
            },
            yAxis: {
              axisLabel: "Y Axis Label"
            },
          },
          datasets: [
            {
              x: {
                name: "date",
                label: "Date"
              },
              y: {
                name: "number",
                label: "Number One"
              },
              data: [
                {
                  x: Date.parse('9 Jun 2015'),
                  y: 1
                },
                {
                  x: Date.parse('15 Jun 2015'),
                  y: 4
                },
                {
                  x: Date.parse('18 Jun 2015'),
                  y: 5
                },
                {
                  x: Date.parse('25 Jun 2015'),
                  y: 9
                },
                {
                  x: Date.parse('26 Jun 2015'),
                  y: 12
                }
              ]
            },
            {
              x: {
                name: "date",
                label: "Date"
              },
              y: {
                name: "number",
                label: "Number Two"
              },
              data: [
                {
                  x: Date.parse('9 Jun 2015'),
                  y: 4
                },
                {
                  x: Date.parse('15 Jun 2015'),
                  y: 6
                },
                {
                  x: Date.parse('18 Jun 2015'),
                  y: 7
                },
                {
                  x: Date.parse('25 Jun 2015'),
                  y: 13
                },
                {
                  x: Date.parse('26 Jun 2015'),
                  y: 14
                }
              ]
            },
            {
              x: {
                name: "date",
                label: "Date"
              },
              y: {
                name: "number",
                label: "Number Three"
              },
              data: [
                {
                  x: Date.parse('9 Jun 2015'),
                  y: 3
                },
                {
                  x: Date.parse('15 Jun 2015'),
                  y: 5
                },
                {
                  x: Date.parse('18 Jun 2015'),
                  y: 9
                },
                {
                  x: Date.parse('25 Jun 2015'),
                  y: 11
                },
                {
                  x: Date.parse('26 Jun 2015'),
                  y: 17
                }
              ]
            }
          ]
        };
    }]);
}(window.angular));
