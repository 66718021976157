; (function (angular) {

  'use strict';

  angular.module("kudosCharts")
    .directive("kudosMiniLineChart", function () {
      return {
        scope: {
          data: '=',
          metadata: '='
        },

        template:'<nvd3 options="options" data="data"></nvd3>',

        link: function (scope) {

          init();

          function init () {
            scope.options = getChartOptions();
          }

          function getChartOptions () {
            return {
              chart: {
                type: 'lineChart',
                height: 225,
                showLegend: false,
                margin: {
                  top: 10,
                  bottom: 30,
                  left: 40,
                  right: 10
                },
                //x-scaling function
                x: function(data) {
                  return data.x;
                },
                //y-scaling function
                y: function(data) {
                  return data.y;
                },

                xScale: d3.time.scale(),

                xAxis: angular.extend(
                  {},
                  { //default x-axis options
                    tickFormat: function(data) {
                      return d3.time.format('%b')(new Date(data));
                    },
                    showMaxMin: false,
                    ticks: 6,
                    staggerLabels: false
                  },
                  (scope.metadata.xAxisLabel) || {}
                ),

                yAxis: angular.extend(
                  {},
                  { //default y-axis options
                    tickFormat: function(data){
                      return d3.format(',.0f')(data); // Seperates thousands with commas with no decimal points
                    },
                    axisLabelDistance: 20
                  },
                  (scope.metadata.yAxisLabel) || {}
                )
              },
              title: {
                enable: true,
                text: scope.metadata.title,
                className: '',
                css: {
                  textAlign: 'center'
                }
              }
            };
          }

        }
      };
    }
  );
}(window.angular));

