; (function (angular) {

  'use strict';

  angular.module('sessions')
    .directive('termsConditionsSummaryModal', [
      '$uibModal',
      function ($uibModal) {
        return {
          link: function (scope, element) {
            element.on('click', function (event) {
              event.preventDefault();
              event.stopImmediatePropagation();

              showTermsAndConditionsModal();
            });

            function showTermsAndConditionsModal () {
              var modalInstance;
              var modalScope = scope.$new();

              modalScope.cancel = function () {
                modalInstance.dismiss('cancel');
              };

              modalInstance = $uibModal.open({
                templateUrl: 'kudosSessions/termsConditionsSummaryModal.directive.html',
                scope: modalScope
              });
            }
          }
        };
      }
  ]);

}(window.angular));

