(function (angular) {
  'use strict';

  angular
    .module('kudos')
    .directive('kudosKpi', [
      function () {
        return {
          scope: {},
          bindToController: {
            title: '@',
            value: '=',
            green: '=?',
            disabled: '=?',
            targetSref: '@',
            targetHref: '@',
            clickable: '=?',
            bottomLabel: '=?'
          },
          transclude: true,
          replace: true,
          templateUrl: 'kudos/directives/kudosKpi.html',
          controllerAs: 'vm',
          controller: ['$state', function ($state) {
            var self = this;

            self.gotoTargetSref = gotoTargetSref;
            self.$onInit = init;

            function init() {
              // If clickable has been defined as false, it is not clickable, if not defined or explicitly defined as true, make it clickable
              self.clickable = angular.isUndefined(self.clickable) || 
                (angular.isDefined(self.clickable) && self.clickable !== false);
              // bottomLabel has to explicitly be set to true and anything else will be considered false
              self.bottomLabel = self.bottomLabel === true;

              self.kpiClass = {
                'kpi-disabled': !!self.disabled,
                'kpi-widget-green': !!self.green,
                'clickable': self.clickable
              };
            }

            function gotoTargetSref() {
              // If the kpi widget is disabled, do not respond to clicks
              if (!!self.disabled || (self.targetHref === 'false')) {
                return;
              }

              if (!!self.targetSref) {
                $state.go(self.targetSref);
              } else if (!!self.targetHref) {
                window.open(self.targetHref, '_blank');
              }
            }
          }]
        };
      }
    ]);

} (window.angular));
