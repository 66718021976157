; (function (angular, _) {

  "use strict";

  angular.module('controllers')
    .controller(
      'InstitutionReportController',
      [
        'reportDetail',
        'favoritedReport',
        'reportMetadata',
        'InstitutionReportService',
        '$stateParams',
        function (reportDetail, favoritedReport, reportMetadata, InstitutionReportService, $stateParams) {
          var self = this;

          self.addReportToFavorites = function(shortCode, reportName){
            InstitutionReportService.addReportToFavorites(shortCode, reportName)
              .then(function(response) {
                self.favoritedReport = response.data.data;
              });
          };

          self.removeReportFromFavorites = function(shortCode, reportName){
            InstitutionReportService.removeReportFromFavorites(shortCode, reportName)
              .then(function(response) {
                self.favoritedReport = response.data.data;
              });
          };

          self.shortCode = $stateParams.shortcode;
          self.reportDetail = reportDetail;
          self.reportMetadata = reportMetadata;
          self.favoritedReport = favoritedReport;
          self.reportCsvLink = InstitutionReportService.getReportCsvUrl($stateParams.shortcode, reportMetadata.name);

          self.isLineChartAvailable = self.reportMetadata.availableCharts && _.includes(self.reportMetadata.availableCharts, 'lineChart');
        }
      ]
    );

}(window.angular, window._));
