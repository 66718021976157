; (function (angular) {
  "use strict";

  angular
    .module('kudosPublishers')
    .directive('loaderBundles', ['PublisherReportService', '$state', 'uiGridTreeViewConstants',
      function (PublisherReportService, $state, uiGridTreeViewConstants) {
        return {
          restrict: 'E',
          scope: {},
          controllerAs: 'ctrl',
          templateUrl: 'kudosPublishers/directives/loaderBundles.directive.html',
          bindToController: {
            publisher: '=',
            loaderBundle: '='
          },
          controller: function () {
            var self = this;

            self.LOAD_LIMIT_BUTTON_VALUES = [30, 60, 100, ''];

            self.gridOptions = {
              enableSorting: true,
              columnDefs: [
                {
                  displayName: 'File name',
                  field: 'filename',
                  headerCellClass: 'wrap-words',
                  cellTooltip: function(row,col){ return row.entity.archived_url; },
                  cellClass: getCellClassForLoaderStatus
                },
                {
                  displayName: 'Type',
                  field: 'loader_type',
                  headerCellClass: 'wrap-words',
                  cellClass: getCellClassForLoaderStatus
                },
                {
                  displayName: 'Received at',
                  field: 'received_at',
                  cellTemplate: cellContentTemplate('{{ row.entity.received_at | date:"d-MMM-yyyy H:mm"}}'),
                  headerCellClass: 'wrap-words',
                  cellClass: getCellClassForLoaderStatus
                },
                {
                  displayName: 'Successful records',
                  field: 'loader_records_count_success',
                  headerCellClass: 'wrap-words',
                  cellClass: getCellClassForLoaderStatus
                },
                {
                  displayName: 'Failed records',
                  field: 'loader_records_count_failure',
                  headerCellClass: 'wrap-words',
                  cellClass: getCellDangerClassForNonZero
                },
                {
                  displayName: 'Unique emails',
                  field: 'accepted_unique_emails',
                  headerCellClass: 'wrap-words',
                  cellClass: getCellClassForLoaderStatus
                },
                {
                  displayName: 'Status',
                  field: 'loader_status',
                  headerCellClass: 'wrap-words',
                  cellClass: getCellClassForLoaderStatus
                },
                {
                  displayName: 'Log',
                  field: 'loader_status',
                  headerCellClass: 'wrap-words',
                  cellTemplate: cellContentTemplate('<button uib-popover-template="grid.appScope.ctrl.htmlPopoverTemplate" popover-trigger="outsideClick" popover-title="{{row.entity.filename}}" popover-append-to-body="true" popover-placement="top-right" class="btn btn-primary btn-xs" ng-click=""><i class="fa fa-question-circle"></i></button>'),
                  width: 60,
                  enableSorting: false
                }
              ],

              enableFullRowSelection: true,
              enableRowHeaderSelection: false,
              enableRowSelection: true,
              multiSelect: false,
              showTreeExpandNoChildren: false,
              modifierKeysToMultiSelect: false,
              noUnselect: true,

              onRegisterApi: onRegisterApi

            };

            // publicly accessible functions used in the view
            self.setLoadLimit = setLoadLimit;
            self.refreshDataGrid = refreshDataGrid;
            self.getLoaderBundlesReportCsvUrl = getLoaderBundlesReportCsvUrl;



            // publicy exposed objects
            self.reportMetadata = {}; // set by a request for data
            self.query = undefined;   // bound to the search input box
            self.loadLimit = undefined; // set by clicking on a load-limit button
            self.htmlPopoverTemplate = "kudosPublishers/directives/loaderBundles.popover.html";
            self.$onInit = init;

            function init() {
              setLoaderBundle(undefined);
              setLoadLimit(self.LOAD_LIMIT_BUTTON_VALUES[0]);
            }

            function setLoadLimit (limit) {
              self.loadLimit = limit;
              refreshDataGrid();
            }

            function setLoaderBundle(loaderBundle) {
              self.loaderBundle = loaderBundle;
            }

            function cellContentTemplate (innerTemplate) {
              return '<div class="ui-grid-cell-contents">' + innerTemplate + '</div>';
            }

            function onRegisterApi(gridApi) {
              // we need to use null here as the scope isn't available and undefined throws an error...
              gridApi.selection.on.rowSelectionChanged(null, function(row) {
                setLoaderBundle(row.entity);
                }
              );
            }

            function refreshDataGrid() {
              return PublisherReportService.getLoaderBundlesReport(self.publisher.short_code, self.query, self.loadLimit)
                .then(function (data) {
                  self.gridOptions.data = data.rows;
                  self.reportMetadata = data.metadata;
                  setLoaderBundle(undefined);
                })
                .catch(function () {
                  $state.go('error', {}, {errorCode: 500});
                });
            }

            function getLoaderBundlesReportCsvUrl() {
              return PublisherReportService.getLoaderBundlesReportCsvUrl(self.publisher.short_code, self.query, self.loadLimit);
            }

            // if the current cell is > 0, return danger colour, otherwise return a colour based on the loader status
            function getCellDangerClassForNonZero(grid, row, col) {
              if (grid.getCellValue(row,col) > 0)
              {
                return "text-danger";
              } else {
                return getCellClassForLoaderStatus(grid, row, col);
              }
            }

            // return a cell class based on the loader status
            function getCellClassForLoaderStatus(grid, row, col) {
              var textClass = undefined;
              switch (row.entity.loader_status.toLowerCase()) {
                case "pending":
                  textClass = "text-muted";
                  break;
                case "processing":
                  textClass = "text-primary";
                  break;
                case "retry":
                  textClass = "text-warning";
                  break;
                case "failure":
                case "partial failure":
                case "rejected":
                  textClass = "text-danger";
                  break;
                case "success":
                  textClass = "text-success";
                  break;
                default:
                  textClass = "text-info";
                  break;
              }
              return textClass;
            }

          }
        }
      }]
    );
} (window.angular));
