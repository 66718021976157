; (function (angular) {

  "use strict";

  angular.module('kudosAdmin')
    .controller(
      'AdminInstitutionSearchController',
      [
        'SessionService',
        'InstitutionService',
        function (SessionService, InstitutionService) {
          var self = this;

          self.updateAutoCompleteList = updateAutoCompleteList;
          self.autoCompleteResults = [];
          self.showGrid = false;
          self.loading = false;

          var latestRequestID;

          self.gridOptions = {
            enableSorting: true,
            columnDefs: [
              {
                name: 'Institution Name',
                field : 'ringgoldName',
                minWidth: 450,
                sort: {
                  direction: 'asc',
                  priority: 0
                }
              },
              {
                name: 'Country',
                field: 'country',
                minWidth: 200
              },
              {
                name: 'Ringgold ID',
                displayName: 'Ringgold ID',
                field: 'ringgoldId'
              },
              {
                name: 'Status',
                field: 'customer',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ !COL_FIELD ? "" : "Current Customer"}}</div>'
              },
              {
                name: 'Select',
                field: 'ringgoldId',
                cellTemplate: '<div class="ui-grid-cell-contents"><a target="_self" href="/admin/manage_institutions/add/{{ COL_FIELD }}" ng-if="!row.entity.customer">Select</a>'
              }
            ]
          };

          function updateAutoCompleteList (searchString) {
            self.loading = true;

            var requestID = Date.now();
            latestRequestID = requestID;
            InstitutionService.findInstitutionsByPartialName(searchString)
              .then(function(institutionList){
                if (requestID === latestRequestID) {
                  self.gridOptions.data = institutionList;
                  self.showGrid = true;
                }
              })
              .finally(function () {
                self.loading = false;
              });
            }

        }
      ]
    );

} (window.angular));
