; (function (angular) {
  'use strict';
  angular
    .module('kudosPublications')
    .service(
      'RegionComponentChangedService',
      [
        RegionComponentChangedService
      ]
    );

    function RegionComponentChangedService () {
      var self = this;
      self.setChanged = setChanged;
      self.setUnchanged = setUnchanged;
      self.hasChanged = hasChanged;

      self.changesFlag = false;
      
      function setChanged() {
        self.changesFlag = true;
      }

      function setUnchanged() {
        self.changesFlag = false;
      }

      function hasChanged() {
        return self.changesFlag;
      }
    }
}(window.angular));
