(function (angular) {
  'use strict';

  angular.module('kudosProfiles').component('kudosDeleteProfileButton', {
    templateUrl: 'kudosProfiles/components/kudosDeleteProfileButton.component.html',
    controllerAs: 'vm',
    bindings: {
      profileId: '@',
      displayName: '@'
    },
    controller: ['ProfileService', 'NotificationService', '$window', function (ProfileService, NotificationService, $window) {
      var self = this;

      self.deleteAccount = function() {
        if (confirm('Are you sure? This cannot be undone!')) {
          ProfileService.deleteAccount(self.profileId)
            .then(function () {
              NotificationService.success(self.displayName + ' has been successfully deleted');
            })
            .catch(function () {
                NotificationService.error(self.displayName + ' could not be deleted');
            });
        }
      }
    }]
  });

} (window.angular));
