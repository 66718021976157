; (function (angular) {

  'use strict';

  angular.module('kudosHub').factory('SearchService', ['$http', function($http) {
    var apiBase = '/internal_api/search/';

    function byDoi(doi) {
      return $http.get(apiBase + encodeURIComponent(doi))
        .then(function (response) {
          return {
            doi: response.data.data.doi,
            authors: response.data.data.authors,
            title: response.data.data.title,
            url: response.data.data.url,
            containerTitleDate: response.data.data.container_title_date,
          };
        });
    }

    return { byDoi: byDoi };
  }]
);

}(window.angular));
