(function (angular) {
  'use strict';

  angular
    .module('altmetric')
    .directive('kudosAltmetricKpi', [
      function () {
        return {
          scope: {},
          bindToController: {
            title: '@',
            doi: '@',
            disabled: '=?',
            bottomLabel: '=?',
            clickable: '=?'
          },
          replace: true,
          transclude: true,
          templateUrl: 'altmetric/altmetric-kpi.directive.html',
          controllerAs: 'vm',
          controller: ['$element', function ($element) {
            var self = this;

            self.$onInit = init;

            function init() {
              if (self.clickable === false) {
                $element.on('click', function (event) {
                  event.preventDefault();
                });
              }

              // bottomLabel has to explicitly be set to true and anything else will be considered false
              self.bottomLabel = self.bottomLabel === true;

              self.kpiAltmetricClass = {
                'altmetric-kpi-disabled': self.disabled
              };
            }
          }]
        };
      }
  ]);

} (window.angular));
