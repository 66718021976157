; (function (angular) {
  'use strict';

  angular
    .module('kudos')
    .controller('tableSortController', [
      function () {
        var self = this;

        self.rows             = [];
        self.reverse          = false;

        self.init             = init;
        self.sortBy           = sortBy;
        self.getSortIconClass = getSortIconClass;

        function init (rows, propertyName, reverse) {
          self.rows         = rows;
          self.propertyName = propertyName;
          self.reverse      = reverse;
        }

        function sortBy (propertyName) {
          if (self.propertyName === propertyName) {
            self.reverse = !self.reverse;
          } else {
            self.reverse = false;
          }

          self.propertyName = propertyName;
        }

        function getSortIconClass (propertyName) {
          if (self.propertyName !== propertyName) {
            return 'fa-sort';
          }

          if (self.reverse) {
            return 'fa-sort-asc';
          }

          return 'fa-sort-desc';
        }
      }
    ]);

}(window.angular));
