; (function (angular) {
  'use strict';

  /* Intended to perform a similar function to interrogating ENV['RACK_ENV'] in Ruby.
   *
   * For now this just has the capability to determine environment from the host (domain) of the page
   * It could in the future interrogate an internal API endpoint to find out what env the Ruby is
   * running under.
   * Think twice and then think again before using this service. The less (ideally none) env-
   * specific conditions and hacks there are in the code, the better. Only use as last resort for
   * temporarily (e.g. few weeks) hiding something or doing something differently.
   */
  angular
    .module('kudos')
      .factory(
        'EnvService',
        [
          function () {

            function getEnv () {
              return decideEnv(window.location.host);
            }

            function decideEnv (hostname) {
              var env;
              if (hostname === 'growkudos.com'
                || hostname.match(/www[0-9]*\.growkudos.com/))
              {
                env = 'production';
              } else if (hostname === 'test.growkudos.com') {
                env = 'review';
              } else {
                env = 'development';
              }
              // no way to detect test (e.g. cucumber) by hostname
              return env;
            }

            return {
              getEnv: getEnv,
              _decideEnv: decideEnv  // quite difficult to test otherwise
            };
          }
        ]
      );

}(window.angular));


