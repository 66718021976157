; (function (angular) {
  'use strict';
  angular
    .module('kudosQueries')
    .factory(
      'PublisherQueryService',
      [
        '$http',
        function ($http) {
          return {
            getQuery: getQuery
          };

          function getQuery (shortCode, queryId) {
            return $http.get('/internal_api/publishers/' + shortCode + '/queries/' + queryId);
          }
        }
      ]
    );

}(window.angular));


