
(function (angular) {
  'use strict';

    angular.module('kudosProfiles').component('kudosMakeUserPremiumButton', {
      templateUrl: 'kudosProfiles/components/kudosMakeUserPremiumButton.component.html',
      controllerAs: 'vm',
      bindings: {
        displayName: '@',
        usersApiUrl: '@'
      },
      controller: ['ProfileService', 'NotificationService', '$window', function (ProfileService, NotificationService, $window) {
        var self = this;

        self.makeUserPremium = function() {
          if (confirm('This will upgrade a user that has not started a trial or a user with an expired trial to be a premium user. '
          + 'Use this for somebody with an expired trial that has now paid or to convert a standard user to premium for some other reason. \n\n'
          + 'If you see an error, try the other button as they may be in a current active trial.')) {
            ProfileService.makeUserPremium(self.usersApiUrl)
              .then(
                function (response) {
                  NotificationService.success(self.displayName + ' has been successfully converted to premium');
                },
                function (errorMsg) {
                  NotificationService.error(errorMsg);
                }
              );
          }
        }
      }]
    });

} (window.angular));
