(function(angular){
  'use strict';
  angular
    .module('kudos')
      .directive('kudosLoader', [
        '$timeout',
        function ($timeout) {
          return {
            scope: {
              show: '=',
              noDelay: '=?'
            },
            link: function (scope) {

              var timeoutPromise;

              // If noDelay is set, set timeout to 0, else default to 1 second
              var delayTime = (!!scope.noDelay ? 0 : 1000);

              scope.fadeInClass = {
                'fade-in': false
              };

              function toggleFadeInClass(fadeIn) {
                scope.fadeInClass = {
                  'fade-in': !!fadeIn
                };
              }

              function startTimeout() {
                timeoutPromise = $timeout(function () {
                  toggleFadeInClass(true);
                }, delayTime);
              }

              function stopTimeout() {
                toggleFadeInClass(false);

                if (!angular.isUndefined(timeoutPromise)) {
                  $timeout.cancel(timeoutPromise);
                }
              }

              scope.$watch('show', function (isShowing) {
                if (isShowing) {
                  startTimeout();
                } else {
                  stopTimeout();
                }
              });

            },
            templateUrl: 'kudos/directives/kudosLoader.html',
          };
        }
    ]);
})(window.angular);

