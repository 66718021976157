(function (angular) {
  'use strict';

  angular
    .module('kudosPublications')
    .component('infographicPanel', {
      templateUrl:
        'kudosPublications/components/infographicPanel/infographicPanel.html',
      bindings: {
        thumbnailUrl: '@',
        featureGuardAttrs: '<',
        doi: '@',
      },
      controllerAs: 'vm',
      controller: [
        '$scope',
        '$timeout',
        '$window',
        'InfographicService',
        'NotificationService',
        function (
          $scope,
          $timeout,
          $window,
          InfographicService,
          NotificationService
        ) {
          var self = this;
          self.infographicFile = null;
          self.currentState = 'init';
          self.requestInProgress = false;

          self.$onInit = function () {
            self.userIsAdmin = self.featureGuardAttrs.user_is_admin;
            self.userHasPremium = self.featureGuardAttrs.user_has_premium;
            self.publisherName = self.featureGuardAttrs.publisher_name;
          };

          self.addInfographicEnabledForUser = function () {
            if (self.userIsAdmin || self.userHasPremium || self.publisherName) {
              return true;
            }
            return false;
          };

          self.hasInfographic = function () {
            if (self.thumbnailUrl === '') {
              return false;
            }
            return true;
          };

          self.getInfographicUrl = function () {
            if (self.hasInfographic()) {
              return self.thumbnailUrl;
            }
            return '/fontawesome-icons/regular/file-image.svg';
          };

          self.getAltText = function () {
            if (self.hasInfographic()) {
              return 'Preview of article infographic';
            } else {
              return 'No article infographic';
            }
          };

          $scope.$on('fileSelected', uploadInfographic);

          function uploadInfographic() {
            if (self.requestInProgress) {
              return;
            }

            self.requestInProgress = true;
            changeButtonState('waiting');

            InfographicService.update(self.doi, self.infographicFile)
              .then(function (data) {
                self.thumbnailUrl = data.thumbnail;
                changeButtonState('success');
              })
              .catch(function (error) {
                if (error.message.toLowerCase() === 'unauthenticated') {
                  $window.location.href = '/hub';
                }
                changeButtonState('error');
                NotificationService.error(error.message);
              })
              .finally(function () {
                self.requestInProgress = false;
                $timeout(function () {
                  changeButtonState('init');
                }, 3000);
              });
          }

          self.removeInfographic = function () {
            if (self.requestInProgress) {
              return;
            }

            self.requestInProgress = true;

            InfographicService.delete(self.doi)
              .then(function () {
                self.thumbnailUrl = '';
              })
              .catch(function (error) {
                NotificationService.error(error.message);
              })
              .finally(function () {
                self.requestInProgress = false;
              });
          };

          function changeButtonState(state) {
            self.currentState = state;
          }

          self.getButtonText = getButtonText;
          self.getButtonClass = getButtonClass;
          self.getButtonIconClass = getButtonIconClass;

          function getButtonText() {
            return buttonStates[self.currentState].buttonText;
          }

          function getButtonIconClass() {
            return buttonStates[self.currentState].buttonIconClass;
          }

          function getButtonClass() {
            return buttonStates[self.currentState].buttonClass;
          }

          var buttonStates = {
            init: {
              name: 'init',
              buttonText: 'Upload',
              buttonClass: 'init',
            },
            waiting: {
              name: 'waiting',
              buttonText: 'Uploading',
              buttonIconClass: 'fa-circle-o-notch fa-spin',
              buttonClass: 'waiting',
            },
            success: {
              name: 'success',
              buttonText: 'Uploaded',
              buttonIconClass: 'fa-thumbs-o-up throb-in slow',
              buttonClass: 'success',
            },
            error: {
              name: 'error',
              buttonText: 'Error',
              buttonIconClass: 'fa-exclamation-circle throb-in slow',
              buttonClass: 'error',
            },
          };
        },
      ],
    })
    .directive('infographicFile', function () {
      return {
        require: 'ngModel',
        link: function ($scope, elem, attrs, ngModel) {
          elem.on('change', function (e) {
            var file = e.target.files[0];
            // Catch drag and drop where file type is not accepted
            if (file.size > 0) {
              ngModel.$setViewValue(file);
              $scope.$emit('fileSelected');
            }
            e.target.value = null;
          });
        },
      };
    });
})(window.angular, window._);
