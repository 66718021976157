(function (angular){
  'use strict';
  angular
    .module('kudos')
      .directive('kudosUploader', [
        'FileUploader',
        function (FileUploader) {
          return {
            scope: {
              uploadUrl: '@',
              uploadName: '@',
              smallButtons: '=?',
              onUploadComplete: '=?',
              onUploadError: '=?'
            },
            link: function (scope) {
              scope.loaded = false;
              scope.uploading = false;

              scope.fileName = '';

              scope.buttonClass = {
                'btn-sm': !!scope.smallButtons
              };

              scope.uploader = new FileUploader({
                url: scope.uploadUrl,
                alias: scope.uploadName
              });

              scope.cancel = function () {
                scope.uploader.clearQueue();
                scope.fileName = '';
                scope.uploading = false;
              };

              scope.upload = function () {
                if (scope.fileLoaded()) {
                  scope.uploading = true;
                  scope.uploader.uploadAll();
                }
              };

              scope.fileLoaded = function () {
                return !!scope.uploader.queue.length;
              };

              scope.uploader.onAfterAddingFile = function (fileItem) {
                scope.fileName = fileItem.file.name;
              };

              scope.uploader.onErrorItem = function (fileItem, response, status, headers) {
                (!!scope.onUploadError && scope.onUploadError(fileItem, response));
                scope.uploading = false;
              };

              scope.uploader.onCompleteItem = function (fileItem, response, status, headers) {
                (status === 200) && (!!scope.onUploadComplete && scope.onUploadComplete(fileItem, response, status, headers));
              };

              scope.uploader.onCompleteAll = function () {
                scope.cancel();
              };

              scope.loaded = true;
            },
            templateUrl: 'kudos/directives/kudosUploader.html'
          };
        }
    ]);
})(window.angular);

