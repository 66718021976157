; (function (angular, _) {

  "use strict";

  angular.module('kudosPublishers')
    .controller(
        'PublisherAccountController',
        [
        'publisherAdmins',
        'publisher',
        'PublisherAdminService',
        'ProfileService',
        'NotificationService',
        function (publisherAdmins, publisher, PublisherAdminService, ProfileService, NotificationService) {
          var self = this;

          self.foundProfiles = [];
          self.publisherAdmins = publisherAdmins;
          self.publisher = publisher;

          self.state = {};
          self.state.searchOpen = false;
          self.state.loading = false;
          self.state.showEmptySearch = false;
          self.state.emailSearch = '';

          self.searchProfiles = searchProfiles;
          self.toggleSearch = toggleSearch;
          self.deleteAdmin = deleteAdmin;
          self.addAdmin = addAdmin;

          self.canSearch = canSearch;
          self.showSearchBox = showSearchBox;
          self.showSearchLoading = showSearchLoading;
          self.onEnterSearchProfiles = onEnterSearchProfiles;

          function addAdmin (id) {
            PublisherAdminService.addPublisherAdmin(id, self.publisher.short_code)
              .then(function (data) {
                self.publisherAdmins = data.admins;
                closeSearch();

                NotificationService.success('Successfully made ' + _.last(self.publisherAdmins).display_name + ' a publisher admin');
              })
              .catch(function (response) {
                handleError(response, 'add', self.publisher.short_code);
              });
          }

          function deleteAdmin (id) {
            PublisherAdminService.deletePublisherAdmin(id, self.publisher.short_code)
              .then(function (data) {
                var who = getProfileFromId(id);
                self.publisherAdmins = data.admins;
                closeSearch();

                NotificationService.success('Successfully removed ' + who + ' as a publisher admin');
              })
              .catch(function (response) {
                handleError(response, 'delete', self.publisher.short_code);
              });
          }

          function handleError (response, action, shortCode) {
            if (error.data.errors) {
              _.each(error.data.errors, function (error) {
                NotificationService.error(error);
              });
            } else {
              NotificationService.error('Could not ' + action + ' publisher admin' + shortCode);
            }
          }

          function searchProfiles () {
            self.state.loading = true;

            ProfileService.searchProfiles(self.state.emailSearch)
              .then(function (profiles) {
                self.foundProfiles = profiles;
                self.state.showEmptySearch = !self.foundProfiles.length;
              })
              .catch(function (error) {
                NotificationService.error('There was a problem searching for users, please try again.');
              })
              .finally(function () {
                self.state.loading = false;
              });
          }

          function toggleSearch (open) {
            self.state.searchOpen = (
              angular.isDefined(open) ?
                open :
                !self.state.searchOpen
            );
          }

          function closeSearch () {
            self.toggleSearch(false);
            self.state.loading = false;
            self.foundProfiles = [];
          }

          function onEnterSearchProfiles (event) {
            // Search profiles on enter key press
            (event.which === 13 && self.searchProfiles());
          }

          function canSearch () {
            return !self.state.emailSearch;
          }

          function showSearchBox () {
            return (self.state.searchOpen && !self.state.loading);
          }

          function showSearchLoading () {
            return (self.state.searchOpen && self.state.loading);
          }

          function getProfileFromId (id) {
            var foundProfile =  _.find(self.publisherAdmins, function (user) {
              return user.id === id;
            });

            return (
              angular.isDefined(foundProfile) ?
                foundProfile.display_name :
                ''
            );
          }

        }
      ]
    );

} (window.angular, window._));

