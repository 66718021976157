; (function (angular) {
  'use strict';
  angular
    .module('kudosCountries')
    .factory(
      'CountryService',
      [
        '$http',
        function ($http) {
          return {
            getAllCountries: function () {
              return $http.get('/internal_api/countries/');
            }
          };
        }
      ]
    );

}(window.angular));

