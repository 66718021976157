; (function (angular) {

  "use strict";

  angular.module('kudosInstitutions').constant('institutionReports', [
    {
      title: 'Publications report: all publications on Kudos',
      description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ institution.institution.displayName }}.',
      name: 'all_publications'
    },
    {
      title: 'Publications report: all publications on Kudos that have been explained excluding adding resources',
      description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ institution.institution.displayName }} and that have been explained by one or more of the authors. An Explained Publication is one where one or more authors has added either a Short Title, text explaining "What\'s it about?", text explaining "Why is it important?" or an Author Perspective.',
      name: 'explained_excluding_resources_publications'
    },
    {
      title: 'Publications report: all publications on Kudos that have one or more resource',
      description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ institution.institution.displayName }} and have added one or more resource links. This includes links to data sets, videos, presentations or news stories.',
      name: 'publications_with_resources'
    },
    {
      title: 'Publications report: all publications on Kudos that have been explained',
      description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ institution.institution.displayName }} and that have been explained by one or more of the authors. An Explained Publication is one where one or more authors has added either a Short Title, text explaining "What\'s it about?", text explaining "Why is it important?", a Perspective or a resource.',
      name: 'explained_publications'
    },
    {
      title: 'Publications report: all resources linked to publications',
      description: 'This report lists all resources linked to publications on Kudos where at least one author has indicated that they are from {{ institution.institution.displayName }}. A resource includes data sets, videos, presentations or news stories.',
      name: 'linked_resources'
    },
    {
      title: 'Publications report: all publications on Kudos that have been shared',
      description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ institution.institution.displayName }} and the publication has been shared by one or more of the authors. Shares means that one of more of the authors has used Kudos to send a tweet, add Facebook post, send an email or post online. When the author shares, a coded link is included in the share which allows readers to link to the publication.',
      name: 'shared_publications'
    },
    {
      title: '',
      description: '',
      name: 'category_overview'
    },
    {
      title: '',
      description: '',
      name: 'recently_active_researchers'
    },
    {
      title: 'Activity report: all activities by date (explain and share)',
      description: 'This report lists all activities undertaken on Kudos by researchers that indicated that they are from {{ institution.institution.displayName }} to help increase the impact of their publications. This included adding or editing a short title, the "What\'s it about?" text, the "Why is it important?" text, an Author Perspective, a Resource or by sharing through Twitter, Facebook or email/online.',
      name: 'explain_share_activity'
    },
    {
      title: 'Activity report: all explain activities (excluding resources) by date',
      description: 'This report lists all explain activities (excluding adding resources) undertaken on Kudos by researchers that indicated that they are from {{ institution.institution.displayName }} to help increase the impact of their publications. This included adding or editing a short title, the "What\'s it about?" text, the "Why is it important?" text or an Author Perspective.',
      name: 'explain_excluding_resources_activity'
    },
    {
      title: 'Activity report: all resource activities by date',
      description: 'This report lists all resource activities undertaken on Kudos by researchers that indicated they are from {{ institution.institution.displayName }} to help increase the impact of their publications. This includes links to data sets, videos, presentations or new stories.',
      name: 'resource_activity'
    },
    {
      title: 'Activity report: all share activities by date',
      description: 'This report lists all share activities undertaken on Kudos by researchers that indicated that they are from {{ institution.institution.displayName }} to help increase the impact of their publications. This included sharing through Twitter, Facebook or email/online.',
      name: 'share_activity'
    },
    {
      title: 'Activity report: all tweets by date',
      description: 'This report lists all tweets sent from Kudos by researchers that indicated that they are from {{ institution.institution.displayName }} to help increase the impact of their publications.',
      name: 'tweet_activity'
    },
    {
      title: 'Researcher report: export of all registered users',
      description: 'This report lists all researchers using Kudos that indicated they were from {{ institution.institution.displayName }}. It includes their name, email address, self-selected subject area and self-selected career stage. Please note that you are bound by Kudos\' terms and conditions regarding the use of this personal data.',
      name: 'all_researchers'
    },
    {
      title: 'Researcher report: clicks on invitation link by date',
      description: 'This report shows the number of clicks on the custom invitation link for {{ institution.institution.displayName }}. This allows you to track the rate at which researchers from {{ institution.institution.displayName }} clicked on the invitation link. Some will have then gone on and registered but some may have clicked the link but not registered.',
      name: 'go_url_views',
      availableCharts: ['lineChart']
    },
    {
      title: 'Researcher report: breakdown by number of publications',
      description: 'This report includes all researchers using Kudos that indicated they were from {{ institution.institution.displayName }}. It shows the distribution of the number of publications each researcher has claimed. A researcher claims a publication to indicate that they are an author of that publication. Some researchers will have claimed multiple publications.',
      name: 'researchers_claims'
    },
    {
      title: 'Researcher report: breakdown by career stage',
      description: 'This report includes all researchers using Kudos that indicated they were from {{ institution.institution.displayName }}. It shows the distribution of researchers across career stages. These career stages are selected by the researcher when they register with Kudos. This field is optional, so some researchers may not have made a selection.',
      name: 'researchers_by_career_stage'
    },
    {
      title: 'Researcher report: breakdown by geographic location',
      description: 'This report includes all researchers using Kudos that indicated they were from {{ institution.institution.displayName }}. It shows the distribution of countries where the researchers are primarily based. These countries are selected by the researcher when they register with Kudos. This field is optional, so some researchers may not have made a selection.',
      name: 'researchers_by_country'
    },
    {
      title: 'Researcher report: breakdown by subject area',
      description: 'This report includes all researchers using Kudos that indicated they were from {{ institution.institution.displayName }}. It shows the distribution of researchers across subject areas. These subject areas are selected by the researcher when they register with Kudos. This field is optional, so some researchers may not have made a selection.',
      name: 'researchers_by_subject'
    },
    {
      title: 'Researcher report: breakdown by date joined Kudos',
      description: 'This report shows the cumulative number of researchers from {{ institution.institution.displayName }} that had registered with Kudos by any given date. This allows you to track the rate at which researchers from {{ institution.institution.displayName }} join Kudos.',
      name: 'researchers_registrations',
      availableCharts: ['lineChart']
    },
    {
      title: 'Performance report: publication page views',
      description: 'This report shows the number of views of publications by date and the cumulative number of views of publications on Kudos for publications where one of more author has indicated that they are from {{ institution.institution.displayName }}.' ,
      name: 'publications_views',
      availableCharts: ['lineChart']
    },
    {
      title: 'Performance report: publication pages - most viewed',
      description: 'This report shows the publication pages with the highest number of views on Kudos for publications where one of more author has indicated that they are from {{ institution.institution.displayName }}.' ,
      name: 'publications_most_viewed'
    },
    {
      title: 'Performance report: share referrals - total by date',
      description: 'This report shows the number of share referrals by date to publications when one or more author has indicated that they were from {{ institution.institution.displayName }}. Share referrals are clicks on coded links in tweets, Facebook post, emails or online resulting from authors using the sharing tools on Kudos.' ,
      name: 'publications_share_referrals'
    },
    {
      title: 'Performance report: shares resulting in highest share referrals',
      description: 'This report shows the shares that resulted in the highest number of share referrals for publications where one of more author has indicated that they are from {{ institution.institution.displayName }}.' ,
      name: 'publications_highest_share_referrals'
    },
    {
      title: 'Performance report: showcase page',
      description: 'This report shows the number of views by date and the cumulative number of views of the {{ institution.institution.displayName }} Showcase.',
      name: 'showcase_page_view_count'
    },
    {
      title: 'Performance report: top Altmetric scores',
      description: 'This report lists the publications from {{ institution.institution.displayName }} on Kudos that have the highest current Altmetric scores. Altmetric data is provided by Altmetric.com',
      name: 'altmetric_scores_top_all_publications'
    },
    {
      title: 'Performance report: top Altmetric scores for publications that have been explained',
      description: 'This report lists the publications from {{ institution.institution.displayName }} on Kudos that have been explained and have the highest current Altmetric scores. Altmetric data is provided by Altmetric.com',
      name: 'altmetric_scores_top_explained_publications'
    },
    {
      title: 'Performance report: highest climbing altmetric scores (all publications)',
      description: 'This report lists the publications from {{ institution.institution.displayName }} on Kudos that have the highest recent growth altmetric scores. The Top 100 are shown and altmetric data is provided by <a href="https://www.altmetric.com" target="_blank">Altmetric.com</a>',
      name: 'altmetric_scores_climbing_all_publications'
    },
    {
      title: 'Performance report: highest climbing altmetric scores (explained publications)',
      description: 'This report lists the explained publications from {{ institution.institution.displayName }} on Kudos that have the highest recent growth altmetric scores. The Top 100 are shown and altmetric data is provided by <a href="https://www.altmetric.com" target="_blank">Altmetric.com</a>',
      name: 'altmetric_scores_climbing_explained_publications'
    }
  ]);
} (window.angular));
