; (function (angular) {
  'use strict';

  angular.module( 'core', ['ui.router', 'restangular', 'toastr']);
  ; (function (angular) {
    'use strict';
  
    angular.module('core')
      .run(["$rootScope", function ($rootScope) {
        var scrollto = function (element, offset) {
          var body = angular.element('body, html');
          body.animate({
            scrollTop: element.offset().top + offset
          }, 100);
        };
  
        $rootScope.scrollToSelector = function (selector) {
          // we need to offset the scroll by the static header size.
          // and we add 10px extra so you can see a glimpse of where you came from.
          var scrollOffset = -60;
          var scrollTarget = angular.element(selector);
          scrollto(scrollTarget, scrollOffset);
        };
      }]);
  }(window.angular));
  
  ; (function (angular, Humanize) {
    'use strict';
  
    angular.module('core')
      .filter('numberToHuman', function () {
        return function (num, precision) {
          return Humanize.compactInteger(num, precision);
        };
      })
      .filter('largeNumberToHuman', function () {
        return function (num, precision, limit) {
          // if limit is not specified, assume 1 million
          limit = typeof limit !== 'undefined' ? limit : 1000000;
          // only humanize the number if it exceeds the limit
          return num < limit ? Humanize.formatNumber(num, 0) : Humanize.compactInteger(num, precision);
        };
      });
  }(window.angular, window.Humanize));
  
  ; (function (angular) {
    'use strict';
  
    angular.module('core')
      .config(['RestangularProvider', function (RestangularProvider) {
        RestangularProvider.setBaseUrl('/internal_api');
  
        RestangularProvider.setResponseExtractor(function(response, operation) {
          var newResponse;
          // This is a get for a list
          if (operation !== "getList") {
            // If it's an element, then we just return the "regular" response as there's no object wrapping it
            newResponse = response.data;
          }
          return newResponse;
        });
      }]);
  
  }(window.angular));
  
  ; (function (angular) {
    'use strict';
  
    angular.module('core')
      .config([
        '$provide',
        '$stateProvider',
        '$urlRouterProvider',
        '$urlMatcherFactoryProvider',
        '$locationProvider',
        function($provide, $stateProvider, $urlRouterProvider, $urlMatcherFactoryProvider, $locationProvider) {
  
          /**
            Auto-scroll to the top of the window on page transition. By default ui-router just replaces content,
            so it's not obvious to users that the page has changed. `autoscroll='true'` helps, but cuts off
            the site title.
  
            See:
            * http://stackoverflow.com/questions/22290570/angular-ui-router-scroll-to-top-not-to-ui-view
            * http://angular-ui.github.io/ui-router/site/#/api/ui.router.state.directive:ui-view
            * http://angular-ui.github.io/ui-router/site/#/api/ui.router.state.$uiViewScroll
           **/
          $provide.decorator('$uiViewScroll', ['$window', function ($window) {
            return function () {
              $window.scrollTo(0, 0);
            };
          }]);
  
          /**
            Routing!
          **/
  
          // Allow optional trailing slash on URLs
          $urlMatcherFactoryProvider.strictMode(false);
  
          // For any unmatched url, show a 404 error but don't change the URL
          $urlRouterProvider.otherwise(function($injector, $location){
             var $state = $injector.get('$state');
             $state.go('error',  {errorCode: 404});
             return $location.path();
          });
  
          // Generic application states
          $stateProvider
            .state('error', {
              controller: "ErrorController as vm",
              templateUrl: "partials/error.html",
              params: {
                errorCode: "500", //default to 500 error
                errorMessage: undefined
              },
              resolve: {
                error: ['$q', '$stateParams', function ($q, $stateParams) {
                  return $q.when({
                    errorCode: $stateParams.errorCode,
                    message: $stateParams.errorMessage
                  });
                }]
              }
            });
  
          // Use the HTML5 History API
          // This gives us proper (non-#-based) routing,
          // providing we have <base> set in our HTML.
          // If not, then turn off client side routing.
          var baseHrefExists = !!document.querySelectorAll('base[href]').length
          $locationProvider.html5Mode(baseHrefExists);
  
      }]);
  
  }(window.angular));
  
  ; (function (angular) {
    'use strict';
  
    angular.module('core')
      .config(['toastrConfig', function (toastrConfig) {
        angular.extend(toastrConfig, {
          allowHtml: true,
          closeButton: true
        });
      }]);
  
  }(window.angular));
  

}(window.angular));

; (function (angular) {
  'use strict';

  angular.module('controllers', [
      'angulartics',
      'ngFileUpload',
      'ui.router',
      'kudos',
      'kudosPublications',
      'kudosProfiles',
      'kudosResources',
      'kudosNotifications',
      'kudosAdmin'
  ]);
  ; (function (angular) {
    "use strict";
  
    angular.module("controllers")
      .controller(
        "ErrorController",
        [
          "error",
          function (error) {
            var self = this;
  
            self.errorCode = error.errorCode;
  
            if(error.errorMessage){
              self.errorMessage = error.errorMessage;
            }else{
              switch (self.errorCode) {
                case 401:
                  self.title = "Not authenticated";
                  self.errorMessage = "You are not authenticated to view this page.";
                  break;
                case 403:
                  self.title = "Not authorized";
                  self.errorMessage = "You are not authorized to view this page.";
                  break;
                case 404:
                  self.title = "Not what you're looking for?";
                  self.errorMessage = "We've hit a problem because the page you're trying to access, doesn't exist.";
                  break;
                case 500:
                  self.title = "Looks like we've hit a problem.";
                  self.errorMessage = "The page you are trying to access is not available. We're working on fixing the problem but if it persists, please get in touch. In the meantime, try refreshing the page.";
                  break;
                default:
                  self.title = "Server error";
                  self.errorMessage = "An error has occurred.";
              }
            }
          }
        ]);
  })(window.angular);
  
  ; (function (angular, _) {
    'use strict';
  
    /**
      WARNING!
  
      This controller exists solely to pass in legacy server-generated variables via ng-init.
  
      IT SHOULD NOT BE USED!
  
      We need to refactor much of this out, particularly the current user code, which can be
      handled as part of the authentication mechanism (which hasn't been implemented in Angular
      at the time of writing).
  
      If you're thinking about adding anything to this controller, think again! It should be added
      to a service instead.
    **/
    angular.module('controllers')
      .controller('KudosAppController', ['SessionService', 'NotificationService',
        function(SessionService, NotificationService) {
          var self = this;
  
          self.init = function(flashMessagesString) {
            var flashMessages = JSON.parse(flashMessagesString);
  
            // Consume the server-generated flash messages
            _.each(flashMessages, function(message, type) {
              switch (type) {
                case 'error':
                case 'danger': //bootstrap uses 'danger'
                  NotificationService.error(message);
                  break;
                case 'warning':
                  NotificationService.warning(message);
                  break;
                case 'success':
                  NotificationService.success(message);
                  break;
              }
            });
  
            // Set up the user
            SessionService.getSession()
              // Unhandled promise rejections raise an error. TODO: monitoring
              .catch(function () {});
          };
        }
      ]);
  
  }(window.angular, window._));
  
  ; (function (angular, _) {
  
    "use strict";
  
    angular.module('controllers')
      .controller(
        'InstitutionController',
        [
          'institution',
          'institutionReports',
          'countries',
          'categoryOverview',
          'recentlyActiveResearchers',
          'latestTweets',
          'recentlyExplainedPublications',
          'exampleExplainedPublications',
          'favoriteReports',
          'ValidationService',
          'InstitutionService',
          'InstitutionReportService',
          'SessionService',
          'NotificationService',
          '$state',
          '$sce',
          '$rootScope',
          '$sanitize',
          function (
            institution,
            institutionReports,
            countries,
            categoryOverview,
            recentlyActiveResearchers,
            latestTweets,
            recentlyExplainedPublications,
            exampleExplainedPublications,
            favoriteReports,
            ValidationService,
            InstitutionService,
            InstitutionReportService,
            SessionService,
            NotificationService,
            $state,
            $sce,
            $rootScope,
            $sanitize
          ) {
            var self = this;
  
            self.currentUser = SessionService.currentUser();
  
            self.countries = countries;
            self.dashboardKPIs = institution.dashboard_kpis;
            self.latestTweets = latestTweets;
            self.categoryOverview = categoryOverview;
  
            self.exampleExplainedPublications = exampleExplainedPublications;
            self.recentlyActiveResearchers = recentlyActiveResearchers;
            self.favoriteReports = favoriteReports;
  
            self.goUrlViewsPath = $state.href('institution/report/go_url_views',{}, {absolute: true});
            self.removeReportFromFavorites = function(reportName) {
              InstitutionReportService.removeReportFromFavorites($state.params.shortcode, reportName)
                .then(function () {
                  return InstitutionReportService.getFavoritedReports($state.params.shortcode);
                })
              .then(function() {
                var index = _.findIndex(self.favoriteReports, {name: reportName});
                if (index > -1) {
                  self.favoriteReports.splice(index, 1);
                }
              });
            };
  
            self.setInstitution = function (institution) {
              self.institution = institution;
            };
  
            self.pageState = {
              recentlyActiveResearcherWidget: {
                showAll: false
              },
              editing: {
                newAdminUserEmail: true
              }
            };
  
            self.showAddInstAdminForm = false;
            self.newAdminUserEmail = '';
  
            var DEFAULT_INSTITUTION_LOGO = '/images/institution.png';
  
            var RECENTLY_EXPLAINED_DEFAULT_COUNT = 3;
            var RECENTLY_EXPLAINED_EXPANDED_COUNT = 20;
  
            self.recentlyExplainedPublications = recentlyExplainedPublications.slice(0, RECENTLY_EXPLAINED_DEFAULT_COUNT);
            self.showMoreLabel = "More...";
            self.showRecentlyExplainedPublications = function() {
              if(recentlyExplainedPublications.length > 3) {
                return true;
              } else {
                return false;
              }
            };
  
  
            self.removeAdminFromCurrentInstitution = function(admin_to_remove) {
              InstitutionService.removeInstitutionAdmin(admin_to_remove, self.institution).then(function() {
                InstitutionService.getInstitution(self.institution.shortCode)
                  .then(function (response) {
                    self.setInstitution(response.data.data.institution);
                  })
                  .catch(function () {
                    $state.go('error', {},  {errorCode: 500});
                  });
              });
            };
  
            self.addAdminToCurrentInstitution = function(new_admin_email) {
              InstitutionService.addInstitutionAdmin(new_admin_email, self.institution).then(function() {
                // the route returns the new admin but we just need o refresh the institution
                // since it contains a brief list of its admins and should be updated anyway
                InstitutionService.getInstitution(self.institution.shortCode)
                  .then(function (response) {
                    self.setInstitution(response.data.data.institution);
                  })
                  .catch(function () {
                    $state.go('error', {},  {errorCode: 500});
                  });
                self.newAdminUserEmail = '';
                self.pageState.editing.newAdminUserEmail = true;  // FIXME this gets overridden by the kudosEditableTextDirective after this function runs :(
              });
            };
  
            self.toggleShowMore = function(){
              self.recentlyExplainedExpanded = !self.recentlyExplainedExpanded;
              var limit = self.recentlyExplainedExpanded ? RECENTLY_EXPLAINED_EXPANDED_COUNT : RECENTLY_EXPLAINED_DEFAULT_COUNT;
              self.recentlyExplainedPublications = recentlyExplainedPublications.slice(0, limit);
              if(self.showMoreLabel === "More...") {
                self.showMoreLabel = "Less...";
              } else {
                self.showMoreLabel = "More...";
              }
            };
  
            // This function mitigates the limitations of ui-sref-active with handling complex state hierarchies.
            self.isReportTabOpen = function () {
              return ($state.includes('institution.report') || $state.includes('institution.report_listing'));
            };
  
            self.toggleShowAllRecentlyActive = function () {
              self.pageState.recentlyActiveResearcherWidget.showAll = !self.pageState.recentlyActiveResearcherWidget.showAll;
            };
  
            self.getShowAllRecentlyActive = function () {
              return self.pageState.recentlyActiveResearcherWidget.showAll;
            };
  
            self.cancelEditing = function () {
              angular.extend(self.pageState.editing, {
                displayName: false,
                displayCountry: false,
                website: false,
                blurb: false
              });
            };
  
            self.updateDisplayName = function (newDisplayName) {
              self.cancelEditing();
              if(angular.isUndefined(newDisplayName) || self.institution.displayName === newDisplayName) {
                return;
              }
  
              InstitutionService.updateInstitutionField(self.institution, 'displayName', newDisplayName)
                .then(function (data) {
                  self.setInstitution(data.data.institution);
                });
            };
  
            self.updateDisplayCountry = function (newDisplayCountry) {
              self.cancelEditing();
              if(angular.isUndefined(newDisplayCountry)) {
                return;
              }
  
              InstitutionService.updateInstitutionField(self.institution, 'displayCountry', newDisplayCountry)
                .then(function (data) {
                  self.setInstitution(data.data.institution);
                });
            };
  
            self.updateBlurb = function (newBlurb) {
              self.cancelEditing();
              if(angular.isUndefined(newBlurb) || self.institution.blurb === newBlurb) {
                return;
              }
  
              InstitutionService.updateInstitutionField(self.institution, 'blurb', newBlurb)
                .then(function (data) {
                  self.setInstitution(data.data.institution);
                });
            };
  
            self.updateWebsite = function (newWebsite) {
              self.cancelEditing();
              if(angular.isUndefined(newWebsite) || self.institution.website === newWebsite) {
                return;
              }
  
              // Validate URL
              var validationError = self.validate.url(newWebsite);
              if(!!validationError) {
                return validationError;
              }
  
              InstitutionService.updateInstitutionField(self.institution, 'website', newWebsite)
                .then(function (data) {
                  self.setInstitution(data.data.institution);
                });
            };
  
            self.getInstitutionLogo = function () {
              return (self.institution.logo || DEFAULT_INSTITUTION_LOGO);
            };
  
            self.logoUploadComplete = function (fileItem, response) {
              NotificationService.success('Successfully uploaded \'' + fileItem.file.name + '\' as logo');
              self.setInstitution(response.data.institution);
            };
  
            self.logoUploadError = function (fileItem, response) {
              NotificationService.error('Logo upload \'' + fileItem.file.name + '\' failed, please try again: ' + response.errors.join('; '));
            };
  
            self.removeLogo = function () {
              InstitutionService.removeInstitutionLogo(self.institution.code)
                .then(
                  function (data) {
                    NotificationService.success('Successfully removed logo!');
                    self.setInstitution(data.data.data.institution);
                  },
                  function () {
                    NotificationService.error('Could not remove logo!');
                  }
                );
            };
  
            self.bannerUploadComplete = function (fileItem, response) {
              NotificationService.success('Successfully uploaded \'' + fileItem.file.name + '\' as banner!');
              self.setInstitution(response.data.institution);
            };
  
            self.bannerUploadError = function (fileItem) {
              NotificationService.error('Banner upload \'' + fileItem.file.name + '\' failed, please try again.');
            };
  
            self.removeBanner = function () {
              InstitutionService.removeInstitutionBanner(self.institution.code)
                .then(
                  function (data) {
                    NotificationService.success('Successfully removed banner!');
                    self.setInstitution(data.data.data.institution);
                  },
                  function () {
                    NotificationService.error('Could not remove banner!');
                  }
                );
            };
  
            self.validate = {
              url: function (url) {
                return ValidationService.isUrl(url, 'URL must start with "http://" or "https://"', true);
              }
            };
  
            self.setInstitution(institution);
            self.cancelEditing();
  
            self.saveShowcasePublicationOrder = function (sortedArray) {
              InstitutionService.reOrderInstitutionShowcasePublications(self.institution, sortedArray)
                .then(function () {
                  NotificationService.success('The institution showcase publications have been successfully re-ordered.');
                });
            };
  
            self.saveShowcaseProfileOrder = function (sortedArray) {
              InstitutionService.reOrderInstitutionShowcaseProfiles(self.institution, sortedArray)
                .then(function () {
                  NotificationService.success('The institution showcase profiles have been successfully re-ordered.');
                });
            };
  
            self.showcasePublicationListSortTemplate = function (publication) {
              var publicationTitle = (!!publication.micro_title ? publication.micro_title : publication.title);
              return $sce.trustAsHtml('<a href="{{ publication.article_url }}">' + $sanitize(publicationTitle) + '</a>');
            };
  
            self.showcaseProfileListSortTemplate = function (profile) {
              return $sce.trustAsHtml('<a target="_self" ng-href="/profile/' + profile.custom_username + '">' + $sanitize(profile.display_name) + '</a>');
            };
  
            self.showcaseUrl = (window.location.protocol + '//' + window.location.host + '/showcase/' + self.institution.code);
            self.inviteUrl = (window.location.protocol + '//' + window.location.host + '/go/' + self.institution.code);
          }
        ]
      );
  
  } (window.angular, window._));
  
  ; (function (angular) {
  
    "use strict";
  
    angular.module('controllers')
      .controller(
        'InstitutionShowcaseController',
        [
          'institution',
          'PageTrackerService',
          function (institution, PageTrackerService) {
            var self = this;
  
            self.institution = institution;
  
            self.backgroundImageContainerClass = {
              'background-present': !!self.institution.banner
            };
  
            self.backgroundImageContainerStyle = {};
  
            if (!!self.institution.banner) {
              self.backgroundImageContainerStyle['background-image'] = 'url(' + self.institution.banner + ')';
            }
            PageTrackerService.trackPageView('/showcase/' + self.institution.code);
          }
        ]
      );
  
  } (window.angular));
  
  
  ; (function (angular) {
  
    "use strict";
  
    angular.module('controllers')
      .controller(
        'InstitutionSearchController',
        [
          'institution',
          'InstitutionService',
          'NotificationService',
          function (institution, InstitutionService, NotificationService) {
            var self = this;
  
            self.institution = institution;
  
            self.searchForm = {};
            self.searching = false;
            self.searchResults = false;
  
            self.submitSearch = function () {
              self.searching = true;
              InstitutionService.searchInstitutionUsers(institution, self.searchForm.email, self.searchForm.name)
                .then(function (response) {
                  self.searchResults = response.data.data.profiles;
                })
                .catch(function () {
                  NotificationService.error('User search failed, please try again');
                })
                .finally(function () {
                  self.searching = false;
                });
            };
  
            self.canSubmit = function () {
              return !!self.searchForm.email || !!self.searchForm.name;
            };
  
          }
        ]
      );
  
  } (window.angular));
  
  
  
  ; (function (angular, _) {
  
    "use strict";
  
    angular.module('controllers')
      .controller(
        'InstitutionReportController',
        [
          'reportDetail',
          'favoritedReport',
          'reportMetadata',
          'InstitutionReportService',
          '$stateParams',
          function (reportDetail, favoritedReport, reportMetadata, InstitutionReportService, $stateParams) {
            var self = this;
  
            self.addReportToFavorites = function(shortCode, reportName){
              InstitutionReportService.addReportToFavorites(shortCode, reportName)
                .then(function(response) {
                  self.favoritedReport = response.data.data;
                });
            };
  
            self.removeReportFromFavorites = function(shortCode, reportName){
              InstitutionReportService.removeReportFromFavorites(shortCode, reportName)
                .then(function(response) {
                  self.favoritedReport = response.data.data;
                });
            };
  
            self.shortCode = $stateParams.shortcode;
            self.reportDetail = reportDetail;
            self.reportMetadata = reportMetadata;
            self.favoritedReport = favoritedReport;
            self.reportCsvLink = InstitutionReportService.getReportCsvUrl($stateParams.shortcode, reportMetadata.name);
  
            self.isLineChartAvailable = self.reportMetadata.availableCharts && _.includes(self.reportMetadata.availableCharts, 'lineChart');
          }
        ]
      );
  
  }(window.angular, window._));
  

}(window.angular));

; (function (angular) {
  'use strict';

  angular.module('avatarEditor', ['ui.bootstrap', 'ngFileUpload']);
  ; (function (angular) {
    'use strict';
  
    angular
    .module('avatarEditor')
    .directive('equalHeightThumbnails', function() {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          // Cache thumbnail selector
          var _thumbnails = element.find(".thumbnail");
  
          // On window resize, resize thumbnail heights
          angular.element(window).on('resize', function () {
            // Reset all thumbnail heights
            // so that max height can be ascertained
            removeThumbnailsHeight();
            // Calculate maximum height and resize thumbnail
            resizeThumbnails();
          });
  
          // Calculate maximum height and resize thumbnail on page load
          resizeThumbnails();
  
          // Function for resizing thumbnails to maximum height
          function resizeThumbnails () {
            _thumbnails.height(
              getMaximumThumbnailHeight(
                getAllThumbnailHeights()
              )
            );
          }
  
          // Function for reseting all thumbnail heights to auto
          function removeThumbnailsHeight () {
            _thumbnails.height('auto');
          }
  
          // Function obtains the hightest height of array provided
          function getMaximumThumbnailHeight (thumbnailHeights) {
            return Math.max.apply(null, thumbnailHeights);
          }
  
          // Returns array of heights for all found thumbnails
          function getAllThumbnailHeights () {
            return _thumbnails.map(function () {
              return $(this).height();
            });
          }
        }
      };
   });
  }(window.angular));
  

}(window.angular));

; (function (angular) {
  'use strict';

  angular.module( 'kudosOrganisations', ['kudosNotifications']);
  ; (function (angular, _) {
  
    'use strict';
  
    angular.module('kudosOrganisations')
      .factory('OrganisationInviteService', [
        '$http',
        'NotificationService',
        function ($http, NotificationService) {
          return {
            createInvite: createInvite
          };
  
          function createInvite (shortCode, articleId, emailAddress, name, message, recommendation) {
            var payload = {
              email: emailAddress,
              article_id: articleId,
              message: message,
              name: name
            };
  
            if (angular.isDefined(recommendation)) {
              payload.triggering_recommendation = {id: recommendation.id}; // see internal_api/app.rb
            }
  
            return $http.post('/internal_api/organisations/' + shortCode + '/invites', payload).catch(function (response) {
              _.each(response.data.errors, function (error) {
                NotificationService.error(error);
              });
            });
          }
        }
      ]
    );
  
  }(window.angular, window._));
  
  
  ; (function (angular) {
    'use strict';
    angular
      .module('kudosOrganisations')
      .factory(
        'OrganisationService',
        [
        '$http',
        '$state',
        function ($http, $state) {
          var self = this;
  
          self.getCustomerOrganisations = function () {
            return $http.get('/internal_api/organisations')
                    .then(function (response) {
                      return response.data.data.organisations;
                    })
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
          };
  
          return self;
        }
      ]
    );
  
  }(window.angular));
  
  ; (function (angular, _) {
  
    'use strict';
  
    angular.module('kudosOrganisations')
      .factory('OrganisationExplainNudgeService', [
        '$http',
        'NotificationService',
        function ($http, NotificationService) {
          return {
            createNudge: createNudge
          };
  
          function createNudge (shortCode, articleId, recommendation, message) {
            var payload = {
              article_id: articleId,
              message: message
            };
  
            if (angular.isDefined(recommendation)) {
              payload.triggering_recommendation = {id: recommendation.id}; // see internal_api/app.rb
            }
  
            return $http.post('/internal_api/organisations/' + shortCode + '/explain_nudges', payload).catch(function (response) {
              _.each(response.data.errors, function (error) {
                NotificationService.error(error);
              });
            });
          }
        }
      ]
    );
  
  }(window.angular, window._));
  
  
  
  ; (function (angular, _) {
  
    'use strict';
  
    angular.module('kudosOrganisations')
      .factory('OrganisationShowcaseService', [
        '$http',
        'NotificationService',
        function ($http, NotificationService) {
          return {
            addPublicationToShowcase: addPublicationToShowcase
          };
  
          function addPublicationToShowcase (shortCode, articleId, recommendation) {
            var payload = {
              article_id: articleId
            };
  
            if (angular.isDefined(recommendation)) {
              payload.triggering_recommendation = {id: recommendation.id}; // see internal_api/app.rb
            }
  
            return $http.post('/internal_api/organisations/' + shortCode + '/showcase', payload).catch(function (response) {
              _.each(response.data.errors, function (error) {
                NotificationService.error(error);
              });
            });
          }
        }
      ]
    );
  
  }(window.angular, window._));
  
  
  

}(window.angular));



; (function (angular) {

  "use strict";

  angular.module('kudosCards', ['templates', 'ngSanitize', 'kudos', 'kudosLists', 'kudosNotifications', 'kudosOrganisations']);
  ; (function (angular) {
  
    'use strict';
    angular.module('kudosCards')
      .directive('kudosCard', [
        function () {
          return {
            transclude: true,
            replace: true,
            templateUrl: 'kudosCards/kudosCard.directive.html',
            controllerAs: 'vm',
            scope: {},
            bindToController: {
              recommendation: '=',
              hideSkip: '=?'
            },
            controller: [
              'CardDeckService',
              'RecommendationService',
              function (CardDeckService, RecommendationService) {
                var self = this;
  
                // initialise the controller
                init();
  
                // public API
                self.skip = skip;
                self.nextCard = CardDeckService.nextCard;
  
                function init () {
                  self.skipped = false;
                }
  
                function skip () {
                  RecommendationService.skipRecommendation(self.recommendation.id)
                    .finally(function () {
                      CardDeckService.nextCard();
                    });
                }
              }
            ]
          };
        }
      ]);
  
  }(window.angular));
  
  
  ; (function (angular) {
  
    'use strict';
    angular.module('kudosCards')
      .directive('kudosActionClaimCard', [
        function () {
          return {
            templateUrl: 'kudosCards/kudosActionClaimCard.directive.html',
            controllerAs: 'vm',
            scope: {},
            bindToController: {
              organisation: '=',
              publication: '=',
              recommendation: '='
            },
            controller: [
              '$element',
              'NotificationService',
              'OrganisationInviteService',
              'CardDeckService',
              function ($element, NotificationService, OrganisationInviteService, CardDeckService) {
                var self = this;
  
                self.cardState = {
                  completed: false,
                  emailStep: true,
                  nameMessageStep: false
                };
  
                self.form = {
                  email: '',
                  message: '',
                  submitted: false
                };
  
                self.createClaimInvite = createClaimInvite;
                self.nextCard = CardDeckService.nextCard;
  
                self.isEmailStep = isEmailStep;
                self.isNameMessageStep = isNameMessageStep;
                self.proceedToNameMessageStep = proceedToNameMessageStep;
  
                function createClaimInvite () {
                  self.form.submitted = true;
  
                  if (!self.form.email) {
                    return;
                  }
  
                  OrganisationInviteService.createInvite(
                    self.organisation.short_code,
                    self.publication.kudos_internal_id,
                    self.form.email,
                    self.form.name,
                    self.form.message,
                    self.recommendation
                  ).then(function (response) {
                    self.cardState.completed = angular.isDefined(response);
                  });
                }
  
                function isEmailStep () {
                  return self.cardState.emailStep && !self.cardState.completed;
                }
  
                function isNameMessageStep () {
                  return self.cardState.nameMessageStep && !self.cardState.completed;
                }
  
                function proceedToNameMessageStep () {
                  self.cardState.emailStep = false;
                  self.cardState.nameMessageStep = true;
                }
              }
            ]
          };
        }
    ]);
  
  }(window.angular));
  
  ; (function (angular) {
  
    'use strict';
    angular.module('kudosCards')
      .directive('kudosActionExplainCard', [
        function () {
          return {
            templateUrl: 'kudosCards/kudosActionExplainCard.directive.html',
            controllerAs: 'vm',
            scope: {},
            bindToController: {
              organisation: '=',
              publication: '=',
              recommendation: '='
            },
            controller: [
              'OrganisationExplainNudgeService',
              'CardDeckService',
              function (OrganisationExplainNudgeService, CardDeckService) {
                var self = this;
  
                self.cardState = {
                  completed: false
                };
  
                self.orgMessage = '';
  
                self.createExplainNudge = createExplainNudge;
                self.nextCard = CardDeckService.nextCard;
  
                function createExplainNudge () {
                  OrganisationExplainNudgeService.createNudge(self.organisation.short_code, self.publication.kudos_internal_id, self.recommendation, self.orgMessage)
                    .then(function (response) {
                      self.cardState.completed = angular.isDefined(response);
                    });
                }
              }
            ]
          };
        }
      ]);
  
  }(window.angular));
  
  
  ; (function (angular) {
  
    'use strict';
    angular.module('kudosCards')
      .directive('kudosActionShareCard', [
        'CardDeckService',
        function (CardDeckService) {
          return {
            templateUrl: 'kudosCards/kudosActionShareCard.directive.html',
            controllerAs: 'vm',
            scope: {},
            bindToController: {
              publication: '=',
              recommendation: '='
            },
            controller: [
              function () {
                var self = this;
  
                self.cardStep = 0;
                self.hideSkip = false;
  
                self.proceedToSharing = proceedToSharing;
                self.onShareSuccess = onShareSuccess;
                self.nextCard = CardDeckService.nextCard;
                self.showNextButton = showNextButton;
  
                function showNextButton () {
                  self.hideSkip = true;
                }
  
                function nextStep () {
                  if (self.cardStep < 2) {
                    self.cardStep++;
                  }
  
                  self.hideSkip = (self.cardStep === 2);
                }
  
                function onShareSuccess () {
                  nextStep();
                }
  
                function proceedToSharing () {
                  nextStep();
                }
              }
            ]
          };
        }
      ]);
  
  }(window.angular));
  
  ; (function (angular) {
  
    'use strict';
    angular.module('kudosCards')
      .directive('kudosPromoteCard', [
        function () {
          return {
            templateUrl: 'kudosCards/kudosPromoteCard.directive.html',
            controllerAs: 'vm',
            scope: {},
            bindToController: {
              organisation: '=',
              publication: '=',
              recommendation: '='
            },
            controller: [
              'OrganisationShowcaseService',
              'CardDeckService',
              function (OrganisationShowcaseService, CardDeckService) {
                var self = this;
  
                self.cardState = {
                  completed: false
                };
  
                self.addPublicationToShowcase = addPublicationToShowcase;
                self.nextCard = CardDeckService.nextCard;
  
                function addPublicationToShowcase () {
                  OrganisationShowcaseService.addPublicationToShowcase(self.organisation.short_code, self.publication.kudos_internal_id, self.recommendation)
                    .then(function (response) {
                      self.cardState.completed = angular.isDefined(response);
                    });
                }
              }
            ]
          };
        }
      ]);
  
  }(window.angular));
  
  ; (function (angular) {
  
    'use strict';
  
    angular.module('kudosCards')
      .directive('kudosFollowCard', [
        function () {
          return {
            templateUrl: 'kudosCards/kudosFollowCard.directive.html',
            controllerAs: 'vm',
            scope: {},
            bindToController: {
              listItem: '=',
              publication: '=',
              recommendation: '='
            },
            controller: [
              'ListItemService',
              'CardDeckService',
              function (ListItemService, CardDeckService) {
                var self = this;
  
                self.cardState = {
                  completed: false
                };
  
                self.addToWatchList = addToWatchList;
                self.nextCard = CardDeckService.nextCard;
  
                function addToWatchList () {
                  ListItemService.addListItemProperty(self.listItem, 'Watched', self.recommendation)
                    .then(function (response) {
                      self.cardState.completed = angular.isDefined(response);
                    });
                }
              }
            ]
          };
        }
      ]);
  
  }(window.angular));
  
  
  ; (function (angular, _) {
  
    'use strict';
    angular.module('kudosCards')
      .directive('kudosCardDeck', [
        '$rootScope',
        '$timeout',
        function ($rootScope, $timeout) {
          return {
            replace: true,
            link: function (scope, element) {
              var cards = [];
              var currentCardIndex = 0;
  
              init();
  
              // Moves to next card when event is received
              $rootScope.$on('cardDeck.next', nextCard);
  
              function init () {
                // Obtains a list of all the cards in the deck
                cards = _.map(element.find('.kudos-card'), function (card, index) {
                  return angular.element(card);
                });
  
                // If there aren't any cards to start with, go straight to the final state
                if (!cards.length) {
                  showCompletionMessage();
                }
              }
  
              /**
               * Display the `completed` state for a short time
               * then transition to `state-final`.
               */
              function showCompletionMessage () {
                element.addClass('completed');
              }
  
              function nextCard () {
                _.each(cards, function (card, index) {
                  // Ensures all cards up to the current card are marked as completed
                  if (index <= currentCardIndex) {
                    card.addClass('completed');
                  }
  
                  // Marks the next card as current
                  if (index === (currentCardIndex + 1)) {
                    card.addClass('current');
                  }
                });
  
                // Increments the current card index
                if (currentCardIndex <= (cards.length - 1)) {
                  currentCardIndex++;
                }
  
                // If all the cards have been completed, adds completed class to card deck container
                if (currentCardIndex === cards.length) {
                  showCompletionMessage();
                }
              }
            }
          };
        }
      ]);
  
  }(window.angular, window._));
  
  ; (function (angular) {
    'use strict';
    angular
      .module('kudosCards')
      .factory(
        'CardDeckService',
        [
          '$rootScope',
          function ($rootScope) {
            return {
              nextCard: nextCard
            };
  
            function nextCard () {
              $rootScope.$emit('cardDeck.next');
            }
          }
        ]
      );
  
  }(window.angular));
  
  
  ; (function (angular) {
  
    'use strict';
    angular.module('kudosCards')
      .directive('kudosUserManagementCard', [
        function () {
          return {
            templateUrl: 'kudosCards/kudosUserManagementCard.directive.html',
            controllerAs: 'vm',
            scope: {},
            bindToController: {
              organisation: '=',
              recommendation: '='
            },
            controller: [
              function () {
                var self = this;
                self.$onInit = init;
  
                var AUTHORISE_TWITTER = 6;
                var AUTHORISE_FACEBOOK = 7;
                var AUTHORISE_LINKEDIN = 8;
  
  
                self.cardState = {
                  completed: false
                };
  
                function init () {
                  if (self.recommendation) {
                    switch (self.recommendation.action) {
  
                      case AUTHORISE_TWITTER:
                        self.social_media_name = "twitter";
                        self.social_media_label = "Twitter";
                        break;
  
                      case AUTHORISE_FACEBOOK:
                        self.social_media_name = "facebook";
                        self.social_media_label = "Facebook";
                        break;
  
                      case AUTHORISE_LINKEDIN:
                        self.social_media_name = "linkedin";
                        self.social_media_label = "LinkedIn";
                        break;
  
                      default:
                        self.social_media_name = "unknown";
                        self.social_media_label = "Unknown";
                    }
                  } else {
                    self.social_media_name = "github"; // for the benefit of the showcase
                    self.social_media_label = "Github";
                  }
                }
  
  
              }
            ]
          };
        }
      ]);
  
  }(window.angular));
  

} (window.angular));

; (function (angular) {
  'use strict';

  angular.module('kudosLists', ['kudosNotifications', 'kudosLists']);
  ; (function (angular, _) {
  
    'use strict';
  
    angular.module('kudosLists')
      .factory('ListItemService', [
        '$http',
        'NotificationService',
        function ($http, NotificationService) {
          return {
            addListItemProperty: addListItemProperty
          };
  
          function addListItemProperty (listItem, newProperty, recommendation) {
  
            var attributes = {};
  
            if (angular.isUndefined(listItem.properties)) {
              listItem.properties = [];
            }
  
            attributes.properties = listItem.properties.concat({
              name: newProperty
            });
  
            return updateListItem(listItem.id, attributes, recommendation);
          }
  
          function updateListItem (id, attributes, recommendation) {
            var payload = attributes;
  
            if (angular.isDefined(recommendation)) {
              payload.triggering_recommendation = {id: recommendation.id}; // see internal_api/app.rb
            }
  
            return $http.put('/internal_api/list_items/' + id , payload)
              .catch(function (response) {
                _.each(response.data.errors, function (error) {
                  NotificationService.error(error);
                });
              });
          }
        }
      ]
    );
  
  }(window.angular, window._));
  
  
  

}(window.angular));




(function (angular) {
  "use strict";

  angular.module("kudos", [
    "core",
    "xeditable",
    "ui.router",
    "controllers",
    "sessions",
    "templates",
  ]);
  ; (function (angular) {
    'use strict';
    angular.module('kudos').filter('trusted', ['$sce', function ($sce) {
      return function(url) {
        return $sce.trustAsResourceUrl(url);
      };
    }]);
  }(window.angular));
  
  (function(angular){
    'use strict';
    angular
      .module('kudos')
        .directive('truncateExpand', [
          function () {
            return {
              scope: {
                content: '@',
                limit: '@'
              },
              link: function (scope) {
                scope.showExpand = (scope.content.length > scope.limit);
                scope.isExpanded = false;
              },
              templateUrl: 'kudos/directives/truncateExpand.html',
            };
          }
      ]);
  })(window.angular);
  
  (function (angular){
    'use strict';
    angular
      .module('kudos')
        .directive('kudosUploader', [
          'FileUploader',
          function (FileUploader) {
            return {
              scope: {
                uploadUrl: '@',
                uploadName: '@',
                smallButtons: '=?',
                onUploadComplete: '=?',
                onUploadError: '=?'
              },
              link: function (scope) {
                scope.loaded = false;
                scope.uploading = false;
  
                scope.fileName = '';
  
                scope.buttonClass = {
                  'btn-sm': !!scope.smallButtons
                };
  
                scope.uploader = new FileUploader({
                  url: scope.uploadUrl,
                  alias: scope.uploadName
                });
  
                scope.cancel = function () {
                  scope.uploader.clearQueue();
                  scope.fileName = '';
                  scope.uploading = false;
                };
  
                scope.upload = function () {
                  if (scope.fileLoaded()) {
                    scope.uploading = true;
                    scope.uploader.uploadAll();
                  }
                };
  
                scope.fileLoaded = function () {
                  return !!scope.uploader.queue.length;
                };
  
                scope.uploader.onAfterAddingFile = function (fileItem) {
                  scope.fileName = fileItem.file.name;
                };
  
                scope.uploader.onErrorItem = function (fileItem, response, status, headers) {
                  (!!scope.onUploadError && scope.onUploadError(fileItem, response));
                  scope.uploading = false;
                };
  
                scope.uploader.onCompleteItem = function (fileItem, response, status, headers) {
                  (status === 200) && (!!scope.onUploadComplete && scope.onUploadComplete(fileItem, response, status, headers));
                };
  
                scope.uploader.onCompleteAll = function () {
                  scope.cancel();
                };
  
                scope.loaded = true;
              },
              templateUrl: 'kudos/directives/kudosUploader.html'
            };
          }
      ]);
  })(window.angular);
  
  
  (function(angular, _) {
    'use strict';
    angular
      .module('kudos')
        .directive('listSortOrder', [
          function () {
            return {
              scope: {
                ngModel: '=',
                onSave: '=',
                templateFn: '=?'
              },
              templateUrl: 'kudos/directives/listSortOrder.html',
              link: function (scope) {
  
                scope.editing = false;
  
                // Verbose function that returns whether the list is being re-arranged or not.
                scope.isEditing = function () {
                  return (scope.editing !== false);
                };
  
                // Verbose function that returns whether an array element at the index provided
                // is the item being moved.
                scope.isEditingIndex = function (index) {
                  return (scope.editing === index);
                };
  
                // Enter "editing" re-arrange mode for item at provided index
                scope.edit = function (index) {
                  scope.editing = index;
                };
  
                // Leave "editing" mode
                scope.cancel = function () {
                  scope.editing = false;
                };
  
                scope.show = {};
  
                // Checks if the first move to row should be shown, this is to ensure you can only move items to
                // appropriate places.
                scope.show.firstMoveToRow = function () {
                  return !scope.isEditing() || scope.isEditingIndex(0);
                };
  
                // Checks if the move to row at a provided index should be shown, this is to ensure you can only
                // move items to appropriate places.
                scope.show.indexMoveToRow = function (index) {
                  return !scope.isEditing() || (scope.isEditingIndex(index + 1) || scope.isEditingIndex(index));
                };
  
                // Moves currently edited item to provided index
                scope.moveTo = function (moveToIndex) {
  
                  // Only shoots web when it should
                  if (scope.editing !== false) {
  
                    // Create a new array from scratch
                    var tempArray = [];
  
                    // Get element of the array that is being moved
                    var movedItem = scope.ngModel[scope.editing];
  
                    _.each(scope.ngModel, function (listItem, index) {
  
                      // Push moved item to array if at desired index
                      if (index === moveToIndex) {
                        tempArray.push(movedItem);
                      }
  
                      // If listItem is not currently moved item, push it to the array.
                      if (index !== scope.editing) {
                        tempArray.push(listItem);
                      }
  
                    });
  
                    // This caters for moving an item to the end of the array (moveToIndex is never reached
                    // because array is not long enough, so without the item being moved goes missing).
                    if (moveToIndex === scope.ngModel.length) {
                      tempArray.push(movedItem);
                    }
  
                    scope.ngModel = tempArray;
  
                    // Call provided onSave scope function with newly arranged array
                    scope.onSave(scope.ngModel);
  
                    // Cancel the moving state
                    scope.cancel();
                  }
                };
  
              }
            };
          }
      ])
      .directive('compile', ["$compile", function($compile) {
        return function(scope, element, attrs) {
          require: '^listSortOrder',
          scope.$watch(
            function(scope) {
              return scope.$eval(attrs.compile);
            },
            function(value) {
              element.html(value);
              $compile(element.contents())(scope);
            }
          );
        };
      }]);
  
  })(window.angular, window._);
  
  
  ; (function (angular) {
    'use strict';
  
    angular
      .module('kudos')
      .directive('kudosEditableText', function() {
        return {
          scope: {
            ngModel: '=',
            isEditable: '=',
            onEdit: '&',
            onAfterSave: '&',
            editing: '=',
            formPlaceholder: '@',
            pristineText: '@',
            truncateAt: '@'
          },
          templateUrl: 'kudos/directives/kudosEditableText.html',
          replace: true,
          link: function (scope) {
  
            scope.cancel = function () {
              scope.editing = false;
            };
  
            scope.show = function () {
              scope.editing = true;
            };
  
            scope.$watch('editing', function (newValue) {
              if (newValue !== undefined) {
                if (newValue) {
                  scope.editableThing.$show();
                } else {
                  scope.editableThing.$hide();
                }
              }
            });
          }
        };
      });
  }(window.angular));
  
  
  (function (angular) {
    'use strict';
  
    angular
      .module('kudos')
      .directive('kudosKpi', [
        function () {
          return {
            scope: {},
            bindToController: {
              title: '@',
              value: '=',
              green: '=?',
              disabled: '=?',
              targetSref: '@',
              targetHref: '@',
              clickable: '=?',
              bottomLabel: '=?'
            },
            transclude: true,
            replace: true,
            templateUrl: 'kudos/directives/kudosKpi.html',
            controllerAs: 'vm',
            controller: ['$state', function ($state) {
              var self = this;
  
              self.gotoTargetSref = gotoTargetSref;
              self.$onInit = init;
  
              function init() {
                // If clickable has been defined as false, it is not clickable, if not defined or explicitly defined as true, make it clickable
                self.clickable = angular.isUndefined(self.clickable) || 
                  (angular.isDefined(self.clickable) && self.clickable !== false);
                // bottomLabel has to explicitly be set to true and anything else will be considered false
                self.bottomLabel = self.bottomLabel === true;
  
                self.kpiClass = {
                  'kpi-disabled': !!self.disabled,
                  'kpi-widget-green': !!self.green,
                  'clickable': self.clickable
                };
              }
  
              function gotoTargetSref() {
                // If the kpi widget is disabled, do not respond to clicks
                if (!!self.disabled || (self.targetHref === 'false')) {
                  return;
                }
  
                if (!!self.targetSref) {
                  $state.go(self.targetSref);
                } else if (!!self.targetHref) {
                  window.open(self.targetHref, '_blank');
                }
              }
            }]
          };
        }
      ]);
  
  } (window.angular));
  
  (function(angular){
    'use strict';
    angular
      .module('kudos')
        .directive('kudosLoader', [
          '$timeout',
          function ($timeout) {
            return {
              scope: {
                show: '=',
                noDelay: '=?'
              },
              link: function (scope) {
  
                var timeoutPromise;
  
                // If noDelay is set, set timeout to 0, else default to 1 second
                var delayTime = (!!scope.noDelay ? 0 : 1000);
  
                scope.fadeInClass = {
                  'fade-in': false
                };
  
                function toggleFadeInClass(fadeIn) {
                  scope.fadeInClass = {
                    'fade-in': !!fadeIn
                  };
                }
  
                function startTimeout() {
                  timeoutPromise = $timeout(function () {
                    toggleFadeInClass(true);
                  }, delayTime);
                }
  
                function stopTimeout() {
                  toggleFadeInClass(false);
  
                  if (!angular.isUndefined(timeoutPromise)) {
                    $timeout.cancel(timeoutPromise);
                  }
                }
  
                scope.$watch('show', function (isShowing) {
                  if (isShowing) {
                    startTimeout();
                  } else {
                    stopTimeout();
                  }
                });
  
              },
              templateUrl: 'kudos/directives/kudosLoader.html',
            };
          }
      ]);
  })(window.angular);
  
  
  ; (function (angular) {
  
    'use strict';
  
    angular.module('kudos')
      .directive('kudosFooter', [function () {
        return {
          templateUrl: 'kudos/directives/kudosFooter.html',
          replace: true,
          link: function (scope) {
            scope.currentYear = new Date().getFullYear();
          }
        };
      }]);
  
  }(window.angular));
  
  
  ; (function (angular, _, $) {
  
    'use strict';
    angular.module('kudos')
      .directive('kudosNavbar', ["SessionService", function (SessionService) {
        return {
          transclude: true,
          templateUrl: 'kudos/directives/kudosNavbar.html',
          controllerAs: 'vm',
          compile: function (element, attrs) {
            // If stateless attr has been applied to directive, remove statechange
            // functionality so that traditional linking is used instead.
            // This is so that the Angular header and sidebar can be used in UX1
            // pages.
            if (angular.isDefined(attrs['stateless'])) {
              return {
                // Post-link intervention function
                post: function (scope, element, attrs) {
                  // Finds all instances of 'ui-sref' in the element tree, and
                  // remove the 'ui-sref' attribute
                  var $anchors = $(element).find('[ui-sref]');
                  if($anchors.length) {
                    _.each($anchors, function (anchor) {
                      // Ensure that anchor tag's behaviour has been properly re-defined
                      $(anchor)
                        .removeAttr('ui-sref')
                        .unbind()
                        .click(function () {
                          window.location = $(this).attr('href');
                        });
                    });
                  }
                }
              };
            }
          },
          controller: ["$scope", "$location", function ($scope, $location) {
            var self = this;
  
            self.isSidebarShown = false;
            self.showConfirmationBanner = false;
            self.toggleSidebar = toggleSidebar;
  
            self.currentUser = SessionService.currentUser;
            self.userIsLoggedIn = SessionService.userIsLoggedIn;
            self.userIsVerified = SessionService.userIsVerified;
  
            self.showLoginForm = SessionService.showLoginForm;
  
            self.resendConfirmationEmail = SessionService.resendConfirmationEmail;
  
            self.showConfirmationBanner = function () {
              return self.userIsLoggedIn() && !self.userIsVerified()
            };
  
            self.isTransparentBackground = function () {
              // match a '/' or '' (empty) path
              return $location.path().match(/^\/?$/) !== null;
            };
  
            function toggleSidebar () {
              $scope.$broadcast('sidebar.toggleShown');
            }
  
            $scope.$on('sidebar.shownChange', function (event, isShown) {
              self.isSidebarShown = isShown;
            });
          }]
        };
      }]);
  
  }(window.angular, window._, window.jQuery));
  
  ; (function (angular) {
  
    'use strict';
    angular.module('kudos')
      .directive('kudosConfirmationBanner', function () {
        return {
          templateUrl: 'kudos/directives/kudosConfirmationBanner.html',
          controllerAs: 'banner',
          controller: ['SessionService', function (SessionService) {
            var self = this;
  
            self.currentUser = SessionService.currentUser;
            self.userIsLoggedIn = SessionService.userIsLoggedIn;
            self.userIsVerified = SessionService.userIsVerified;
  
  
            self.resendConfirmationEmail = SessionService.resendConfirmationEmail;
  
            self.showConfirmationBanner = function () {
              return self.userIsLoggedIn() && !self.userIsVerified();
            };
          }]
        };
      });
  
  }(window.angular));
  
  ; (function (angular) {
  
    'use strict';
    angular.module('kudos')
      .directive('kudosSidebar', ["$document", function ($document) {
        return {
  
          templateUrl: 'kudos/directives/kudosSidebar.html',
          controllerAs: "vm",
          controller: ["$scope", "SessionService", function($scope, SessionService){
            var self = this;
  
            self.toggleShown = toggleShown;
            self.isLoggedIn = SessionService.userIsLoggedIn;
            self.isAdmin = SessionService.userIsAdmin;
            self.currentUser = SessionService.currentUser;
            self.signOut = SessionService.signOut;
  
            self.showLoginForm = SessionService.showLoginForm;
            self.userIsLoggedIn = SessionService.userIsLoggedIn;
  
            function toggleShown() {
              self.isShown = !self.isShown;
              $scope.$emit('sidebar.shownChange', self.isShown);
            }
  
            $scope.$on('sidebar.toggleShown', toggleShown);
            $scope.$on('sidebar.hide', function () {
              self.isShown = false;
              $scope.$emit('sidebar.shownChange', self.isShown);
            });
            $scope.$on('sidebar.show', function () {
              self.isShown = true;
              $scope.$emit('sidebar.shownChange', self.isShown);
            });
          }],
          link: function (scope, element, attrs) {
            $document.click(function(event) {
              if(!event.target.closest('.sidebar') && !event.target.closest('.navbar')){
                if(element.find('.sidebar').is(":visible")) {
                  scope.$emit('sidebar.hide');
                  scope.$digest();
                }
              }
            });
          }
        };
      }]);
  
  }(window.angular));
  
  ; (function (angular) {
  
    'use strict';
  
    angular.module('kudos')
      .factory('ValidationService', [
        function () {
          return {
  
            isUrl: function (input, error, optional) {
              if(optional && input === '') {
                return undefined;
              }
  
              if(angular.isUndefined(input) || !input.match(/^https?:\/\/.+$/)) {
                return error;
              }
            },
  
            isTrue: function (input, error) {
              if (input === false) {
                return error;
              }
            },
  
            isTruthy: function (input, error) {
              if (!input) {
                return error;
              }
            }
  
          };
        }
      ]
    );
  
  }(window.angular));
  
  
  ; (function (angular) {
    'use strict';
  
    /* Intended to perform a similar function to interrogating ENV['RACK_ENV'] in Ruby.
     *
     * For now this just has the capability to determine environment from the host (domain) of the page
     * It could in the future interrogate an internal API endpoint to find out what env the Ruby is
     * running under.
     * Think twice and then think again before using this service. The less (ideally none) env-
     * specific conditions and hacks there are in the code, the better. Only use as last resort for
     * temporarily (e.g. few weeks) hiding something or doing something differently.
     */
    angular
      .module('kudos')
        .factory(
          'EnvService',
          [
            function () {
  
              function getEnv () {
                return decideEnv(window.location.host);
              }
  
              function decideEnv (hostname) {
                var env;
                if (hostname === 'growkudos.com'
                  || hostname.match(/www[0-9]*\.growkudos.com/))
                {
                  env = 'production';
                } else if (hostname === 'test.growkudos.com') {
                  env = 'review';
                } else {
                  env = 'development';
                }
                // no way to detect test (e.g. cucumber) by hostname
                return env;
              }
  
              return {
                getEnv: getEnv,
                _decideEnv: decideEnv  // quite difficult to test otherwise
              };
            }
          ]
        );
  
  }(window.angular));
  
  
  
  ; (function (angular) {
    'use strict';
  
    angular
    .module('kudos')
    .directive('noHtml5ValidationBubble', [
      function() {
        return {
          restrict: 'C',
          link: function (scope, element, attributes) {
            element.on('invalid', function (event) {
              // Stops native browser inline valiadtion bubble
              event.preventDefault();
            });
          }
        };
       }
     ]);
  
  }(window.angular));
  
  ; (function (angular) {
    'use strict';
  
    angular
    .module('kudos')
    .directive('styleFormValidation', [
      '$rootScope',
      function () {
        return {
          restrict: 'A',
          link: function (scope, element) {
            /* If child input element adds/removes HTML5 :invalid
             * pseudo form validation element, add or remove .invalid
             * class to container so that the error state can be shared.
             */
            element.on('keyup paste', changeHandler);
  
            function changeHandler () {
              if (!!element.find('input:invalid').length) {
                element.addClass('invalid');
              } else {
                element.removeClass('invalid');
              }
            }
  
            changeHandler();
          }
        };
      }
    ]);
  
  }(window.angular));
  
  
  ; (function (angular, _, moment) {
  
    'use strict';
  
    angular
      .module('kudos')
        .factory(
          'RecommendationService',
          [
            '$http',
            'NotificationService',
            function ($http, NotificationService) {
              return {
                skipRecommendation: skipRecommendation,
                updateRecommendation: updateRecommendation
              };
  
              function skipRecommendation (id) {
                return updateRecommendation(id, {
                  skipped_until: moment().startOf('day').add(7, 'days').format('YYYY-MM-DD')
                });
              }
  
              function updateRecommendation (id, attributes) {
                return $http.put('/internal_api/recommendations/' + id, attributes)
                  .catch(function (response) {
                    _.each(response.data.errors, function (error) {
                      NotificationService.error(error);
                    });
                  });
              }
            }
          ]
        );
  
  }(window.angular, window._, window.moment));
  
  ; (function (angular) {
    'use strict';
  
    angular
    .module('kudos')
    .directive('toggleClass', [
      'toggleClassService',
      function (
        toggleClassService
      ) {
        return {
          scope: {
            className: '@',
            toggleGroup: '@'
          },
          link: function (scope, element) {
            element.find('a').on('click', function (event) {
              event.stopImmediatePropagation();
            });
  
            element.find('.toggle-class-click').on('click', toggleClass);
  
            function toggleClass () {
              if (hasClass()) {
                removeClass();
              } else {
                addClass();
              }
            }
  
            scope.toggler = {on: addClass, off: removeClass, isOn: hasClass};
            if (scope.toggleGroup) {
              addToToggleGroup(scope.toggleGroup, scope.$id, scope.toggler);
            }
  
            function hasClass() {
              return element.hasClass(scope.className);
            }
  
            function addClass() {
              element.addClass(scope.className);
            }
  
            function removeClass() {
              element.removeClass(scope.className);
            }
  
            function addToToggleGroup(groupId, uid, toggler) {
              toggleClassService.addToGroup(groupId, uid, toggler);
              scope.$on('$destroy', function () {
                toggleClassService.removeFromGroup(groupId, uid);
              });
            }
          }
        };
      }
    ]);
  
  }(window.angular));
  
  ; (function (angular) {
    'use strict';
  
    angular
    .module('kudos')
    .service('toggleClassService', [
      function () {
        var self = this;
  
        self.addToGroup = addTogglerToGroup;
        self.removeFromGroup = removeTogglerFromGroup;
        self.toggleGroup = toggleEachInGroup;
  
        // Allow groups structure and toggler interface to change independently of
        // each other.
        function toggleEachInGroup(groupId) {
          var group = getGroup(groupId);
          var allOn = true;
          angular.forEach(group, function (toggler, uid) {
            // This defines the toggler interface.
            if (!implementsMethods(toggler, ['on', 'off', 'isOn'])) {
              throw new Error('Toggler must have methods "on", "off", and "isOn": uid='+uid);
            }
            if (allOn && !toggler.isOn()) {
              allOn = false;
            }
          });
          angular.forEach(group, function (toggler) {
            if (allOn) {
              toggler.off();
            } else {
              toggler.on();
            }
          });
        }
  
        function implementsMethods(toggler, methods) {
            if (!toggler || !angular.isObject(toggler)) {
              return false;
            }
            for (var i = 0; i < methods.length; i++) {
              if (!angular.isFunction(toggler[methods[i]])) {
                return false;
              }
            }
            return true;
        }
  
        self._groups = {};
  
        function getGroup(id) {
          var group = self._groups[id];
          if (!group) {
            throw new Error('Group does not exist: groupId=' + id);
          }
          return group;
        }
  
        function getOrCreateGroup(id) {
          var group = self._groups[id];
          if (!group) {
            group = self._groups[id] = {};
          }
          return group;
        }
  
        function addTogglerToGroup(groupId, uid, toggler) {
          var group = getOrCreateGroup(groupId);
          if (group[uid]) {
            throw new Error('Toggler is already in group: groupId='+groupId+' uid='+uid);
          }
          group[uid] = toggler;
        }
  
        function removeTogglerFromGroup(groupId, uid) {
          if (!self._groups[groupId]) {
            return;
          }
          delete self._groups[groupId][uid];
        }
      }
    ]);
  
  }(window.angular));
  
  ; (function (angular) {
    'use strict';
  
    angular
    .module('kudos')
    .directive('toggleClassGroup', [
      'toggleClassService',
      function (
        toggleClassService
      ) {
        return {
          scope: {
            group: '@'
          },
          link: function (scope, element) {
            element.on('click', toggleGroup);
  
            function toggleGroup () {
              toggleClassService.toggleGroup(scope.group);
            }
          }
        };
      }
    ]);
  
  }(window.angular));
  
  ; (function (angular, _) {
    'use strict';
  
    angular
      .module('kudos')
        .service(
          'kudosFormService',
          [
            '$q',
            '$rootScope',
            '$window',
            'RegionComponentChangedService',
            kudosFormService
          ]
        );
  
    function kudosFormService ($q, $rootScope, $window, RegionComponentChangedService) {
      var self = this;
  
      // Form save event broadcast name constant
      self.FormSaveStateEventName = 'kudosFormSaveState';
      self.UnsavedFormUnloadEventName = 'unsavedFormUnload';
  
      self.form = {};
      self.updateFunction = false;
  
      self.init = init;
  
      $window.onbeforeunload = onBeforeUnloadHandler;
  
      // Function for handling onBeforeUnload event and showing
      // a confirmation dialog when a user attempts
      // to close or leave the page with unsaved form changes.
      function onBeforeUnloadHandler () {
        if (_.keys(getAllChanges()).length || RegionComponentChangedService.hasChanged()) {
          // Broadcast event that signifies that the user tried to
          // close the page when the form has been changed and not saved.
          broadcastFormEvent(self.UnsavedFormUnloadEventName, 'opened');
  
          // unloadEvent.returnValue = getUnsavedChangeConfirmationText();
          return getUnsavedChangeConfirmationText();
        }
      }
  
      // Function for obtaining confirmation text to be shown when a user attempts
      // to close/leave the page with unsaved form changes.
      function getUnsavedChangeConfirmationText () {
        return 'You have unsaved edits. Before you leave this page, do you want to save your work?';
      }
  
      // Method to delegate form saving, and form state.
      function submitForm () {
        broadcastFormEvent(self.FormSaveStateEventName, 'waiting');
  
        var modelChanges = getAllChanges();
  
        // Run form submission function and broadcasts an event on success/failure
        getUpdateFunctions(
          modelChanges
        ).then(
          function () {
            broadcastFormEvent(self.FormSaveStateEventName, 'success');
            updateFormModel(modelChanges);
          },
          function () {
            broadcastFormEvent(self.FormSaveStateEventName, 'error');
          }
        );
      }
  
      // Method to return function that updates all changed fields for explain
      // fields and then returns a promise.
      function getUpdateFunctions (modelChanges) {
        return $q.all(
          _.map(
            modelChanges,
            function (changedFieldValue, changedFieldName) {
              return self.updateFunction(changedFieldName, changedFieldValue);
            }
          )
        );
      }
  
      // Updates the form model (used for comparing pre-save changes)
      function updateFormModel (modelChanges) {
        _.each(modelChanges, function (value, key) {
            // Assign top-level values to ensure object/array item deletions.
          self.model[key] = value;
        });
      }
  
      // Method for broadcasting form save activity so that other
      // components can respond appropriately to it.
      function broadcastFormEvent (eventName, state) {
        $rootScope.$broadcast(eventName, state);
      }
  
      // Method to compare original model with form fields and
      // returns an object detailing the field changed and the
      // new value. This can be used to selectively update changed
      // model fields.
      function getAllChanges () {
        var changes = {};
  
        _.each(self.form.fields, function (value, key) {
          if (value !== self.model[key]) {
            changes[key] = value;
          }
        });
        return changes;
      }
  
      // Service instance constructor.
      // Accepts config object:
      // e.g.
      // {
      //   model: {
      //     id: 1,
      //     name: 'Jeremy'
      //     lay_summary: "This is lay."
      //     article_id: 2,
      //     perspective: "My thoughts"
      //   },
      //   updateModelFunction: someUpdateFunction,
      // }
  
      function init (config) {
        self.model = config.model;
        self.updateFunction = config.updateModelFunction;
  
        self.form = {
          fields: _.clone(self.model),
          submit: submitForm
        };
      }
    }
  }(window.angular, window._));
  
  ; (function (angular) {
    'use strict';
  
    angular
      .module('kudos')
      .directive('progressButton', [
        function () {
          return {
            replace: true,
            templateUrl: 'kudos/progressButton.directive.html',
            scope: {},
            bindToController: {
              processState: '&',
              disabled: '&?',
              onClick: '=',
              buttonStates: '&?'
            },
            link: function (scope, element, attrs, controllers) {
              element.on('click', function (event) {
                event.preventDefault();
  
                scope.$apply(function () {
                  if (controllers.currentState !== 'waiting') {
                    scope.button.onClick();
                  }
                });
              });
            },
            controllerAs: 'button',
            controller: [
              '$scope',
              function ($scope) {
  
                var self = this;
  
                // Button state property definitions
                var ButtonStates = getButtonStates();
  
                self.currentState = ButtonStates.init.name;
  
                self.getButtonText = getButtonText;
                self.getButtonClass = getButtonClass;
                self.getButtonIconClass = getButtonIconClass;
                self.getButtonDisabled = getButtonDisabled;
  
                self.$onInit = function() {
                  // Apply override to button states when/if available
                  $scope.$watch(
                    function () {
                      return angular.isFunction(self.buttonStates);
                    },
                    function (buttonStatesDefined) {
                      if (buttonStatesDefined) {
                        ButtonStates = getButtonStates();
                      }
                    }
                  );
  
                  // Watch for changes in processState element attribute
                  $scope.$watch(
                    function () {
                      return self.processState();
                    },
                    function (newState, oldState) {
                      if (newState !== oldState) {
                        changeCurrentState (newState);
                      }
                    }
                  );
  
                  // Initialise initial state
                  changeCurrentState(self.processState());
                };
  
  
                function changeCurrentState (newState) {
                  if (angular.isUndefined(newState)) {
                    newState = 'initial';
                  }
  
                  self.currentState = newState;
                }
  
                function getButtonText () {
                  return ButtonStates[self.currentState].buttonText;
                }
  
                function getButtonIconClass () {
                  return ButtonStates[self.currentState].buttonIconClass;
                }
  
                function getButtonClass () {
                  return ButtonStates[self.currentState].buttonClass;
                }
  
                function getButtonDisabled () {
                  return !!self.disabled && self.disabled();
                }
  
                function getButtonStates () {
                  return angular.merge({}, getBaseButtonStates(), getOverridenButtonStates());
                }
  
                function getOverridenButtonStates () {
                  return (angular.isFunction(self.buttonStates) ? self.buttonStates() : {});
                }
  
                function getBaseButtonStates () {
                  return {
                    init: {
                      name: 'init',
                      buttonText: 'Save',
                      buttonClass: 'init'
                    },
                    waiting: {
                      name: 'waiting',
                      buttonText: 'Saving',
                      buttonIconClass: 'fa-circle-o-notch fa-spin',
                      buttonClass: 'waiting'
                    },
                    success: {
                      name: 'success',
                      buttonText: 'Saved',
                      buttonIconClass: 'fa-thumbs-o-up throb-in slow',
                      buttonClass: 'success'
                    },
                    error: {
                      name: 'error',
                      buttonText: 'Error',
                      buttonIconClass: 'fa-exclamation-circle throb-in slow',
                      buttonClass: 'error'
                    }
                  };
                }
              }
            ]
        };
      }
    ]);
  
  }(window.angular));
  
  ; (function (angular, _) {
    'use strict';
  
    angular
      .module('kudos')
        .service(
          'extraInfoBoxService',
          [
          function () {
            var self = this;
  
            self.showExtraInfoBoxes = {};
  
            self.toggleExtraInfoBox = function (boxName) {
              _.forOwn(self.showExtraInfoBoxes, function (value, key) {
                if (key !== boxName) {
                  self.showExtraInfoBoxes[key] = false;
                }
              });
  
              if (angular.isDefined(self.showExtraInfoBoxes[boxName])) {
                self.showExtraInfoBoxes[boxName] = !self.showExtraInfoBoxes[boxName];
              }
            };
  
            self.getExtraInfoBoxIndicatorClass = function (who) {
              var showing = !!self.showExtraInfoBoxes[who];
  
              return {
                'fa-angle-up': showing,
                'fa-angle-down': !showing
              };
            };
          }]
        );
  }(window.angular, window._));
  
  ; (function (angular) {
    'use strict';
  
    angular
      .module('kudos')
      .controller('tableSortController', [
        function () {
          var self = this;
  
          self.rows             = [];
          self.reverse          = false;
  
          self.init             = init;
          self.sortBy           = sortBy;
          self.getSortIconClass = getSortIconClass;
  
          function init (rows, propertyName, reverse) {
            self.rows         = rows;
            self.propertyName = propertyName;
            self.reverse      = reverse;
          }
  
          function sortBy (propertyName) {
            if (self.propertyName === propertyName) {
              self.reverse = !self.reverse;
            } else {
              self.reverse = false;
            }
  
            self.propertyName = propertyName;
          }
  
          function getSortIconClass (propertyName) {
            if (self.propertyName !== propertyName) {
              return 'fa-sort';
            }
  
            if (self.reverse) {
              return 'fa-sort-asc';
            }
  
            return 'fa-sort-desc';
          }
        }
      ]);
  
  }(window.angular));
  
  ; (function (angular) {
    'use strict';
  
    angular.module('kudos').component('kudosDefaultAvatar', {
      templateUrl: 'kudos/components/kudosDefaultAvatar.html',
      bindings: {
        colour: '@',
        text: '@',
        size: '@'
      },
      controllerAs: 'vm',
      controller: function () {
          var self = this;
  
          self.sizes = {
            small: { side: 30, fontSize: "12px" },
            medium: { side: 60, fontSize: "24px" },
            large: { side: 120, fontSize: "48px" },
            xlarge: { side: 175, fontSize: "80px" }
          };
  
          self.$onInit = function() {
            self.ensureValidSize();
          };
  
          self.cx = function() {
            return self.side() / 2;
          };
  
          self.cy = function() {
            return self.side() / 2;
          };
  
          self.r = function() {
            return self.side() / 2;
          };
  
          self.side = function() {
            return self.sizes[self.size].side;
          };
  
          self.fontSize = function() {
            return self.sizes[self.size].fontSize;
          };
  
          self.ensureValidSize = function() {
            if (!self.sizes.hasOwnProperty(self.size)) {
              self.size = 'medium';
            }
          };
      }
    }
  
  );}(window.angular));
  
  ; (function (angular) {
    'use strict';
  
    angular.module('kudos').component('kudosAvatar', {
      templateUrl: "kudos/components/kudosAvatar.html",
      controllerAs: 'vm',
      bindings: {
        size: '@',
        avatar: '<',
        withShadow: '@'
      },
      controller: function () {
        var self = this;
        self.sizes = {
          small: { '1x': '_30x30', '2x': '_60x60' },
          medium: { '1x': '_60x60', '2x': '_120x120' },
          large: { '1x': '_120x120', '2x': '_240x240' },
          xlarge: { '1x': '_240x240'}
        };
  
        self.$onInit = function() {
          self.ensureValidSize();
        };
  
        self.hasShadow = function () {
          return self.withShadow === 'true';
        };
  
        self.ensureValidSize = function() {
          if (!self.sizes.hasOwnProperty(self.size)) {
            self.size = 'medium';
          }
        };
  
        self.getAccountAvatarThumb = function() {
          return getAccountAvatarURL(self.sizes[self.size]['1x']);
        };
  
        self.getAccountAvatarThumbSet = function() {
          var thumb1x = getAccountAvatarURL(self.sizes[self.size]['1x']);
          var thumb2x = getAccountAvatarURL(self.sizes[self.size]['2x']);
          if (thumb1x !== '') {
            if (thumb2x !== undefined) {
              return thumb1x + " 1x, " + thumb2x + " 2x";
            }
            return thumb1x + " 1x";
          }
          return '';  //don't return null, as this will be interpretted as the url "/null"
        };
  
        function getAccountAvatarURL(size) {
          return self.avatar && self.avatar[size];
        }
  
        self.hasDefautAvatar = function() {
          return !!(self.avatar && self.avatar.default_avatar);
        };
  
        function getDefaultAvatar() {
          if(self.hasDefautAvatar()) {
            return self.avatar.default_avatar;
          }
          return {};
        }
  
        self.defautAvatarColour = function() {
          return getDefaultAvatar().colour;
        };
  
        self.defautAvatarInitials = function() {
          return getDefaultAvatar().initials;
        };
      }
    }
  
  );}(window.angular));
  
  ; (function (angular) {
    'use strict';
  
    angular.module('kudos').component('kudosCookiesPolicy', {
      controller: function () {
      }
    }
  
  );}(window.angular, window.jQuery));
  
})(window.angular);

(function (angular) {
  "use strict";

  angular.module("kudosAbout", ["angulartics", "kudosNotifications"]);
  ; (function (angular) {
    'use strict';
  
    angular.module('kudosAbout')
      .controller(
        'AboutController',
        [
          'extraInfoBoxService',
          function (extraInfoBoxService) {
            var self = this;
  
            self.showExtraInfoBoxes = extraInfoBoxService.showExtraInfoBoxes = {
              mobilize: false,
              why_kudos: false,
              measure: false,
              case_studies: false,
              support: false,
              videos: false
            };
  
            self.toggleExtraInfoBox = extraInfoBoxService.toggleExtraInfoBox;
            self.getExtraInfoBoxIndicatorClass = extraInfoBoxService.getExtraInfoBoxIndicatorClass;
  
          }
        ]);
  })(window.angular);
  
  ; (function (angular) {
    'use strict';
  
    angular.module('kudosAbout')
      .controller(
        'AboutPublishersController',
        [
          'extraInfoBoxService',
          function (extraInfoBoxService) {
            var self = this;
  
            self.showExtraInfoBoxes = extraInfoBoxService.showExtraInfoBoxes = {
              increase_usage: false,
              mobilize: false,
              support: false,
              engage: false,
              optimize: false,
              case_studies: false,
            };
  
            self.toggleExtraInfoBox = extraInfoBoxService.toggleExtraInfoBox;
            self.getExtraInfoBoxIndicatorClass = extraInfoBoxService.getExtraInfoBoxIndicatorClass;
  
          }
        ]);
  })(window.angular);
  
  ; (function (angular) {
    'use strict';
  
    angular.module('kudosAbout')
      .controller(
        'AboutInstitutionsController',
        [
          'extraInfoBoxService',
          function (extraInfoBoxService) {
            var self = this;
  
            self.showExtraInfoBoxes = extraInfoBoxService.showExtraInfoBoxes = {
              maximize: false,
              mobilize: false,
              support: false,
              showcase: false,
              broaden: false,
              streamline: false,
            };
  
            self.toggleExtraInfoBox = extraInfoBoxService.toggleExtraInfoBox;
            self.getExtraInfoBoxIndicatorClass = extraInfoBoxService.getExtraInfoBoxIndicatorClass;
  
          }
        ]);
  })(window.angular);
  
  (function (angular) {
    'use strict';
  
    angular.module('kudosAbout').component('aboutResearchGroupForm', {
      templateUrl: 'kudosAbout/aboutResearchGroupForm.component.html',
      controllerAs: 'vm',
      controller: [
        function () {
          var self = this;
  
          self.toggleSelected = function (boxName) {
            if (self.selected == boxName) {
              self.selected = '';
            } else {
              self.selected = boxName;
            }
          };
        }
      ]
    }
  );}(window.angular));
})(window.angular);

; (function (angular) {

  "use strict";

  angular.module('kudosAdmin', ['angulartics', 'ui.router', 'kudosNotifications']);
  ; (function (angular) {
    'use strict';
    angular
      .module('kudosAdmin')
      .factory(
        'AdminService',
        [
        'Restangular',
        function (Restangular) {
          return Restangular.service('admin');
        }]);
  
  }(window.angular));
  
  ; (function (angular, _) {
  
    "use strict";
  
    angular.module('kudosAdmin')
      .controller(
        'AdminController',
        [
          '$state',
          'SessionService',
          function ($state, SessionService) {
            var institutionStates = [
              'admin.manage_institutions.search',
              'admin.institutions'
            ];
  
            var self = this;
  
            self.userIsAdmin = SessionService.userIsAdmin;
            self.currentUser = SessionService.currentUser;
  
            self.isInstitutionTabOpen = function () {
              return _.includes(institutionStates, $state.current.name);
            };
          }
        ]
      );
  
  } (window.angular, window._));
  
  ; (function (angular) {
  
    "use strict";
  
    angular.module('kudosAdmin')
      .controller(
        'AdminInstitutionSearchController',
        [
          'SessionService',
          'InstitutionService',
          function (SessionService, InstitutionService) {
            var self = this;
  
            self.updateAutoCompleteList = updateAutoCompleteList;
            self.autoCompleteResults = [];
            self.showGrid = false;
            self.loading = false;
  
            var latestRequestID;
  
            self.gridOptions = {
              enableSorting: true,
              columnDefs: [
                {
                  name: 'Institution Name',
                  field : 'ringgoldName',
                  minWidth: 450,
                  sort: {
                    direction: 'asc',
                    priority: 0
                  }
                },
                {
                  name: 'Country',
                  field: 'country',
                  minWidth: 200
                },
                {
                  name: 'Ringgold ID',
                  displayName: 'Ringgold ID',
                  field: 'ringgoldId'
                },
                {
                  name: 'Status',
                  field: 'customer',
                  cellTemplate: '<div class="ui-grid-cell-contents">{{ !COL_FIELD ? "" : "Current Customer"}}</div>'
                },
                {
                  name: 'Select',
                  field: 'ringgoldId',
                  cellTemplate: '<div class="ui-grid-cell-contents"><a target="_self" href="/admin/manage_institutions/add/{{ COL_FIELD }}" ng-if="!row.entity.customer">Select</a>'
                }
              ]
            };
  
            function updateAutoCompleteList (searchString) {
              self.loading = true;
  
              var requestID = Date.now();
              latestRequestID = requestID;
              InstitutionService.findInstitutionsByPartialName(searchString)
                .then(function(institutionList){
                  if (requestID === latestRequestID) {
                    self.gridOptions.data = institutionList;
                    self.showGrid = true;
                  }
                })
                .finally(function () {
                  self.loading = false;
                });
              }
  
          }
        ]
      );
  
  } (window.angular));
  
  ; (function (angular) {
  
    "use strict";
  
    angular.module('kudosAdmin')
      .controller(
        'AdminSearchController',
        [
          '$window',
          'AdminReportService',
          'PublisherService',
          'PublicationService',
          'NotificationService',
          function ($window, AdminReportService, PublisherService, PublicationService, NotificationService) {
            var self = this;
  
            self.profileSearch = profileSearch;
            self.publisherSearch = publisherSearch;
            self.contributorPublicationsSearch = contributorPublicationsSearch;
            self.showGrid = false;
  
            var cellContentTemplate = function (innerTemplate) {
              return '<div class="ui-grid-cell-contents">' + innerTemplate + '</div>';
            };
  
            function profileSearch (searchString) {
              $window.location.href = '/admin/user_search?q=' + searchString;
            }
  
            self.adminResultsGridOptions = {};
  
  
            function publisherSearch (searchString) {
              PublisherService.findPublisherByPartialName(searchString)
                .then(function(publisherList){
                  self.adminResultsGridOptions = AdminReportService.compileGridOptions.publisher_report(publisherList);
                  self.showGrid = true;
                })
                .catch(function () {
                  NotificationService.error('Couldn\'t perform search');
                });
            }
  
  
            function contributorPublicationsSearch (email) {
              PublicationService.getPublicationsByContributor(email)
                .then(function(response){
                  self.adminResultsGridOptions = AdminReportService.compileGridOptions.publications_by_contributor_list(response.data.data.publications);
                  self.showGrid = true;
                })
                .catch(function (response) {
                  console.log(response);
                  if (response.hasOwnProperty('data') && response.data.hasOwnProperty('errors')) {
                    NotificationService.error(response.data.errors.join("<br>"));
                  } else {
                    NotificationService.error('Cannot perform search.')
                  }
                });
            }
  
          }
        ]
      );
  
  } (window.angular));
  
  ; (function (angular) {
  
    "use strict";
  
    angular.module('kudosAdmin')
      .controller(
        'AdminDashboardController',
        [
          'categoryOverview',
          'exampleExplainedPublications',
          function (categoryOverview, exampleExplainedPublications) {
            var self = this;
  
            self.categoryOverview = categoryOverview;
            self.exampleExplainedPublications = exampleExplainedPublications;
          }
        ]
      );
  
  } (window.angular));
  
  ; (function (angular) {
  
    "use strict";
  
    angular.module('kudosAdmin')
      .controller(
        'AdminInstitutionsController',
        [
          'institutions',
          'AdminReportService',
          function (institutions, AdminReportService) {
            var self = this;
  
            self.institutions = institutions;
            self.gridOptions = AdminReportService.compileGridOptions.institution_report(self.institutions);
  
          }
        ]
      );
  
  } (window.angular));
  
  ; (function (angular, _) {
  
    "use strict";
  
    angular.module('kudosAdmin')
      .controller(
        'AdminPublisherController',
        [
          'publishers',
          'AdminReportService',
          'PublisherService',
          function (publishers, AdminReportService, PublisherService) {
            var publisherStates = PublisherService.STATES;
  
            var FILTER_ALL = {
              label: 'All',
              states: [] // Empty means all states
            };
  
            var FILTER_CUSTOMERS = {
              label: 'Customers',
              states: [publisherStates.CUSTOMER]
            };
  
            var FILTER_PAST_CUSTOMERS = {
              label: 'Past Customers',
              states: [publisherStates.EX_CUSTOMER]
            };
  
            var selectedFilterButton = 'Customers';  //do customers first, as ALL takes a long time to load
  
            var self = this;
  
            self.viewStates = {
              LOADING: 1,
              READY: 2
            };
  
            self.viewState = self.viewStates.READY;
  
            self.publishers = publishers.publishers;
            self.gridOptions = AdminReportService.compileGridOptions.publisher_report(self.publishers);
  
            // Create filter buttons from consts
            self.filterButtons = _.map(
              [
                FILTER_CUSTOMERS, //do customers first, as ALL takes a long time to load
                FILTER_PAST_CUSTOMERS,
                FILTER_ALL
              ],
              function (filter) {
                return {
                  name: filter.label,
                  getButtonClass: function () {
                    return {
                      active: (selectedFilterButton === filter.label)
                    };
                  },
                  onClick: function () {
                    // If already selected, do nothing
                    if (selectedFilterButton === filter.label) {
                      return;
                    }
  
                    // If not already selected, reload grid with new publisher filter
                    selectedFilterButton = filter.label;
                    reloadPublishers(filter);
                  }
                };
              }
            );
  
            // Reloads the publisher grid with the provided filter
            function reloadPublishers(filter) {
              self.viewState = self.viewStates.LOADING;
  
              PublisherService.getCustomerPublishers(filter.states.join(','))
                .then(function (publishers) {
                  self.gridOptions = AdminReportService.compileGridOptions.publisher_report(publishers.publishers);
                })
                .finally(function () {
                  self.viewState = self.viewStates.READY;
                });
            }
  
          }
        ]
      );
  
  } (window.angular, window._));
  
  ; (function (angular) {
  
    "use strict";
  
    angular.module('kudosAdmin')
      .controller(
        'AdminOrganisationController',
        [
          'organisations',
          'AdminReportService',
          function (organisations, AdminReportService) {
            var self = this;
  
            self.organisations = organisations;
            self.gridOptions = AdminReportService.compileGridOptions.organisations_report(self.organisations);
          }
        ]
      );
  
  } (window.angular));
  
  ; (function (angular) {
  
    "use strict";
  
    angular.module('kudosAdmin')
      .controller(
        'AdminAccountManagementController',
        [
          function () {
            this.getAllUnsubscribed = function () {
              var url = window.location.origin + '/subscriptions/mss_all_unsubscribed';
              var queries = [];
              if (this.publisher) {
                queries.push('publisher=' + this.publisher);
              }
              if (this.campaign) {
                queries.push('campaign=' + this.campaign);
              }
              window.location.href = url + '?' + queries.join('&');
            };
          }
        ]
      );
  
  } (window.angular));
  
  ; (function (angular, _) {
    'use strict';
    angular
      .module('kudosAdmin')
      .factory(
        'AdminReportService',
        [
          function () {
  
            var cellContentTemplate = function (innerTemplate) {
              return '<div class="ui-grid-cell-contents">' + innerTemplate + '</div>';
            };
  
            var adminReportService = {};
  
            adminReportService.compileGridOptions = {};
  
            adminReportService.compileGridOptions.publications_by_contributor_list = function (publicationList) {
              return {
                enableSorting: true,
                minRowsToShow: 20,
                columnDefs: [
                  {
                    displayName: 'Title',
                    field: 'title',
                    headerCellClass: 'wrap-words',
                    cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encoded_doi}}" target="_blank" ng-bind-html="row.entity.title"></a>')
                  },
                  {
                    displayName: 'DOI',
                    field: 'doi',
                    headerCellClass: 'wrap-words'
                  },
                  {
                    displayName: 'Loaded by publisher',
                    field: 'loaded_by_publisher'
                  },
                  {
                    displayName: 'Owner by publisher',
                    field: 'owned_by_publisher'
                  }
                ],
                data: _.map(publicationList, function (publication) {
                  return {
                    title: publication.title,
                    encoded_doi: publication.encoded_doi,
                    doi: publication.id,
                    loaded_by_publisher: null,  // TODO in Ruby API
                    owned_by_publisher: publication.publisher.name
                  };
                })
              };
  
            };
  
            adminReportService.compileGridOptions.publisher_report = function (publishers) {
              return {
                enableSorting: true,
                minRowsToShow: 20,
                columnDefs: [
                  {
                    displayName: 'Preferred Publisher Name',
                    field : 'name',
                    maxWidth: 240,
                    cellTemplate: cellContentTemplate('<a target="_self" ng-href="/publishers/{{ row.entity.shortCode }}" title="{{ row.entity.name }} ({{ row.entity.shortCode }})">{{ row.entity.name }}</a>'),
                  },
                  {
                    displayName: 'Contact',
                    field: 'admins',
                  },
                  {
                    displayName: 'FTP details',
                    field: 'ftpDetails',
                    enableColumnMenu: false,
                  },
                  {
                    displayName: 'DOI/cover loading',
                    field: 'loadingEnabled',
                    headerCellClass: 'wrap-words',
                    enableColumnMenu: false,
                    maxWidth: 90
                  },
                  {
                    displayName: 'Usage loading',
                    field: 'usageLoadingEnabled',
                    headerCellClass: 'wrap-words',
                    enableColumnMenu: false,
                    maxWidth: 70
                  },
                  {
                    displayName: 'Status',
                    field: 'currentStateLabel',
                  },
                  {
                    displayName: 'Edit',
                    field: 'shortCode',
                    cellTemplate: cellContentTemplate('<a target="_self" ng-href="/publishers/edit/{{ row.entity.shortCode }}">Edit</a>'),
                    enableColumnMenu: false,
                    maxWidth: 50
                  }
                ],
                data: _.map(publishers, function (publisher) {
                  return {
                    name: publisher.name,
                    shortCode: publisher.short_code,
                    admins: publisher.admins.join(', '),
                    ftpDetails: (publisher.ftp_details ? publisher.ftp_details.username + ' / ' + publisher.ftp_details.password : ""),
                    loadingEnabled: (publisher.loading_enabled ? "Yes" : "No"),
                    usageLoadingEnabled: (publisher.usage_loading_enabled ? "Yes" : "No"),
                    currentStateLabel: (publisher.current_state_label),
                    autoLoaded: (publisher.auto_loaded ? "Yes" : "No")
                  };
                })
              };
            };
  
            adminReportService.compileGridOptions.institution_report = function (institutions) {
              return {
                enableSorting: true,
                minRowsToShow: 20,
                columnDefs: [
                  {
                    displayName: 'Preferred Institution Name',
                    field: 'displayName',
                    cellTemplate: cellContentTemplate('<a target="_self" ng-href="/institutions/{{ row.entity.shortCode }}">{{row.entity.displayName}}</a>'),
                    minWidth: 350,
                    sort: {
                      direction: 'asc',
                      priority: 0
                    }
                  },
                  {
                    displayName: 'Country',
                    field: 'country',
                    minWidth: 100
                  },
                  {
                    displayName: 'Kudos Code',
                    field: 'shortCode',
                    maxWidth: 95
                  },
                  {
                    displayName: 'Status',
                    field: 'currentStateLabel',
                    cellTemplate: cellContentTemplate('{{ COL_FIELD }}')
                  },
                  {
                    displayName: 'Expiry Date',
                    field: 'expiryDate',
                    maxWidth: 100,
                    cellTemplate: cellContentTemplate('{{COL_FIELD | date:"MMM yyyy"}}')
                  },
                  {
                    displayName: 'Edit',
                    field: 'ringgoldId',
                    maxWidth: 70,
                    cellTemplate: cellContentTemplate('<a target="_self" ng-href="/admin/manage_institutions/edit/{{ COL_FIELD }}">Edit</a>')
                  }
                ],
                data: institutions
              };
  
            };
  
            adminReportService.compileGridOptions.organisations_report = function (organisations) {
              return {
                enableSorting: true,
                minRowsToShow: 20,
                columnDefs: [
                  {
                    displayName: 'Organisation Name',
                    field: 'name',
                    minWidth: 350,
                    sort: {
                      direction: 'asc',
                      priority: 0
                    }
                  },
                  {
                    displayName: 'Short Code',
                    field: 'shortCode',
                    cellTemplate: cellContentTemplate('<a target="_self" ng-href="/orgs/{{ row.entity.shortCode }}">{{row.entity.shortCode}}</a>'),
                  },
                  {
                    displayName: 'FTP details',
                    field: 'ftpDetails',
                    enableColumnMenu: false,
                  },
                  {
                    displayName: 'Status',
                    field: 'currentStateLabel',
                  },
                  {
                    displayName: 'Type',
                    field: 'type',
                  },
                  {
                    displayName: 'Edit',
                    field: 'shortCode',
                    cellTemplate: cellContentTemplate('<a target="_self" ng-href="/admin/organisations/{{ row.entity.shortCode }}">Edit</a>'),
                    enableColumnMenu: false,
                    maxWidth: 50
                  }
                ],
                data: _.map(organisations, function (organisation) {
                  return {
                    name: organisation.name,
                    shortCode: organisation.short_code,
                    ftpDetails: (organisation.ftp_details ? organisation.ftp_details.username + ' / ' + organisation.ftp_details.password : ""),
                    currentStateLabel: organisation.current_state_label,
                    type: organisation.type
                  };
                })
              };
  
            };
  
            return adminReportService;
          }
        ]
      );
  
  }(window.angular, window._));
  
  ; (function (angular) {
    "use strict";
  
    angular.module('kudosAdmin')
      .config([
        '$stateProvider',
        function ($stateProvider) {
          $stateProvider
            .state('admin', {
              url: '/admin',
              abstract: true,
              templateUrl: 'kudosAdmin/admin.html',
              controller: 'AdminController as vm',
              resolve: {
                categoryOverview: ['$state', 'AdminService', function ($state, AdminService) {
                  return AdminService.one('admin').one('reports', 'category_overview').get()
                    .then(function (categoryOverview) {
                      return categoryOverview.rows;
                    })
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
                }],
                exampleExplainedPublications: ['$state', 'AdminService', function ($state, AdminService) {
                  return AdminService.one('admin').one('reports', 'explained_publications_examples').get()
                    .then(function (explained_publications_examples) {
                      return explained_publications_examples.rows;
                    })
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
                }]
              }
            })
            .state('admin.dashboard', {
              url: '',
              templateUrl: 'kudosAdmin/admin.dashboard.html',
              controller: 'AdminDashboardController as adminDashboard'
            })
            .state('admin.manage_institutions', {
              url: '/manage_institutions',
              abstract: true,
              templateUrl: 'kudosAdmin/admin.manage-institutions.html'
            })
            .state('admin.manage_institutions.search', {
              url: '/search',
              templateUrl: 'kudosAdmin/admin.manage-institutions.search.html',
              controller: 'AdminInstitutionSearchController as adminInstitutionSearch'
            })
            .state('admin.reports', {
              url: '/reports',
              templateUrl: 'kudosAdmin/admin.reports.html'
            })
            .state('admin.institutions', {
              url: '/institutions',
              templateUrl: 'kudosAdmin/admin.institutions.html',
              controller: 'AdminInstitutionsController as adminInstitutions',
              resolve: {
                institutions: ['InstitutionService', '$state', function (InstitutionService, $state) {
                  var states = InstitutionService.institutionSubscriptionStates.join(',');
                  return InstitutionService.getCustomerInstitutions(states)
                    .then(function (response) {
                      return response.data.data.institutions;
                    })
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
                }]
              }
            })
            .state('admin.publishers', {
              url: '/publishers',
              templateUrl: 'kudosAdmin/admin.publishers.html',
              controller: 'AdminPublisherController as adminPublishers',
              resolve: {
                publishers: ['PublisherService', function (PublisherService) {
                  var states = PublisherService.STATES.CUSTOMER; // start with customers to keep list small
                  return PublisherService.getCustomerPublishers(states);
                }]
              }
            })
            .state('admin.organisations', {
              url: '/organisations',
              templateUrl: 'kudosAdmin/admin.organisations.html',
              controller: 'AdminOrganisationController as adminOrganisations',
              resolve: {
                organisations: ['OrganisationService', function (OrganisationService) {
                  return OrganisationService.getCustomerOrganisations();
                }]
              }
            })
            .state('admin.search', {
              url: '/search',
              templateUrl: 'kudosAdmin/admin.search.html',
              controller: 'AdminSearchController as adminSearch'
            })
            .state('admin.account-management', {
              url: '/account-management',
              templateUrl: 'kudosAdmin/admin.account-management.html',
              controller: 'AdminAccountManagementController as adminAccountManagement'
            });
        }
      ]);
  
  } (window.angular));
  

} (window.angular));

/**
 * kudosAnalytics plugin for Angulartics: http://luisfarzati.github.io/angulartics/
 */
(function (angular) {
  'use strict';

  /**
   * @ngdoc overview
   * @name angulartics.kudos.analytics
   * Enables Kudos analytics
   */
  angular.module('angulartics.kudos.analytics', ['angulartics', 'angulartics.google.analytics']);
  (function (angular) {
    'use strict';
  
    angular.module('angulartics.kudos.analytics')
      .config(['$analyticsProvider', function ($analyticsProvider) {
        $analyticsProvider.settings.ga.anonymizeIp = true;
        // Log kudos referrals.
        // @deprecated Use the `/events/` API in preference.
        $analyticsProvider.registerEventTrack(function (action, properties) {
          // only log article referrals to the Kudos API - ignore other events
          if (action === "Referral" && properties.category === "Article" && !!properties.label){
  
            // Grab the service manually (rather than using DI) because you can't inject services
            // in the config phase. We know this event will only happen once we're in the run phase,
            // so this is safe.
            var $http = angular.element(document.documentElement).injector().get('$http');
            var decodedDoi = encodeURIComponent(properties.label).replace(/%2F/g, "%252F");
  
            $http.post("/internal_api/publications/" + decodedDoi + "/kudos_referral", {});
          }
        });
  
        // Validation of the event-tracking logging (currently in the publication page `resolve`)
        $analyticsProvider.registerPageTrack(function (path) {
          // Check if we are on the publications page
          if(path.substr(0, 14) === '/publications/') {
            // If on /publications/ route, we can assume its an angular page and $http can be injected
            var $http = angular.element(document.documentElement).injector().get('$http');
  
            // Pull the encoded DOI out of the path string
            var eventToLog = {
              category: 'publication',
              action: 'pageview_validation',
              label: path
            };
  
            $http.post("/internal_api/events", eventToLog);
          }
        });
      }]);
  })(window.angular);
  
  (function (angular) {
    'use strict';
  
    angular.module('angulartics.kudos.analytics')
      .directive(
        'recordReadReferralEvent',
        [
          '$analytics',
          function ($analytics) {
            return {
              scope: {
                doi: '@'
              },
              link: function (scope, element) {
                element.on('click', function () {
                  $analytics.eventTrack(
                    'Referral',
                    {
                      category: 'Article',
                      label: scope.doi
                    }
                  );
                });
              }
            };
          }
        ]
      );
  
  
  })(window.angular);
  
  
  (function (angular) {
    'use strict';
  
    angular.module('angulartics.kudos.analytics')
      .directive(
        'eventTrack',
        [
          '$analytics',
          function ($analytics) {
            return {
              link: function (scope, element, attrs) {
                if (!!attrs.eventTrack && !!attrs.category && !!attrs.label) {
                  addClickListener();
                }
  
                function addClickListener () {
                  element.on('click', function () {
                    $analytics.eventTrack(
                      attrs.eventTrack,
                      {
                        category: attrs.category,
                        label: attrs.label
                      }
                    );
                  });
                }
              }
            };
          }
        ]
      );
  })(window.angular);
  

})(window.angular);

; (function (angular) {

  "use strict";

  angular.module('kudosCountries', []);
  ; (function (angular) {
    'use strict';
    angular
      .module('kudosCountries')
      .factory(
        'CountryService',
        [
          '$http',
          function ($http) {
            return {
              getAllCountries: function () {
                return $http.get('/internal_api/countries/');
              }
            };
          }
        ]
      );
  
  }(window.angular));
  
  

} (window.angular));

; (function (angular) {

  "use strict";

  angular.module('kudosInstitutions', ['angulartics', 'kudosNotifications', 'ui.router', 'kudosPublications', 'kudosProfiles']);
  ; (function (angular) {
  
    "use strict";
  
    angular.module('kudosInstitutions').constant('institutionReports', [
      {
        title: 'Publications report: all publications on Kudos',
        description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ institution.institution.displayName }}.',
        name: 'all_publications'
      },
      {
        title: 'Publications report: all publications on Kudos that have been explained excluding adding resources',
        description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ institution.institution.displayName }} and that have been explained by one or more of the authors. An Explained Publication is one where one or more authors has added either a Short Title, text explaining "What\'s it about?", text explaining "Why is it important?" or an Author Perspective.',
        name: 'explained_excluding_resources_publications'
      },
      {
        title: 'Publications report: all publications on Kudos that have one or more resource',
        description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ institution.institution.displayName }} and have added one or more resource links. This includes links to data sets, videos, presentations or news stories.',
        name: 'publications_with_resources'
      },
      {
        title: 'Publications report: all publications on Kudos that have been explained',
        description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ institution.institution.displayName }} and that have been explained by one or more of the authors. An Explained Publication is one where one or more authors has added either a Short Title, text explaining "What\'s it about?", text explaining "Why is it important?", a Perspective or a resource.',
        name: 'explained_publications'
      },
      {
        title: 'Publications report: all resources linked to publications',
        description: 'This report lists all resources linked to publications on Kudos where at least one author has indicated that they are from {{ institution.institution.displayName }}. A resource includes data sets, videos, presentations or news stories.',
        name: 'linked_resources'
      },
      {
        title: 'Publications report: all publications on Kudos that have been shared',
        description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ institution.institution.displayName }} and the publication has been shared by one or more of the authors. Shares means that one of more of the authors has used Kudos to send a tweet, add Facebook post, send an email or post online. When the author shares, a coded link is included in the share which allows readers to link to the publication.',
        name: 'shared_publications'
      },
      {
        title: '',
        description: '',
        name: 'category_overview'
      },
      {
        title: '',
        description: '',
        name: 'recently_active_researchers'
      },
      {
        title: 'Activity report: all activities by date (explain and share)',
        description: 'This report lists all activities undertaken on Kudos by researchers that indicated that they are from {{ institution.institution.displayName }} to help increase the impact of their publications. This included adding or editing a short title, the "What\'s it about?" text, the "Why is it important?" text, an Author Perspective, a Resource or by sharing through Twitter, Facebook or email/online.',
        name: 'explain_share_activity'
      },
      {
        title: 'Activity report: all explain activities (excluding resources) by date',
        description: 'This report lists all explain activities (excluding adding resources) undertaken on Kudos by researchers that indicated that they are from {{ institution.institution.displayName }} to help increase the impact of their publications. This included adding or editing a short title, the "What\'s it about?" text, the "Why is it important?" text or an Author Perspective.',
        name: 'explain_excluding_resources_activity'
      },
      {
        title: 'Activity report: all resource activities by date',
        description: 'This report lists all resource activities undertaken on Kudos by researchers that indicated they are from {{ institution.institution.displayName }} to help increase the impact of their publications. This includes links to data sets, videos, presentations or new stories.',
        name: 'resource_activity'
      },
      {
        title: 'Activity report: all share activities by date',
        description: 'This report lists all share activities undertaken on Kudos by researchers that indicated that they are from {{ institution.institution.displayName }} to help increase the impact of their publications. This included sharing through Twitter, Facebook or email/online.',
        name: 'share_activity'
      },
      {
        title: 'Activity report: all tweets by date',
        description: 'This report lists all tweets sent from Kudos by researchers that indicated that they are from {{ institution.institution.displayName }} to help increase the impact of their publications.',
        name: 'tweet_activity'
      },
      {
        title: 'Researcher report: export of all registered users',
        description: 'This report lists all researchers using Kudos that indicated they were from {{ institution.institution.displayName }}. It includes their name, email address, self-selected subject area and self-selected career stage. Please note that you are bound by Kudos\' terms and conditions regarding the use of this personal data.',
        name: 'all_researchers'
      },
      {
        title: 'Researcher report: clicks on invitation link by date',
        description: 'This report shows the number of clicks on the custom invitation link for {{ institution.institution.displayName }}. This allows you to track the rate at which researchers from {{ institution.institution.displayName }} clicked on the invitation link. Some will have then gone on and registered but some may have clicked the link but not registered.',
        name: 'go_url_views',
        availableCharts: ['lineChart']
      },
      {
        title: 'Researcher report: breakdown by number of publications',
        description: 'This report includes all researchers using Kudos that indicated they were from {{ institution.institution.displayName }}. It shows the distribution of the number of publications each researcher has claimed. A researcher claims a publication to indicate that they are an author of that publication. Some researchers will have claimed multiple publications.',
        name: 'researchers_claims'
      },
      {
        title: 'Researcher report: breakdown by career stage',
        description: 'This report includes all researchers using Kudos that indicated they were from {{ institution.institution.displayName }}. It shows the distribution of researchers across career stages. These career stages are selected by the researcher when they register with Kudos. This field is optional, so some researchers may not have made a selection.',
        name: 'researchers_by_career_stage'
      },
      {
        title: 'Researcher report: breakdown by geographic location',
        description: 'This report includes all researchers using Kudos that indicated they were from {{ institution.institution.displayName }}. It shows the distribution of countries where the researchers are primarily based. These countries are selected by the researcher when they register with Kudos. This field is optional, so some researchers may not have made a selection.',
        name: 'researchers_by_country'
      },
      {
        title: 'Researcher report: breakdown by subject area',
        description: 'This report includes all researchers using Kudos that indicated they were from {{ institution.institution.displayName }}. It shows the distribution of researchers across subject areas. These subject areas are selected by the researcher when they register with Kudos. This field is optional, so some researchers may not have made a selection.',
        name: 'researchers_by_subject'
      },
      {
        title: 'Researcher report: breakdown by date joined Kudos',
        description: 'This report shows the cumulative number of researchers from {{ institution.institution.displayName }} that had registered with Kudos by any given date. This allows you to track the rate at which researchers from {{ institution.institution.displayName }} join Kudos.',
        name: 'researchers_registrations',
        availableCharts: ['lineChart']
      },
      {
        title: 'Performance report: publication page views',
        description: 'This report shows the number of views of publications by date and the cumulative number of views of publications on Kudos for publications where one of more author has indicated that they are from {{ institution.institution.displayName }}.' ,
        name: 'publications_views',
        availableCharts: ['lineChart']
      },
      {
        title: 'Performance report: publication pages - most viewed',
        description: 'This report shows the publication pages with the highest number of views on Kudos for publications where one of more author has indicated that they are from {{ institution.institution.displayName }}.' ,
        name: 'publications_most_viewed'
      },
      {
        title: 'Performance report: share referrals - total by date',
        description: 'This report shows the number of share referrals by date to publications when one or more author has indicated that they were from {{ institution.institution.displayName }}. Share referrals are clicks on coded links in tweets, Facebook post, emails or online resulting from authors using the sharing tools on Kudos.' ,
        name: 'publications_share_referrals'
      },
      {
        title: 'Performance report: shares resulting in highest share referrals',
        description: 'This report shows the shares that resulted in the highest number of share referrals for publications where one of more author has indicated that they are from {{ institution.institution.displayName }}.' ,
        name: 'publications_highest_share_referrals'
      },
      {
        title: 'Performance report: showcase page',
        description: 'This report shows the number of views by date and the cumulative number of views of the {{ institution.institution.displayName }} Showcase.',
        name: 'showcase_page_view_count'
      },
      {
        title: 'Performance report: top Altmetric scores',
        description: 'This report lists the publications from {{ institution.institution.displayName }} on Kudos that have the highest current Altmetric scores. Altmetric data is provided by Altmetric.com',
        name: 'altmetric_scores_top_all_publications'
      },
      {
        title: 'Performance report: top Altmetric scores for publications that have been explained',
        description: 'This report lists the publications from {{ institution.institution.displayName }} on Kudos that have been explained and have the highest current Altmetric scores. Altmetric data is provided by Altmetric.com',
        name: 'altmetric_scores_top_explained_publications'
      },
      {
        title: 'Performance report: highest climbing altmetric scores (all publications)',
        description: 'This report lists the publications from {{ institution.institution.displayName }} on Kudos that have the highest recent growth altmetric scores. The Top 100 are shown and altmetric data is provided by <a href="https://www.altmetric.com" target="_blank">Altmetric.com</a>',
        name: 'altmetric_scores_climbing_all_publications'
      },
      {
        title: 'Performance report: highest climbing altmetric scores (explained publications)',
        description: 'This report lists the explained publications from {{ institution.institution.displayName }} on Kudos that have the highest recent growth altmetric scores. The Top 100 are shown and altmetric data is provided by <a href="https://www.altmetric.com" target="_blank">Altmetric.com</a>',
        name: 'altmetric_scores_climbing_explained_publications'
      }
    ]);
  } (window.angular));
  
  ; (function (angular, _) {
  
    "use strict";
  
    angular.module('kudosInstitutions').config([
      '$stateProvider',
      'institutionReports',
      function ($stateProvider, institutionReports) {
  
      // Now set up the institution states
        $stateProvider
          .state('institution', {
            url: '/institutions/{shortcode}',
            abstract: true,
            templateUrl: "partials/institution.html",
            controller: 'InstitutionController as institution',
            resolve: {
              institution: ['$stateParams', '$state', '$timeout', '$q', 'InstitutionService', 'SessionService', function ($stateParams, $state, $timeout, $q, InstitutionService) {
  
                return InstitutionService.getInstitution($stateParams.shortcode)
                  .then(function (response) {
                    return response.data.data.institution;
                  })
                  .catch(function () {
                    $state.go('error', {},  {errorCode: 500});
                  });
              }],
  
              latestTweets: ["$stateParams", "$state", "$timeout", "$q", "InstitutionService", function ($stateParams, $state, $timeout, $q, InstitutionService) {
                var deferred = $q.defer();
  
                InstitutionService.getInstitutionsLatestTweets($stateParams.shortcode)
                  .then(function (response) {
                    deferred.resolve(response.data.data.latest_tweets);
                  })
                  .catch(function () {
                    $state.go('error', {}, {errorCode: 500});
                  });
  
                  return deferred.promise;
              }],
  
              favoriteReports: ["$stateParams", "$q", "$state", "InstitutionReportService", function ($stateParams, $q, $state, InstitutionReportService) {
                return InstitutionReportService
                  .getFavoritedReports($stateParams.shortcode)
                    .then(function (response) {
                      return _.map(response.data.data, function(value, key) {
                        return _.find(institutionReports, {name: value.report_name})
                      });
                    })
                    .catch(function() {
                      $state.go('500');
                    });
              }],
  
              countries: ["CountryService", "$q", "$state", function (CountryService, $q, $state) {
                var deferred = $q.defer();
  
                CountryService
                  .getAllCountries()
                    .then(function (response) {
                      deferred.resolve(response.data.data.countries);
                    })
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
  
                return deferred.promise;
              }],
  
              categoryOverview: ["$stateParams", "$state", "$q", "InstitutionReportService", function ($stateParams, $state, $q, InstitutionReportService) {
                var deferred = $q.defer(); //$q is an angular promise
  
                InstitutionReportService
                  .getReport($stateParams.shortcode, 'category_overview')
                    .then(function (response) {
                      deferred.resolve(response.data.data.report);
                    })
                    .catch(function() {
                      $state.go('error', {}, {errorCode: 500});
                    });
  
                return deferred.promise;
              }],
  
              recentlyActiveResearchers: ["$stateParams", "$state", "$q", "InstitutionReportService", function ($stateParams, $state, $q, InstitutionReportService) {
                var deferred = $q.defer();
  
                InstitutionReportService
                  .getReport($stateParams.shortcode, 'recently_active_researchers')
                    .then(function (response) {
                      deferred.resolve(response.data.data.report);
                    })
                    .catch(function() {
                      $state.go('error', {}, {errorCode: 500});
                    });
  
                return deferred.promise;
              }],
  
              exampleExplainedPublications: ["$state", "$stateParams", "InstitutionReportService", function ($state, $stateParams, InstitutionReportService) {
                return InstitutionReportService.getReport($stateParams.shortcode, 'explained_publications_examples')
                  .then(function (response) {
                    return response.data.data.report;
                  })
                  .catch(function () {
                    $state.go('error', {}, {errorCode: 500});
                  });
              }],
  
              recentlyExplainedPublications: ["$stateParams", "$state", "$q", "InstitutionReportService", function ($stateParams, $state, $q, InstitutionReportService) {
                var deferred = $q.defer();
  
                InstitutionReportService
                  .getReport($stateParams.shortcode, 'recently_explained_publications')
                  .then(function (response) {
                    deferred.resolve(response.data.data.report);
                  })
                  .catch(function() {
                    $state.go('error', {}, {errorCode: 500});
                  });
  
                return deferred.promise;
              }]
            }
          })
          .state('institution.dashboard', {
            url: '',
            templateUrl: "partials/institution.dashboard.html"
          })
  
          .state('institution.search', {
            url: '/search',
            controller: 'InstitutionSearchController as search',
            templateUrl: "partials/institution.search.html"
          })
          .state('institution.invitations', {
            url: '/invitations',
            templateUrl: "partials/institution.invitations.html"
          })
          .state('institution.showcase', {
            url: '/showcase',
            templateUrl: "partials/institution.showcase.html"
          })
          .state('institution.resources', {
            url: '/resources',
            templateUrl: "partials/institution.resources.html"
          })
          .state('institution.branding', {
            url: '/branding',
            templateUrl: "partials/institution.branding.html"
          })
          .state('institution.account', {
            url: '/account',
            templateUrl: "partials/institution.account.html"
          })
          .state('institution.report_listing', {  // Report List Route
            url: '/reports',
            templateUrl: "partials/institution.report_listing.html"
          })
          .state('institution.report', {  // Report Parent Route
            url: '/reports',
            template: '<div ui-view><div/>',
            abstract: true
          });
  
        institutionReports.forEach(function (reportMetadata) {
          $stateProvider.state('institution/report/' + reportMetadata.name, {
            parent: 'institution.report',
            abstract: true,
            templateUrl: 'partials/institution.report_detail.html',
            controller: 'InstitutionReportController as vm',
            url: '/' + reportMetadata.name,
            resolve: {
              reportDetail: ["$stateParams", "$state", "InstitutionReportService", function($stateParams, $state, InstitutionReportService) {
                return InstitutionReportService.getReport($stateParams.shortcode, reportMetadata.name)
                  .then(function (response) {
                    return response.data.data.report;
                  })
                .catch(function () {
                  $state.go('error', {}, {errorCode: 500});
                });
              }],
              favoritedReport: ["$stateParams", "$state", "InstitutionReportService", function($stateParams, $state, InstitutionReportService) {
                return InstitutionReportService.getFavoritedReport($stateParams.shortcode, reportMetadata.name)
                  .then(function (response) {
                    return response.data.data;
                  })
                  .catch(function (response) {
                    $state.go('500');
                  });
              }],
              reportMetadata: function () {
                return angular.extend({}, reportMetadata);
              }
            }
          });
  
          $stateProvider.state('institution/report/' + reportMetadata.name + '/table', {
            parent: 'institution/report/' + reportMetadata.name,
            controller: ["reportGridOptions", "$scope", function (reportGridOptions, $scope) {
              $scope.reportGridOptions = reportGridOptions;
            }],
            template: '<div ui-grid="reportGridOptions" ui-grid-resize-columns class="grid"></div>',
            url: '',
            resolve: {
              reportGridOptions: ["reportDetail", "InstitutionReportService", function (reportDetail, InstitutionReportService) {
                // Get raw report data and compile it to be UI-GRID friendly
                return InstitutionReportService.compileGridOptions[reportMetadata.name](reportDetail);
              }]
            }
          });
  
          var availableReportCharts = reportMetadata.availableCharts;
  
          // Only create report detail chart routes if chart definitions are defined for that report
          if (angular.isDefined(availableReportCharts)) {
            _.each(availableReportCharts, function (reportChartType) {
              var reportChartTypeKebabCase = _.kebabCase(reportChartType);
  
              $stateProvider.state('institution/report/' + reportMetadata.name + '/' + reportChartType, {
                parent: 'institution/report/' + reportMetadata.name,
                controller: ["reportChartOptions", "$scope", function (reportChartOptions, $scope) {
                  $scope.reportChartOptions = reportChartOptions;
                }],
                template: '<kudos-' + reportChartTypeKebabCase + ' report-chart-options="reportChartOptions"></kudos-' + reportChartTypeKebabCase + '>',
                url: '/' + reportChartTypeKebabCase,
                resolve: {
                  reportChartOptions: ["reportDetail", "InstitutionReportService", function (reportDetail, InstitutionReportService) {
                    return InstitutionReportService.compileChartOptions[reportMetadata.name][reportChartType](reportDetail);
                  }]
                }
              });
            });
          }
        });
      }
    ]);
  } (window.angular, window._));
  
  ; (function (angular) {
    "use strict";
  
    angular
      .module('kudosInstitutions')
      .controller(
        'InstitutionAffiliationController',
        [
          '$timeout',
          'InstitutionAffiliationService',
          'InstitutionSuggestionService',
          '$window',
          function ($timeout, InstitutionAffiliationService, InstitutionSuggestionService, $window) {
            var self = this;
  
            self.state = InstitutionSuggestionService.state;
  
            self.processButton = {
              state: 'init'
            };
  
            self.manualSelectionMode = false;
            self.manualSelection = undefined;
  
            self.selectInstitution = InstitutionSuggestionService.selectInstitution;
            self.getSelectedInstitution = InstitutionSuggestionService.getSelectedInstitution;
  
            self.getCountryFlagClass = getCountryFlagClass;
            self.getLoadingIconClass = getLoadingIconClass;
            self.getInstitutionSelectionClass = getInstitutionSelectionClass;
            self.toggleManualSelection = toggleManualSelection;
            self.inManualSelection = inManualSelection;
            self.getSuggestedInstitutions = InstitutionSuggestionService.getSuggestedInstitutions;
  
            self.saveInstitutionAssociation = saveInstitutionAssociation;
  
            self.displaySuggestedInstitutions = InstitutionSuggestionService.displaySuggestedInstitutions;
            self.save = save;
            self.returnToReferer = false;
  
            function inManualSelection () {
              return self.manualSelectionMode;
            }
  
            function toggleManualSelection () {
              self.manualSelectionMode = !self.manualSelectionMode;
              self.processButton.state = 'init';
  
              InstitutionSuggestionService.resetState();
            }
  
            function getInstitutionSelectionClass (institution) {
              return {
                active: (self.getSelectedInstitution() === institution)
              };
            }
  
            function getCountryFlagClass (institution) {
              return 'flag-icon-' + institution.country_code.toLowerCase();
            }
  
            function getLoadingIconClass () {
              if (self.state.loading) {
                return 'fa-circle-o-notch fa-spin';
              }
  
              return 'fa-search';
            }
  
            function save () {
              self.returnToReferer = true;
              saveInstitutionAssociation();
            }
  
            function saveInstitutionAssociation () {
              self.processButton.state = 'waiting';
  
              InstitutionAffiliationService
                .saveInstitutionAssociation(self.getSelectedInstitution() || self.manualSelection)
                .then(function(data) {
                  if (data.tokenClaimsInvalid) {
                    var searchParams = 'goto=' + encodeURIComponent(redirectURL());
                    redirectToURL('/sessions/new?' + searchParams);
                  } else {
                    handleSaveSuccess();
                  }
                })
                .catch(function(error) { handleSaveError(error); });
            }
  
            function handleSaveSuccess () {
              self.processButton.state = 'success';
  
              $timeout(function(){
                redirectToURL(redirectURL());
              }, 1000);
            }
  
            function redirectURL () {
              if (self.returnToReferer) {
                return $window.location.pathname + $window.location.search;
              }
              return '/register/job_role';
            }
  
            function redirectToURL (location){
              $window.location.href = location;
            }
  
            function handleSaveError (error) {
              console.error('Error saving institution affiliation: ' + error);
              self.processButton.state = 'error';
            }
          }
        ]
      );
  }(window.angular));
  
  ; (function (angular) {
    'use strict';
    angular
      .module('kudosInstitutions')
      .service(
        'InstitutionAffiliationService',
        [
          'ProfileService',
          'SessionService',
          InstitutionAffiliationService
        ]
      );
  
    function InstitutionAffiliationService (ProfileService, SessionService) {
      var self = this;
  
      self.saveInstitutionAssociation = saveInstitutionAssociation;
  
      function buildRequestPayload (institution) {
        var payload = {};
  
        if (angular.isObject(institution)) {
          payload = {
            institution_name: institution.displayName,
            institution_id: institution.ringgoldId
          };
        }
  
        if (angular.isString(institution)) {
          payload = {
            institution_name: institution,
            institution_id: null
          };
        }
  
        return payload;
      }
  
      function saveInstitutionAssociation (institution) {
        // If not logged in, should return undefined which will
        // make backend return error, which will be presented to
        // the user.
        var accountId = SessionService.currentUser().id;
        var payload = buildRequestPayload(institution);
  
        return ProfileService.updateAccount(accountId, payload)
          .then(function (data) {
              return {
                tokenClaimsInvalid: data.tokenClaimsInvalid
              };
          });
      }
    }
  
  }(window.angular));
  
  ; (function (angular) {
    'use strict';
    angular
      .module('kudosInstitutions')
      .service(
        'InstitutionSuggestionService',
        [
          '$rootScope',
          '$timeout',
          'InstitutionService',
          InstitutionSuggestionService
        ]
      );
  
    function InstitutionSuggestionService ($rootScope, $timeout, InstitutionService) {
      var queryInstitutionTimeoutPromise;
  
      var self = this;
  
      self.state = {};
  
      // Resets the service state
      resetState();
  
      self.selectInstitution = selectInstitution;
      self.getSelectedInstitution = getSelectedInstitution;
      self.getSuggestedInstitutions = getSuggestedInstitutions;
      self.displaySuggestedInstitutions = displaySuggestedInstitutions;
      self.resetState = resetState;
  
      $rootScope.$watch(
        function () {
          return self.state.search;
        },
        function (newValue) {
          if (self.state.original === newValue) {
            return;
          }
  
          if (!newValue) {
            return self.resetState();
          }
  
          if (angular.isDefined(queryInstitutionTimeoutPromise)) {
            $timeout.cancel(queryInstitutionTimeoutPromise);
          }
  
          queryInstitutionTimeoutPromise = $timeout(queryInstitutions, 800);
        }
      );
  
      function queryInstitutions () {
        self.state.loading = true;
  
        InstitutionService
          .findInstitutionsByPartialName(self.state.search, 10, 0, 'academic')
          .then(handleInstitutionQueryResponse)
          .finally(handleInstitutionQueryFinally);
      }
  
      function handleInstitutionQueryResponse (institutions) {
        self.state.results = institutions;
  
        // If a new search is made, reset the institution selection
        self.state.selected = false;
      }
  
      function handleInstitutionQueryFinally () {
        self.state.loading = false;
      }
  
      function getSelectedInstitution () {
        return self.state.selected;
      }
  
      function selectInstitution (institution) {
        self.state.selected = institution;
        self.state.original = self.state.search = institution.displayName;
      }
  
      function getSuggestedInstitutions () {
        return self.state.results;
      }
  
      function displaySuggestedInstitutions () {
        if (self.getSelectedInstitution()) {
          return false;
        }
        return self.state.results;
      }
  
      function resetState () {
        self.state.search = '';
        self.state.results = false;
        self.state.loading = false;
        self.state.selected = false;
      }
    }
  
  }(window.angular));
  
  ; (function (angular, _) {
    'use strict';
    angular
      .module('kudosInstitutions')
      .factory(
        'InstitutionService',
        [
          '$http',
          '$analytics',
          '$q',
          'NotificationService',
          function ($http, $analytics, $q, NotificationService) {
  
            function compileUpdatedPublicationShowcaseArray(publications) {
              return _.map(publications, function (publication) {
                return {
                  doi: publication.id
                };
              });
            }
  
            function compileUpdatedProfileShowcaseArray(profiles) {
              return _.map(profiles, function (profile) {
                return {
                  id: profile.id
                };
              });
            }
  
            function updateInstitutionPublicationShowcase(institution, updatedShowcase) {
              return InstitutionService.updateInstitutionField(institution, 'showcasePublications', updatedShowcase);
            }
  
            function updateInstitutionProfileShowcase(institution, updatedShowcase) {
              return InstitutionService.updateInstitutionField(institution, 'showcaseProfiles', updatedShowcase);
            }
  
            var InstitutionService = {
  
              getInstitution: function (shortCode) {
                return $http.get('/internal_api/institutions/' + shortCode);
              },
  
              getInstitutionsLatestTweets: function (shortCode) {
                return $http.get('/internal_api/institutions/' + shortCode + '/latest_tweets');
              },
  
              getInstitutionsShowcasePublications: function (shortCode) {
                return $http.get('/internal_api/institutions/' + shortCode + '/showcase/publications');
              },
  
              getInstitutionsShowcaseProfiles: function (shortCode) {
                return $http.get('/internal_api/institutions/' + shortCode + '/showcase/profiles');
              },
  
              addPublicationToInstitutionShowcase: function (institution, publicationDOI) {
                var unableToAddErrorMessage = 'The institution showcase publication could not be added, please try again.';
  
                return InstitutionService.getInstitution(institution.code)
                  .then(function (response) {
                    institution = response.data.data.institution;
  
                    var addedPublication = {
                      id: publicationDOI
                    };
  
                    var updatedShowcaseArray = compileUpdatedPublicationShowcaseArray(
                      institution.showcasePublications.concat(addedPublication)
                    );
  
                    return updateInstitutionPublicationShowcase(institution, updatedShowcaseArray)
                      .then(function (response) {
                        $analytics.eventTrack('Added publication to institution showcase', {  category: 'institution showcase' });
                        return response.data;
                      })
                      .catch(function () {
                        NotificationService.error(unableToAddErrorMessage);
                      });
                    })
                    .catch(function () {
                      NotificationService.error(unableToAddErrorMessage);
                    });
              },
  
              addProfileToInstitutionShowcase: function (institution, profileId) {
                var unableToAddErrorMessage = 'The institution showcase profile could not be added, please try again.';
  
                var addedProfile = {
                  id: profileId
                };
  
                var updatedShowcaseArray = compileUpdatedProfileShowcaseArray(
                  institution.showcaseProfiles.concat(addedProfile)
                );
  
                return updateInstitutionProfileShowcase(institution, updatedShowcaseArray)
                  .then(function (response) {
                    $analytics.eventTrack('Added profile to institution showcase', {  category: 'institution showcase' });
                    return response.data;
                  })
                  .catch(function () {
                    NotificationService.error(unableToAddErrorMessage);
                  });
              },
  
              removePublicationFromInstitutionShowcase: function (institution, publicationDOI) {
                var unableToRemoveErrorMessage = 'The institution showcase publication could not be removed, please try again.';
  
                return InstitutionService.getInstitution(institution.code)
                  .then(function (response) {
                    institution = response.data.data.institution;
  
                    var updatedShowcaseArray = compileUpdatedPublicationShowcaseArray(institution.showcasePublications);
  
                    _.remove(updatedShowcaseArray, function (publication) {
                      return (publication.doi === publicationDOI);
                    });
  
                    return updateInstitutionPublicationShowcase(institution, updatedShowcaseArray)
                      .then(function (response) {
                        $analytics.eventTrack('Removed publication from institution showcase', {  category: 'institution showcase' });
                        return response.data;
                      })
                      .catch(function () {
                        NotificationService.error(unableToRemoveErrorMessage);
                      });
                  })
                  .catch(function () {
                    NotificationService.error(unableToRemoveErrorMessage);
                  });
              },
  
              removeProfileFromInstitutionShowcase: function (institution, profileId) {
                var unableToRemoveErrorMessage = 'The institution showcase profile could not be removed, please try again.';
  
                var updatedShowcaseArray = compileUpdatedProfileShowcaseArray(institution.showcaseProfiles);
  
                _.remove(updatedShowcaseArray, function (profile) {
                  return (profile.id === profileId);
                });
  
                return updateInstitutionProfileShowcase(institution, updatedShowcaseArray)
                  .then(function (response) {
                    $analytics.eventTrack('Removed profile from institution showcase', {  category: 'institution showcase' });
                    return response.data;
                  })
                  .catch(function () {
                    NotificationService.error(unableToRemoveErrorMessage);
                  });
              },
  
              reOrderInstitutionShowcasePublications: function (institution, reOrderedPublications) {
                var updatedShowcaseArray = compileUpdatedPublicationShowcaseArray(reOrderedPublications);
  
                return updateInstitutionPublicationShowcase(institution, updatedShowcaseArray)
                  .then(function (response) {
                    $analytics.eventTrack('Re-ordered institution showcase publications', {  category: 'institution showcase' });
                    return response.data;
                  })
                  .catch(function () {
                    NotificationService.error('The institution showcase publications could not be re-ordered, please try again.');
                  });
              },
  
              reOrderInstitutionShowcaseProfiles: function (institution, reOrderedProfiles) {
                var updatedShowcaseArray = compileUpdatedProfileShowcaseArray(reOrderedProfiles);
  
                return updateInstitutionProfileShowcase(institution, updatedShowcaseArray)
                  .then(function (response) {
                    $analytics.eventTrack('Re-ordered institution showcase profiles', {  category: 'institution showcase' });
                    return response.data;
                  })
                  .catch(function () {
                    NotificationService.error('The institution showcase profiles could not be re-ordered, please try again.');
                  });
              },
  
              updateInstitutionField: function(institution, fieldName, newValue) {
                var updatePacket = {};
                updatePacket[fieldName] = newValue;
  
                return $http.put('/internal_api/institutions/' + institution.code, updatePacket)
                  .then(function (response) {
                    $analytics.eventTrack("edit " + fieldName, {  category: 'institution edits' });
                    return response.data;
                  })
                  .catch(function () {
                    NotificationService.error('The ' + fieldName.replace('_', ' ') + ' could not be updated, please try again.');
                  });
              },
  
              findInstitutionsByPartialName: function (searchString, limit, parentId, type) {
                var params = {
                  query: searchString
                };
  
                if (!!limit) {
                  params.limit = limit;
                }
  
                if (angular.isDefined(parentId)) {
                  params.parent = parentId;
                }
  
                if (angular.isDefined(type)) {
                  params.type = type;
                }
  
                return $http({url: '/internal_api/institutions/search', method: "GET", params: params})
                  .then(function (response) {
                    return response.data.data.search_results;
                  });
              },
  
              getIfUserIsInstitutionAdmin: function (userId) {
                return $http.get('/internal_api/institution_admins/' + userId)
                  .then(function (response) {
                    return response.data.data.institution_admin;
                  })
                  .catch(function () {});
              },
  
              addInstitutionAdmin: function (new_admin_email, institution) {
                var errormsg = 'Failed to add institutional admin privileges for ' + new_admin_email.name;
  
                return $http.put('/internal_api/institution_admins/' + new_admin_email + '/for_institution/' + institution.code)
                  .then(function (response) {
                    if (response.status === 200) {
                      NotificationService.success('Institutional admin privileges added for ' + new_admin_email);
                    }
                    return response.data.data.institution_admin;
                  })
                  .catch(function (error) {
                    NotificationService.error(errormsg + '. The error was ' + error.data.errors[0]);
                  });
              },
  
              removeInstitutionAdmin: function (admin_to_remove, institution) {
                var errormsg = 'Failed to revoke institutional admin privileges for ' + admin_to_remove.name;
  
                return $http.delete('/internal_api/institution_admins/' + admin_to_remove.id + '/for_institution/' + institution.code)
                  .then(function (response) {
                    if (response.status === 204) {
                      NotificationService.success('Institutional admin privileges revoked for ' + admin_to_remove.name);
                    }
                    return response;
                  })
                  .catch(function (error) {
                    NotificationService.error(errormsg + '. The error was ' + error.data.errors[0]);
                  });
              },
  
              removeInstitutionLogo: function (shortCode) {
                return $http.get('/internal_api/institutions/' + shortCode + '/logo/remove');
              },
  
              removeInstitutionBanner: function (shortCode) {
                return $http.get('/internal_api/institutions/' + shortCode + '/banner/remove');
              },
  
              searchInstitutionUsers: function (institution, email, name) {
                var query = [];
  
                if (!!email) {
                  query.push('email=' + email);
                }
  
                if (!!name) {
                  query.push('name=' + name);
                }
  
                query = '?' + query.join('&');
  
                return $http.get('/internal_api/institutions/' + institution.code + '/profiles' + query);
              },
  
              getCustomerInstitutions: function (states) {
                return $http.get('/internal_api/institutions/?state=' + states);
              },
  
              institutionSubscriptionStates: ['customer', 'trial']
  
            };
  
            return InstitutionService;
          }
        ]
      );
  
  }(window.angular, window._));
  
  ; (function (angular, _) {
    'use strict';
    angular
      .module('kudosInstitutions')
      .factory(
        'InstitutionReportService',
        [
          '$http', 'NotificationService', '$state', 'PublicationService',
          function ($http, NotificationService, $state, PublicationService) {
  
            function addReportToFavorites(shortCode, reportName) {
              return $http.post('/internal_api/institutions/' + shortCode + '/favorites/' + reportName)
                .then(function (response) {
                  NotificationService.success('This report has been added to your favourites.');
                  return response;
                })
                .catch(function () {
                  $state.go('error', {}, {errorCode: 500});
                });
            };
  
            function removeReportFromFavorites(shortCode, reportName) {
              return $http.delete('/internal_api/institutions/' + shortCode + '/favorites/' + reportName)
                .then(function (response) {
                  NotificationService.success('This report has been removed from your favorites.');
                  return response;
  
                })
                .catch(function () {
                  $state.go('error', {}, {errorCode: 500});
                });
            };
  
            var cellContentTemplate = function (innerTemplate) {
              return '<div class="ui-grid-cell-contents">' + innerTemplate + '</div>';
            };
  
            /*
             * Bespoke sorting algorithm for date fields.
             * This is required so that blank dates can be sorted to the bottom, the default
             * date sorting algorithm places blanks at the top.
             */
            var dateSortingAlgorithm = function (a, b) {
              // If both a and b are not blank - sort normally.
              if (!!a && !!b) {
                if (a === b) {
                  return 0;
                } else if (a > b) {
                  return 1;
                } else {
                  return -1;
                }
              // If b is blank, place it after a.
              } else if (!!a && !b) {
                return 1;
              // If a is blank, place it after b.
              } else if (!a && !!b) {
                return -1;
              // If both are blank, place them at the same level.
              } else {
                return 0;
              }
            };
  
            return {
  
              addReportToFavorites: addReportToFavorites,
              removeReportFromFavorites: removeReportFromFavorites,
  
              getFavoritedReports: function(shortCode) {
                return $http.get('/internal_api/institutions/' + shortCode + '/favorites');
              },
  
              getFavoritedReport: function(shortCode, reportName) {
                return $http.get('/internal_api/institutions/' + shortCode + '/favorites/' + reportName);
              },
  
              getReport: function (shortCode, reportName) {
                return $http.get('/internal_api/institutions/' + shortCode + '/reports/' + reportName);
              },
  
              getReportUrl: function (shortCode, reportName) {
                return ('/internal_api/institutions/' + shortCode + '/reports/' + reportName);
              },
  
              getReportCsvUrl: function (shortCode, reportName) {
                return (this.getReportUrl(shortCode, reportName) + '.csv');
              },
  
              compileChartOptions: {
                go_url_views: {
                  lineChart: function (report) {
                    return {
                      metadata: {
                        yAxis: {
                          axisLabel: "Cumulative number of clicks on the invitation link by this date"
                        }
                      },
                      datasets: [
                        {
                          x: {
                            name: "date_with_views",
                            label: "Date"
                          },
                          y: {
                            name: "number_before_date",
                            label: "Cumulative number of clicks"
                          },
                          data: _.map(report, function (row) {
                            return {
                              x: Date.parse(row.date_with_views),
                              y: row.number_before_date
                            };
                          })
                        }
                      ]
                    };
                  }
                },
  
                researchers_registrations: {
                  lineChart: function (report) {
                    return {
                      metadata: {
                        yAxis: {
                          axisLabel: "Cumulative number of researchers registered with Kudos by this date"
                        }
                      },
                      datasets: [
                        {
                          x: {
                            name: "date_with_registrations",
                            label: "Date"
                          },
                          y: {
                            name: "number_before_date",
                            label: "Cumulative number of researchers"
                          },
                          data: _.map(report, function (row) {
                            return {
                              x: Date.parse(row.date_with_registrations),
                              y: row.number_before_date
                            };
                          })
                        }
                      ]
                    };
                  }
                },
  
                publications_views: {
                  lineChart:  function (report) {
                    return {
                      metadata: {
                        yAxis: {
                          axisLabel: "Cumulative number of views of publications by this date"
                        },
                      },
                      datasets: [
                        {
                          x: {
                            name: "date",
                            label: "Date"
                          },
                          y: {
                            name: "cumulative_views",
                            label: "Cumulative number of views"
                          },
                          data: _.map(report, function (row) {
                            return {
                              x: Date.parse(row.date),
                              y: row.cumulative_views
                            };
                          })
                        }
                      ]
                    };
                  }
                },
              },
  
              compileGridOptions: {
  
                all_publications: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'publicationDate',
                        cellTemplate: cellContentTemplate('{{ row.entity.publicationDate | date:"MMM yyyy"}}'),
                        maxWidth: 110,
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'publicationTitle',
                        cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                      },
                      {
                        field: 'DOI',
                        headerCellClass: 'uppercase',
                        maxWidth: 250
                      }
                    ],
                    data: _.map(report, function (article) {
  
                      return {
                        publicationDate: article.date,
                        publicationTitle: article.title,
                        DOI: article.doi,
                        encodedDOI: article.encoded_doi
                      };
                    })
                  };
                },
  
                explained_excluding_resources_publications: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'publicationDate',
                        cellTemplate: cellContentTemplate('{{ row.entity.publicationDate | date:"MMM yyyy"}}'),
                        headerCellClass: 'wrap-words',
                        maxWidth: 110,
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'publicationTitle',
                        cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                      },
                      {
                        field: 'DOI',
                        headerCellClass: 'uppercase',
                        maxWidth: 250
                      }
                    ],
                    data: _.map(report, function (article) {
  
                      return {
                        publicationDate: article.date,
                        publicationTitle: article.title,
                        DOI: article.doi,
                        encodedDOI: article.encoded_doi
                      };
                    })
                  };
                },
  
                publications_with_resources: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'publicationDate',
                        cellTemplate: cellContentTemplate('{{ row.entity.publicationDate | date:"MMM yyyy"}}'),
                        maxWidth: 110,
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'publicationTitle',
                        cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                      },
                      {
                        field: 'DOI',
                        headerCellClass: 'uppercase',
                        maxWidth: 250
                      }
                    ],
                    data: _.map(report, function (article) {
  
                      return {
                        publicationDate: article.date,
                        publicationTitle: article.title,
                        DOI: article.doi,
                        encodedDOI: article.encoded_doi
                      };
                    })
                  };
                },
  
                explained_publications: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'publicationDate',
                        cellTemplate: cellContentTemplate('{{ row.entity.publicationDate | date:"MMM yyyy"}}'),
                        maxWidth: 110,
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'publicationTitle',
                        cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                      },
                      {
                        field: 'DOI',
                        headerCellClass: 'uppercase',
                        maxWidth: 250
                      }
                    ],
                    data: _.map(report, function (article) {
  
                      return {
                        publicationDate: article.date,
                        publicationTitle: article.title,
                        DOI: article.doi,
                        encodedDOI: article.encoded_doi
                      };
                    })
                  };
                },
  
                linked_resources: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'publicationDate',
                        cellTemplate: cellContentTemplate('{{ row.entity.publicationDate | date:"MMM yyyy"}}'),
                        maxWidth: 100,
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'desc',
                          priority: 0
                        }
                      },
                      {
                        field: 'publicationTitle',
                        cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                      },
                      {
                        field: 'resourceTitle'
                      },
                      {
                        field: 'resourceDescription'
                      },
                      {
                        field: 'resourceType',
                        maxWidth: 90,
                        headerCellClass: 'wrap-words'
                      },
                      {
                        field: 'resourceLink',
                        cellTemplate: cellContentTemplate('<a href="{{ row.entity.resourceLink }}" target="_blank">{{ row.entity.resourceLink }}</a>')
                      }
                    ],
                    data: _.map(report, function (resource) {
  
                      return {
                        publicationDoi: resource.publication_doi,
                        publicationDate: resource.publication_date,
                        publicationTitle: resource.publication_title,
                        resourceTitle: resource.resource_title,
                        resourceDescription: resource.resource_description,
                        resourceType: resource.resource_type,
                        resourceLink: resource.resource_link,
                        encodedDOI: PublicationService.encodeDOI(resource.publication_doi)
                      };
                    })
                  };
                },
  
                shared_publications: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'publicationDate',
                        cellTemplate: cellContentTemplate('{{ row.entity.publicationDate | date:"MMM yyyy"}}'),
                        maxWidth: 110,
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'publicationTitle',
                        cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                      },
                      {
                        field: 'DOI',
                        headerCellClass: 'uppercase',
                        maxWidth: 250
                      }
                    ],
                    data: _.map(report, function (article) {
  
                      return {
                        publicationDate: article.date,
                        publicationTitle: article.title,
                        DOI: article.doi,
                        encodedDOI: article.encoded_doi
                      };
                    })
                  };
                },
  
                explain_share_activity: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'date',
                        cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                        maxWidth: 130,
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'friendlyNote',
                        displayName: 'Activity',
                        cellTemplate: cellContentTemplate('{{ row.entity.friendlyNote }} <a ng-show="!!row.entity.readTweetUrl" href="{{row.entity.readTweetUrl}}" title="read tweet" target="_blank">read tweet</a>')
                      },
                      {
                        field: 'accountName',
                        displayName: 'Author',
                        cellTemplate: cellContentTemplate('<a href="/profile/{{row.entity.customUsername}}" target="_blank" ng-bind-html="row.entity.accountName"></a>')
                      },
                      {
                        field: 'publicationTitle',
                        cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                      },
                      {
                        field: 'publicationDOI',
                        displayName: 'DOI of Publication',
                        maxWidth: 250,
                        cellTemplate: cellContentTemplate('{{ row.entity.publicationDOI }}')
                      }
                    ],
                    data: _.map(report, function (activity) {
  
                      return {
                        date: activity.date,
                        friendlyNote: activity.friendly_note,
                        accountName: activity.account_name,
                        customUsername: activity.custom_username,
                        publicationTitle: activity.publication_title,
                        publicationDOI: activity.publication_doi,
                        readTweetUrl: activity.read_tweet_url,
                        encodedDOI: PublicationService.encodeDOI(activity.publication_doi)
                      };
                    })
                  };
                },
  
                explain_excluding_resources_activity: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'date',
                        cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                        maxWidth: 130,
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'friendlyNote',
                        displayName: 'Activity',
                        cellTemplate: cellContentTemplate('{{ row.entity.friendlyNote }} <a ng-show="!!row.entity.readTweetUrl" href="{{row.entity.readTweetUrl}}" title="read tweet" target="_blank">read tweet</a>')
                      },
                      {
                        field: 'accountName',
                        displayName: 'Author',
                        cellTemplate: cellContentTemplate('<a href="/profile/{{row.entity.customUsername}}" target="_blank" ng-bind-html="row.entity.accountName"></a>')
                      },
                      {
                        field: 'publicationTitle',
                        cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                      },
                      {
                        field: 'publicationDOI',
                        displayName: 'DOI of Publication',
                        maxWidth: 250,
                        cellTemplate: cellContentTemplate('{{ row.entity.publicationDOI }}')
                      }
                    ],
                    data: _.map(report, function (activity) {
  
                      return {
                        date: activity.date,
                        friendlyNote: activity.friendly_note,
                        accountName: activity.account_name,
                        customUsername: activity.custom_username,
                        publicationTitle: activity.publication_title,
                        publicationDOI: activity.publication_doi,
                        readTweetUrl: activity.read_tweet_url,
                        encodedDOI: PublicationService.encodeDOI(activity.publication_doi)
                      };
                    })
                  };
                },
  
                resource_activity: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'date',
                        cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                        maxWidth: 130,
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'friendlyNote',
                        displayName: 'Activity',
                        cellTemplate: cellContentTemplate('{{ row.entity.friendlyNote }} <a ng-show="!!row.entity.readTweetUrl" href="{{row.entity.readTweetUrl}}" title="read tweet" target="_blank">read tweet</a>')
                      },
                      {
                        field: 'accountName',
                        displayName: 'Author',
                        cellTemplate: cellContentTemplate('<a href="/profile/{{row.entity.customUsername}}" target="_blank" ng-bind-html="row.entity.accountName"></a>')
                      },
                      {
                        field: 'publicationTitle',
                        cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                      },
                      {
                        field: 'publicationDOI',
                        displayName: 'DOI of Publication',
                        maxWidth: 250,
                        cellTemplate: cellContentTemplate('{{ row.entity.publicationDOI }}')
                      }
                    ],
                    data: _.map(report, function (activity) {
  
                      return {
                        date: activity.date,
                        friendlyNote: activity.friendly_note,
                        accountName: activity.account_name,
                        customUsername: activity.custom_username,
                        publicationTitle: activity.publication_title,
                        publicationDOI: activity.publication_doi,
                        readTweetUrl: activity.read_tweet_url,
                        encodedDOI: PublicationService.encodeDOI(activity.publication_doi)
                      };
                    })
                  };
                },
  
                share_activity: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'date',
                        cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                        maxWidth: 130,
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'friendlyNote',
                        displayName: 'Activity',
                        cellTemplate: cellContentTemplate('{{ row.entity.friendlyNote }} <a ng-show="!!row.entity.readTweetUrl" href="{{row.entity.readTweetUrl}}" title="read tweet" target="_blank">read tweet</a>')
                      },
                      {
                        field: 'accountName',
                        displayName: 'Author',
                        cellTemplate: cellContentTemplate('<a href="/profile/{{row.entity.customUsername}}" target="_blank" ng-bind-html="row.entity.accountName"></a>')
                      },
                      {
                        field: 'publicationTitle',
                        cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                      },
                      {
                        field: 'publicationDOI',
                        displayName: 'DOI of Publication',
                        maxWidth: 250,
                        cellTemplate: cellContentTemplate('{{ row.entity.publicationDOI }}')
                      }
                    ],
                    data: _.map(report, function (activity) {
  
                      return {
                        date: activity.date,
                        friendlyNote: activity.friendly_note,
                        accountName: activity.account_name,
                        customUsername: activity.custom_username,
                        publicationTitle: activity.publication_title,
                        publicationDOI: activity.publication_doi,
                        readTweetUrl: activity.read_tweet_url,
                        encodedDOI: PublicationService.encodeDOI(activity.publication_doi)
                      };
                    })
                  };
                },
  
                tweet_activity: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'date',
                        cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                        maxWidth: 130,
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'friendlyNote',
                        displayName: 'Activity',
                        cellTemplate: cellContentTemplate('{{ row.entity.friendlyNote }}')
                      },
                      {
                        field: 'accountName',
                        displayName: 'Author',
                        cellTemplate: cellContentTemplate('<a href="/profile/{{row.entity.customUsername}}" target="_blank" ng-bind-html="row.entity.accountName"></a>')
                      },
                      {
                        field: 'publicationTitle',
                        cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.publicationTitle"></a>')
                      },
                      {
                        field: 'publicationDOI',
                        displayName: 'DOI of Publication',
                        maxWidth: 250,
                        cellTemplate: cellContentTemplate('{{ row.entity.publicationDOI }}')
                      },
                      {
                        field: 'tweet',
                        name: 'Read Tweet',
                        cellTemplate: cellContentTemplate('<a ng-show="!!row.entity.readTweetUrl" href="{{row.entity.readTweetUrl}}" title="read tweet" target="_blank">read tweet</a>')
                      }
                    ],
                    data: _.map(report, function (activity) {
  
                      return {
                        date: activity.date,
                        friendlyNote: activity.friendly_note,
                        accountName: activity.account_name,
                        customUsername: activity.custom_username,
                        publicationTitle: activity.publication_title,
                        publicationDOI: activity.publication_doi,
                        readTweetUrl: activity.read_tweet_url,
                        encodedDOI: PublicationService.encodeDOI(activity.publication_doi)
                      };
                    })
                  };
                },
  
                researchers_claims: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'number_of_claims',
                        displayName: 'Number of publications claimed',
                        cellTemplate: cellContentTemplate('{{ row.entity.number_of_claims }}'),
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'desc',
                          priority: 0
                        }
                      },
                      {
                        field: 'researchers_claimed',
                        displayName: 'Number of Researchers that have claimed this number of publications',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.researchers_claimed }}')
                      }
                    ],
                    data: _.map(report, function (row) {
  
                      return {
                        number_of_claims: row.number_of_claims,
                        researchers_claimed: row.researchers_claimed
                      };
                    })
                  };
                },
  
                researchers_registrations: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'date',
                        cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                        headerCellClass: 'wrap-words',
                        width: 130,
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'number_on_date',
                        displayName: 'Number of researchers registered with Kudos on this date',
                        cellTemplate: cellContentTemplate('{{ row.entity.number_on_date }}'),
                        headerCellClass: 'wrap-words'
                      },
                      {
                        field: 'number_before_date',
                        displayName: 'Cumulative number of researchers registered with Kudos by this date',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.number_before_date }}')
                      }
                    ],
                    data: _.map(report, function (row) {
  
                      return {
                        date: row.date_with_registrations,
                        number_on_date: row.number_on_date,
                        number_before_date: row.number_before_date
                      };
                    })
                  };
                },
  
                researchers_by_career_stage: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'stage',
                        displayName: 'Career stage',
                        cellTemplate: cellContentTemplate('{{ row.entity.stage || "(Not Specified)" }}'),
                        headerCellClass: 'wrap-words',
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'acc_number',
                        displayName: 'Number of Researchers',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.acc_number }}'),
                        sort: {
                          direction: 'desc',
                          priority: 0
                        }
                      }
                    ],
                    data: _.map(report, function (row) {
  
                      return {
                        stage: row.stage,
                        acc_number: row.acc_number
                      };
                    })
                  };
                },
  
                researchers_by_country: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'country',
                        displayName: 'Country',
                        cellTemplate: cellContentTemplate('{{ row.entity.country || "(Not Specified)" }}'),
                        headerCellClass: 'wrap-words'
                      },
                      {
                        field: 'acc_number',
                        displayName: 'Number of Researchers',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.acc_number }}'),
                        sort: {
                          direction: 'desc',
                          priority: 0
                        }
                      }
                    ],
                    data: _.map(report, function (row) {
  
                      return {
                        country: row.country_name,
                        acc_number: row.acc_number
                      };
                    })
                  };
                },
  
                researchers_by_subject: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'subject',
                        displayName: 'Subject area',
                        cellTemplate: cellContentTemplate('{{ row.entity.subject || "(Not Specified)" }}'),
                        headerCellClass: 'wrap-words',
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'acc_number',
                        displayName: 'Number of Researchers',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.acc_number }}'),
                        sort: {
                          direction: 'desc',
                          priority: 0
                        }
                      }
                    ],
                    data: _.map(report, function (row) {
  
                      return {
                        subject: row.subject,
                        acc_number: row.acc_number
                      };
                    })
                  };
                },
  
                go_url_views: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'date',
                        cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                        headerCellClass: 'wrap-words',
                        width: 130,
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'number_on_date',
                        displayName: 'Number of clicks on the invitation link on this date',
                        cellTemplate: cellContentTemplate('{{ row.entity.number_on_date }}'),
                        headerCellClass: 'wrap-words',
                        width: 350,
                        sort: {
                          direction: 'desc',
                          priority: 0
                        }
                      },
                      {
                        field: 'number_before_date',
                        displayName: 'Cumulative number of clicks on the invitation link by this date',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.number_before_date }}')
                      }
                    ],
                    data: _.map(report, function (row) {
  
                      return {
                        date: row.date_with_views,
                        number_on_date: row.number_on_date,
                        number_before_date: row.number_before_date
                      };
                    })
                  };
                },
  
                all_researchers: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'registered',
                        cellTemplate: cellContentTemplate('{{ row.entity.registered | date:"d MMM yyyy"}}'),
                        displayName: 'Registration date',
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'name',
                        displayName: "User's name",
                        cellTemplate: cellContentTemplate('<a href="/profile/{{row.entity.customUsername}}" target="_blank" ng-bind-html="row.entity.name"></a>'),
                        headerCellClass: 'wrap-words'
                      },
                      {
                        field: 'email',
                        displayName: "User's email address",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.email }}')
                      },
                      {
                        field: 'subject',
                        displayName: 'Subject area',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.subject || "-" }}')
                      },
                      {
                        field: 'career_stage',
                        displayName: 'Career stage',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.career_stage || "-" }}')
                      },
                      {
                        field: 'country',
                        displayName: 'Country',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.country || "-" }}')
                      }
                    ],
                    data: _.map(report, function (account) {
  
                      return {
                        name: account.name,
                        registered: account.registered,
                        email: account.email,
                        career_stage: account.career_stage,
                        subject: account.subject,
                        country: account.country,
                        customUsername: account.custom_username,
                      };
                    })
                  };
                },
  
                publications_views: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'date',
                        cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                        displayName: 'Date',
                        headerCellClass: 'wrap-words',
                        width: 100,
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'views',
                        displayName: 'Number of views of publications on this date',
                        cellTemplate: cellContentTemplate('{{ row.entity.views }}'),
                        headerCellClass: 'wrap-words'
                      },
                      {
                        field: 'cumulative_views',
                        displayName: "Cumulative number of view of publications by this date",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.cumulative_views }}')
                      }
                    ],
                    data: _.map(report, function (record) {
  
                      return {
                        date: record.date,
                        views: record.views,
                        cumulative_views: record.cumulative_views
                      };
                    })
                  };
                },
  
                publications_most_viewed: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'rank',
                        cellTemplate: cellContentTemplate('{{ row.entity.rank }}'),
                        displayName: 'Rank',
                        headerCellClass: 'wrap-words',
                        width: 100
                      },
                      {
                        field: 'number_of_views',
                        displayName: 'Number of views of the publication page',
                        width: 100,
                        cellTemplate: cellContentTemplate('{{ row.entity.number_of_views }}'),
                        headerCellClass: 'wrap-words'
                      },
                      {
                        field: 'title',
                        displayName: "Publication title",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.title"></a>')
                      },
                      {
                        field: 'doi',
                        displayName: "DOI",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="http://dx.doi.org/{{row.entity.doi}}" target="_blank" ng-bind-html="row.entity.doi"></a>')
                      }
                    ],
                    data: _.map(report, function (publication) {
  
                      return {
                        rank: publication.rank,
                        number_of_views: publication.number_of_views,
                        title: publication.title,
                        doi: publication.doi,
                        encodedDOI: PublicationService.encodeDOI(publication.doi)
                      };
                    })
                  };
                },
  
                publications_share_referrals: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'date_with_share_referrals',
                        cellTemplate: cellContentTemplate('{{ row.entity.date_with_share_referrals | date:"d MMM yyyy"}}'),
                        displayName: 'Date',
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'sum_referrals_on_date',
                        displayName: 'Total number of share referrals on this date',
                        cellTemplate: cellContentTemplate('{{ row.entity.sum_referrals_on_date }}'),
                        headerCellClass: 'wrap-words'
                      }
                    ],
                    data: _.map(report, function (record) {
  
                      return {
                        date_with_share_referrals: record.date_with_share_referrals,
                        sum_referrals_on_date: record.sum_referrals_on_date
                      };
                    })
                  };
                },
  
                publications_highest_share_referrals: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'rank',
                        cellTemplate: cellContentTemplate('{{ row.entity.rank }}'),
                        displayName: 'Rank',
                        headerCellClass: 'wrap-words',
                        width: 100
                      },
                      {
                        field: 'number_share_referrals',
                        displayName: 'Number of Share Referrals',
                        width: 100,
                        cellTemplate: cellContentTemplate('{{ row.entity.number_share_referrals }}'),
                        headerCellClass: 'wrap-words'
                      },
                      {
                        field: 'type_share',
                        displayName: 'Type of share',
                        width: 150,
                        cellTemplate: cellContentTemplate('{{ row.entity.type_share }} <a ng-show="!!row.entity.readTweetUrl" href="{{row.entity.readTweetUrl}}" title="read tweet" target="_blank">read tweet</a>'),
                        headerCellClass: 'wrap-words'
                      },
                      {
                        field: 'publication_title',
                        displayName: "Publication title",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="publications/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.title"></a>')
                      },
                      {
                        field: 'publication_doi',
                        displayName: "DOI",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="http://dx.doi.org/{{row.entity.doi}}" target="_blank" ng-bind-html="row.entity.doi"></a>')
                      }
                    ],
                    data: _.map(report, function (publication) {
  
                      return {
                        rank: publication.rank,
                        number_share_referrals: publication.number_share_referrals,
                        type_share: publication.type_share,
                        readTweetUrl: publication.read_tweet_url,
                        title: publication.publication_title,
                        doi: publication.publication_doi,
                        encodedDOI: PublicationService.encodeDOI(publication.publication_doi)
                      };
                    })
                  };
                },
  
                showcase_page_view_count: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'date',
                        cellTemplate: cellContentTemplate('{{ row.entity.date | date:"d MMM yyyy"}}'),
                        maxWidth: 110,
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'desc',
                          priority: 0
                        },
                        sortingAlgorithm: dateSortingAlgorithm
                      },
                      {
                        field: 'pageViews',
                        displayName: 'Number of views of showcase on this date'
                      },
                      {
                        field: 'cumulativePageViews',
                        displayName: 'Cumulative number of views of showcase by this date'
                      }
                    ],
                    data: _.map(report, function (pageTracker) {
                      return {
                        date: pageTracker.date,
                        pageViews: pageTracker.page_views,
                        cumulativePageViews: pageTracker.cumulative_page_views
                      };
                    })
                  };
                },
  
                altmetric_scores_top_all_publications: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'rank',
                        displayName: 'Rank',
                        cellTemplate: cellContentTemplate('{{ row.entity.rank }}'),
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'asc',
                          priority: 0
                        },
                        maxWidth: 80 // make the rank column a bit narrower
                      },
                      {
                        field: 'scoreLatest',
                        displayName: 'Current Altmetric score',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.scoreLatest }}'),
                        maxWidth: 80 // make the score column a bit narrower
                      },
                      {
                        field: 'title',
                        displayName: "Publication title",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="/articles/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.title"></a>'),
                      },
                      {
                        field: 'title',
                        displayName: "Kudos detailed metrics page",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="/statistics/article/{{row.entity.id}}" target="_blank">More details</a>'),
                        maxWidth: 100
                      },
                      {
                        field: 'title',
                        displayName: "Altmetrics detailed metrics page",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="http://www.altmetric.com/details.php?doi={{row.entity.decodedDOI}}" target="_blank">More details</a>'),
                        maxWidth: 110
                      },
                      {
                        field: 'decodedDOI',
                        displayName: "DOI",
                        maxWidth: 190
                      }
                    ],
                    data: report // no map function required as JSON is in the right format
                  };
                },
  
                altmetric_scores_top_explained_publications: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'rank',
                        displayName: 'Rank',
                        cellTemplate: cellContentTemplate('{{ row.entity.rank }}'),
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'asc',
                          priority: 0
                        },
                        maxWidth: 80 // make the rank column a bit narrower
                      },
                      {
                        field: 'scoreLatest',
                        displayName: 'Current Altmetric score',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.scoreLatest }}'),
                        maxWidth: 80 // make the score column a bit narrower
                      },
                      {
                        field: 'title',
                        displayName: "Publication title",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="/articles/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.title"></a>'),
                      },
                      {
                        field: 'title',
                        displayName: "Kudos detailed metrics page",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="/statistics/article/{{row.entity.id}}" target="_blank">More details</a>'),
                        maxWidth: 100
                      },
                      {
                        field: 'title',
                        displayName: "Altmetrics detailed metrics page",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="http://www.altmetric.com/details.php?doi={{row.entity.decodedDOI}}" target="_blank">More details</a>'),
                        maxWidth: 110
                      },
                      {
                        field: 'decodedDOI',
                        displayName: "DOI",
                        maxWidth: 190
                      }
                    ],
                    data: report // no map function required as JSON is in the right format
                  };
                },
  
                altmetric_scores_climbing_all_publications: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'rank',
                        displayName: 'Rank',
                        cellTemplate: cellContentTemplate('{{ row.entity.rank }}'),
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'asc',
                          priority: 0
                        },
                        maxWidth: 80 // make the rank column a bit narrower
                      },
                      {
                        field: 'scorePercentageChange',
                        displayName: '% increase over the last 7 days',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.scorePercentageChange }}%'),
                        maxWidth: 80 // make the score column a bit narrower
                      },
                      {
                        field: 'scoreLatest',
                        displayName: 'Current Altmetric score',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.scoreLatest }}'),
                        maxWidth: 80 // make the score column a bit narrower
                      },
                      {
                        field: 'scoreLatest',
                        displayName: 'Altmetric score 7 days ago',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.scoreLastWeek }}'),
                        maxWidth: 80 // make the score column a bit narrower
                      },
                      {
                        field: 'title',
                        displayName: "Publication title",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="/articles/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.title"></a>'),
                      },
                      {
                        field: 'title',
                        displayName: "Kudos detailed metrics page",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="/statistics/article/{{row.entity.id}}" target="_blank">More details</a>'),
                        maxWidth: 100
                      },
                      {
                        field: 'title',
                        displayName: "Altmetrics detailed metrics page",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="http://www.altmetric.com/details.php?doi={{row.entity.decodedDOI}}" target="_blank">More details</a>'),
                        maxWidth: 110
                      },
                      {
                        field: 'decodedDOI',
                        displayName: "DOI",
                        maxWidth: 190
                      }
                    ],
                    data: report // no map function required as JSON is in the right format
                  };
                },
  
                altmetric_scores_climbing_explained_publications: function (report) {
                  return {
                    enableSorting: true,
                    minRowsToShow: 20,
                    columnDefs: [
                      {
                        field: 'rank',
                        displayName: 'Rank',
                        cellTemplate: cellContentTemplate('{{ row.entity.rank }}'),
                        headerCellClass: 'wrap-words',
                        sort: {
                          direction: 'asc',
                          priority: 0
                        },
                        maxWidth: 80 // make the rank column a bit narrower
                      },
                      {
                        field: 'scorePercentageChange',
                        displayName: '% increase over the last 7 days',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.scorePercentageChange }}%'),
                        maxWidth: 80 // make the score column a bit narrower
                      },
                      {
                        field: 'scoreLatest',
                        displayName: 'Current Altmetric score',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.scoreLatest }}'),
                        maxWidth: 80 // make the score column a bit narrower
                      },
                      {
                        field: 'scoreLatest',
                        displayName: 'Altmetric score 7 days ago',
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('{{ row.entity.scoreLastWeek }}'),
                        maxWidth: 80 // make the score column a bit narrower
                      },
                      {
                        field: 'title',
                        displayName: "Publication title",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="/articles/{{row.entity.encodedDOI}}" target="_blank" ng-bind-html="row.entity.title"></a>'),
                      },
                      {
                        field: 'title',
                        displayName: "Kudos detailed metrics page",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="/statistics/article/{{row.entity.id}}" target="_blank">More details</a>'),
                        maxWidth: 100
                      },
                      {
                        field: 'title',
                        displayName: "Altmetrics detailed metrics page",
                        headerCellClass: 'wrap-words',
                        cellTemplate: cellContentTemplate('<a href="http://www.altmetric.com/details.php?doi={{row.entity.decodedDOI}}" target="_blank">More details</a>'),
                        maxWidth: 110
                      },
                      {
                        field: 'decodedDOI',
                        displayName: "DOI",
                        maxWidth: 190
                      }
                    ],
                    data: report // no map function required as JSON is in the right format
                  };
                }
              }
            };
          }
        ]
      );
  
  }(window.angular, window._));
  
  

} (window.angular));

; (function (angular) {

  'use strict';

  angular.module( 'kudosNotifications', ['toastr']);
  ; (function (angular) {
    'use strict';
    angular
      .module("kudosNotifications")
      .factory("NotificationService", ["toastr", function(toastr){
  
        var pub = {};
  
        pub.success = function(message, title){
          toastr.success(message, title, {timeOut: 8000});
        };
  
        pub.info = function(message, title){
          toastr.info(message, title, {timeOut: 0});
        };
  
        pub.warning = function(message, title){
          toastr.warning(message, title, {timeOut: 0});
        };
  
        pub.error = function(message, title){
          toastr.error(message, title, {timeOut: 0});
        };
  
        pub.clear = function(toast){
          if(angular.isDefined(toast)) {
            toastr.clear([toast]);
          } else {
            toastr.clear();
          }
        };
  
        return pub;
      }]);
  
  }(window.angular));
  

}(window.angular));

; (function (angular) {

  "use strict";

  angular.module('kudosProfiles', ['angulartics', 'ngFileUpload', 'kudosNotifications', 'ui.router', 'kudosInstitutions', 'templates']);
  (function (angular) {
    'use strict';
  
    angular.module('kudosProfiles').component('kudosDeleteProfileButton', {
      templateUrl: 'kudosProfiles/components/kudosDeleteProfileButton.component.html',
      controllerAs: 'vm',
      bindings: {
        profileId: '@',
        displayName: '@'
      },
      controller: ['ProfileService', 'NotificationService', '$window', function (ProfileService, NotificationService, $window) {
        var self = this;
  
        self.deleteAccount = function() {
          if (confirm('Are you sure? This cannot be undone!')) {
            ProfileService.deleteAccount(self.profileId)
              .then(function () {
                NotificationService.success(self.displayName + ' has been successfully deleted');
              })
              .catch(function () {
                  NotificationService.error(self.displayName + ' could not be deleted');
              });
          }
        }
      }]
    });
  
  } (window.angular));
  
  (function (angular) {
    'use strict';
  
    angular.module('kudosProfiles').component('kudosChangeInstitutionButton', {
      templateUrl: 'kudosProfiles/components/kudosChangeInstitutionButton.component.html',
      controllerAs: 'vm',
      bindings: {
        profileId: '@',
        displayName: '@',
      },
      controller: ['ProfileService', 'NotificationService', '$window', function (ProfileService, NotificationService, $window) {
        var self = this;
  
        self.changeInstitution = function() {
          var shortCode = prompt("Please enter Kudos institution short code:");
          if (shortCode === null) {
            return;
          }
  
          ProfileService.changeInstitution(self.profileId, shortCode).then(
            function (response) {
              NotificationService.success(self.displayName + " institution updated to '" + response.data.institution_name + "'");
              $window.location.reload();
            },
            function () {
              NotificationService.error(self.displayName + ' institution could not be updated');
            }
          );
        }
      }]
    });
  
  } (window.angular));
  
  (function (angular) {
    'use strict';
  
      angular.module('kudosProfiles').component('kudosMakeUserPremiumButton', {
        templateUrl: 'kudosProfiles/components/kudosMakeUserPremiumButton.component.html',
        controllerAs: 'vm',
        bindings: {
          displayName: '@',
          usersApiUrl: '@'
        },
        controller: ['ProfileService', 'NotificationService', '$window', function (ProfileService, NotificationService, $window) {
          var self = this;
  
          self.makeUserPremium = function() {
            if (confirm('This will upgrade a user that has not started a trial or a user with an expired trial to be a premium user. '
            + 'Use this for somebody with an expired trial that has now paid or to convert a standard user to premium for some other reason. \n\n'
            + 'If you see an error, try the other button as they may be in a current active trial.')) {
              ProfileService.makeUserPremium(self.usersApiUrl)
                .then(
                  function (response) {
                    NotificationService.success(self.displayName + ' has been successfully converted to premium');
                  },
                  function (errorMsg) {
                    NotificationService.error(errorMsg);
                  }
                );
            }
          }
        }]
      });
  
  } (window.angular));
  
  (function (angular) {
    'use strict';
  
    angular
      .module('kudosProfiles')
      .directive('kudosProfileInfoBox', [
        function () {
          return {
            scope: {
              account: '=',
              isEditable: '=?'
            },
            templateUrl: 'kudosProfiles/directives/kudosProfileInfoBox.html',
            compile: function (elem, attrs) {
              if (angular.isUndefined(attrs.isEditable)) {
                attrs.isEditable = false;
              }
            },
            link: function (scope) {
              scope.loaded = false;
            }
          };
        }
      ]);
  
  } (window.angular));
  
  ; (function (angular, _) {
    'use strict';
    angular
      .module('kudosProfiles')
      .factory(
        'ProfileService',
        [
          '$http',
          '$analytics',
          '$q',
          'NotificationService',
          'Upload',
          function ($http, $analytics, $q, NotificationService, Upload) {
  
            // Accepts a map of keys with their new values
            function update(accountId, changedAttributes){
              return $http.put( "/internal_api/accounts/" + accountId, changedAttributes);
            }
  
            return {
              uploadAvatarImage: uploadAvatarImage,
  
              getProfile: function (id) {
                return $http.get('/internal_api/accounts/' + id);
              },
  
              updateAccount: function (accountId, changedAttributes) {
                var changedAttributesKeys = _.keys(changedAttributes);
                return update(accountId, changedAttributes)
                  .then(function(response) {
                      var profile = response.data.data.profile;
                      // Force image refresh if avatar has changed
                      if(changedAttributes.avatar || changedAttributes.avatar_crop){
                        _.each(profile.avatar, function(url, key) {
                          profile.avatar[key] = url + '?decache='+Math.random();
                        });
                      }
                      return response;
                    })
                  .then(
                    function (response) {
                      _.each(changedAttributesKeys, function (changedAttribute) {
                        $analytics.eventTrack("edit " + changedAttribute, {  category: 'account edits', label: 'ux2' });
                      });
                      var tokenClaimsInvalid = response.headers('X-Token-Claims-Invalid');
                      return {
                        data: response.data.data,
                        tokenClaimsInvalid: tokenClaimsInvalid && tokenClaimsInvalid.toLowerCase() === 'true' ? true : false
                      };
  
                    })
                  .catch(function (error) {
                      NotificationService.error('The ' + changedAttributesKeys.join(', ') + ' could not be updated, please try again.');
                      return $q.reject(error);
                    }
                  );
              },
  
              userIsProfile: function (user, profile) {
                return user.id === profile.id;
              },
  
              promoteAccount: function (accountId) {
                var deferred = $q.defer();
  
                $http.post('/accounts/promote?id=' + accountId, {})
                  .then(
                    function (response) {
                      return deferred.resolve(response.data);
                    },
                    function (error) {
                      return deferred.reject(error);
                    }
                  );
  
                return deferred.promise;
  
              },
  
              demoteAccount: function (accountId) {
                var deferred = $q.defer();
  
                $http.post('/accounts/demote?id=' + accountId, {})
                  .then(
                    function (response) {
                      return deferred.resolve(response.data);
                    },
                    function (error) {
                      return deferred.reject(error);
                    }
                  );
  
                return deferred.promise;
  
              },
  
              removeClaimedPublications: function (accountId) {
                var deferred = $q.defer();
  
                $http.post('/accounts/remove_claimed_publications?id=' + accountId, {})
                  .then(
                    function (response) {
                      return deferred.resolve(response.data);
                    },
                    function (error) {
                      return deferred.reject(error);
                    }
                  );
  
                return deferred.promise;
              },
  
              deleteAccount: function (accountId) {
                var deferred = $q.defer();
  
                $http.post('/accounts/delete?id=' + accountId, {})
                  .then(
                    function (response) {
                      return deferred.resolve(response.data);
                    },
                    function (error) {
                      return deferred.reject(error);
                    }
                  );
  
                return deferred.promise;
              },
  
              changeInstitution: function (accountId, shortCode) {
                return $http.post('/accounts/change_institution?id=' + accountId + '&short-code=' + shortCode, {});
              },
  
              searchProfiles: function (email) {
                email = encodeURIComponent(email);
                return $http.get('/internal_api/accounts/search?email=' + email)
                  .then(function (response) {
                    return response.data.data.accounts;
                  });
              },
  
              makeUserPremium: function (usersApiUrl) {
                var deferred = $q.defer();
                $http.post(usersApiUrl, {}, {withCredentials: true})
                  .then(
                    function (response) {
                      return deferred.resolve(response.data);
                    },
                    function (error) {
                      if (error.status === 409) {
                        return deferred.reject('User is not eligible for a new membership');
                      } else {
                        return deferred.reject('Unexpected error making new membership');
                      }
                    }
                  );
  
                return deferred.promise;
              },
            };
  
            function uploadAvatarImage (accountId, file) {
              return Upload.upload({
                  url: '/internal_api/accounts/'+accountId+'/avatar',
                  method: 'POST',
                  fields: { 'profile[id]': accountId },
                  sendFieldsAs: 'json',
                  file: file,
                  fileFormDataName: 'profile[avatar]'
              });
            }
          }
        ]
      );
  
  }(window.angular, window._));
  

} (window.angular));

; (function (angular) {
  'use strict';

  angular.module('kudosPublications', ['sessions', 'angulartics', 'kudos', 'kudosNotifications', 'kudosInstitutions', 'kudosPublishers', 'kudosModals', 'unsplash', 'templates']);
  ; (function (angular, _) {
    'use strict';
    angular
      .module('kudosPublications')
      .factory(
        'PublicationService',
        [
          '$analytics',
          '$q',
          '$location',
          '$http',
          'ModalService',
          'NotificationService',
          'SessionService',
          function(
            $analytics,
            $q,
            $location,
            $http,
            ModalService,
            NotificationService,
            SessionService
          ) {
  
            var apiBase = '/internal_api/publications/';
            var pub = {
              getPublication: getPublication,
              getPublicationInterventions: getPublicationInterventions,
              getPublications: getPublications,
              getPublicationsByContributor: getPublicationsByContributor,
              encodeDOI: encodeDOI,
              decodeDOI: decodeDOI
            };
  
            function getPublication(doi) {
              return $http.get(apiBase + encodeDOI(doi));
            }
  
            function getPublicationInterventions(doi) {
              return $http.get(apiBase + encodeDOI(doi) + '/interventions');
            }
  
            function getPublications (filters, limit) {
              var filterArray = [];
  
              // Creates an array of all the filters from provided filters object
              _.each(_.keys(filters), function(filterKey) {
                filterArray.push(filterKey + '=' + filters[filterKey]);
              });
  
              // Adds limit filter to filter array
              if (angular.isDefined(limit)) {
                filterArray.push('limit=' + limit);
              }
  
              // If filtersObject and limit has been provided, make this into GET param string
              var filterString = (!!filterArray.length ? ('?' + filterArray.join('&')) : '');
  
              return $http.get(apiBase + filterString);
            }
  
            function getPublicationsByContributor (email) {
              return $http.get(apiBase + 'by_contributor/' + email);
            }
  
            function encodeDOI (doi) {
              // We double-encode slashes because INTERNETS.
              // Slashes are interpreted as slashes even when they're encoded once, and Apache actively blocks encoded slashes.
              // see https://github.com/growkudos/growkudos/issues/5118#issuecomment-152466933
              return encodeURIComponent(doi).replace(/%2F/g, "%252F");
            }
  
            function decodeDOI (encodedDOI) {
              encodedDOI = encodedDOI.replace(/%252F/g, "%2F");
              return decodeURIComponent(encodedDOI);
            }
  
            pub.placeholderText = {
              microTitle: 'Add a short title to make your publication easier to find and understand.',
              laySummary: 'Add a simple, non-technical explanation or the lay summary of your publication to make it more accessible to a broader audience. Please note that this is not the abstract, but a plain language summary to help more people find and understand your work.',
              impactStatement: 'Add an explanation of what is unique and/or timely about your work, and the difference it might make to help increase readership.',
              authorPerspective: "Add your own personal perspective about this publication. Note that this is your opportunity to comment as an individual, whereas the 'What's it about?' and 'Why is it Important?' sections are jointly created by one or more authors."
            };
  
            // Accepts a map of keys with their new values
            function update(doi, changedAttributes){
              return $http.put( apiBase + encodeDOI(doi), changedAttributes);
            }
  
            pub.updatePublicationField = function(publication, fieldName, newValue){
              var updatePacket    = {};
              var fieldNameAlias  = pub.getFieldNameAlias(fieldName);
  
              updatePacket[fieldName] = newValue;
              return update(publication.id, updatePacket)
                .then(function (response) {
                  $analytics.eventTrack("edit " + fieldName, {  category: 'publication edits', label: 'ux2' });
                  return response.data;
                })
                .catch(function (data) {
                  var errorMessage  = 'The ' + fieldNameAlias.replace('_', ' ') + ' could not be updated';
                  var error         = errorMessage;
  
                  if (data) {
                    // Always provide some sort of error
                    error = (angular.isUndefined(data.statusText) ? data : data.statusText);
  
                    // Uses serverside error text if provided
                    if (data.data.errors.length) {
                      error = _.head(data.data.errors);
                    }
  
                    errorMessage = errorMessage + ': ' + error;
                    errorMessage = errorMessage.replace(fieldName, fieldNameAlias).replace('_', ' ');
                  }
  
                  NotificationService.error(errorMessage, 'Update failed');
  
                  return $q.reject(error);
                });
            };
  
            pub.refreshMetadata = function(publication) {
              return $http.post( apiBase + encodeDOI(publication.id) + '/refresh_metadata', {})
                .then(function (response) {
                  NotificationService.success('Metadata refreshed');
                  // Get the updated publication
                  // At the moment the refresh_metadata POST request returns HTML, so just query the
                  return response;
                }, function() {
                  NotificationService.error('An error occurred, please try again.');
                  return $q.reject();
                });
            };
  
            pub.claim = function(doi) {
  
              if(!SessionService.userIsLoggedIn()){
                NotificationService.error('You must sign in to claim articles.');
                return $q.reject('Not authenticated');
              }
  
              var claimConfirmationModalOptions = {
                okButton: {
                  text: 'Yes - this is my publication',
                  isDismissed: false,
                  classes: 'btn-primary'
                },
                cancelButton: {
                  text: 'Cancel',
                  isDismissed: true,
                  classes: 'btn-muted'
                },
                title: 'Claim Publication',
                content: 'Please confirm that you are an author of this publication. Your name as shown below will be listed as a contributor on the Kudos publication page, and we may notify your co-authors if they are registered with us.',
                subContent: SessionService.currentUser().display_name
              };
  
              return ModalService
                .openConfirmationModal(claimConfirmationModalOptions)
                  .result
                    // If the confirm modal button is pressed
                    .then(function () {
                      return $http.post(apiBase + encodeDOI(doi) + '/claim', {});
                    })
                      // If the claim post request is successful
                      .then(function (data) {
                        $analytics.eventTrack('Claim', {  category: 'Article', label: doi });
  
                        NotificationService.success('You have successfully claimed this publication');
  
                        if (!SessionService.userIsVerified()) {
                          NotificationService.warning('You cannot explain and enrich this publication to help increase its impact yet because your registration is not yet confirmed. Please check your email for a welcome message with a confirmation link.');
                        }
                        return data.data;
                      })
                      .catch(function(response) {
                        // Create alerts if catch is thrown as a result of failed request
                        if(angular.isDefined(response.data) && response.data.errors.length){
                          response.data.errors.forEach(function(error) {
                            NotificationService.error(error);
                          });
  
                          return $q.reject(response.data.errors[0]);
                        }
                        return $q.reject(response);
                      });
            };
  
            pub.unclaim = function(doi) {
  
              var unClaimConfirmationModalOptions = {
                okButton: {
                  text: 'Yes — unclaim publication',
                  isDismissed: false,
                  classes: 'btn-primary'
                },
                cancelButton: {
                  text: 'Cancel',
                  isDismissed: true,
                  classes: 'btn-muted'
                },
                title: 'Unclaim Publication',
                content: 'By unclaiming this publication, you will remove it from your account. You will no longer be listed as an author on the Kudos publication page and will no longer have access to detailed metrics for this publication.',
                subContent: false
              };
  
              return ModalService
                .openConfirmationModal(unClaimConfirmationModalOptions)
                  .result
                    // If the confirmation modal confirm button is pressed
                    .then(function () {
                      return $http.post(apiBase + encodeDOI(doi) + '/unclaim', {});
                    })
                      // If the unclaim post request is successful
                      .then(function (data) {
                        $analytics.eventTrack('Unclaim', {  category: 'Article', label: doi });
                        NotificationService.success('Unclaimed successfully');
                        return data.data;
                      })
                      .catch(function(response) {
                        // Create alerts if catch is thrown as a result of failed request
                        if(angular.isDefined(response.data) && response.data.errors){
                          response.data.errors.forEach(function(error) {
                            NotificationService.error(error);
  
                            return $q.reject(response.data.errors[0]);
                          });
                        }
                        return $q.reject(response);
                      });
            };
  
            pub.getFieldNameAlias = function (fieldName) {
              var fieldNameAliasLookup = {
                micro_title: 'plain language title'
              };
  
              return fieldNameAliasLookup[fieldName] || fieldName;
            };
  
            pub.flashRecommendedAction = false;
  
            return pub;
          }
        ]
      );
  
  }(window.angular, window._));
  
  ; (function (angular) {
    'use strict';
    angular
      .module('kudosPublications')
      .service(
        'RegionComponentChangedService',
        [
          RegionComponentChangedService
        ]
      );
  
      function RegionComponentChangedService () {
        var self = this;
        self.setChanged = setChanged;
        self.setUnchanged = setUnchanged;
        self.hasChanged = hasChanged;
  
        self.changesFlag = false;
        
        function setChanged() {
          self.changesFlag = true;
        }
  
        function setUnchanged() {
          self.changesFlag = false;
        }
  
        function hasChanged() {
          return self.changesFlag;
        }
      }
  }(window.angular));
  
  ; (function (angular, _) {
  
    'use strict';
  
    angular.module('kudosPublications')
      .factory('AuthorPerspectiveService', [
        '$http',
        '$q',
        'NotificationService',
        function ($http, $q, NotificationService) {
  
          var apiBaseUrl = '/author_perspectives';  // the PUT is handled in the web app, not API - due to CORS problemss
  
          var pub = {
  
            create: function (newPerspective) {
              return $http.post(apiBaseUrl, {author_perspective: newPerspective}).catch(handleCatch);
            },
  
            get: function (perspectiveId) {
              return $http.get(apiBaseUrl + '/' + perspectiveId);
            },
  
            update: function (perspective) {
              return $http.put(apiBaseUrl + '/' +  perspective.id, {author_perspective: perspective}).catch(handleCatch);
            },
  
            delete: function (perspectiveId) {
              return $http.delete(apiBaseUrl + '/' + perspectiveId);
            },
  
            setApiBaseUrl: function (newApiBaseUrl){
              apiBaseUrl = newApiBaseUrl;
            },
  
            getApiBaseUrl: function (){
              return apiBaseUrl;
            }
          };
  
          function handleCatch (response) {
            _.each(response.data.message, function (error) {
              NotificationService.error(error);
            });
  
            return $q.reject(response);
          }
  
          return pub;
      }
    ]);
  
  }(window.angular, window._));
  
  ; (function (angular) {
    'use strict';
    angular.module('kudosPublications')
      .factory('InfographicService', [
        '$http',
        function ($http) {
  
          function buildUrl(doi) {
            var apiBaseUrl =  '/internal_api/publications';
            var resource = 'infographic';
            //  Can't use URL constructor here as not supported by PhantomJS
            var url = [apiBaseUrl, encodeURIComponent(doi), resource].join('/');
            return encodeURI(url);
          }
  
          function buildErrorResponse(response, action) {
            var error;
  
            switch (response.status) {
              case 401:
                error = 'unauthenticated';
                break;
              case 400:
                if (response.data && response.data.errors && response.data.errors.length > 0) {
                  error = response.data.errors[0];
                  break;
                }
                error = 'Unable to ' + action + ' infographic, please try again';
                break;
              default:
                error = 'Unable to ' + action + ' infographic, please try again';
            }
  
            return new Error(error, { cause : new Error('Got ' + response.status + ' from infographic API')});
          }
  
          return {
            delete: function(doi) {
              return $http.delete(buildUrl(doi)).catch(function(response) {
                throw buildErrorResponse(response, 'delete');
              });
            },
            update: function(doi, infographicFile) {
              var formData = new FormData();
              formData.append('file', infographicFile);
  
              return $http.put(
                buildUrl(doi),
                formData,
                // Required to ensure the XHR API sets the content-type
                // header with the boundary present
                { headers: { 'Content-Type': undefined } }
              ).then(function (response) {
                return response.data.data;
              }).catch(function(response){
                throw buildErrorResponse(response, 'update');
              });
            }
          };
        }
      ]);
  
    }(window.angular, window._));
  
  ; (function (angular) {
  
    'use strict';
  
    var publicationsModule = angular.module('kudosPublications');
  
    publicationsModule.directive('kudosPublicationExampleBox', [
        function () {
        return {
          scope: {
            helpText: '@?',
            visible: '=?'
          },
          templateUrl: 'kudosPublications/directives/kudosPublicationExampleBox/kudosPublicationExampleBox.html',
          transclude: true,
          link: function (scope) {
            if (angular.isUndefined(scope.visible)) {
              scope.visible = false;
            }
  
            scope.toggle = function () {
              scope.visible = !scope.visible;
            };
          }
        };
      }
    ]);
  
  }(window.angular));
  
  ; (function (angular) {
    'use strict';
  
    angular.module('kudosPublications')
      .directive('kudosPublicationBoxes', function () {
        return {
          scope: {
            publications: '=',
            halfWidth: '=?'
          },
          templateUrl: 'kudosPublications/kudosPublicationBoxes.directive.html',
          replace: true
        };
      });
  
  }(window.angular));
  
  ; (function (angular) {
    'use strict';
  
    angular.module('kudosPublications')
      .directive('kudosPublicationBox', function () {
        return {
          scope: {
            publication: '=',
            halfWidth: '=?'
          },
          templateUrl: 'kudosPublications/kudosPublicationBox.directive.html',
          replace: true
        };
      });
  
  }(window.angular));
  
  (function (angular) {
    'use strict';
  
    angular
      .module('kudosPublications')
      .directive('publicationInfographicView', [
        function () {
          return {
            scope: {},
            bindToController: {
              publication: '='
            },
            replace: true,
            templateUrl: 'kudosPublications/publication-infographic/publication-infographic-view.directive.html',
            controllerAs: 'vm',
            controller: [
              '$uibModal',
              '$rootScope',
              function ($uibModal, $rootScope) {
  
                var self = this;
                self.showInfographicModal = showInfographicModal;
                self.$onInit = init;
  
                function init() {
                  // Ensure that there self.publication.infographic is never undefined
                  self.publication.infographic = self.publication.infographic || {};
                }
  
                function showInfographicModal () {
                  var modalInstance;
                  var modalScope = $rootScope.$new();
  
                  modalScope.close = function () {
                    modalInstance.dismiss('cancel');
                  };
  
                  modalScope.infographic_url = self.publication.infographic.infographic;
  
                  modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'kudosPublications/publication-infographic/publication-infographic-modal.html',
                    size: 'lg',
                    scope: modalScope
                  });
                }
              }
            ]
          };
        }
      ]);
  
  } (window.angular));
  
  ; (function (angular, _) {
    'use strict';
  
    angular.module('kudosPublications')
      .controller(
        'PublicationExplainFormController',
        [
          'AuthorPerspectiveService',
          'PublicationService',
          'SessionService',
          'kudosFormService',
          '$rootScope',
          '$timeout',
          function (AuthorPerspectiveService, PublicationService, SessionService, kudosFormService, $rootScope, $timeout) {
            var self = this;
  
            self.saveButtonState = 'init';
  
            // Once listens for form save success/failure and changes button state appropriately
            $rootScope.$on(kudosFormService.FormSaveStateEventName, function (event, data) {
              changeSaveButtonState(data);
            });
  
            self.formService         = kudosFormService;
            self.initController      = initController;
            self.getUpdateFunction   = getUpdateFunction;
            self.getCharacterCount   = getCharacterCount;
            self.hasEnoughCharacters = hasEnoughCharacters;
            // minCharacterLength is shown on the character count partial, as well as being used to check the number
            // of characters against, so it only needs to be changed in one place.
            self.minCharacterLength  = 250;
  
            function getCharacterCount(text) {
              if(text) {
                return text.trim().length;
              }
  
              return 0;
            }
  
            function hasEnoughCharacters(text) {
              return getCharacterCount(text) >= self.minCharacterLength;
            }
  
            // Resets the save button state to default
            function resetSaveButtonState () {
              changeSaveButtonState('init');
            }
  
            // Sets the button state to a specified state
            function changeSaveButtonState (newState) {
              self.saveButtonState = newState;
  
              // If the state is success, this resets it after 3 seconds
              if (newState === 'success') {
                $timeout(resetSaveButtonState, 3000);
              }
            }
  
            // Descide whether to create, update, or delete an author perspective.
            function delegatePerspectiveRequest (fieldName, newValue) {
              var currentUserId   = SessionService.currentUser().id;
              var publicationDOI  = self.publication.id;
  
              // Build a payload for create/update functions.
              var payload = buildPerspectivePayload(currentUserId, publicationDOI, fieldName, newValue);
  
              // If perspective has no ID, then its new and needs to be created.
              if (_.isUndefined(self.perspective.id)) {
                return perspectiveCreateRequest(payload);
              }
  
              // If perspective text is blank, then it should be deleted.
              if (_.trim(newValue) === '') {
                return perspectiveDeleteRequest(self.perspective.id);
              }
  
              // At this point, assume that it is present and will be updated.
              return perspectiveUpdateRequest(payload);
            }
  
            // Create a payload for create/update author perspective request
            function buildPerspectivePayload (currentUserId, publicationDOI, fieldName, newValue) {
              var basePayload = {};
  
              basePayload.account_id      = currentUserId;
              basePayload.publication_doi = publicationDOI;
  
              basePayload[fieldName]      = newValue;
  
              return _.merge(self.perspective, basePayload);
            }
  
            // Update author perspective with provided payload
            function perspectiveUpdateRequest (payload) {
              return AuthorPerspectiveService.update(payload).then(updatePerspectiveFromResponse);
            }
  
            // Create author perspective with provided payload
            function perspectiveCreateRequest (payload) {
              return AuthorPerspectiveService.create(payload).then(updatePerspectiveFromResponse);
            }
  
            // Delete author perspective with perspective id
            function perspectiveDeleteRequest (perspectiveId) {
              return AuthorPerspectiveService.delete(perspectiveId).then(removePerspective);
            }
  
            // From create/update request, update locally stored author perspective
            function updatePerspectiveFromResponse (response) {
              self.perspective = response.data.author_perspective;
            }
  
            // From delete request, delete locally stored author perspective
            function removePerspective () {
              self.perspective = {};
            }
  
            // Updates the publication with the provided field and value
            function publicationUpdate (fieldName, newValue) {
              return PublicationService.updatePublicationField(self.publication, fieldName, newValue);
            }
  
            // Delegate between making a publication request or
            // an author perspective request.
            function getUpdateFunction (fieldName, newValue) {
              if (fieldName !== 'perspective') {
                return publicationUpdate(fieldName, newValue);
              }
  
              return delegatePerspectiveRequest(fieldName, newValue);
            }
  
            // Create a form model comprising of both publication and perspective
            // for the form service to be modelled around.
            function buildFormServiceModel (publication, perspective) {
              return _.merge({}, publication, perspective);
            }
  
            // Build form service config object
            function buildFormServiceConfig (publication, perspective) {
              return {
                model:                buildFormServiceModel(publication, perspective),
                updateModelFunction:  getUpdateFunction
              };
            }
  
            // Boot explain form controller
            function initController (publication, perspective) {
              var formServiceConfig = buildFormServiceConfig(publication, perspective);
  
              self.publication      = publication;
              self.perspective      = perspective;
  
              self.formService.init(formServiceConfig);
            }
          }
        ]
      );
  })(window.angular, window._);
  
  ; (function (angular) {
    'use strict';
  
    angular.module('kudosPublications')
      .controller(
        'PublicationAuthorPageController',
        [
          '$rootScope',
          '$q',
          'PublicationService',
          'kudosFormService',
          function ($rootScope, $q, PublicationService, kudosFormService) {
            var self = this;
  
            $rootScope.$on('publicationResourcesUpdated', updateModels);
            $rootScope.$on(kudosFormService.FormSaveStateEventName, function (event, data) {
              if (data === 'success' && self.updating === false) {
                updateModels();
              }
            });
  
            self.initController         = initController;
            self.updateModels           = updateModels;
            self.getModel               = getModel;
            self.updating               = false;
            self.flashRecommendedAction = flashRecommendedAction;
  
            self.modelStore = {};
  
            function initController (models) {
              self.modelStore = models;
            }
  
            function getModel (modelName) {
              return self.modelStore[modelName];
            }
  
            function updateModels () {
              self.updating = true;
  
              updatePublication();
            }
  
            function updatePublication () {
              return PublicationService.getPublication(self.modelStore.publication.id)
                .then(
                  function (response) {
                    self.modelStore.publication = response.data.data.article;
                    self.updating = false;
                  },
                  function () {
                    self.updating = false;
                  }
                );
            }
  
            function flashRecommendedAction() {
              return PublicationService.flashRecommendedAction;
            }
          }
        ]
      );
  })(window.angular);
  
  ; (function (angular, _) {
    'use strict';
  
    angular.module('kudosPublications')
      .directive('publicationResources', function () {
        return {
          scope: {},
          bindToController: {
            doi: '@',
            resources: '=?',
            featureGuardAttrs: '=?'
          },
          templateUrl: 'kudosPublications/publicationResources.directive.html',
          controllerAs: 'vm',
          controller: [
            '$rootScope',
            '$timeout',
            'ModalService',
            'NotificationService',
            'ResourceService',
            function ($rootScope, $timeout, ModalService, NotificationService, ResourceService) {
              var self = this;
  
              self.state = {};
              self.state.listing = true;
              self.state.model = {};
              self.state.progressButtonState = 'init';
  
              self.gotoListResourcesState = gotoListResourcesState;
              self.gotoAddResourceState = gotoAddResourceState;
              self.gotoEditResourceState = gotoEditResourceState;
              self.deleteResource = deleteResource;
              self.saveResource = saveResource;
              self.isResourceFormInvalid = isResourceFormInvalid;
              self.scrollToResourcePanel = scrollToResourcePanel;
  
              function isResourceFormInvalid () {
                if (angular.isUndefined(self.state.formInstance)) {
                  return true;
                }
  
                return self.state.formInstance.$invalid;
              }
  
              function deleteResource (resource) {
                confirmResourceDeletion(
                  resource,
                  function () {
                    ResourceService
                      .delete(resource.id)
                      .then(handleDeleteSuccess(resource))
                      .then(broadcastResourceUpdateEvent)
                      .catch(handleDeleteCatch);
                  }
                );
              }
  
              function gotoListResourcesState () {
                self.state.listing = true;
                self.state.model = {};
              }
  
              function gotoAddResourceState () {
                gotoEditResourceState({});
              }
  
              function gotoEditResourceState (resource) {
                self.state.listing = false;
                self.state.model = resource;
  
                self.scrollToResourcePanel();
              }
  
              function scrollToResourcePanel() {
                var element = angular.element('#action-resource');
  
                var pixelOffset = -60;
                var body = angular.element('body,html');
  
                body.animate({
                  scrollTop: element.offset().top + pixelOffset
                }, 300);
              }
  
              function saveResource () {
                self.state.progressButtonState = 'waiting';
  
                var resourceModel = self.state.model;
  
                if (angular.isDefined(resourceModel.id)) {
                  return updateResource(resourceModel);
                }
  
                return createResource(resourceModel);
              }
  
              function createResource (newResource) {
                newResource.publication_doi = self.doi;
  
                ResourceService
                  .create(newResource)
                  .then(handleSaveSuccess)
                  .then(broadcastResourceUpdateEvent)
                  .catch(handleSaveCatch);
              }
  
              function updateResource (resource) {
                ResourceService
                  .update(resource)
                  .then(handleSaveSuccess)
                  .then(broadcastResourceUpdateEvent)
                  .catch(handleSaveCatch);
              }
  
              function handleSaveSuccess (resource) {
                self.state.progressButtonState = 'success';
  
                // Add or update resource in self.resources
                addToOrUpdateLocalResources(resource);
  
                $timeout(
                  function () {
                    self.state.progressButtonState = 'init';
                    gotoListResourcesState();
                  },
                  2000
                );
              }
  
              function addToOrUpdateLocalResources (resource) {
                // If saved resource is new (no id), add it to the local list
                if (angular.isUndefined(self.state.model.id)) {
                  self.resources.push(resource);
                } else {
                  var updatedResourceIndex = _.findIndex(self.resources, { id: resource.id });
  
                  self.resources[updatedResourceIndex] = resource;
                }
              }
  
              function handleSaveCatch () {
                self.state.progressButtonState = 'error';
  
                NotificationService.error('There was an error saving the resource.');
  
                $timeout(
                  function () {
                    self.state.progressButtonState = 'init';
                  },
                  3000
                );
              }
  
              function handleDeleteSuccess (deletedResource) {
                return function () {
                  self.resources = _.remove(self.resources, function (resource) {
                    return resource !== deletedResource;
                  });
                };
              }
  
              function handleDeleteCatch () {
                NotificationService.error('There was an error removing the resource.');
              }
  
              function broadcastResourceUpdateEvent (resources) {
                $rootScope.$broadcast('publicationResourcesUpdated', resources);
              }
  
              function confirmResourceDeletion (resource, onConfirmCallback) {
  
                var deleteConfirmationModalOptions = {
                  okButton: {
                    text: 'Delete',
                    isDismissed: false,
                    classes: 'btn-danger'
                  },
                  cancelButton: {
                    text: 'Cancel',
                    isDismissed: true
                  },
                  title: 'Delete resource.',
                  content: 'Are you sure you want to remove the publication resource titled: ' + resource.title + '?',
                  subContent: ''
                };
  
                ModalService
                  .openConfirmationModal(deleteConfirmationModalOptions)
                  .result
                  .then(onConfirmCallback);
              }
            }
          ]
        };
      });
  }(window.angular, window._));
  
  ; (function (angular) {
    'use strict';
  
    angular.module('kudosPublications')
      .directive(
        'publicationResourcesList',
        [
          function () {
            return {
              scope: {
                resources: '=?',
              },
              templateUrl: 'kudosPublications/publicationResourcesList.directive.html',
              require: '^^publicationResources',
              link: function (scope, element, attrs, publicationResourcesController) {
                scope.gotoEditResourceState = publicationResourcesController.gotoEditResourceState;
                scope.deleteResource = publicationResourcesController.deleteResource;
              }
            };
          }
        ]);
  
  }(window.angular));
  
  ; (function (angular) {
    'use strict';
  
    angular.module('kudosPublications')
      .directive(
        'publicationResourcesEdit',
        [
          'ResourceService',
          function (ResourceService) {
            return {
              scope: {
                resources: '=?',
              },
              templateUrl: 'kudosPublications/publicationResourcesEdit.directive.html',
              require: '^^publicationResources',
              link: function (scope, element, attrs, publicationResourcesController) {
                scope.resourceTypes = [];
  
                publicationResourcesController.state.formInstance = scope.formInstance;
  
                scope.gotoListResourcesState = publicationResourcesController.gotoListResourcesState;
                scope.model = publicationResourcesController.state.model;
                scope.resourceTypesStillLoading = resourceTypesStillLoading;
  
                init();
  
                function init () {
                  obtainResourceTypes();
                }
  
                function resourceTypesStillLoading () {
                  return !scope.resourceTypes.length;
                }
  
                function obtainResourceTypes () {
                  ResourceService.getResourceTypes().then(function (resourceTypes) {
                    scope.resourceTypes = resourceTypes;
                  });
                }
  
              }
            };
          }
        ]);
  
  }(window.angular));
  
  ; (function (angular) {
    'use strict';
  
    angular.module('kudosPublications')
      .directive(
        'publicationResourcesAdd',
          function () {
            return {
              scope: {
                featureGuardAttrs: '=?',
              },
              controllerAs: 'vm',
              templateUrl: 'kudosPublications/publicationResourcesAdd.directive.html',
              require: '^^publicationResources',
              link: function (scope, element, attrs, publicationResourcesController) {
                scope.gotoAddResourceState = publicationResourcesController.gotoAddResourceState;
              },
              controller: function($scope) {
                var self = this;
  
                self.userIsAdmin = $scope.featureGuardAttrs.user_is_admin;
                self.userHasPremium = $scope.featureGuardAttrs.user_has_premium;
                self.publisherName = $scope.featureGuardAttrs.publisher_name;
  
                self.addResourcesIsEnabled = addResourcesIsEnabledForUser();
  
                function addResourcesIsEnabledForUser() {
                  if (self.userIsAdmin || self.userHasPremium || self.publisherName) {
                    return true;
                  }
  
                  return false;
                }
              }
            };
          });
  
  }(window.angular));
  
  ; (function (angular, _) {
    'use strict';
  
    angular.module('kudosPublications')
      .directive('kudosAdminRefreshPublication', function () {
        return {
          scope: {},
          bindToController: {
            doi: '@',
            onReloadSuccess: '=?'
          },
          templateUrl: 'kudosPublications/kudosAdminRefreshPublication.directive.html',
          controllerAs: 'vm',
          controller: directiveControllerDependencies()
        };
      });
  
    function directiveControllerDependencies () {
      return [
        '$q',
        'PublicationService',
        directiveController
      ];
    }
  
    function directiveController ($q, PublicationService) {
      var self = this;
  
      self.refreshMetadata = refreshMetadata;
      self.buttonState = 'init';
      self.buttonStates = getButtonStates();
  
      function refreshMetadata () {
        self.buttonState = 'waiting';
  
        PublicationService
          .refreshMetadata(buildRefreshMetdadataPayload())
          .then(onReloadSuccessCallback)
          .catch(onReloadFailCallback);
      }
  
      function buildRefreshMetdadataPayload () {
        return {
          id: self.doi
        };
      }
  
      function onReloadFailCallback () {
        self.buttonState = 'error';
      }
  
      function onReloadSuccessCallback (response) {
        self.buttonState = 'success';
  
        if (_.isFunction(self.onReloadSuccess)) {
          return self.onReloadSuccess(response);
        }
      }
  
      function getButtonStates () {
        return {
          init: {
            name: 'init',
            buttonText: 'Synchronise with Crossref',
            buttonClass: 'btn-default'
          },
          waiting: {
            buttonText: 'Synchronising...'
          },
          success: {
            buttonText: 'Synchronised successfully'
          },
          error: {
            buttonText: 'Synchronisation failed'
          }
        };
      }
    }
  }(window.angular, window._));
  
  ; (function (angular, location) {
  
    "use strict";
  
    angular
      .module("kudosPublications")
      .directive("kudosPublicationClaimButton", function() {
        var directive = {
          restrict: "E",
          templateUrl: "kudosPublications/directives/kudosPublicationClaimButton/kudosPublicationClaimButton.html",
          scope: {},
          bindToController: {
            doi: "@",
            claimButtonClass: "@",
            claimText: "@"
          },
          controller: [
            'PublicationService',
            function (PublicationService) {
              var vm = this;
  
              vm.buttonPressed  = buttonPressed;
              vm.$onInit = init;
      
              function init() {
                vm.buttonText = valueOrDefault(vm.claimText, "Claim this publication");
                vm.buttonClasses = valueOrDefault(vm.claimButtonClass, "btn orange-background btn-block");
                vm.buttonDisabled = false;
              }
  
              function buttonPressed() {
                vm.buttonDisabled = true;
  
                PublicationService.claim(vm.doi)
                  .then(onClaimSuccess)
                  .finally(
                    function() {
                      vm.buttonDisabled = false;
                    }
                  );
              }
  
              function onClaimSuccess () {
                location.href = '/publications/' + PublicationService.encodeDOI(vm.doi);
              }
            }
          ],
          controllerAs: "vm"
        };
  
        return directive;
      });
  
    /**
     * Helper function to simplify the setting of default values.
     * @param  value        A (possibly undefined) value
     * @param  defaultValue The value to return if `value` isn't present
     * @return              `value` if it is defined, else `defaultValue`
     */
    function valueOrDefault (value, defaultValue) {
      return (
        angular.isUndefined(value) ?
          defaultValue :
          value
      );
    }
  
  }(window.angular, window.location));
  
  ; (function (angular, location) {
  
    "use strict";
  
    angular
      .module("kudosPublications")
      .directive("kudosPublicationUnclaimButton", function() {
        var directive = {
          restrict: "E",
          templateUrl: "kudosPublications/directives/kudosPublicationUnclaimButton/kudosPublicationUnclaimButton.html",
          scope: {},
          bindToController: {
            doi: "@",
            unclaimButtonClass: "@",
            unclaimText: "@",
            reloadPage: '=?'
          },
          controllerAs: "vm",
          controller: [
            'PublicationService',
            'SessionService',
            function (PublicationService, SessionService) {
              var vm = this;
              vm.buttonPressed = buttonPressed;
              vm.$onInit = init;
  
              function init() {
                vm.buttonText = valueOrDefault(vm.unclaimText, "Unclaim this publication");
                vm.buttonClasses = valueOrDefault(vm.unclaimButtonClass, "btn btn-danger btn-block");
                vm.buttonDisabled = false;
              }
  
              function buttonPressed() {
                vm.buttonDisabled = true;
  
                PublicationService.unclaim(vm.doi)
                  .then(onUnclaimSuccess)
                  .finally(
                    function() {
                      vm.buttonDisabled = false;
                    }
                  );
              }
  
              function onUnclaimSuccess () {
                if (!!vm.reloadPage) {
                  location.reload();
                } else if (/\/author/.test(location.pathname)) {
                  // ux3 feature
                  location.href = location.pathname.replace('/author', '?ux3=')
                } else {
                  // reload the session so we pick up the new publication
                  SessionService.getSession();
                }
              }
            }
          ]
        };
  
        return directive;
      });
  
    /**
     * Helper function to simplify the setting of default values.
     * @param  value        A (possibly undefined) value
     * @param  defaultValue The value to return if `value` isn't present
     * @return              `value` if it is defined, else `defaultValue`
     */
    function valueOrDefault (value, defaultValue) {
      return (
        angular.isUndefined(value) ?
          defaultValue :
          value
      );
    }
  
  }(window.angular, window.location));
  
  /**
   * Claim/Unclaim button for a publication.
   *
   * This is a lightweight directive which only requires a DOI, not a full publication object.
   *
   * This directive has the following dependencies:
   *
   *   1. `SessionService.currentUser`, which it expects to return a user with an `articles` attribute.
   *        `articles` is an array of objects which must each have an `id` attribute which is the
   *        un-prefixed DOI (e.g. "10.5555/2014-04-01").
   *   2. `SessionService.getSession` to trigger the update of the user's list of owned publications.
   *   3. `PublicationService.claim`, which is responsible for the confirmation of the claim.
   *   4. `PublicationService.unclaim`, which is responsible for the confirmation of the unclaim.
   *
   */
  ; (function (angular) {
  
    "use strict";
  
    angular
      .module("kudosPublications")
      .directive("kudosPublicationClaimUnclaimButton", function() {
        var directive = {
          restrict: "E",
          replace: true,
          templateUrl: "kudosPublications/directives/kudosPublicationClaimUnclaimButton/kudosPublicationClaimUnclaimButton.html",
          scope: {},
          bindToController: {
            doi: "@",
            claimButtonClass: "@",
            unclaimButtonClass: "@",
            claimText: "@",
            unclaimText: "@"
          },
          controller: kudosPublicationClaimButtonController,
          controllerAs: "vm"
        };
  
        return directive;
      });
  
    kudosPublicationClaimButtonController.$inject = ["PublicationService", "SessionService"];
    function kudosPublicationClaimButtonController(PublicationService, SessionService) {
  
      var vm = this;
      vm.$onInit = init;
      vm.publicationIsClaimed = publicationIsClaimed;
  
      function init() {
        vm.claimButtonClass = valueOrDefault(vm.claimButtonClass, "btn orange-background btn-block");
        vm.unclaimButtonClass = valueOrDefault(vm.unclaimButtonClass, "btn btn-danger btn-block");
  
        vm.claimText = valueOrDefault(vm.claimText, "Claim this publication");
        vm.unclaimText = valueOrDefault(vm.unclaimText, "Unclaim this publication");
      }
  
      function publicationIsClaimed () {
        return SessionService.userIsAuthorFor(vm.doi);
      }
    }
  
    /**
     * Helper function to simplify the setting of default values.
     * @param  value        A (possibly undefined) value
     * @param  defaultValue The value to return if `value` isn't present
     * @return              `value` if it is defined, else `defaultValue`
     */
    function valueOrDefault (value, defaultValue) {
      return (
        angular.isUndefined(value) ?
          defaultValue :
          value
      );
    }
  
  }(window.angular));
  
  ; (function (angular) {
    'use strict';
  
    angular.module('kudosPublications')
      .directive(
        'kudosPublicationRecommendedAction', function() {
          return {
            scope: {
              action: '=',
              mailto: '='
            },
            link: function (scope) {
              scope.getRecommendedActionTitleText = getRecommendedActionTitleText;
              scope.getRecommendedActionText = getRecommendedActionText;
              scope.getRecommendedActionButtonText = getRecommendedActionButtonText;
              scope.chooseAction = chooseAction;
  
              function getRecommendedActionTitleText () {
                if (angular.isArray(scope.action)) {
                  return scope.action[1];
                }
              }
  
              function getRecommendedActionText () {
                if (angular.isArray(scope.action)) {
                  return scope.action[0];
                }
              }
  
              function getRecommendedActionButtonText () {
                if (angular.isArray(scope.action)) {
                  return scope.action[2];
                }
              }
  
              function getScrollElementId () {
                if (angular.isArray(scope.action)) {
                  return scope.action[3];
                }
  
                return;
              }
  
              function chooseAction () {
                if(scope.action[3] === 'action-invite-coauthors') {
                  sendEmail();
                } else {
                  scrollToAction();
                }
              }
  
              function sendEmail () {
                location.href = "mailto:?" + scope.mailto;
              }
  
              function scrollToAction () {
                // Append to hash for template ID
                var scrollTargetId = getScrollElementId();
  
                if (angular.isUndefined(scrollTargetId)) {
                  return;
                }
  
                var scrollOffset = -60;
                var scrollTarget = angular.element('#' + scrollTargetId);
  
                scrollTo(scrollTarget, scrollOffset);
              }
  
              function scrollTo (element, offset) {
                var body = angular.element('body,html');
                body.animate({
                  scrollTop: element.offset().top + offset
                }, 300);
              }
            },
            templateUrl: 'kudosPublications/directives/kudosPublicationRecommendedAction/kudosPublicationRecommendedAction.directive.html',
          };
        }
      );
  
  }(window.angular));
  
  ; (function (angular, _) {
    'use strict';
  
    angular.module('kudosPublications')
      .component(      
        'geographicRegionsPanel', {
          bindings: {
            region: '=',
            publication: '<',
          },
          templateUrl: "kudosPublications/components/geographicRegionsPanel/geographicRegionsPanel.html",
          controllerAs: 'vm',
          require: ['$timeout', 'PublicationService', 'RegionComponentChangedService'],
          controller: ["$timeout", "PublicationService", "RegionComponentChangedService", function ($timeout, PublicationService, RegionComponentChangedService) {
            var self = this;
            self.selectAllCheckboxes = selectAllCheckboxes;
            self.checkboxToggle = checkboxToggle;
            self.submit = submit;
            self.getAllChanges = getAllChanges;
            self.resetSaveButton = resetSaveButton;
            self.hasChanged = hasChanged;
  
            this.$onInit = function() {
              this.regionButtonState = 'init';
              
              this.regionsObject ={
                Africa: false,
                Antarctica: false,
                Asia: false,
                Australasia: false,
                Europe: false,
                'North America': false,
                'South America': false
              };
  
              this.continentColour={
                true: {fill: '#1b5199'},
                false: {fill: '#c4d0d3'}
              };
  
              for(var i = 0; i < this.region.length; i++) {
                this.regionsObject[this.region[i]] = true;
              }
            };
  
            function selectAllCheckboxes() {
              for(var region in this.regionsObject) {
                this.regionsObject[region] = true;
              }
              self.hasChanged();
            }
  
            function checkboxToggle(key) {
              this.regionsObject[key] = !this.regionsObject[key];
              self.hasChanged();
            }
  
            function submit() {
              var changes = self.getAllChanges();
              if(_.isEqual(changes, ['noChanges'])) {
                self.resetSaveButton();
                return;
              }
              self.regionButtonState = 'waiting';
              PublicationService.updatePublicationField(self.publication, 'regions', changes)
              .then(function () {
                self.resetSaveButton();
              })
              .catch(function () {
                self.regionButtonState = 'error';
              });
            }
  
            function resetSaveButton() {
              self.regionButtonState = 'success';
              RegionComponentChangedService.setUnchanged();
              $timeout(function() {
                self.regionButtonState = 'init';
              }, 3000);
            }
  
            function hasChanged() {
              var newRegions = [];
              for(var region in this.regionsObject) {
                if(this.regionsObject[region]) {
                  newRegions.push(region);
                }
              }
              if(!_.isEqual(newRegions, self.region)) {
                RegionComponentChangedService.setChanged();
                return;
              }
              RegionComponentChangedService.setUnchanged();
            }
             
            function getAllChanges() {
              var newRegions = [];
              for(var region in this.regionsObject) {
                if(this.regionsObject[region]) {
                  newRegions.push(region);
                }
              }
              if(!_.isEqual(newRegions, self.region)) {
                self.region = newRegions;
                return newRegions;
              }
              return ['noChanges'];
            }
          }]
        }
      );
  }(window.angular, window._));
  
  (function (angular) {
    'use strict';
  
    angular.module('kudosPublications').component('featuredImagePanel', {
      templateUrl:
        'kudosPublications/components/featuredImagePanel/featuredImagePanel.html',
      bindings: {
        articleId: '@',
        imageUrl: '@',
        imageCredits: '<',
      },
      controllerAs: 'vm',
      controller: [
        'NotificationService',
        'UnsplashService',
        '$q',
        '$http',
        '$rootScope',
        function (NotificationService, UnsplashService, $q, $http, $rootScope) {
          var self = this;
          self.progressButton = {};
  
          // Setup state.
          self.$onInit = function () {
            self.reset();
  
            $rootScope.$broadcast('FeaturedImagePanelOnInitEvent', {
              article_id: self.articleId,
              image_url: self.imageUrl,
            });
          };
  
          var NO_MORE_PAGES = 'no more pages for query';
          var GENERAL_ERROR =
            'We’ve hit a problem. Please try again or contact support if the problem persists.';
  
          self.hasImage = function () {
            if (self.imageUrl === '') {
              return false;
            }
            return true;
          };
  
          self.getImageUrl = function () {
            if (self.hasImage()) {
              return self.imageUrl;
            }
            return '/fontawesome-icons/regular/file-image.svg';
          };
  
          self.reset = function () {
            self.progressButton.state = 'init';
            self.hideSelectPhotoPanel();
            self.page = 1;
            self.selected = null;
            self._previousQuery = null;
            self.photos = null;
          };
  
          self.setSelected = function (photo) {
            self.selected = photo;
          };
  
          self.showSelectPhotoPanel = function () {
            self.showPhotos = true;
          };
  
          self.hideSelectPhotoPanel = function () {
            self.showPhotos = false;
          };
  
          self.isPreviousDisabled = function () {
            return self._loading || self.page <= 1;
          };
  
          var nextDisabled = true;
          self.isNextDisabled = function () {
            return self._loading || nextDisabled;
          };
  
          self.previousPage = function (query) {
            if (!self.isPreviousDisabled()) {
              self.page--;
              self._search(query);
            }
          };
  
          self.nextPage = function (query) {
            self.page++;
            self._search(query);
          };
  
          var queryTotalHits = {};
          self.getPhotos = function (query) {
            nextDisabled = false;
            if (
              angular.isDefined(queryTotalHits[query]) &&
              self.page > queryTotalHits[query]
            ) {
              return $q.reject(NO_MORE_PAGES);
            }
  
            var options = {
              orientation: UnsplashService.OPTIONS.ORIENTATION.LANDSCAPE_ONLY,
            };
            return UnsplashService.getPhotos(query, options, self.page).then(
              function (urlsAndPages) {
                if (self.page >= urlsAndPages.totalPages) {
                  nextDisabled = true;
                }
  
                queryTotalHits = {};
                queryTotalHits[query] = urlsAndPages.totalPages;
                return urlsAndPages.urls;
              }
            );
          };
  
          self.isSearchDisabled = function (query) {
            return self._loading || !query || query === self._previousQuery;
          };
  
          self.search = function (query) {
            if (self.isSearchDisabled(query)) {
              return;
            }
            self._previousQuery = query;
            self.page = 1;
  
            self._search(query);
          };
  
          self._search = function (query) {
            self._loading = true;
  
            // TODO: should only show after receiving photos from getPhotos()? but would need a loading indicator
            self.showSelectPhotoPanel();
  
            return self
              .getPhotos(query)
              .then(function (photos) {
                self._loading = false;
  
                self.photos = photos;
              })
              .catch(function (rejection) {
                self._loading = false;
  
                NotificationService.error(GENERAL_ERROR);
  
                if (rejection === NO_MORE_PAGES) {
                  self.photos = []; // empty array shows message
                  return;
                }
                return $q.reject(rejection);
              });
          };
  
          self.deletePhoto = function () {
            return $http
              .delete(
                '/internal_api/publication_featured_image/' + self.articleId
              )
              .then(function () {
                self.imageUrl = '';
  
                $rootScope.$broadcast('FeaturedImageDeleteEvent', {
                  article_id: self.articleId,
                  image_url: self.imageUrl,
                });
              })
              .catch(function (responseOrError) {
                // TODO: log the responseOrError for monitoring
                NotificationService.error(GENERAL_ERROR);
                if (responseOrError instanceof Error) {
                  throw responseOrError;
                }
              });
          };
  
          self.savePhoto = function () {
            self._loading = true;
  
            var photo = self.selected;
            self.progressButton.state = 'waiting';
  
            var body = {
              image_url: photo.url,
              preview_image_url: photo.preview,
              credit_link: photo.creditUserLink,
              credit_user: photo.creditUserName,
              download_location: photo.downloadLocation,
            };
  
            return $http
              .post(
                '/internal_api/publication_featured_image/save/' + self.articleId,
                body
              )
              .then(function (response) {
                self._loading = false;
  
                var savedPhoto = response.data.data;
                self.imageUrl = savedPhoto.saved_preview_image_url;
                self.reset();
  
                $rootScope.$broadcast('FeaturedImageUpdateEvent', {
                  article_id: self.articleId,
                  image_url: photo.url,
                });
              })
              .catch(function (responseOrError) {
                self._loading = false;
  
                // TODO: log the responseOrError for monitoring
                self.progressButton.state = 'error';
                NotificationService.error(GENERAL_ERROR);
                if (responseOrError instanceof Error) {
                  throw responseOrError;
                }
              });
          };
        },
      ],
    });
  })(window.angular, window._);
  
  (function (angular) {
    'use strict';
  
    angular
      .module('kudosPublications')
      .component('infographicPanel', {
        templateUrl:
          'kudosPublications/components/infographicPanel/infographicPanel.html',
        bindings: {
          thumbnailUrl: '@',
          featureGuardAttrs: '<',
          doi: '@',
        },
        controllerAs: 'vm',
        controller: [
          '$scope',
          '$timeout',
          '$window',
          'InfographicService',
          'NotificationService',
          function (
            $scope,
            $timeout,
            $window,
            InfographicService,
            NotificationService
          ) {
            var self = this;
            self.infographicFile = null;
            self.currentState = 'init';
            self.requestInProgress = false;
  
            self.$onInit = function () {
              self.userIsAdmin = self.featureGuardAttrs.user_is_admin;
              self.userHasPremium = self.featureGuardAttrs.user_has_premium;
              self.publisherName = self.featureGuardAttrs.publisher_name;
            };
  
            self.addInfographicEnabledForUser = function () {
              if (self.userIsAdmin || self.userHasPremium || self.publisherName) {
                return true;
              }
              return false;
            };
  
            self.hasInfographic = function () {
              if (self.thumbnailUrl === '') {
                return false;
              }
              return true;
            };
  
            self.getInfographicUrl = function () {
              if (self.hasInfographic()) {
                return self.thumbnailUrl;
              }
              return '/fontawesome-icons/regular/file-image.svg';
            };
  
            self.getAltText = function () {
              if (self.hasInfographic()) {
                return 'Preview of article infographic';
              } else {
                return 'No article infographic';
              }
            };
  
            $scope.$on('fileSelected', uploadInfographic);
  
            function uploadInfographic() {
              if (self.requestInProgress) {
                return;
              }
  
              self.requestInProgress = true;
              changeButtonState('waiting');
  
              InfographicService.update(self.doi, self.infographicFile)
                .then(function (data) {
                  self.thumbnailUrl = data.thumbnail;
                  changeButtonState('success');
                })
                .catch(function (error) {
                  if (error.message.toLowerCase() === 'unauthenticated') {
                    $window.location.href = '/hub';
                  }
                  changeButtonState('error');
                  NotificationService.error(error.message);
                })
                .finally(function () {
                  self.requestInProgress = false;
                  $timeout(function () {
                    changeButtonState('init');
                  }, 3000);
                });
            }
  
            self.removeInfographic = function () {
              if (self.requestInProgress) {
                return;
              }
  
              self.requestInProgress = true;
  
              InfographicService.delete(self.doi)
                .then(function () {
                  self.thumbnailUrl = '';
                })
                .catch(function (error) {
                  NotificationService.error(error.message);
                })
                .finally(function () {
                  self.requestInProgress = false;
                });
            };
  
            function changeButtonState(state) {
              self.currentState = state;
            }
  
            self.getButtonText = getButtonText;
            self.getButtonClass = getButtonClass;
            self.getButtonIconClass = getButtonIconClass;
  
            function getButtonText() {
              return buttonStates[self.currentState].buttonText;
            }
  
            function getButtonIconClass() {
              return buttonStates[self.currentState].buttonIconClass;
            }
  
            function getButtonClass() {
              return buttonStates[self.currentState].buttonClass;
            }
  
            var buttonStates = {
              init: {
                name: 'init',
                buttonText: 'Upload',
                buttonClass: 'init',
              },
              waiting: {
                name: 'waiting',
                buttonText: 'Uploading',
                buttonIconClass: 'fa-circle-o-notch fa-spin',
                buttonClass: 'waiting',
              },
              success: {
                name: 'success',
                buttonText: 'Uploaded',
                buttonIconClass: 'fa-thumbs-o-up throb-in slow',
                buttonClass: 'success',
              },
              error: {
                name: 'error',
                buttonText: 'Error',
                buttonIconClass: 'fa-exclamation-circle throb-in slow',
                buttonClass: 'error',
              },
            };
          },
        ],
      })
      .directive('infographicFile', function () {
        return {
          require: 'ngModel',
          link: function ($scope, elem, attrs, ngModel) {
            elem.on('change', function (e) {
              var file = e.target.files[0];
              // Catch drag and drop where file type is not accepted
              if (file.size > 0) {
                ngModel.$setViewValue(file);
                $scope.$emit('fileSelected');
              }
              e.target.value = null;
            });
          },
        };
      });
  })(window.angular, window._);
  
  ; (function (angular, _) {
    'use strict';
  
    angular.module('kudosPublications')
      .component(
        'showcaseYourWorkPanel', {
          bindings: {
            publication: '<',
            featuredImageUrl: '@'
          },
          templateUrl: "kudosPublications/components/showcaseYourWorkPanel/showcaseYourWorkPanel.html",
          controllerAs: 'vm',
          require: ['PublicationService'],
          controller: ["PublicationService", '$timeout', '$rootScope',
            function (PublicationService, $timeout, $rootScope) {
              var self = this;
              self.isComplete = isComplete;
              self.isWhatsItAboutEmpty = isWhatsItAboutEmpty;
              self.isWhatsItAboutComplete = isWhatsItAboutComplete;
              self.isFeaturedImageComplete = isFeaturedImageComplete;
              self.flashRecommendedAction = flashRecommendedAction;
              self.scrollToAction = scrollToAction;
  
              function isComplete() {
                return (self.isWhatsItAboutComplete() && self.isFeaturedImageComplete());
              }
  
              function isWhatsItAboutEmpty() {
                return (!self.publication.lay_summary || self.publication.lay_summary.trim().length === 0);
              }
  
              function isWhatsItAboutComplete() {
                return (self.publication.lay_summary && self.publication.lay_summary.trim().length >= 250);
              }
  
              function isFeaturedImageComplete() {
                return !!self.featuredImageUrl;
              }
  
              var imageEventHandler = function (evt, data) {
                self.featuredImageUrl = data.image_url;
              };
  
              $rootScope.$on('FeaturedImageDeleteEvent', imageEventHandler);
              $rootScope.$on('FeaturedImageUpdateEvent', imageEventHandler);
              $rootScope.$on('FeaturedImagePanelOnInitEvent', imageEventHandler);
  
              function flashRecommendedAction() {
                PublicationService.flashRecommendedAction = true;
  
                // If we didn't set this to false the flash class would remain on the
                // recommended action element, making subsequent clicks unable to flash.
                $timeout(function() {
                  PublicationService.flashRecommendedAction = false;
                }, 5000);
              }
  
              function scrollToAction(scrollTargetId) {
                if (angular.isUndefined(scrollTargetId)) {
                  return;
                }
  
                var scrollOffset = -60;
                var scrollTarget = angular.element('#' + scrollTargetId);
  
                scrollTo(scrollTarget, scrollOffset);
              }
  
              function scrollTo (element, offset) {
                var prefersReducedMotion = window.matchMedia('(prefers-reduced-motion)').matches;
                var body = angular.element('body,html');
                var newScrollValue = element.offset().top + offset;
  
                if (prefersReducedMotion) {
                  body.scrollTop(newScrollValue);
                } else {
                  body.animate({scrollTop: newScrollValue}, 300);
                }
              }
          }]
        }
      );
  }(window.angular, window._));
  
  (function (angular) {
    'use strict';
  
    angular.module('unsplash', []);
    (function (angular) {
      'use strict';
    
      angular.module('unsplash').service('UnsplashService', [
        '$http',
        function (
          $http
        ) {
          var self = this;
    
          var BASE = '/internal_api/publication_featured_image/search_unsplash';
    
          self.OPTIONS = {
            ORIENTATION: {
              LANDSCAPE_ONLY: 'landscape',
              PORTRAIT_ONLY: 'portrait',
              ANY: null,
              SQUARE: 'squarish'
            }
          };
    
          self.getPhotos = function (query, options, page) {
            var params = {};
            if (options.orientation) {
              params.orientation = options.orientation;
            }
    
            params.page = page || 1;
    
            return self.get(query, params)
              .then(function (response) {
                var data = response.data.data;
                var items = data.hits;
                var totalPages = data.total_pages;
    
                var urls = [];
                angular.forEach(items, function (item, i) {
                  urls[i] = {
                    url: item.urls.regular,
                    preview: item.urls.thumb,
                    creditUserLink: item.user.links.html,
                    creditUserName: item.user.name,
                    downloadLocation: item.links.download_location
                  };
                });
    
                return {urls: urls, totalPages: totalPages};
              });
          };
    
          self.get = function (query, params) {
            return $http.get(self.buildURL(query, params), {cache: true});
          };
    
          self.defaultParams = {
            per_page: 20
          };
    
          self.buildURL = function (query, params) {
            var totalParams = angular.extend({q: query}, self.defaultParams, params);
    
            var queryStrings = [];
            angular.forEach(totalParams, function (val, key) {
              queryStrings.push(key + '=' + encodeURIComponent(val));
            });
    
            return BASE + '?' + queryStrings.join('&');
          };
        }
      ]);
    
    }(window.angular));
    
  
  }(window.angular));
  

}(window.angular));

; (function (angular) {
  "use strict";

  angular.module('kudosPublishers', ['ui.router', 'restangular', 'kudosNotifications', 'templates']);
  ; (function (angular) {
    "use strict";
  
    angular.module('kudosPublishers')
      .controller(
        'PublisherController',
        [
          '$stateParams',
          '$state',
          'publisher',
          'publisherReports',
          'NotificationService',
          'PublisherService',
          'SessionService',
          function (
            $stateParams,
            $state,
            publisher,
            publisherReports,
            NotificationService,
            PublisherService,
            SessionService
          ) {
            var DEFAULT_PUBLISHER_AVATAR = '/images/avatar_publisher_default.png';
            var DEFAULT_PUBLISHER_AVATAR_THUMB = '/images/avatar_publisher_default_thumb.png';
            var DEFAULT_PUBLISHER_AVATAR_THUMB_2X = '/images/avatar_publisher_default_thumb@2x.png';
  
            var self = this;
  
            self.publisher = publisher;
            self.publisherReports = publisherReports;
  
            self.shortCode = $stateParams.shortcode;
  
            // This function mitigates the limitations of ui-sref-active with handling complex state hierarchies.
            self.isReportTabOpen = function () {
              return ($state.includes('publisher.reports') || $state.includes('publisher.reports.list'));
            };
  
            self.isAdmin = function () {
              return SessionService.userIsAdmin();
            }
  
            self.getPublisherAvatarTrimmed = function () {
              if (angular.isDefined(self.publisher.avatar) && angular.isDefined(self.publisher.avatar.avatar.trimmed)) {
                return self.publisher.avatar.avatar.trimmed.url;
              } else {
                return DEFAULT_PUBLISHER_AVATAR;
              }
            };
  
            self.getPublisherAvatarThumb = function () {
              if (angular.isDefined(self.publisher.avatar) && angular.isDefined(self.publisher.avatar.avatar.thumb)) {
                return self.publisher.avatar.avatar.thumb.url;
              } else {
                return DEFAULT_PUBLISHER_AVATAR_THUMB;
              }
            };
  
            self.getPublisherAvatarThumb2x = function () {
              if (angular.isDefined(self.publisher.avatar) && angular.isDefined(self.publisher.avatar.avatar.thumb_2x)) {
                return self.publisher.avatar.avatar.thumb_2x.url;
              } else {
                return null;
              }
            };
  
            self.getPublisherAvatarThumbSet = function () {
              if (angular.isDefined(self.publisher.avatar) && angular.isDefined(self.publisher.avatar.avatar.thumb)) {
                if (angular.isDefined(self.publisher.avatar.avatar.thumb_2x)) {
                  return self.publisher.avatar.avatar.thumb.url + " 1x, " + self.publisher.avatar.avatar.thumb_2x.url + " 2x";
                }
                else {
                  return self.publisher.avatar.avatar.thumb.url + " 1x"; //only a low-res thumbnail is available
                }
              } else {
                //no thumbnail is available
                return DEFAULT_PUBLISHER_AVATAR_THUMB + " 1x, " + DEFAULT_PUBLISHER_AVATAR_THUMB_2X + " 2x";
              }
            };
  
  
            self.avatarUploadComplete = function (fileItem, response) {
              NotificationService.success('Successfully uploaded \'' + fileItem.file.name + '\' as logo');
              setPublisher(response.data.publisher);
            };
  
            self.avatarUploadError = function (fileItem, response) {
              NotificationService.error('Logo upload \'' + fileItem.file.name + '\' failed, please try again: ' + response.errors.join('; '));
            };
  
            self.removeAvatar = function () {
              PublisherService.removePublisherAvatar(self.publisher)
                .then(
                  function (data) {
                    NotificationService.success('Successfully removed logo!');
                    setPublisher(data.publisher);
                  },
                  function () {
                    NotificationService.error('Could not remove logo!');
                  }
                );
            };
  
            function setPublisher(publisher) {
              self.publisher = publisher;
            }
          }
        ]
      );
  
  } (window.angular));
  
  ; (function (angular, _) {
    "use strict";
  
    angular.module('kudosPublishers')
      .controller(
        'PublisherDashboardController',
        [
          '$stateParams',
          '$state',
          'publisher',
          'latestTweets',
          'categoryOverview',
          'sharesByChannel',
          'recentlyExplainedPublications',
          'recentlyActiveAuthors',
          'exampleExplainedPublications',
          'favoriteReports',
          'PublisherReportService',
          'shareActivityCountQuery',
          'explainActivityCountQuery',
          'registeredAuthorActivityCountQuery',
          function (
            $stateParams,
            $state,
            publisher,
            latestTweets,
            categoryOverview,
            sharesByChannel,
            recentlyExplainedPublications,
            recentlyActiveAuthors,
            exampleExplainedPublications,
            favoriteReports,
            PublisherReportService,
            shareActivityCountQuery,
            explainActivityCountQuery,
            registeredAuthorActivityCountQuery
          ) {
  
            var self = this;
  
            self.publisher = publisher;
            self.latestTweets = latestTweets.tweets;
            self.categoryOverview = categoryOverview.rows;
            self.sharesByChannel = sharesByChannel.rows;
            self.recentlyExplainedPublications = recentlyExplainedPublications.rows;
            self.recentlyActiveAuthors = recentlyActiveAuthors.rows;
            self.exampleExplainedPublications = exampleExplainedPublications.rows;
  
            self.favoriteReports = favoriteReports;
  
            self.shareActivityCountQuery = shareActivityCountQuery;
            self.explainActivityCountQuery = explainActivityCountQuery;
            self.registeredAuthorActivityCountQuery = registeredAuthorActivityCountQuery;
  
            self.removeReportFromFavorites = function(reportName) {
              PublisherReportService.removeReportFromFavorites($stateParams.shortcode, reportName)
                .then(function () {
                  return PublisherReportService.getFavoritedReports($stateParams.shortcode);
                })
              .then(function () {
                var index = _.findIndex(self.favoriteReports, {name: reportName});
                if (index > -1) {
                  self.favoriteReports.splice(index, 1);
                }
                });
            };
  
            self.showMore = {
              recentlyExplainedPublications: {
                _minLimit: 3,
                _showMore: false,
                toggle: function () {
                  this._showMore = !this._showMore;
                },
                getLabel: function () {
                  return (this._showMore ? 'Less' : 'More') + '...';
                },
                getLimit: function () {
                  return (this._showMore ? self.recentlyExplainedPublications.length : this._minLimit);
                },
                showLabel: function () {
                  return (self.recentlyExplainedPublications.length > this._minLimit);
                }
              },
              recentlyActiveAuthors: {
                _minLimit: 5,
                _showMore: false,
                toggle: function () {
                  this._showMore = !this._showMore;
                },
                getLabel: function () {
                  return (this._showMore ? 'Less' : 'More') + '...';
                },
                getLimit: function () {
                  return (this._showMore ? self.recentlyActiveAuthors.length : this._minLimit);
                },
                showLabel: function () {
                  return (self.recentlyActiveAuthors.length > this._minLimit);
                }
              }
            };
          }
        ]
      );
  
  } (window.angular, window._));
  
  ; (function (angular, _) {
    "use strict";
  
    angular.module('kudosPublishers')
      .controller(
        'PublisherReportController',
        [ 'reportMetadata',
          'PublisherService',
          'PublisherReportService',
          'SessionService',
          'favoritedReport',
          'publisher',
          '$stateParams',
          '$state',
          '$interpolate',
          function (reportMetadata, PublisherService, PublisherReportService, SessionService, favoritedReport, publisher, $stateParams, $state, $interpolate) {
            var self = this;
            self.favorited = favoritedReport;
  
            self.showEmailButtons = true;
            self.report = {
              name: reportMetadata.name,
              title: reportMetadata.title,
              description: $interpolate(reportMetadata.description)({ publisher: publisher }),
              columns: reportMetadata.columns,
            }
  
            self.removeReportFromFavorites = function(reportName) {
              PublisherReportService.removeReportFromFavorites($stateParams.shortcode, reportName)
                .then(function(response) {
                  self.favorited = response.data.data;
                });
            };
  
            self.addReportToFavorites = function(reportName ){
              PublisherReportService.addReportToFavorites($stateParams.shortcode, reportName)
                .then(function(response) {
                  self.favorited = response.data.data;
                });
            };
  
            self.currentUserEmail = function() {
              return SessionService.currentUser().email;
            };
  
            self.requestEmailReport = function (reportName){
              return PublisherService.one($stateParams.shortcode).one('email_reports', reportName).get()
                .then(function () {
                  self.showEmailButtons = false;
                })
                .catch(function () {
                  $state.go('error', {}, {errorCode: 500});
                });
            };
          }
        ]
      );
  
  } (window.angular, window._));
  
  ; (function (angular, _) {
  
    "use strict";
  
    angular.module('kudosPublishers')
      .controller(
          'PublisherAccountController',
          [
          'publisherAdmins',
          'publisher',
          'PublisherAdminService',
          'ProfileService',
          'NotificationService',
          function (publisherAdmins, publisher, PublisherAdminService, ProfileService, NotificationService) {
            var self = this;
  
            self.foundProfiles = [];
            self.publisherAdmins = publisherAdmins;
            self.publisher = publisher;
  
            self.state = {};
            self.state.searchOpen = false;
            self.state.loading = false;
            self.state.showEmptySearch = false;
            self.state.emailSearch = '';
  
            self.searchProfiles = searchProfiles;
            self.toggleSearch = toggleSearch;
            self.deleteAdmin = deleteAdmin;
            self.addAdmin = addAdmin;
  
            self.canSearch = canSearch;
            self.showSearchBox = showSearchBox;
            self.showSearchLoading = showSearchLoading;
            self.onEnterSearchProfiles = onEnterSearchProfiles;
  
            function addAdmin (id) {
              PublisherAdminService.addPublisherAdmin(id, self.publisher.short_code)
                .then(function (data) {
                  self.publisherAdmins = data.admins;
                  closeSearch();
  
                  NotificationService.success('Successfully made ' + _.last(self.publisherAdmins).display_name + ' a publisher admin');
                })
                .catch(function (response) {
                  handleError(response, 'add', self.publisher.short_code);
                });
            }
  
            function deleteAdmin (id) {
              PublisherAdminService.deletePublisherAdmin(id, self.publisher.short_code)
                .then(function (data) {
                  var who = getProfileFromId(id);
                  self.publisherAdmins = data.admins;
                  closeSearch();
  
                  NotificationService.success('Successfully removed ' + who + ' as a publisher admin');
                })
                .catch(function (response) {
                  handleError(response, 'delete', self.publisher.short_code);
                });
            }
  
            function handleError (response, action, shortCode) {
              if (error.data.errors) {
                _.each(error.data.errors, function (error) {
                  NotificationService.error(error);
                });
              } else {
                NotificationService.error('Could not ' + action + ' publisher admin' + shortCode);
              }
            }
  
            function searchProfiles () {
              self.state.loading = true;
  
              ProfileService.searchProfiles(self.state.emailSearch)
                .then(function (profiles) {
                  self.foundProfiles = profiles;
                  self.state.showEmptySearch = !self.foundProfiles.length;
                })
                .catch(function (error) {
                  NotificationService.error('There was a problem searching for users, please try again.');
                })
                .finally(function () {
                  self.state.loading = false;
                });
            }
  
            function toggleSearch (open) {
              self.state.searchOpen = (
                angular.isDefined(open) ?
                  open :
                  !self.state.searchOpen
              );
            }
  
            function closeSearch () {
              self.toggleSearch(false);
              self.state.loading = false;
              self.foundProfiles = [];
            }
  
            function onEnterSearchProfiles (event) {
              // Search profiles on enter key press
              (event.which === 13 && self.searchProfiles());
            }
  
            function canSearch () {
              return !self.state.emailSearch;
            }
  
            function showSearchBox () {
              return (self.state.searchOpen && !self.state.loading);
            }
  
            function showSearchLoading () {
              return (self.state.searchOpen && self.state.loading);
            }
  
            function getProfileFromId (id) {
              var foundProfile =  _.find(self.publisherAdmins, function (user) {
                return user.id === id;
              });
  
              return (
                angular.isDefined(foundProfile) ?
                  foundProfile.display_name :
                  ''
              );
            }
  
          }
        ]
      );
  
  } (window.angular, window._));
  
  
  ; (function (angular) {
  
    "use strict";
  
    angular.module('kudosPublishers')
      .controller(
      'PublisherSearchController',
      [
        'publisher',
        'PublisherService',
        'NotificationService',
        function (publisher, PublisherService, NotificationService) {
          var self = this;
  
          self.publisher = publisher;
  
          self.searchForm = {};
          self.searching = false;
          self.searchResults = false;
  
          self.submitSearch = function () {
            self.searching = true;
            PublisherService.searchPublisherUsers(publisher, self.searchForm.email, self.searchForm.name)
              .then(function (response) {
                self.searchResults = response.profiles;
              })
              .catch(function () {
                NotificationService.error('User search failed, please try again');
              })
              .finally(function () {
                self.searching = false;
              });
          };
  
          self.canSubmit = function () {
            return !!self.searchForm.email || !!self.searchForm.name;
          };
  
        }
      ]
    );
  
  } (window.angular));
  
  
  
  ;(function (angular) {
    "use strict";
  
    // TODO: move this to the server side so we can do a lookup once.
    // All attributes except `compileGridOptions` should be copied verbatim from
    // `/internal_api/publishers/PUB_SHORT_CODE/reports/` until that happens.
    // This is only used for the headers; no data is shown.
    angular.module('kudosPublishers').constant('publisherReports', [
      {
        name: 'all_activities_by_date',
        visible: true,
        type: 'activity_report',
        title: 'Activity report: all activities by date (explain and share)',
        description: 'This report lists all activities undertaken on Kudos by authors that indicated that they have published with {{ publisher.name }} to help increase the impact of their publications. This included adding or editing a short title, the "What\'s it about?" text, the "Why is it important?" text, an Author Perspective, a Resource or by sharing through Twitter, Facebook, LinkedIn, Weibo, WeChat or email/online.',
        columns: ['Date', 'Activity', 'Author', 'Publication title', 'Journal title', 'DOI of publication']
      },
      {
        name: 'all_explained_activities',
        visible: true,
        type: 'activity_report',
        title: 'Activity report: all explained activities (excluding adding resources) by date',
        description: 'This report lists all explain activities undertaken on Kudos by authors that indicated that they have published with {{ publisher.name }} to help increase the impact of their publications. This included adding or editing a short title, the "What\'s it about?" text, the "Why is it important?" text or an Author Perspective.',
        columns: ['Date', 'Activity', 'Author', 'Publication title', 'DOI of publication']
      },
      {
        name: 'all_resource_activities_by_date',
        visible: true,
        type: 'activity_report',
        title: 'Activity report: all resource activities by date',
        description: 'This report lists all resource activities undertaken on Kudos by authors that indicated that they have published with {{ publisher.name }} to help increase the impact of their publications. This includes both adding or editing a Resource.',
        columns: ['Date', 'Activity', 'Author', 'Publication title', 'DOI of publication']
      },
      {
        name: 'all_share_activities_by_date',
        visible: true,
        type: 'activity_report',
        title: 'Activity report: all share activities by date',
        description: 'This report lists all share undertaken on Kudos by authors that indicated that they have published with {{ publisher.name }} to help increase the impact of their publications. This included sharing through Twitter, Facebook, LinkedIn, Weibo, WeChat or email/online.',
        columns: ['Date', 'Activity', 'Author', 'Publication title','Journal title', 'DOI of publication']
      },
      {
        name: 'all_tweets_by_date',
        visible: false,
        type: 'activity_report',
        title: 'Activity report: all tweets by date',
        description: 'This report lists all tweets sent from Kudos by authors that indicated that they have published with {{ publisher.name }} to help increase the impact of their publications.',
        columns: ['', '', '', '', '']
      },
      {
        name: 'publication_page_views_by_date',
        visible: false,
        type: 'performance_report',
        title: 'Performance report: publication page views',
        description: 'This report shows the number of views of publications by date and the cumulative number of views of publications on Kudos for publications where one of more author has indicated that they have published with {{ publisher.name }} ',
        columns: ['Date', 'Number of views of the publication page on this date', 'Cumulative number of views of the publication page by this date']
      },
      {
        name: 'detailed_explain_activity_report',
        visible: true,
        type: 'publication_report',
        title: 'Publication report: detailed explain activity report',
        description: 'This report is a detailed list of all publications on Kudos where at least one author has indicated that they have published with {{ publisher.name }} and that have been explained by one or more of the authors. This includes adding or editing a Short Title, adding or editing text explaining "What\'s it about?", "Why is it important?" or the Author Perspective or adding a Resource.',
        columns: ['Publication Date', 'Most Recent Explain Date', 'Publication Title', 'Journal Title', 'Short Title?', 'What\'s it about?', 'Why is it important?', 'Number of perspectives', 'Number of resources']
      },
      {
        name: 'most_viewed',
        visible: true,
        type: 'performance_report',
        title: 'Performance report: publication page - most viewed',
        description: 'This report shows the publication page with the highest number of views on Kudos for publications where one of more author has indicated that they are from {{ publisher.name }}.',
        columns: ['Rank','Number of views of the publication page', 'Publication title', 'DOI']
      },
      {
        name: 'publisher_showcase_total_views_by_date',
        visible: false,
        type: 'performance_report',
        title: 'Performance report: Publisher showcase page - total views by date',
        description: 'This report shows the number of view of the Publisher Showcase page for {{ publisher.name }}.',
        columns: []
      },
      {
        name: 'publisher_showcase_cumulative_views_by_date',
        visible: false,
        type: 'performance_report',
        title: 'Performance report: Publisher showcase page - cumulative views by date',
        description: 'This report shows the cumulative number of view of the Publisher Showcase page by date on Kudos for publications where one of more author has indicated that they are from {{ publisher.name }}.',
        columns: []
      },
      {
        name: 'share_referrals_total_by_date',
        visible: false,
        type: 'performance_report',
        title: 'Performance report: share referrals - total by date',
        description: 'This report shows the number of share referrals by date to publications when one or more author has indicated that they were from {{ publisher.name }}. Share referrals are clicks on coded links in tweets, Facebook post, emails or online resulting from authors using the sharing tools on Kudos.',
        columns: ['Date', 'Total number of share referrals on this date', 'Total cumulative number of share referrals on this date']
      },
      {
        name: 'share_referrals_highest',
        visible: true,
        type: 'performance_report',
        title: 'Performance report: shares resulting in highest share referrals',
        description: 'This report shows the shares that resulted in the highest number of share referrals for publications where one of more author has indicated that they are from {{ publisher.name }}.',
        columns: ['Rank', 'Number of Share Referrals', 'Type of share', 'Publication title', 'Journal title', 'DOI']
      },
      {
        name: 'doi_click_throughs_cumulative_by_date',
        visible: false,
        type: 'performance_report',
        title: 'Performance report: DOI click-throughs - cumulative by date',
        description: 'This report shows the cumulative number of DOI click-throughs by date to publications when one or more author has indicated that they were from {{ publisher.name }}. DOI click-throughs are where a reader has click through from Kudos or from a shared link to read the publication.',
        columns: ['Date', 'Cumulative number of DOI click-throughs by this date']
      },
      {
        name: 'doi_click_throughs_total_by_date',
        visible: false,
        type: 'performance_report',
        title: 'Performance report: DOI click-throughs - total by date',
        description: 'This report shows the total number of DOI click-throughs by date to publications when one or more author has indicated that they were from {{ publisher.name }}. DOI click-throughs are where a reader has click through from Kudos or from a shared link to read the publication.',
        columns: ['Date', 'Number of DOI click-throughs on this date']
      },
      {
        name: 'altmetric_scores_top_all_publications',
        visible: true,
        type: 'performance_report',
        title: 'Performance report: top altmetric scores (all publications)',
        description: 'This report lists the publications from {{ publisher.name }} on Kudos that have the highest current altmetric scores. The Top 100 are shown and altmetric data is provided by <a href="https://www.altmetric.com" target="_blank">Altmetric.com</a>',
        columns: ['Rank', 'Current Altmetric score', 'Publication title', 'Kudos detailed metrics page', 'Altmetrics detailed metrics page', 'DOI']
      },
      {
        name: 'altmetric_scores_top_explained_publications',
        visible: true,
        type: 'performance_report',
        title: 'Performance report: top altmetric scores (explained publications)',
        description: 'This report lists the explained publications from {{ publisher.name }} on Kudos that have the highest current altmetric scores. The Top 100 are shown and altmetric data is provided by <a href="https://www.altmetric.com" target="_blank">Altmetric.com</a>',
        columns: ['Rank', 'Current Altmetric score', 'Publication title', 'Kudos detailed metrics page', 'Altmetrics detailed metrics page', 'DOI']
      },
      {
        name: 'altmetric_scores_climbing_all_publications',
        visible: true,
        type: 'performance_report',
        title: 'Performance report: highest climbing altmetric scores (all publications)',
        description: 'This report lists the publications from {{ publisher.name }} on Kudos that have the highest recent growth altmetric scores. The Top 100 are shown and altmetric data is provided by <a href="https://www.altmetric.com" target="_blank">Altmetric.com</a>',
        columns: ['Rank', '% increase over the last 7 days', 'Current Altmetric score', 'Altmetric score 7 days ago', 'Publication title', 'Kudos detailed metrics page', 'Altmetrics detailed metrics page', 'DOI']
      },
      {
        name: 'altmetric_scores_climbing_explained_publications',
        visible: true,
        type: 'performance_report',
        title: 'Performance report: highest climbing altmetric scores (explained publications)',
        description: 'This report lists the explained publications from {{ publisher.name }} on Kudos that have the highest recent growth altmetric scores. The Top 100 are shown and altmetric data is provided by <a href="https://www.altmetric.com" target="_blank">Altmetric.com</a>',
        columns: ['Rank','% increase over the last 7 days', 'Current Altmetric score', 'Altmetric score 7 days ago', 'Publication title', 'Kudos detailed metrics page', 'Altmetrics detailed metrics page', 'DOI'
    ]
      },
      {
        name: 'all_publications',
        visible: false,
        type: 'publication_report',
        title: 'Publications report: all publications on Kudos',
        description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ publisher.name }}.',
        columns: ['Publication Date', 'Publication Title', 'DOI']
      },
      {
        name: 'all_publications_explained',
        visible: true,
        type: 'publication_report',
        title: 'Publications report: all publications on Kudos that have been explained',
        description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ publisher.name }} and that have been explained by one or more of the authors. This means that the publication has at least one of the following: a Short Title, text explaining "What\'s it about?", text explaining "Why is it important?", an Author Perspective or a Resource.',
        columns: ['Publication Date', 'Publication Title', 'DOI']
      },
      {
        name: 'all_publications_shared',
        visible: true,
        type: 'publication_report',
        title: 'Publications report: all publications on Kudos that have been shared',
        description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{ publisher.name }} and the publication has been shared by one or more of the authors. Shares means that one of more of the authors has used Kudos to send a tweet, add a Facebook post, send an email or post online. When the author shares, a coded link is included in the share which allows readers to link to the publication.',
        columns: ['Publication Date', 'Publication Title', 'DOI']
      },
      {
        name: 'all_publications_with_resources',
        visible: true,
        type: 'publication_report',
        title: 'Publications report: all publications on Kudos that have one or more resource',
        description: 'This report lists all publications on Kudos where at least one author has indicated that they are from {{publisher.name}} and have added one or more resource links. This includes links to data sets, videos, presentations or news stories.',
        columns: ['Publication Date', 'Publication Title', 'DOI']
      },
      {
        name: 'all_resources_linked_to_publications',
        visible: true,
        type: 'publication_report',
        title: 'Publications report: all resources linked to publications',
        description: 'This report lists all resources linked to publications on Kudos where at least one author has indicated that they are from {{ publisher.name }. A resource includes data sets, videos, presentations or news stories.',
        columns: ['Publication Date', 'Publication Title', 'Resource Title', 'Resource Description', 'Resource Type', 'Resource Link']
      },
      {
        name: 'recently_explained_publications',
        visible: true,
        type: 'publication_report',
        title: 'Publication report: recently explained publications',
        description: 'This report lists publications on Kudos where at least one author has indicated that they have published with  {{ publisher.name }} and that have recently been explained by one or more of the authors. This means that the publication has recently had at least one of the following added or edited: a Short Title, text explaining "What\'s it about?", text explaining "Why is it important?", an Author Perspective or a Resource.',
        columns: ['Date', 'Time', 'Publication title', 'DOI']
      },
      {
        name: 'key_metrics_by_journal',
        visible: true,
        type: 'publication_report',
        title: 'Publications report: key metrics by journal',
        description: 'This report lists all journals on Kudos that have been published by {{publisher.name}}. Key metrics are shown for each journal.',
        columns: ['Journal', 'ISSNs', 'Publications on Kudos', 'Publications linked to a registered user', 'Publications explained', 'Publications shared', 'Kudos views', 'Clicks on shared links', 'Clicks from Kudos to the DOI']
      },
      {
        name: 'all_authors',
        type: 'author_report',
        title: 'Author report: export of all registered users',
        description: 'This report lists all authors using Kudos who have published with {{ publisher.name }}. It includes their name, email address, self-selected subject area and self-selected career stage. Please note that you are bound by Kudos\' terms and conditions regarding the use of this personal data.',
        columns: ['Registration date', 'User\'s name', 'User\'s email address', 'Subject area', 'Career stage', 'Country']
      },
      {
        name: 'authors_by_country',
        type: 'author_report',
        title: 'Author report: breakdown by geographic location',
        description: 'This report lists all authors using Kudos who have published with {{ publisher.name }}. It shows the distribution of countries where the authors are primarily based. These countries are selected by the author when they register with Kudos. This field is optional, so some authors may not have made a selection.',
        columns: ['Country', 'Number of Authors']
      },
      {
        name: 'authors_claims',
        type: 'author_report',
        title: 'Author report: breakdown by number of publications',
        description: 'This report lists all authors using Kudos who have published with {{ publisher.name }}. It shows the distribution of the number of publications each author has claimed. A author claims a publication to indicate that they are an author of that publication. Some authors will have claimed multiple publications.',
        columns: ['Number of publications claimed', 'Number of Authors who have claimed this number of publications' ]
      },
      {
        name: 'authors_by_career_stage',
        type: 'author_report',
        title: 'Author report: breakdown by career stage',
        description: 'This report lists all authors using Kudos who have published with {{ publisher.name }}. It shows the distribution of authors across career stages. These career stages are selected by the author when they register with Kudos. This field is optional, so some authors may not have made a selection.',
        columns: ['Career stage', 'Number of Authors']
      },
      {
        name: 'authors_by_subject',
        type: 'author_report',
        title: 'Author report: breakdown by subject area',
        description: 'This report lists all authors using Kudos who have published with {{ publisher.name }}. It shows the distribution of authors across subject areas. These subject areas are selected by the author when they register with Kudos. This field is optional, so some authors may not have made a selection.',
        columns: ['Subject area', 'Number of Authors']
      },
      {
        name: 'authors_most_publications',
        type: 'author_report',
        title: 'Author report: authors with the most publications',
        description: 'This report lists authors using Kudos who have published with {{ publisher.name }}. It ranks authors to show which authors have the most {{ publisher.name }} publications claimed in Kudos.',
        columns: ['Rank', 'Number of claimed publications', 'Author\'s name', 'Author\'s email']
      },
      {
        name: 'authors_most_explained_publications',
        type: 'author_report',
        title: 'Author report: authors who have explained the most publications',
        description: 'This report lists authors using Kudos who have published with {{ publisher.name }}. It ranks authors to show which authors have explained the most {{ publisher.name }} publications in Kudos.',
        columns: ['Rank', 'Number of explained publications', 'Author\'s name', 'Author\'s email']
      },
      {
        name: 'authors_most_shared_publications',
        type: 'author_report',
        title: 'Author report: authors who have shared the most',
        description: 'This report lists authors using Kudos who have published with {{ publisher.name }}. It ranks authors to show which authors have shared the most {{ publisher.name }} publications in Kudos. It shows the number of different publications shared at least once.',
        columns: ['Rank', 'Number of different shared publications', 'Author\'s name', 'Author\'s email']
      },
      {
        name: 'authors_most_share_referrals',
        type: 'author_report',
        title: 'Author report: authors who have had the highest share referrals',
        description: 'This report lists authors using Kudos who have published with {{ publisher.name }}.  It ranks authors to show which authors have had the highest total number of share referrals for {{ publisher.name }} publications in Kudos.',
        columns: ['Total number of share referrals', 'Author\'s name', 'Author\'s email']
      },
      {
        name: 'recently_active_authors',
        type: 'author_report',
        title: 'Author report: recently active authors',
        description: 'This report lists authors using Kudos that have published with {{ publisher.name }} and that been recently active on Kudos.',
        columns: ['Date of Activity', 'Time of Activity', 'Author\'s name']
      }
    ]);
  }(window.angular));
  
  ; (function (angular, _) {
    "use strict";
  
    angular.module('kudosPublishers')
      .config([
        '$stateProvider',
        'publisherReports',
        function ($stateProvider, publisherReports) {
  
            $stateProvider
              .state('publisher', {
                url: '/publishers/{shortcode}',
                abstract: true,
                templateUrl: "kudosPublishers/publisher.html",
                controller: 'PublisherController as vm',
                resolve: {
                  publisher: ['$state', '$stateParams', 'PublisherService', function ($state, $stateParams, PublisherService) {
                    return PublisherService.one($stateParams.shortcode).get()
                      .catch(function () {
                        $state.go('error', {}, {errorCode: 500});
                      });
                  }]
                }
              })
              .state('publisher.dashboard', {
                url: '?feature',
                controller: 'PublisherDashboardController as vm',
                templateUrl: "kudosPublishers/publisher.dashboard.html",
                resolve: {
                  latestTweets: ['$stateParams', '$state', 'PublisherService', function ($stateParams, $state, PublisherService) {
                    return PublisherService.one($stateParams.shortcode).one('tweets').get()
                      .catch(function () {
                        $state.go('error', {}, {errorCode: 500});
                      });
                  }],
                  categoryOverview: ['$state', '$stateParams', 'PublisherService', function ($state, $stateParams, PublisherService) {
                    return PublisherService.one($stateParams.shortcode).one('reports', 'category_overview').get()
                      .catch(function () {
                        $state.go('error', {}, {errorCode: 500});
                      });
                  }],
                  sharesByChannel: ['$state', '$stateParams', 'PublisherService', function ($state, $stateParams, PublisherService) {
                    return PublisherService.one($stateParams.shortcode).one('reports', 'share_channels_stats').get()
                      .catch(function () {
                        $state.go('error', {}, {errorCode: 500});
                      });
                  }],
                  recentlyExplainedPublications: ['$state', '$stateParams', 'PublisherService', function ($state, $stateParams, PublisherService) {
                    return PublisherService.one($stateParams.shortcode).one('reports/recently_explained_publications?limit=20').get()
                      .catch(function () {
                        $state.go('error', {}, {errorCode: 500});
                      });
                  }],
                  recentlyActiveAuthors: ['$state', '$stateParams', 'PublisherService', function ($state, $stateParams, PublisherService) {
                    return PublisherService.one($stateParams.shortcode).one('reports/recently_active_authors?limit=20').get()
                      .catch(function () {
                        $state.go('error', {}, {errorCode: 500});
                      });
                  }],
                  exampleExplainedPublications: ['$state', '$stateParams', 'PublisherService', function ($state, $stateParams, PublisherService) {
                    return PublisherService.one($stateParams.shortcode).one('reports', 'explained_publications_examples').get()
                      .catch(function () {
                        $state.go('error', {}, {errorCode: 500});
                      });
                  }],
                  favoriteReports: ['$state', '$stateParams', 'PublisherReportService', function ($state, $stateParams, PublisherReportService) {
                    return PublisherReportService
                      .getFavoritedReports($stateParams.shortcode)
                      .then(function(response) {
                        return _.map(response.data.data, function(value) {
                          return _.find(publisherReports, {name: value.report_name});
                        });
                      })
                      .catch(function (error) {
                        $state.go('error', {}, {errorCode: 500, errorMessage: error});
                      });
                  }],
                  shareActivityCountQuery: ['$state', '$stateParams', 'PublisherQueryService', function ($state, $stateParams, PublisherQueryService) {
                    return PublisherQueryService.getQuery($stateParams.shortcode, 1)
                      .then(function(response) {
                        return response.data.data.query;
                      })
                      .catch(function (error) {
                        $state.go('error', {}, {errorCode: 500, errorMessage: error});
                      });
                  }],
                  explainActivityCountQuery: ['$state', '$stateParams', 'PublisherQueryService', function ($state, $stateParams, PublisherQueryService) {
                    return PublisherQueryService.getQuery($stateParams.shortcode, 2)
                      .then(function(response) {
                        return response.data.data.query;
                      })
                      .catch(function (error) {
                        $state.go('error', {}, {errorCode: 500, errorMessage: error});
                      });
                  }],
                  registeredAuthorActivityCountQuery: ['$state', '$stateParams', 'PublisherQueryService', function ($state, $stateParams, PublisherQueryService) {
                    return PublisherQueryService.getQuery($stateParams.shortcode, 3)
                      .then(function(response) {
                        return response.data.data.query;
                      })
                      .catch(function (error) {
                        $state.go('error', {}, {errorCode: 500, errorMessage: error});
                      });
                  }]
                }
              })
  
              .state('publisher.search', {
                url: '/search',
                controller: 'PublisherSearchController as search',
                templateUrl: "kudosPublishers/publisher.search.html"
              })
              .state('publisher.benchmarking', {
                url: '/benchmarking',
                templateUrl: "kudosPublishers/publisher.benchmarking.html"
              })
              .state('publisher.showcase', {
                url: '/showcase',
                templateUrl: "kudosPublishers/publisher.showcase.html"
              })
              .state('publisher.loading', {
                url: '/loading',
                templateUrl: "kudosPublishers/publisherLoading.html"
              })
              .state('publisher.branding', {
                url: '/branding',
                templateUrl: "kudosPublishers/publisher.branding.html"
              })
              .state('publisher.account', {
                url: '/account',
                templateUrl: "kudosPublishers/publisher.account.html",
                controller: 'PublisherAccountController as account',
                resolve: {
                  publisherAdmins: ['$state', '$stateParams', 'PublisherService', function ($state, $stateParams, PublisherService) {
                    return PublisherService.one($stateParams.shortcode).one('admins').get()
                      .then(function (response) {
                        return response.admins;
                      })
                      .catch(function () {
                        $state.go('error', {}, {errorCode: 500});
                      });
                  }]
                }
              })
  
              .state('publisher.reports', {
                url: '/reports',
                abstract: true,
                template: "<ui-view></ui-view>"
              })
  
              .state('publisher.reports.list', {
                url: '',
                templateUrl: "kudosPublishers/publisher.reports.list.html"
              });
  
              publisherReports.forEach(function (report) {
                $stateProvider.state('publisher/reports/' + report.name, {
                  parent: 'publisher.reports',
                  templateUrl: "kudosPublishers/publisher.reports.detail.html",
                  controller: 'PublisherReportController',
                  controllerAs: 'vm',
                  url: '/' + report.name,
                  resolve: {
                    reportMetadata: function () {
                      return report;
                    },
                    favoritedReport: ['$state', '$stateParams', 'PublisherReportService', 'reportMetadata', function($state, $stateParams, PublisherReportService, reportMetadata) {
                      return PublisherReportService.getFavoritedReport($stateParams.shortcode, reportMetadata.name)
                        .then(function (response) {
                          return response.data.data;
                        })
                        .catch(function () {
                          $state.go('500');
                        });
                    }]
                  }
                });
              });
        }
      ]);
  
  } (window.angular, window._));
  
  ; (function (angular, _) {
    'use strict';
    angular
      .module('kudosPublishers')
      .factory(
        'PublisherService',
        [
        'Restangular',
        'NotificationService',
        function (Restangular, NotificationService) {
          var Publisher = Restangular.service('publishers');
  
          Publisher.STATES = {
            CUSTOMER: 'customer',
            NON_CUSTOMER: 'non_customer',
            EX_CUSTOMER: 'ex_customer'
          };
  
          Publisher.searchPublisherUsers = function (publisher, email, name) {
            var query = {};
  
            if (!!email) query.email = email;
            if (!!name) query.name =  name;
  
            return Publisher.one(publisher.short_code).one('/profiles').get(query);
          };
  
          Publisher.removePublisherAvatar = function (publisher) {
            return Publisher.one(publisher.short_code).one('/avatar').one('/remove').remove();
          };
  
          Publisher.getCustomerPublishers = function (states) {
            return Publisher.one('').get({state: states});
          };
  
          Publisher.findPublisherByPartialName = function (searchString) {
            var query = {};
            query.query = searchString;
  
            return Publisher.one('search').get(query)
              .then(function (response) {
                return response.search_results;
              })
              .catch(function () {
                NotificationService.error("Couldn't perform search");
                return [];
              });
          };
          Publisher.publisherSubscriptionStates = _.values(Publisher.STATES);
  
          return Publisher;
        }
      ]
    );
  
  }(window.angular, window._));
  
  ; (function (angular) {
    'use strict';
    angular
      .module('kudosPublishers')
      .factory(
        'PublisherAdminService',
        [
        'Restangular',
        function (Restangular) {
          var publisherAdmins = Restangular.service('publisher_admins');
  
          publisherAdmins.getIfUserIsPublisherAdmin = function (userId) {
            return publisherAdmins.one(userId).get()
              .then(function (response) {
                return response.publisher_admin;
              });
          };
  
          publisherAdmins.addPublisherAdmin = function (id, shortCode) {
            var payload = {
              id: id
            };
            return Restangular.service('publishers').one(shortCode).one('admins').customPOST(payload);
          };
  
          publisherAdmins.deletePublisherAdmin = function (id, shortCode) {
            return Restangular.service('publishers').one(shortCode).one('admins').one(id.toString()).customDELETE();
          };
  
          return publisherAdmins;
        }]);
  
  }(window.angular));
  
  ; (function (angular) {
    'use strict';
    angular
      .module('kudosPublishers')
      .factory(
        'PublisherReportService',
        [
          '$http', '$state', '$httpParamSerializer', 'NotificationService', 'PublisherService',
          function ($http, $state, $httpParamSerializer, NotificationService, PublisherService) {
  
            function addReportToFavorites(shortCode, reportName) {
              return $http.post('/internal_api/publishers/' + shortCode + '/favorites/' + reportName)
                .then(function (response) {
                  NotificationService.success('This report has been added to your favourites.');
                  return response;
                })
                .catch(function () {
                  $state.go('error', {}, {errorCode: 500});
                });
            }
  
            function removeReportFromFavorites(shortCode, reportName) {
              return $http.delete('/internal_api/publishers/' + shortCode + '/favorites/' + reportName)
                .then(function (response) {
                  NotificationService.success('This report has been removed from your favorites.');
                  return response;
  
                })
                .catch(function () {
                  $state.go('error', {}, {errorCode: 500});
                });
            }
  
            function getFavoritedReports(shortCode) {
              return $http.get('/internal_api/publishers/' + shortCode + '/favorites');
            }
  
            function getFavoritedReport(shortCode, reportName) {
              return $http.get('/internal_api/publishers/' + shortCode + '/favorites/' + reportName);
            }
  
            function getLoaderBundlesReport(shortCode, query, limit) {
              var params = {};
              if (angular.isDefined(query) && query != null && query !== '') {
                params.filters = {query: query};
              }
              if (angular.isDefined(limit)) {
                params.limit = limit;
              }
              return PublisherService.one(shortCode).one('reports/loader_bundles?' + $httpParamSerializer(params)).get();
            }
  
            function getLoaderBundlesReportCsvUrl(shortCode, query, limit) {
              var params = {};
              if (angular.isDefined(query) && query != null && query !== '') {
                params.filters = {query: query};
              }
              if (angular.isDefined(limit)) {
                params.limit = limit;
              }
              return '/internal_api/publishers/' + shortCode + '/reports/loader_bundles.csv?' + $httpParamSerializer(params);
            }
  
            function getLoaderBundleLogsReport(shortCode, loaderBundle, limit) {
              var params = {};
              if (angular.isDefined(loaderBundle)) {
                params.filters = {loader_bundle_id: loaderBundle.id};
              }
              if (angular.isDefined(limit)) {
                params.limit = limit;
              }
              return PublisherService.one(shortCode).one('reports/loader_bundle_logs?' + $httpParamSerializer(params)).get();
            }
  
            function getLoaderBundleLogsReportCsvUrl(shortCode, loaderBundle, limit) {
              var params = {};
              if (angular.isDefined(loaderBundle)) {
                params.filters = {loader_bundle_id: loaderBundle.id};
              }
              if (angular.isDefined(limit)) {
                params.limit = limit;
              }
              return '/internal_api/publishers/' + shortCode + '/reports/loader_bundle_logs.csv?' + $httpParamSerializer(params);
            }
  
            function getLoaderRecordsReport(shortCode, loaderBundle, limit) {
              var params = {};
              if (angular.isDefined(loaderBundle)) {
                params.filters = {loader_bundle_id: loaderBundle.id};
              }
              if (angular.isDefined(limit)) {
                params.limit = limit;
              }
              return PublisherService.one(shortCode).one('reports/loader_records?' + $httpParamSerializer(params)).get();
            }
  
            function getLoaderRecordsReportCsvUrl(shortCode, loaderBundle, limit) {
              var params = {};
              if (angular.isDefined(loaderBundle)) {
                params.filters = {loader_bundle_id: loaderBundle.id};
              }
              if (angular.isDefined(limit)) {
                params.limit = limit;
              }
              return '/internal_api/publishers/' + shortCode + '/reports/loader_records.csv?' + $httpParamSerializer(params);
            }
  
            function getLoaderRecordLogsReport(shortCode, loaderQueue, limit) {
              var params = {};
              if (angular.isDefined(loaderQueue)) {
                params.filters = {loader_record_id: loaderQueue.id};
              }
              if (angular.isDefined(limit)) {
                params.limit = limit;
              }
              return PublisherService.one(shortCode).one('reports/loader_record_logs?' + $httpParamSerializer(params)).get();
            }
  
            function getLoaderRecordLogsReportCsvUrl(shortCode, loaderQueue, limit) {
              var params = {};
              if (angular.isDefined(loaderQueue)) {
                params.filters = {loader_record_id: loaderQueue.id};
              }
              if (angular.isDefined(limit)) {
                params.limit = limit;
              }
              return '/internal_api/publishers/' + shortCode + '/reports/loader_record_logs.csv?' + $httpParamSerializer(params);
            }
  
            return {
              addReportToFavorites: addReportToFavorites,
              removeReportFromFavorites: removeReportFromFavorites,
              getFavoritedReports: getFavoritedReports,
              getFavoritedReport: getFavoritedReport,
              getLoaderBundlesReport: getLoaderBundlesReport,
              getLoaderBundlesReportCsvUrl: getLoaderBundlesReportCsvUrl,
              getLoaderBundleLogsReport: getLoaderBundleLogsReport,
              getLoaderBundleLogsReportCsvUrl: getLoaderBundleLogsReportCsvUrl,
              getLoaderRecordsReport: getLoaderRecordsReport,
              getLoaderRecordsReportCsvUrl: getLoaderRecordsReportCsvUrl,
              getLoaderRecordLogsReport: getLoaderRecordLogsReport,
              getLoaderRecordLogsReportCsvUrl: getLoaderRecordLogsReportCsvUrl
  
            }
          }
        ]
      )
  }(window.angular));
  
  ; (function (angular) {
    "use strict";
  
    angular
      .module('kudosPublishers')
      .directive('loaderBundles', ['PublisherReportService', '$state', 'uiGridTreeViewConstants',
        function (PublisherReportService, $state, uiGridTreeViewConstants) {
          return {
            restrict: 'E',
            scope: {},
            controllerAs: 'ctrl',
            templateUrl: 'kudosPublishers/directives/loaderBundles.directive.html',
            bindToController: {
              publisher: '=',
              loaderBundle: '='
            },
            controller: function () {
              var self = this;
  
              self.LOAD_LIMIT_BUTTON_VALUES = [30, 60, 100, ''];
  
              self.gridOptions = {
                enableSorting: true,
                columnDefs: [
                  {
                    displayName: 'File name',
                    field: 'filename',
                    headerCellClass: 'wrap-words',
                    cellTooltip: function(row,col){ return row.entity.archived_url; },
                    cellClass: getCellClassForLoaderStatus
                  },
                  {
                    displayName: 'Type',
                    field: 'loader_type',
                    headerCellClass: 'wrap-words',
                    cellClass: getCellClassForLoaderStatus
                  },
                  {
                    displayName: 'Received at',
                    field: 'received_at',
                    cellTemplate: cellContentTemplate('{{ row.entity.received_at | date:"d-MMM-yyyy H:mm"}}'),
                    headerCellClass: 'wrap-words',
                    cellClass: getCellClassForLoaderStatus
                  },
                  {
                    displayName: 'Successful records',
                    field: 'loader_records_count_success',
                    headerCellClass: 'wrap-words',
                    cellClass: getCellClassForLoaderStatus
                  },
                  {
                    displayName: 'Failed records',
                    field: 'loader_records_count_failure',
                    headerCellClass: 'wrap-words',
                    cellClass: getCellDangerClassForNonZero
                  },
                  {
                    displayName: 'Unique emails',
                    field: 'accepted_unique_emails',
                    headerCellClass: 'wrap-words',
                    cellClass: getCellClassForLoaderStatus
                  },
                  {
                    displayName: 'Status',
                    field: 'loader_status',
                    headerCellClass: 'wrap-words',
                    cellClass: getCellClassForLoaderStatus
                  },
                  {
                    displayName: 'Log',
                    field: 'loader_status',
                    headerCellClass: 'wrap-words',
                    cellTemplate: cellContentTemplate('<button uib-popover-template="grid.appScope.ctrl.htmlPopoverTemplate" popover-trigger="outsideClick" popover-title="{{row.entity.filename}}" popover-append-to-body="true" popover-placement="top-right" class="btn btn-primary btn-xs" ng-click=""><i class="fa fa-question-circle"></i></button>'),
                    width: 60,
                    enableSorting: false
                  }
                ],
  
                enableFullRowSelection: true,
                enableRowHeaderSelection: false,
                enableRowSelection: true,
                multiSelect: false,
                showTreeExpandNoChildren: false,
                modifierKeysToMultiSelect: false,
                noUnselect: true,
  
                onRegisterApi: onRegisterApi
  
              };
  
              // publicly accessible functions used in the view
              self.setLoadLimit = setLoadLimit;
              self.refreshDataGrid = refreshDataGrid;
              self.getLoaderBundlesReportCsvUrl = getLoaderBundlesReportCsvUrl;
  
  
  
              // publicy exposed objects
              self.reportMetadata = {}; // set by a request for data
              self.query = undefined;   // bound to the search input box
              self.loadLimit = undefined; // set by clicking on a load-limit button
              self.htmlPopoverTemplate = "kudosPublishers/directives/loaderBundles.popover.html";
              self.$onInit = init;
  
              function init() {
                setLoaderBundle(undefined);
                setLoadLimit(self.LOAD_LIMIT_BUTTON_VALUES[0]);
              }
  
              function setLoadLimit (limit) {
                self.loadLimit = limit;
                refreshDataGrid();
              }
  
              function setLoaderBundle(loaderBundle) {
                self.loaderBundle = loaderBundle;
              }
  
              function cellContentTemplate (innerTemplate) {
                return '<div class="ui-grid-cell-contents">' + innerTemplate + '</div>';
              }
  
              function onRegisterApi(gridApi) {
                // we need to use null here as the scope isn't available and undefined throws an error...
                gridApi.selection.on.rowSelectionChanged(null, function(row) {
                  setLoaderBundle(row.entity);
                  }
                );
              }
  
              function refreshDataGrid() {
                return PublisherReportService.getLoaderBundlesReport(self.publisher.short_code, self.query, self.loadLimit)
                  .then(function (data) {
                    self.gridOptions.data = data.rows;
                    self.reportMetadata = data.metadata;
                    setLoaderBundle(undefined);
                  })
                  .catch(function () {
                    $state.go('error', {}, {errorCode: 500});
                  });
              }
  
              function getLoaderBundlesReportCsvUrl() {
                return PublisherReportService.getLoaderBundlesReportCsvUrl(self.publisher.short_code, self.query, self.loadLimit);
              }
  
              // if the current cell is > 0, return danger colour, otherwise return a colour based on the loader status
              function getCellDangerClassForNonZero(grid, row, col) {
                if (grid.getCellValue(row,col) > 0)
                {
                  return "text-danger";
                } else {
                  return getCellClassForLoaderStatus(grid, row, col);
                }
              }
  
              // return a cell class based on the loader status
              function getCellClassForLoaderStatus(grid, row, col) {
                var textClass = undefined;
                switch (row.entity.loader_status.toLowerCase()) {
                  case "pending":
                    textClass = "text-muted";
                    break;
                  case "processing":
                    textClass = "text-primary";
                    break;
                  case "retry":
                    textClass = "text-warning";
                    break;
                  case "failure":
                  case "partial failure":
                  case "rejected":
                    textClass = "text-danger";
                    break;
                  case "success":
                    textClass = "text-success";
                    break;
                  default:
                    textClass = "text-info";
                    break;
                }
                return textClass;
              }
  
            }
          }
        }]
      );
  } (window.angular));
  
  ; (function (angular) {
    "use strict";
  
    angular
      .module('kudosPublishers')
      .directive('loaderBundleLogs', ['PublisherReportService', '$state',
        function (PublisherReportService, $state) {
          return {
            restrict: 'E',
            scope: {},
            bindToController: {
              publisher: '=',
              loaderBundle: '='
            },
            templateUrl: 'kudosPublishers/directives/loaderBundleLogs.directive.html',
            controllerAs: 'ctrl',
            controller: function () {
              var self = this;
              self.logs = [];
              self.getMessageTypeIcon = getMessageTypeIcon;
              self.getLoaderBundleLogsCsvUrl = getLoaderBundleLogsCsvUrl;
              self.$onInit = init;
  
              function init() {
                getLoaderBundleLogs(self.publisher, self.loaderBundle);
              }
  
              function getMessageTypeIcon(messageType) {
                switch (messageType.toLowerCase()) {
                  case "debug":
                    return '<i class="fa fa-stethoscope fa-lg text-muted" aria-hidden="true" title="Debug message"></i>';
                  case "info":
                    return '<i class="fa fa-info-circle fa-lg text-info" aria-hidden="true" title="Informational message"></i>';
                  case "warn":
                    return '<i class="fa fa-exclamation-triangle fa-lg text-warning" aria-hidden="true" title="Warning message"></i>';
                  case "error":
                    return '<i class="fa fa-exclamation-circle fa-lg text-danger" aria-hidden="true" title="Error message"></i>';
                  case "fatal":
                    return '<i class="fa fa-frown-o fa-lg text-danger" aria-hidden="true" title="Fatal error message"></i>';
                  default:
                    return messageType;
                }
              }
  
              function getLoaderBundleLogs(publisher, loaderBundle) {
                if (angular.isDefined(loaderBundle) && angular.isDefined(publisher)) {
                  return PublisherReportService.getLoaderBundleLogsReport(publisher.short_code, loaderBundle)
                    .then(function (data) {
                      self.logs = data.rows;
                    })
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
                }
              }
  
              function getLoaderBundleLogsCsvUrl() {
                if (angular.isDefined(self.loaderBundle) && angular.isDefined(self.publisher)) {
                  return PublisherReportService.getLoaderBundleLogsReportCsvUrl(self.publisher.short_code, self.loaderBundle);
                }
                return undefined;
              }
            }
          }
        }
      ]);
  })(window.angular);
  
  ; (function (angular) {
    "use strict";
  
    angular
      .module('kudosPublishers')
      .directive('loaderRecords', ['PublisherReportService', '$state',
        function (PublisherReportService, $state) {
          return {
            restrict: 'E',
            scope: {
              loaderBundle: '='
            },
            controllerAs: 'ctrl',
            templateUrl: 'kudosPublishers/directives/loaderRecords.directive.html',
            bindToController: {
              publisher: '=',
              loaderBundle: '='
            },
            link: function(scope, element, attrs) {
              scope.$watch('loaderBundle', function(){
                scope.ctrl.refreshDataGrid(); // cause dataGrid to be redrawn when loaderBundle changes
              });
            },
            controller: function () {
              var self = this;
  
              self.LOAD_LIMIT_BUTTON_VALUES = [30, 60, 100, ''];
  
              self.gridOptions = {
                enableSorting: true,
                columnDefs: [
                  {
                    displayName: 'DOI',
                    field: 'doi',
                    headerCellClass: 'wrap-words',
                    cellClass: getCellClassForLoaderStatus
                  },
                  {
                    displayName: 'Email',
                    field: 'email',
                    headerCellClass: 'wrap-words',
                    cellClass: getCellClassForLoaderStatus
                  },
                  {
                    displayName: 'Retry count',
                    field: 'retry_count',
                    headerCellClass: 'wrap-words',
                    cellClass: getCellDangerClassForNonZero
                  },
                  {
                    displayName: 'Last processed at',
                    field: 'process_attempted_at',
                    cellTemplate: cellContentTemplate('{{ row.entity.process_attempted_at | date:"d-MMM-yyyy H:mm"}}'),
                    headerCellClass: 'wrap-words',
                    cellClass: getCellClassForLoaderStatus
                  },
                  {
                    displayName: 'Status',
                    field: 'loader_status',
                    headerCellClass: 'wrap-words',
                    cellClass: getCellClassForLoaderStatus
                  },
                  {
                    displayName: 'Log',
                    field: 'loader_status',
                    headerCellClass: 'wrap-words',
                    cellTemplate: cellContentTemplate('<button uib-popover-template="grid.appScope.ctrl.htmlPopoverTemplate" popover-trigger="outsideClick" popover-title="{{row.entity.doi}}" popover-append-to-body="true" popover-placement="top-right" class="btn btn-primary btn-xs"><i class="fa fa-question-circle"></i></button>'),
                    width: 60,
                    enableSorting: false
                  }
                ],
                enableRowSelection: true,// we need to enable this to allow clickover popovers to work
                enableRowHeaderSelection: false,
                multiSelect: false,
                modifierKeysToMultiSelect: false,
                noUnselect: true
              };
  
  
              // publicly accessible functions used in the view
              self.setLoadLimit = setLoadLimit;
              self.refreshDataGrid = refreshDataGrid;
              self.getLoaderRecordsReportCsvUrl = getLoaderRecordsReportCsvUrl;
  
  
              // publicy exposed objects
              self.reportMetadata = {}; // this is set by a request for data
              self.loadLimit = undefined; // set by clicking on a load-limit button
              self.htmlPopoverTemplate = "kudosPublishers/directives/loaderRecords.popover.html";
              self.$onInit = init;
  
              function init() {
                setLoadLimit(self.LOAD_LIMIT_BUTTON_VALUES[0]);
              }
  
              function setLoadLimit (limit) {
                self.loadLimit = limit;
                refreshDataGrid();
              }
  
              function cellContentTemplate (innerTemplate) {
                return '<div class="ui-grid-cell-contents">' + innerTemplate + '</div>';
              }
  
              function refreshDataGrid() {
                if (angular.isDefined(self.publisher) && angular.isDefined(self.loaderBundle) && angular.isDefined(self.loadLimit)) {
                  return PublisherReportService.getLoaderRecordsReport(self.publisher.short_code, self.loaderBundle, self.loadLimit)
                    .then(function (data) {
                      self.gridOptions.data = data.rows;
                      self.reportMetadata = data.metadata;
                    })
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
                } else {
                  self.gridOptions.data = []; //clear grid
                }
              }
  
              function getLoaderRecordsReportCsvUrl() {
                return PublisherReportService.getLoaderRecordsReportCsvUrl(self.publisher.short_code, self.loaderBundle, self.loadLimit);
              }
  
              // if the current cell is > 0, return danger colour, otherwise return a colour based on the loader status
              function getCellDangerClassForNonZero(grid, row, col) {
                if (grid.getCellValue(row,col) > 0)
                {
                  return "text-danger";
                } else {
                  return getCellClassForLoaderStatus(grid, row, col);
                }
              }
  
              // return a cell class based on the loader status
              function getCellClassForLoaderStatus(grid, row, col) {
                var textClass = undefined;
                switch (row.entity.loader_status.toLowerCase()) {
                  case "pending":
                    textClass = "text-muted";
                    break;
                  case "processing":
                    textClass = "text-primary";
                    break;
                  case "retry":
                    textClass = "text-warning";
                    break;
                  case "failure":
                  case "partial failure":
                  case "rejected":
                    textClass = "text-danger";
                    break;
                  case "success":
                    textClass = "text-success";
                    break;
                  default:
                    textClass = "text-info";
                    break;
                }
                return textClass;
              }
  
            }
          }
        }]
      );
  } (window.angular));
  
  ; (function (angular) {
    "use strict";
  
    angular
      .module('kudosPublishers')
      .directive('loaderRecordLogs', ['PublisherReportService', '$state',
        function (PublisherReportService, $state) {
          return {
            restrict: 'E',
            scope: {},
            bindToController: {
              publisher: '=',
              loaderRecord: '='
            },
  
            templateUrl: 'kudosPublishers/directives/loaderRecordLogs.directive.html',
            controllerAs: 'ctrl',
  
            controller: function () {
              var self = this;
              self.logs = [];
              self.getMessageTypeIcon = getMessageTypeIcon;
              self.getLoaderRecordLogsCsvUrl = getLoaderRecordLogsCsvUrl;
              self.$onInit = init;
  
              function init() {
                getLoaderRecordLogs(self.publisher, self.loaderRecord);
              }
  
              function getMessageTypeIcon(messageType) {
                switch (messageType) {
                  case "Debug":
                    return '<i class="fa fa-stethoscope fa-lg text-muted" aria-hidden="true" title="Debug message"></i>';
                  case "Info":
                    return '<i class="fa fa-info-circle fa-lg text-info" aria-hidden="true" title="Informational message"></i>';
                  case "Warn":
                    return '<i class="fa fa-exclamation-triangle fa-lg text-warning" aria-hidden="true" title="Warning message"></i>';
                  case "Error":
                    return '<i class="fa fa-exclamation-circle fa-lg text-danger" aria-hidden="true" title="Error message"></i>';
                  case "Fatal":
                    return '<i class="fa fa-frown-o fa-lg text-danger" aria-hidden="true" title="Fatal error message"></i>';
                  default:
                    return messageType;
                }
              }
  
              function getLoaderRecordLogs(publisher, loaderRecord) {
                if (angular.isDefined(loaderRecord) && angular.isDefined(publisher)) {
                  return PublisherReportService.getLoaderRecordLogsReport(publisher.short_code, loaderRecord)
                    .then(function (data) {
                      self.logs = data.rows;
                    })
                    .catch(function () {
                      $state.go('error', {}, {errorCode: 500});
                    });
                }
              }
  
              function getLoaderRecordLogsCsvUrl() {
                if (angular.isDefined(self.loaderRecord) && angular.isDefined(self.publisher)) {
                  return PublisherReportService.getLoaderRecordLogsReportCsvUrl(self.publisher.short_code, self.loaderRecord);
                }
                return undefined;
              }
  
            }
          }
        }
      ]);
  })(window.angular);
  
  (function (angular, _) {
    'use strict';
  
    angular.module('kudosPublishers').component('kudosSharesByChannelPanel', {
      templateUrl:
        'kudosPublishers/components/kudosSharesByChannelPanel.component.html',
      controllerAs: 'vm',
      bindings: {
        data: '<',
      },
      controller: function () {
        var self = this;
  
        self.$onInit = function () {
          self.clicksData = {
            link: getShares(self.data, 'link', 'non_pdf'),
            twitter: getShares(self.data, 'twitter', 'non_pdf'),
            linkedin: getShares(self.data, 'linkedin', 'non_pdf'),
            facebook: getShares(self.data, 'facebook', 'non_pdf'),
            weibo: getShares(self.data, 'weibo', 'non_pdf'),
            wechat: getShares(self.data, 'wechat', 'non_pdf'),
            mastodon: getShares(self.data, 'mastodon', 'non_pdf'),
            bluesky: getShares(self.data, 'bluesky', 'non_pdf'),
            whatsapp: getShares(self.data, 'whatsapp', 'non_pdf'),
          };
          self.avgClicksData = {
            link: getAvgClicks(self.data, 'link', 'non_pdf'),
            twitter: getAvgClicks(self.data, 'twitter', 'non_pdf'),
            linkedin: getAvgClicks(self.data, 'linkedin', 'non_pdf'),
            facebook: getAvgClicks(self.data, 'facebook', 'non_pdf'),
            weibo: getAvgClicks(self.data, 'weibo', 'non_pdf'),
            wechat: getAvgClicks(self.data, 'wechat', 'non_pdf'),
            mastodon: getAvgClicks(self.data, 'mastodon', 'non_pdf'),
            bluesky: getAvgClicks(self.data, 'bluesky', 'non_pdf'),
            whatsapp: getAvgClicks(self.data, 'whatsapp', 'non_pdf'),
          };
          if (hasSPfd()) {
            self.pdfClicksData = {
              research_gate: getShares(self.data, 'research_gate', 'pdf'),
              academia_edu: getShares(self.data, 'academia_edu', 'pdf'),
              mendeley: getShares(self.data, 'mendeley', 'pdf'),
              other: getShares(self.data, 'other', 'pdf'),
            };
            self.pdfAvgClicksData = {
              research_gate: getAvgClicks(self.data, 'research_gate', 'pdf'),
              academia_edu: getAvgClicks(self.data, 'academia_edu', 'pdf'),
              mendeley: getAvgClicks(self.data, 'mendeley', 'pdf'),
              other: getAvgClicks(self.data, 'other', 'pdf'),
            };
  
            self.clickMaxCount = Math.max.apply(
              null,
              _.values(angular.merge({}, self.clicksData, self.pdfClicksData))
            );
            self.avgMaxCount = Math.max.apply(
              null,
              _.values(
                angular.merge({}, self.avgClicksData, self.pdfAvgClicksData)
              )
            );
          } else {
            self.pdfClicksData = {
              research_gate: '?',
              academia_edu: '?',
              mendeley: '?',
              other: '?',
            };
            self.pdfAvgClicksData = {
              research_gate: '?',
              academia_edu: '?',
              mendeley: '?',
              other: '?',
            };
          }
        };
  
        function hasSPfd() {
          return self.data.hasOwnProperty('pdf');
        }
  
        self.showSPdfUpSell = function () {
          return !hasSPfd();
        };
  
        function getShares(data, key, type) {
          return (
            (data && data[type] && data[type][key] && data[type][key].shares) || 0
          );
        }
  
        function getAvgClicks(data, key, type) {
          var shares = getShares(data, key, type);
          var clicks =
            (data && data[type] && data[type][key] && data[type][key].clicks) ||
            0;
          var avgClicks = parseFloat((clicks / shares).toFixed(2));
          return isFinite(avgClicks) ? avgClicks : 0;
        }
      },
    });
  })(window.angular, window._);
  
  (function (angular, _) {
    'use strict';
  
    angular.module('kudosPublishers').component('kudosChannelGroup', {
      templateUrl: 'kudosPublishers/components/kudosChannelGroup.component.html',
      bindings: {
        data: '<',
        maxCount: '=',
        showLabel: '=',
      },
      controller: function () {
        var self = this;
  
        self.$onInit = function () {
          // TODO: Consider moving max/min width to bindings and make it 'input argument'
          var maxWidth = 70;
          var minWidth = 25;
          self.channels = self._statsToChannels(
            self.data,
            maxWidth,
            minWidth,
            self.maxCount
          );
        };
  
        self._statsToChannels = function (stats, maxWidth, minWidth, maxCount) {
          var result = [];
  
          Object.keys(stats).forEach(function (key) {
            var size = null,
              count = null,
              backgroundClass = null;
  
            if (stats[key] === '?') {
              size = 50;
              count = '?';
              backgroundClass = getAttribute(
                'channelInactive',
                'backgroundClass'
              );
            } else if (stats[key] === 0) {
              size = minWidth;
              count = '-';
              backgroundClass = getAttribute(key, 'backgroundClass');
            } else {
              var maxValue = maxCount || Math.max.apply(null, _.values(stats));
              size = Math.sqrt(stats[key] / maxValue) * maxWidth;
              if (size < minWidth) {
                size = minWidth;
              }
              count = stats[key];
              backgroundClass = getAttribute(key, 'backgroundClass');
            }
  
            var margin = (maxWidth - size) / 2;
            size = size.toFixed(2) + 'px';
            margin = margin.toFixed(2) + 'px';
            result.push({
              label: getAttribute(key, 'label'),
              class: getAttribute(key, 'iconClass') + ' ' + backgroundClass,
              count: count,
              style: {
                height: size,
                width: size,
                lineHeight: size,
                margin: margin,
              },
            });
          });
  
          return result;
        };
  
        function getAttribute(key, attribute) {
          var translations = {
            link: {
              label: 'Link',
              iconClass: 'share-icon fa fa-link',
              backgroundClass: 'link-background-color',
            },
            twitter: {
              label: 'X',
              iconClass: 'share-icon fa-brands fa-x-twitter',
              backgroundClass: 'twitter-background-color',
            },
            linkedin: {
              label: 'LinkedIn',
              iconClass: 'share-icon fa fa-linkedin',
              backgroundClass: 'linkedin-background-color',
            },
            facebook: {
              label: 'Facebook',
              iconClass: 'share-icon fa fa-facebook',
              backgroundClass: 'facebook-background-color',
            },
            wechat: {
              label: 'WeChat',
              iconClass: 'share-icon fa fa-wechat',
              backgroundClass: 'wechat-background-color',
            },
            weibo: {
              label: 'Weibo',
              iconClass: 'share-icon fa fa-weibo',
              backgroundClass: 'weibo-background-color',
            },
            mastodon: {
              label: 'Mastodon',
              iconClass: 'share-icon fa-brands fa-mastodon',
              backgroundClass: 'mastodon-background-color',
            },
            bluesky: {
              label: 'Bluesky',
              iconClass: 'share-icon fa-brands fa-bluesky',
              backgroundClass: 'bluesky-background-color',
            },
            whatsapp: {
              label: 'WhatsApp',
              iconClass: 'share-icon fa-brands fa-whatsapp',
              backgroundClass: 'whatsapp-background-color',
            },
  
            research_gate: {
              label: 'ResearchGate',
              iconClass: 'share-icon ai ai-researchgate',
              backgroundClass: 'researchgate-background-color',
            },
            academia_edu: {
              label: 'Academia',
              iconClass: 'share-icon ai ai-academia ',
              backgroundClass: 'academia-background-color',
            },
            mendeley: {
              label: 'Mendeley',
              iconClass: 'share-icon ai ai-mendeley',
              backgroundClass: 'mendeley-background-color',
            },
            channelInactive: {
              label: 'Other',
              iconClass: 'share-icon fa fa-file-pdf-o',
              backgroundClass: 'channel-inactive-background-color',
            },
            other: {
              label: 'Other',
              iconClass: 'share-icon fa fa-file-pdf-o',
              backgroundClass: 'other-background-color',
            },
          };
  
          return translations[key] && translations[key][attribute];
        }
      },
    });
  })(window.angular, window._);
  

} (window.angular));

(function (angular) {

  "use strict";

  angular.module('kudosResources', ['kudos']);
  ; (function (angular) {
  
    'use strict';
  
    angular.module('kudosResources')
      .factory("ResourceService", [
        "$location",
        "$http",
        function ($location, $http) {
  
          function getResourceTypesFromResponse (response, callback) {
            if (callback !== undefined) {
              callback(response.data.resource_types);
            }
  
            return response.data.resource_types;
          }
  
          function getResourceFromResponse (response, callback) {
            if (callback !== undefined) {
              callback(response.data.resource);
            }
  
            return response.data.resource;
          }
  
          var apiBaseUrl = "/internal_api/resources";  // the PUT is handled in the web app, not API - due to CORS problems
  
          var pub = {
            create: function (newResource, callback) {
              return $http.post(apiBaseUrl, {resource: newResource})
                .then(function (response) {
                  return getResourceFromResponse(response, callback);
                });
            },
  
            get: function (resourceId, callback) {
              return $http.get(apiBaseUrl + '/' + resourceId)
                .then(function (response) {
                  return getResourceFromResponse(response, callback);
                });
            },
  
            update: function (resource, callback) {
              return $http.put(apiBaseUrl + '/' +  resource.id, {resource: resource})
                .then(function (response) {
                  return getResourceFromResponse(response, callback);
                });
            },
  
            delete: function (resourceId, callback) {
              return $http.delete(apiBaseUrl + '/' + resourceId)
                .then(function (response) {
                  return getResourceFromResponse(response, callback);
                });
            },
  
            // FIXME this should cache the resource types so we don't do an API call each time.
            getResourceTypes: function () {
              return $http.get(apiBaseUrl + '/types', {cache: true}).then(getResourceTypesFromResponse);
            },
  
            setApiBaseUrl: function (newApiBaseUrl){
              apiBaseUrl = newApiBaseUrl;
            },
  
            getApiBaseUrl: function (){
              return apiBaseUrl;
            }
          };
  
          return pub;
      }
    ]);
  
  }(window.angular));
  

}(window.angular));

(function (angular) {
  "use strict";

  angular.module("sessions", [
    "ui.router",
    "kudosNotifications",
    "ui.bootstrap",
    "ngCookies",
    "templates",
  ]);
  ; (function (angular) {
  
    'use strict';
  
    angular.module('sessions')
      .directive('termsConditionsSummaryModal', [
        '$uibModal',
        function ($uibModal) {
          return {
            link: function (scope, element) {
              element.on('click', function (event) {
                event.preventDefault();
                event.stopImmediatePropagation();
  
                showTermsAndConditionsModal();
              });
  
              function showTermsAndConditionsModal () {
                var modalInstance;
                var modalScope = scope.$new();
  
                modalScope.cancel = function () {
                  modalInstance.dismiss('cancel');
                };
  
                modalInstance = $uibModal.open({
                  templateUrl: 'kudosSessions/termsConditionsSummaryModal.directive.html',
                  scope: modalScope
                });
              }
            }
          };
        }
    ]);
  
  }(window.angular));
  
  
  (function (angular, _) {
    "use strict";
    angular.module("sessions").factory("SessionService", [
      "$http",
      "$location",
      "$window",
      "$q",
      "$rootScope",
      "NotificationService",
      "$cookies",
      function (
        $http,
        $location,
        $window,
        $q,
        $rootScope,
        NotificationService,
        $cookies
      ) {
        // local variables
        var locals = {
          currentUserObject: {},
        };
  
        var sessionService = {
          CURRENT_USER_UPDATE_EVENT_NAME: "currentUserUpdate",
  
          firstTime: false,
  
          showLoginForm: showLoginForm,
  
          signOut: function () {
            $http
              .delete("/sessions/destroy", { params: { do_not_redirect: true } })
              .then(function () {
                $window.location.href = "/authentication";
              })
              .catch(function () {
                NotificationService.error(
                  "There was an error signing out, please try again"
                );
              });
          },
  
          getSession: function () {
            var growkudosSessionCheck = "growkudosSessionCheck";
  
            return $q(function (resolve, reject) {
              if (angular.isDefined($cookies.getObject(growkudosSessionCheck))) {
                // user has a growkudosSessionCheck cookie, so *probably* has a growkudos session
                resolve(
                  $http
                    .get("/internal_api/sessions/")
                    .then(function (response) {
                      var current_user = response.data.data.current_user;
                      if (current_user) {
                        sessionService.currentUser(current_user);
                        sessionService.userIsVerified(!!current_user.confirmed);
                        sessionService.userIsAdmin(!!current_user.admin);
                        $cookies.put(growkudosSessionCheck, 1);
                      } else {
                        $cookies.remove(growkudosSessionCheck);
                      }
                      return current_user;
                    })
                    .catch(function (response) {
                      $cookies.remove(growkudosSessionCheck);
                      return response.data;
                    })
                );
              } else {
                // user does not have a growkudosSessionCheck cookie, so no need to call /internal_api/sessions/
                reject({});
              }
            });
          },
  
          currentUser: function (user) {
            if (user === undefined) {
              return locals.currentUserObject || {};
            } else {
              locals.currentUserObject = user;
            }
  
            $rootScope.$broadcast(this.CURRENT_USER_UPDATE_EVENT_NAME, user);
          },
  
          userIsVerified: function () {
            return (
              locals.currentUserObject !== undefined &&
              !!locals.currentUserObject.confirmed
            );
          },
  
          userIsAdmin: function () {
            return (
              locals.currentUserObject !== undefined &&
              !!locals.currentUserObject.admin
            );
          },
  
          userIsAdminFor: function (user, shortCode) {
            if (user === undefined) {
              return false;
            }
            var isAdmin = false;
  
            if (angular.isDefined(user.institutions_administered)) {
              _.each(user.institutions_administered, function (institution) {
                if (institution.shortCode === shortCode) {
                  isAdmin = true;
                }
              });
            }
  
            if (angular.isDefined(user.publishers_administered)) {
              _.each(user.publishers_administered, function (publisher) {
                if (publisher.short_code === shortCode) {
                  isAdmin = true;
                }
              });
            }
            return isAdmin;
          },
  
          userIsLoggedIn: function () {
            return (
              locals.currentUserObject !== undefined &&
              Object.keys(locals.currentUserObject).length !== 0
            );
          },
  
          resendConfirmationEmail: function () {
            return $http
              .post("/internal_api/accounts/resend_confirmation")
              .then(function () {
                NotificationService.success("Confirmation Link Sent.");
              })
              .catch(function () {
                NotificationService.error("Could not send confirmation email.");
              });
          },
  
          userIsAuthorFor: function (doi) {
            var user = this.currentUser();
  
            if (angular.isUndefined(user)) {
              return false;
            }
  
            return angular.isDefined(_.find(user.articles, { id: doi }));
          },
        };
  
        return sessionService;
  
        function showLoginForm() {
          var loginUrl =
            "/sessions/new?goto=" + encodeURIComponent($window.location.href);
          $window.location.href = loginUrl;
        }
      },
    ]);
  })(window.angular, window._);
  
})(window.angular);

(function(angular) {
  'use strict';

  angular.module("showcase", ['ngAnimate']);
  (function(angular) {
    'use strict';
  
    angular.module("showcase").config(["$stateProvider", function($stateProvider) {
      $stateProvider
        .state('angular_component_showcase', {
          url: '/angular_component_showcase',
          abstract: true,
          templateUrl: "showcase/showcase.html",
          controller: "ShowcaseController"
        })
        .state('angular_component_showcase.universal', {
          url: '',
          templateUrl: "showcase/showcase.universal.html"
        })
        .state('angular_component_showcase.publication', {
          url: '/publication',
          templateUrl: "showcase/showcase.publication.html"
        })
        .state('angular_component_showcase.edit_in_place_options', {
          url: '/edit_in_place_options',
          templateUrl: "showcase/showcase.edit_in_place_options.html"
        })
        .state('angular_component_showcase.charts', {
          url: '/charts',
          templateUrl: "showcase/showcase.charts.html"
        })
        .state('angular_component_showcase.cards', {
          url: '/cards',
          templateUrl: 'showcase/showcase.cards.html',
          controller: 'ShowcaseCardController',
          controllerAs: 'vm',
          resolve: {
            publication: [
              'PublicationService',
              function (PublicationService) {
                return PublicationService.getPublication('10.1087/20120103')
                  .then(function (response) {
                    return response.data.data.article;
                  })
                  .catch(function (response) {
                    return;
                  });
              }
            ],
            organisation: [
              function () {
                return {
                  short_code: 'test'
                };
              }
            ],
          }
        });
    }]);
  }(window.angular));
  
  (function(angular) {
    'use strict';
  
    angular
      .module("showcase")
      .controller("ShowcaseController",
          ["$scope", "$http", "$location", "PublicationService", "NotificationService",
          function ($scope, $http, $location, PublicationService, NotificationService) {
  
        $scope.NotificationService = NotificationService;
  
        $scope.kudosEditableText = {
          text: 'test text',
          isEditable: true,
          editEvent: function(newText) { alert("Text changed to '" + newText + "'"); }
        };
  
          $scope.kudosPublicationOfficialMetadata = {};
  
          $scope.kudosResourceList = {
              editable: true,
              publication: {
                  resources: [{
                      id: 12,
                      title: "Rosetta ESA page",
                      url: "http://sci.esa.int/rosetta/",
                      description: "The ESA's page on Rosetta, including links to news and updates."
                  },{
                      id:223,
                      title: "Rosetta Mission: can you land on a comet?",
                      url: "http://www.bbc.co.uk/news/science-environment-29746430",
                      description: "The European Space Agency has made history by successfully landing a robot on a comet in deep space. Can you put the Philae lander in the right spot on a spinning comet in this interactive game? In real life, the landing took about seven hours, so we've sped things up for you. For a more in-depth look at the comet, the lander and the mission, read on below the game."
                }]
              },
  
              create: function(resource) { alert("Resource created: " + JSON.stringify( resource )); },
              update: function(resource) { alert("An update occurred: " + JSON.stringify( resource )); },
              delete: function(id) { alert("Resource " + id + " has been deleted"); },
              resourceTypes: [
                { "name": "Type 1", "type": "resource_type_1" },
                { "name": "Type 2", "type": "resource_type_2"}
              ],
              publicationDoi: "1234/doi"
          };
  
          $scope.kudosResource = {
              editable: true,
              beingEdited: false,
              resource: {
                  id: 12,
                  title: "Rosetta ESA page",
                  url: "http://sci.esa.int/rosetta/",
                  description: "The ESA's page on Rosetta, including links to news and updates."
              },
              editEvent: function() { alert("An edit event happened, new value: " + JSON.stringify( $scope.kudosResource.resource )); },
              deleteEvent: function() { alert("Resource delete event occurred"); },
              resourceTypes: [
                { "name": "Type 1", "type": "resource_type_1" },
                { "name": "Type 2", "type": "resource_type_2"}
              ]
          };
  
  
          $scope.editableTextareaToggled = {
              editable: true,
              textToEdit: "Here's some sample text",
              editEvent: function(newValue) { alert("An edit event happened, new value: " + newValue); }
          };
  
          //this is a stub function for the Kudos official publication metadata directive
          $scope.recordReadEvent = function(){};
  
          $scope.init = function() {
  
              PublicationService.getPublication('10.1087/20120103')
                  .then(function(response) {
                      $scope.kudosPublicationOfficialMetadata.publication = response.data.data.article;
                      //$scope.editableResourceToggled.resource = publication.resources[0];
                  })
                  .catch(function(response) {
                      console.error(response.status);
                  });
          };
  
          // edit_in_place_options
          $scope.eip = {
            makeEditable: function (event) {
              angular.element(event.target).attr('contenteditable', true);
              angular.element(event.target).focus();
            },
            toggleEditWithIcon: function () {
              angular.element('#toggleEditWithIcon').attr('contenteditable', true);
              angular.element('#toggleEditWithIcon').focus();
            },
            account: {
              display_name: 'Miss Jessica S Trumbleton',
              title: 'Miss',
              name: 'Jessica',
              middle_name: 'Susan',
              surname: 'Trumbleton'
            }
          };
  
          $scope.reportChartOptions = {
            metadata: {
              xAxis: {
                axisLabel: "X Axis Label"
              },
              yAxis: {
                axisLabel: "Y Axis Label"
              },
            },
            datasets: [
              {
                x: {
                  name: "date",
                  label: "Date"
                },
                y: {
                  name: "number",
                  label: "Number One"
                },
                data: [
                  {
                    x: Date.parse('9 Jun 2015'),
                    y: 1
                  },
                  {
                    x: Date.parse('15 Jun 2015'),
                    y: 4
                  },
                  {
                    x: Date.parse('18 Jun 2015'),
                    y: 5
                  },
                  {
                    x: Date.parse('25 Jun 2015'),
                    y: 9
                  },
                  {
                    x: Date.parse('26 Jun 2015'),
                    y: 12
                  }
                ]
              },
              {
                x: {
                  name: "date",
                  label: "Date"
                },
                y: {
                  name: "number",
                  label: "Number Two"
                },
                data: [
                  {
                    x: Date.parse('9 Jun 2015'),
                    y: 4
                  },
                  {
                    x: Date.parse('15 Jun 2015'),
                    y: 6
                  },
                  {
                    x: Date.parse('18 Jun 2015'),
                    y: 7
                  },
                  {
                    x: Date.parse('25 Jun 2015'),
                    y: 13
                  },
                  {
                    x: Date.parse('26 Jun 2015'),
                    y: 14
                  }
                ]
              },
              {
                x: {
                  name: "date",
                  label: "Date"
                },
                y: {
                  name: "number",
                  label: "Number Three"
                },
                data: [
                  {
                    x: Date.parse('9 Jun 2015'),
                    y: 3
                  },
                  {
                    x: Date.parse('15 Jun 2015'),
                    y: 5
                  },
                  {
                    x: Date.parse('18 Jun 2015'),
                    y: 9
                  },
                  {
                    x: Date.parse('25 Jun 2015'),
                    y: 11
                  },
                  {
                    x: Date.parse('26 Jun 2015'),
                    y: 17
                  }
                ]
              }
            ]
          };
      }]);
  }(window.angular));
  
  (function(angular) {
    'use strict';
  
    angular
      .module('showcase')
      .controller('ShowcaseCardController', [
        'publication',
        'organisation',
        '$timeout',
        function (publication, organisation, $timeout) {
          var self = this;
  
          self.publication = publication;
          self.organisation = organisation;
        }
      ]);
  
  }(window.angular));
  

}(window.angular));

; (function (angular) {
  'use strict';

  angular.module( 'kudosPageTracker', []);
  ; (function (angular) {
    'use strict';
  
    angular.module( 'kudosPageTracker')
      .factory(
        'PageTrackerService',
        [
          '$http',
          function ($http) {
            return {
              trackPageView: function (url) {
                return $http.post(
                  '/internal_api/page_trackers/track',
                  {
                    url: url
                  }
                );
              }
            };
          }
        ]
      );
  
  }(window.angular));
  
  

}(window.angular));


;(function (angular) {
  'use strict';

  angular.module('trendmd', []);
  ; (function (angular, trendmd_widget, trendmd_journal_id, trendmd_track_id) {
    // dependency injection of window.x variables doesn't seem to work
    // so using the vars directly, which does work (check XHR reqs being made)
  
    "use strict";
  
    angular
      .module('trendmd')
      .directive('trendmd', [function () {
        return {
          templateUrl: 'trendmd/trendmd.html',
          link: function (scope, element) {
            scope.trendmd_div = element.find('#trendmd-suggestions');
            if(trendmd_widget){
              trendmd_widget.register({journal_id: trendmd_journal_id, element: '#trendmd-suggestions', track_id: trendmd_track_id});  // this definitely sends a request to TrendMD, but div stays empty
            }
          },
  
          controllerAs: 'vm',
          controller: function() {
  
          }
        };
      }]);
  }(window.angular, window.TrendMD, window.TRENDMD_JOURNAL_ID, window.TRENDMD_TRACK_ID));
  

}(window.angular));

; (function (angular) {
  'use strict';

  angular.module('kudosCharts', ['nvd3', 'templates']);
  ; (function (angular) {
  
    'use strict';
  
    angular.module("kudosCharts")
      .directive("kudosMiniLineChart", function () {
        return {
          scope: {
            data: '=',
            metadata: '='
          },
  
          template:'<nvd3 options="options" data="data"></nvd3>',
  
          link: function (scope) {
  
            init();
  
            function init () {
              scope.options = getChartOptions();
            }
  
            function getChartOptions () {
              return {
                chart: {
                  type: 'lineChart',
                  height: 225,
                  showLegend: false,
                  margin: {
                    top: 10,
                    bottom: 30,
                    left: 40,
                    right: 10
                  },
                  //x-scaling function
                  x: function(data) {
                    return data.x;
                  },
                  //y-scaling function
                  y: function(data) {
                    return data.y;
                  },
  
                  xScale: d3.time.scale(),
  
                  xAxis: angular.extend(
                    {},
                    { //default x-axis options
                      tickFormat: function(data) {
                        return d3.time.format('%b')(new Date(data));
                      },
                      showMaxMin: false,
                      ticks: 6,
                      staggerLabels: false
                    },
                    (scope.metadata.xAxisLabel) || {}
                  ),
  
                  yAxis: angular.extend(
                    {},
                    { //default y-axis options
                      tickFormat: function(data){
                        return d3.format(',.0f')(data); // Seperates thousands with commas with no decimal points
                      },
                      axisLabelDistance: 20
                    },
                    (scope.metadata.yAxisLabel) || {}
                  )
                },
                title: {
                  enable: true,
                  text: scope.metadata.title,
                  className: '',
                  css: {
                    textAlign: 'center'
                  }
                }
              };
            }
  
          }
        };
      }
    );
  }(window.angular));
  
  
  ; (function (angular) {
  
    'use strict';
  
    angular.module("kudosCharts")
      .directive("kudosMiniBarChart", function () {
        return {
          scope: {
            data: '=',
            metadata: '='
          },
  
          template:'<nvd3 options="options" data="data"></nvd3>',
  
          link: function (scope) {
  
            init();
  
            function init () {
              scope.options = getChartOptions();
            }
  
            function getChartOptions () {
              return {
                chart: {
                  type: 'multiBarChart',
                  height: 225,
                  showLegend: false,
                  showControls: false,
                  reduceXTicks: false,
                  forceY: [0,1],
                  margin: {
                    top: 10,
                    bottom: 30,
                    left: 40,
                    right: 10
                  },
                  //x-scaling function
                  x: function(data) {
                    return data.x;
                  },
                  //y-scaling function
                  y: function(data) {
                    return data.y;
                  },
  
                  xAxis: angular.extend(
                    {},
                    { //default x-axis options
                      tickFormat: function(data) {
                        return d3.time.format('%b')(new Date(data))
                      },
                      showMaxMin: false,
                      staggerLabels: false
                    },
                    (scope.metadata.xAxisLabel) || {}
                  ),
  
                  yAxis: angular.extend(
                    {},
                    { //default y-axis options
                      tickFormat: function(data){
                        return d3.format(',.0d')(data); // no decimal points
                      },
                      axisLabelDistance: 20
                    },
                    (scope.metadata.yAxisLabel) || {}
                  )
                },
                title: {
                  enable: true,
                  text: scope.metadata.title,
                  className: '',
                  css: {
                    textAlign: 'center'
                  }
                }
              };
            }
  
          }
        };
      }
    );
  }(window.angular));
  
  
  ; (function (angular, _) {
  
    'use strict';
  
    angular.module("kudosCharts")
      .directive("kudosLineChart", function () {
        return {
          scope: {
            reportChartOptions: '='
          },
  
          template:'<nvd3 options="options" data="data"></nvd3>',
  
          link: function (scope) {
  
            scope.options = {
              chart: {
                type: 'lineChart',
                height: 450,
                margin : {
                  top: 20,
                  right: 20,
                  bottom: 60,
                  left: 65
                },
                //x-scaling function
                x: function(data) {
                  return data.x;
                },
                //y-scaling function
                y: function(data) {
                  return data.y;
                },
  
                xAxis: angular.extend(
                  {},
                  { //default x-axis options
                    axisLabel: 'Date',
                    tickFormat: function(data) {
                      return d3.time.format('%d %b %y')(new Date(data))
                    },
                    showMaxMin: false,
                    staggerLabels: true
                  },
                  (scope.reportChartOptions.metadata.xAxis) || {}
                ),
  
                yAxis: angular.extend(
                  {},
                  { //default y-axis options
                    axisLabel: 'Y Axis',
                    tickFormat: function(data){
                      return d3.format(',')(data);
                    },
                    axisLabelDistance: 20
                  },
                  (scope.reportChartOptions.metadata.yAxis) || {}
                )
              }
            };
  
            scope.data = _.map(scope.reportChartOptions.datasets, function (dataSet) {
              return {
                key: dataSet.y.label,
                values: dataSet.data
              }
            });
  
          }
        };
      }
    );
  }(window.angular, window._));
  
  
  ; (function (angular) {
  
    'use strict';
  
    angular.module("kudosCharts")
      .directive("kudosMonthlyCountLineChart", [
        'queryChartService',
        function (queryChartService) {
          return {
            scope: {
              query: '=',
              title: '@'
            },
  
            template:'<kudos-mini-bar-chart ng-if="queryData.length" data="queryData" metadata="queryMetadata"></kudos-mini-bar-chart>',
  
            link: function (scope) {
  
              init();
  
              function init () {
                scope.queryData = queryChartService.massageData(scope.query, 6);
                scope.queryMetadata = queryChartService.massageMetadata(scope.query, scope.title);
              }
            }
          };
        }
      ]);
  
  }(window.angular));
  
  ; (function (angular) {
  
    'use strict';
  
    angular.module("kudosCharts")
      .directive("kudosCumulativeMonthlyCountLineChart", [
        'queryChartService',
        function (queryChartService) {
          return {
            scope: {
              query: '=',
              title: '@'
            },
  
            template:'<kudos-mini-line-chart ng-if="queryData.length" data="queryData" metadata="queryMetadata"></kudos-mini-line-chart>',
  
            link: function (scope) {
  
              init();
  
              function init () {
                scope.queryData = queryChartService.massageData(scope.query, 6, true);
                scope.queryMetadata = queryChartService.massageMetadata(scope.query, scope.title);
              }
            }
          };
        }
      ]);
  
  }(window.angular));
  
  ; (function (angular, _) {
    'use strict';
  
    angular
      .module('kudosCharts')
        .factory(
          'queryChartService',
          [
            function () {
              return {
                massageData: massageData,
                cumulateQueryResults: cumulateQueryResults,
                massageMetadata: massageMetadata
              };
  
              function massageData (query, numberOfDays, cumulate) {
                var queryResults = query.results;
  
                if (!!cumulate) {
                  queryResults = cumulateQueryResults(query.results);
                }
  
                var lastSixResults = _.takeRight(queryResults, 6);
  
                var queryResults = _.map(lastSixResults, function (result) {
                  return {
                    series: 0,
                    x: (new Date(result.year + '/' + result.month + '/1')).getTime(),
                    y: result.count
                  };
                });
  
                return [
                  {
                    key: query.metadata.name,
                    values: queryResults
                  }
                ];
              }
  
              function cumulateQueryResults (queryResults) {
                var cumulativeCount = 0;
  
                return _.map(queryResults, function (queryResult) {
                  // For some reason, queryResult is a reference, and by
                  // cumulating here, it affects everything else bound to
                  // this query dataset. So we clone it so the cumulation
                  // is only reflected in this directive.
                  queryResult = _.clone(queryResult);
  
                  queryResult.count += cumulativeCount;
  
                  cumulativeCount = queryResult.count;
  
                  return queryResult;
                });
              }
  
              function massageMetadata(query, title) {
                return {
                  xAxisLabel: query.metadata.name,
                  yAxisLabel: '',
                  title: title
                };
              }
  
            }
          ]
        );
  
  }(window.angular, window._));
  
  
  ; (function (angular) {
  
    'use strict';
  
    angular.module('kudosCharts')
      .directive(
        'kudosPercentageCircle',
        [
          'kudosPercentageCircleService',
          function (kudosPercentageCircleService) {
            return {
              scope: {
                percent: '='
              },
              link: function (scope, element) {
                var service = kudosPercentageCircleService.new(element);
                // Watch for changes on the scope, and update
                // percentage in real time.
                scope.$watch('percent', function (newPercentage) {
                  service.update(newPercentage);
                });
  
                // Set initial percentage value.
                service.update(scope.percent);
              }
            };
          }
        ]
      );
  }(window.angular));
  
  ; (function (angular, d3, _) {
    'use strict';
  
    angular
      .module('kudosCharts')
        .factory(
          'kudosPercentageCircleService',
          [
            function () {
              return {
                new: function (element) {
  
                  var self = {};
  
                  self.update = update;
  
                  self._arcTween                    = _arcTween;
                  self._colorTween                  = _colorTween;
                  self._getTargetColorForPercentage = _getTargetColorForPercentage;
                  self._createBackground            = _createBackground;
                  self._createForeground            = _createForeground;
                  self._createText                  = _createText;
                  self._createSVG                   = _createSVG;
                  self._createArc                   = _createArc;
                  self._withProportions             = _withProportions;
                  self._getProportions              = _getProportions;
                  self._sanitizePercentage          = _sanitizePercentage;
                  self._transitionPercentageCircle  = _transitionPercentageCircle;
  
                  init(element);
  
                  return self;
  
                  function init (element) {
                    self.element    = element;
                    self.arc        = _createArc();
                    self.svg        = _createSVG();
                    self.text       = _createText();
                    self.background = _createBackground();
                    self.foreground = _createForeground();
                  }
  
                  function update (percentage) {
                    self._sanitizePercentage(percentage, self._transitionPercentageCircle);
                  }
  
                  function _transitionPercentageCircle (percentage) {
                    self.foreground
                        .transition()
                        .duration(750)
                        .call(self._arcTween, percentage)
                        .call(self._colorTween, 'fill', self._getTargetColorForPercentage(percentage));
  
                    self.background
                        .transition()
                        .duration(750)
                        .call(self._colorTween, 'stroke', self._getTargetColorForPercentage(percentage, 0.25));
                  }
  
                  function _sanitizePercentage (percentage, cb) {
                    if (_.isUndefined(percentage))        { return cb(0);   }
                    if (_.isNaN(parseInt(percentage)))    { return cb(0);   }
                    if (percentage < 0)                   { return cb(0);   }
                    if (percentage > 100)                 { return cb(100); }
  
                    return cb(parseInt(percentage, 10));
                  }
  
                  function _getProportions () {
                    var props = {};
  
                    props.width       = 130;
                    props.height      = 130;
  
                    props.padding     = 5;
                    props.guageWidth  = 6;
  
                    props.outerRadius = (Math.min(props.width, props.height) / 2) - (props.padding * 2);
                    props.innerRadius = (props.outerRadius - props.guageWidth);
  
                    props.fontSize    = (Math.min(props.width, props.height) / 4);
  
                    return props;
                  }
  
                  function _withProportions (cb) {
                    return cb(self._getProportions());
                  }
  
                  function _createArc () {
                    return self._withProportions(function (proportions) {
                      return d3
                        .svg
                        .arc()
                        .innerRadius(proportions.innerRadius)
                        .outerRadius(proportions.outerRadius)
                        .startAngle(0);
                    });
                  }
  
                  function _createSVG () {
                    return self._withProportions(function (proportions) {
                      return d3.select(self.element.get(0))
                               .append('svg')
                               .attr('width', '100%')
                               .attr('height', '100%')
                               .attr('viewBox','0 0 '+Math.min(proportions.width,proportions.height) +' '+Math.min(proportions.width,proportions.height) )
                               .attr('preserveAspectRatio','xMinYMin')
                               .append('g')
                               .attr('transform', 'translate(' + Math.min(proportions.width,proportions.height) / 2 + ',' + Math.min(proportions.width,proportions.height) / 2 + ')');
                    });
                  }
  
                  function _createText () {
                    return self._withProportions(function (proportions) {
                      // subtext
                      self.svg.append('text')
                              .text('complete')
                              .attr('class', 'text-complete')
                              .attr('text-anchor', 'middle')
                              .style('font-size', (proportions.fontSize /2)+'px')
                              .style('fill', '#8b8f8b')
                              .attr('dy', (proportions.fontSize * 0.75))
                              .attr('dx', 2);
  
                      return self.svg.append('text')
                                     .text('0%')
                                     .attr('class', 'text-percentage')
                                     .attr('text-anchor', 'middle')
                                     .style('font-size', proportions.fontSize+'px')
                                     .style('fill', '#8b8f8b')
                                     .attr('dy', proportions.fontSize/4)
                                     .attr('dx', 2);
                    });
                  }
  
                  function _createForeground () {
                    return self.svg.append('path')
                                   .datum({
                                     endAngle: 0
                                   })
                                   .style('fill', self._getTargetColorForPercentage(0))
                                   .attr('d', self.arc);
                  }
  
                  function _createBackground () {
                    return self._withProportions(function (proportions) {
                      return self.svg.append('circle')
                                     .attr('cx', 0)
                                     .attr('cy', 0)
                                     .attr('r', proportions.innerRadius + 3)
                                     .style('stroke', self._getTargetColorForPercentage(0, 0.25))
                                     .style('fill', 'none');
                    });
                  }
  
                  function _getTargetColorForPercentage (percentage, darken) {
                    var colorIndex  = (Math.floor(percentage / 10) - 1);
                    var darkenBy    = darken || 0;
  
                    // Ensure color index is always in bounds.
                    colorIndex      = (colorIndex < 0 ? 0 : colorIndex);
                    colorIndex      = (colorIndex > 9 ? 9 : colorIndex);
  
                    var targetColors = [
                      d3.rgb(229, 40, 34),  // <10%
                      d3.rgb(229, 82, 36),  // <20%
                      d3.rgb(226, 104, 38), // <30%
                      d3.rgb(224, 118, 38), // <40%
                      d3.rgb(239, 160, 20), // <50%
                      d3.rgb(248, 170, 18), // <60%
                      d3.rgb(250, 234, 37), // <70%
                      d3.rgb(170, 201, 17), // <80%
                      d3.rgb(118, 184, 42), // <90%
                      d3.rgb(105, 180, 45)  // <100%
                    ];
  
                    var targetColor = targetColors[colorIndex];
  
                    return targetColor.darker(darkenBy);
                  }
  
                  function _colorTween(transition, styleProperty, targetColor) {
                    transition.styleTween(styleProperty, function () {
                      return d3.interpolateRgb(this.style[styleProperty], targetColor);
                    });
                  }
  
                  function _arcTween(transition, percentage) {
                    transition.attrTween('d', function (d) {
                      var newAngle    = ((percentage / 100) * (2 * Math.PI));
                      var interpolate = d3.interpolate(d.endAngle, newAngle);
  
                      return function (tick) {
                        d.endAngle = interpolate(tick);
  
                        // Get the percentage mid tween to so that the number counts up/down to target.
                        var midTransitionPercentage = Math.round((d.endAngle/ (2 * Math.PI)) * 100);
  
                        self.text.text(midTransitionPercentage + '%');
  
                        return self.arc(d);
                      };
                    });
                  }
  
                }
              };
            }
          ]
        );
  }(window.angular, window.d3, window._));
  
  ; (function (angular) {
  
    'use strict';
  
    angular.module('kudosCharts')
      .directive(
        'kudosPublicationActivityChart',
        [
          '$http',
          '$timeout',
          'googleChartService',
          function ($http, $timeout, googleChartService) {
            return {
              scope: {
                publicationId: '@'
              },
              templateUrl: 'kudosCharts/kudosPublicationActivity.directive.html',
              link: function (scope, element) {
                scope._googleChartService = new googleChartService();
  
                scope.dateRangeForm = {
                  options: [
                    { value: '30',  label: '30 days'  },
                    { value: '90',  label: '90 days'  },
                    { value: '180', label: '180 days' },
                    { value: 'all', label: 'All time' },
                  ],
                  selectOption:   selectDateRangeFormOption,
                  getOptionClass: getDateRangeFormOptionClass,
                  getFormLabel:   getFormLabel,
                  selected:       '30'
                };
  
                scope.$watch('dateRangeForm.selected', handleDateRangeFormSelected);
  
                $timeout(init, 0);
  
                function init () {
                  scope._googleChartService.init(getElement(), getChartOptions(), getStatistics);
                }
  
                function handleDateRangeFormSelected (newValue, oldValue) {
                  if (angular.isUndefined(newValue))  { return; }
                  if (newValue === oldValue)          { return; }
  
                  scope._googleChartService.updateChart();
                }
  
                function selectDateRangeFormOption (option) {
                  scope.dateRangeForm.selected = option.value;
                }
  
                function getFormLabel () {
                  var verb = 'Show';
  
                  if (scope._googleChartService.loadingChartData) {
                    verb = 'Loading';
                  }
  
                  return verb + ' data for last:';
                }
  
                function getDateRangeFormOptionClass (option) {
                  if (option.value === scope.dateRangeForm.selected) {
                    return 'btn-primary';
                  }
  
                  return 'btn-default';
                }
  
                function getElement () {
                  return element.find('#chart-' + scope.publicationId)[0];
                }
  
                function getChartOptions () {
                  return {
                    height: '400',
                    width: angular.element(getElement()).width(),
                    chartArea: {
                      top: 20,
                      height: '65%',
                      width: '85%'
                    },
                    colors: [
                      '#f0733a',
                      '#7f9818',
                      '#3486be',
                      '#8234be'
                    ],
                    legend: {
                      position: 'none'
                    },
                    hAxis: {
                      slantedText: true,
                      slantedTextAngle: 45
                    },
                    vAxis: {
                      viewWindow: {
                        min: 0
                      }
                    },
                    tooltip: {
                      isHtml: true
                    }
                  };
                }
  
                function getStatistics () {
                  var id        = scope.publicationId;
                  var dateRange = scope.dateRangeForm.selected;
  
                  return $http.get('/article_metrics/statistics/' + id + '?date_range=' + dateRange);
                }
  
              }
            };
          }
        ]
      );
  
  }(window.angular));
  
  ; (function (angular) {
  
    'use strict';
  
    angular.module('kudosCharts')
      .directive(
        'kudosPublicationAltmetricsChart',
        [
          '$http',
          '$timeout',
          'googleChartService',
          function ($http, $timeout, googleChartService) {
            return {
              scope: {
                publicationId: '@',
                publicationDoi: '@'
              },
              templateUrl: 'kudosCharts/kudosPublicationAltmetrics.directive.html',
              link: function (scope, element) {
                scope._googleChartService = new googleChartService();
  
                scope.dateRangeForm = {
                  options: [
                    { value: '30',  label: '30 days'  },
                    { value: '90',  label: '90 days'  },
                    { value: '180', label: '180 days' },
                    { value: 'all', label: 'All time' },
                  ],
                  selectOption:   selectDateRangeFormOption,
                  getOptionClass: getDateRangeFormOptionClass,
                  getFormLabel:   getFormLabel,
                  loading:        false,
                  selected:       '30'
                };
  
                scope.$watch('dateRangeForm.selected', handleDateRangeFormSelected);
  
                $timeout(init, 0);
  
                function init () {
                  scope._googleChartService.init(getElement(), getChartOptions(), getStatistics);
                }
  
                function handleDateRangeFormSelected (newValue, oldValue) {
                  if (angular.isUndefined(newValue))  { return; }
                  if (newValue === oldValue)          { return; }
  
                  scope._googleChartService.updateChart();
                }
  
                function selectDateRangeFormOption (option) {
                  scope.dateRangeForm.selected = option.value;
                }
  
                function getFormLabel () {
                  var verb = 'Show';
  
                  if (scope._googleChartService.loadingChartData) {
                    verb = 'Loading';
                  }
  
                  return verb + ' data for last:';
                }
  
                function getDateRangeFormOptionClass (option) {
                  if (option.value === scope.dateRangeForm.selected) {
                    return 'btn-primary';
                  }
  
                  return 'btn-default';
                }
  
                function getElement () {
                  return element.find('#chart-' + scope.publicationId)[0];
                }
  
                function getChartOptions () {
                  return {
                    height: '400',
                    width: angular.element(getElement()).width(),
                    chartArea: {
                      top: 20,
                      height: '65%',
                      width: '85%'
                    },
                    colors: [
                      '#427dfe',
                      '#809A14'
                    ],
                    legend: {
                      position: 'none'
                    },
                    hAxis: {
                      slantedText: true,
                      slantedTextAngle: 45
                    },
                    vAxis: {
                      viewWindow: {
                        min: 0
                      }
                    },
                    tooltip: {
                      isHtml: true
                    }
                  };
                }
  
                function getStatistics () {
                  var id        = scope.publicationId;
                  var dateRange = scope.dateRangeForm.selected;
  
                  return $http.get('/article_metrics/altmetrics/' + id + '?date_range=' + dateRange);
                }
  
              }
            };
          }
        ]
      );
  
  }(window.angular));
  
  ; (function (angular, google) {
    'use strict';
  
    angular
      .module('kudosCharts')
        .service(
          'googleChartService',
          [
            '$window',
            googleChartFactory
          ]
        );
  
    function googleChartFactory ($window) {
  
      function googleChartService () {
        var self = this;
  
        self.loadingChartData   = false;
  
        self.element            = false;
        self.chartOptions       = {};
        self.getChartDataFn     = false;
  
        self.init               = init;
        self.getChartData       = getChartData;
        self.initChart          = initChart;
        self.updateChart        = updateChart;
        self.resizeChart        = resizeChart;
        self.getElement         = getElement;
        self.buildChart         = buildChart;
        self.drawChart          = drawChart;
        self.buildChartInstance = buildChartInstance;
        self.buildChartData     = buildChartData;
        self.getChartOptions    = getChartOptions;
        self.loadChartPackage   = loadChartPackage;
  
        function init (element, chartOptions, getChartDataFn) {
          self.element        = element;
          self.chartOptions   = chartOptions;
          self.getChartDataFn = getChartDataFn;
  
          loadChartPackage(initChart);
        }
  
        function getChartData () {
          if (!self.getChartDataFn) { return; }
  
          return self.getChartDataFn();
        }
  
        function initChart () {
          getChartData()
            .then(function (response) {
              self.currentBuiltChart = buildChart(getElement(), response.data);
  
              drawChart(self.currentBuiltChart);
            })
            .then(function () {
              // Redraw reized chart on window resize event
              angular.element($window).bind('resize', resizeChart);
            });
        }
  
        function updateChart () {
          self.loadingChartData = true;
  
          if (!getChartData()) { return; }
  
          getChartData()
            .then(function (response) {
              self.currentBuiltChart = buildChart(getElement(), response.data);
              drawChart(self.currentBuiltChart);
            })
            .finally(function () {
              self.loadingChartData = false;
            });
        }
  
        function resizeChart () {
          self.currentBuiltChart.options = getChartOptions();
  
          drawChart(self.currentBuiltChart);
        }
  
        function getElement () {
          return self.element;
        }
  
        function buildChart (element, statistics) {
          return {
            instance: buildChartInstance(element),
            data:     buildChartData(statistics),
            options:  getChartOptions()
          };
        }
  
        function drawChart (googleChart) {
          return googleChart.instance.draw(googleChart.data, googleChart.options);
        }
  
        function buildChartInstance (element) {
          return new google.visualization.LineChart(element);
        }
  
        function buildChartData (statistics) {
          return new google.visualization.DataTable(statistics);
        }
  
        function getChartOptions () {
          return self.chartOptions;
        }
  
        function loadChartPackage (callback) {
          google.charts.load('current', { packages: ['corechart'] });
  
          google.charts.setOnLoadCallback(callback);
        }
      }
  
      return googleChartService;
  
    }
  
  }(window.angular, window.google));
  

}(window.angular));

; (function (angular) {
  'use strict';

  angular.module('altmetric', ['kudos', 'templates']);
  ; (function (angular) {
  
    'use strict';
  
    angular.module('altmetric')
      .directive('altmetricBadge', function () {
        return {
          scope: {},
          bindToController: {
            doi: '@'
          },
          templateUrl: 'altmetric/altmetric-badge.directive.html',
          controllerAs: 'vm',
          controller: ['$timeout', function ($timeout) {
            this.$onInit = function () {
              $timeout(window._altmetric_embed_init, 500);
            };
          }]
        };
      }
    );
  }(window.angular));
  
  (function (angular) {
    'use strict';
  
    angular
      .module('altmetric')
      .directive('kudosAltmetricKpi', [
        function () {
          return {
            scope: {},
            bindToController: {
              title: '@',
              doi: '@',
              disabled: '=?',
              bottomLabel: '=?',
              clickable: '=?'
            },
            replace: true,
            transclude: true,
            templateUrl: 'altmetric/altmetric-kpi.directive.html',
            controllerAs: 'vm',
            controller: ['$element', function ($element) {
              var self = this;
  
              self.$onInit = init;
  
              function init() {
                if (self.clickable === false) {
                  $element.on('click', function (event) {
                    event.preventDefault();
                  });
                }
  
                // bottomLabel has to explicitly be set to true and anything else will be considered false
                self.bottomLabel = self.bottomLabel === true;
  
                self.kpiAltmetricClass = {
                  'altmetric-kpi-disabled': self.disabled
                };
              }
            }]
          };
        }
    ]);
  
  } (window.angular));
  

}(window.angular));

; (function (angular) {
  'use strict';

  angular.module('kudosShares', ['kudosPublications', 'kudosNotifications', 'facebook', 'kudos', 'templates', 'ngPromiseExtras']);
  ; (function (angular, _) {
    'use strict';
  
    angular.module('kudosShares')
      .factory(
        'ShareService',
        [
          '$http',
          '$q',
          '$timeout',
          'NotificationService',
          'SessionService',
          function ($http, $q, $timeout, NotificationService, SessionService) {
            return {
              availableChannels: function () {
                var currentUser = SessionService.currentUser();
  
                return [
                  {
                    name: 'twitter',
                    title: 'Twitter',
                    enabled: !!currentUser.twitter_login_present,
                    characterLimit: 115 // Twitter wraps URLs with t.co(); this means the maximum url length could be 23 chars (as of 5-10-2015)
                      // we also require a space between the url and the message, which implies a maximum message length of
                      // 140 - 23 - 1 = 116 characters. But for future-protecting, allow the URL to be upto 24 chars,
                      // i.e. maximum twitter message length of 115.
                      // See https://dev.twitter.com/rest/reference/get/help/configuration for current twitter url length.
                  },
                  {
                    name: 'linkedin',
                    title: 'LinkedIn',
                    enabled: !!currentUser.linkedin_login_present,
                    characterLimit: 675
                  }
                ];
              },
  
              sharePublicationToChannel: function (channel, doi, shareMessage, recommendation) {
                var payload = {
                  channel: channel,
                  doi: doi,
                  share_message: shareMessage
                };
  
                if (angular.isDefined(recommendation)) {
                  payload.triggering_recommendation = {id: recommendation.id}; // see internal_api/app.rb
                }
  
                return $http.post('/internal_api/shares/share_publication_via_channel', payload).then(function (response) {
                  return response.data.data.intervention;
                });
              },
  
              sharePublicationToChannels: function (selectedChannels, doi, shareMessage, shareToChannel) {
                var promises = _.map(selectedChannels, function (channel) {
                  return shareToChannel(channel, doi, shareMessage);
                });
  
                return $q.allSettled(promises)
                  .then(function (values) {
                    if (_.every(values, { state: 'fulfilled' })) {
                      NotificationService.success('Successfully posted to social media accounts');
  
                      return $q.resolve(values);
                    }
  
                    if (_.every(values, { state: 'rejected' })) {
                      NotificationService.error('Errors in posting to social media accounts. Please retry.');
                    } else {
                      NotificationService.warning('Posted to social media accounts with one or more errors');
                    }
  
                    return $q.reject(values);
                  });
              },
  
              generateShareableLink: function (doi, label) {
                return $http.post(
                  '/internal_api/shares/generate_shareable_link',
                  {doi: doi, label: label}
                ).then(function (response) {
                    return response.data.data.intervention;
                  })
                  .catch(function () {
                    NotificationService.error('Share link could not be generated.');
                  });
              }
  
            };
          }
        ]
      );
  
  }(window.angular, window._));
  
  (function (angular) {
    'use strict';
  
    angular.module('kudosShares').directive('kudosGenerateShareableLinkBox', [
      function () {
        return {
          scope: {},
          bindToController: {
            doi: '@',
            onShareUrlCreated: '=?',
            showCopyLinkButton: '=?',
            analyticsCategory: '@',
            analyticsLabel: '@',
            strictLabel: '@',
          },
          templateUrl: 'kudosShares/kudosGenerateShareableLinkBox.directive.html',
          controllerAs: 'vm',
          controller: [
            '$element',
            '$document',
            'ShareService',
            'NotificationService',
            function ($element, $document, ShareService, NotificationService) {
              var self = this;
  
              self.label = '';
              self.customLabel = '';
              self.shareResult = null;
  
              self.defaultShareConfig = Object.freeze({
                buildShareIntentUri: function (shareUrl) {
                  var subject = encodeURIComponent(
                    'See my latest publication on Kudos'
                  );
                  var body = encodeURIComponent(
                    'See my latest publication here:\r' + shareUrl
                  );
  
                  return 'mailto:?subject=' + subject + '&body=' + body;
                },
                shareIntentText: 'Send by email\u2026',
                showCopyLinkButton: true,
              });
  
              self.shareConfigs = Object.freeze({
                Facebook: Object.freeze({
                  buildShareIntentUri: function (shareUrl) {
                    return (
                      'https://www.facebook.com/dialog/share?app_id=382090738578650&display=page&href=' +
                      encodeURIComponent(shareUrl)
                    );
                  },
                  shareIntentText: 'Post on Facebook\u2026',
                  showCopyLinkButton: false,
                }),
                LinkedIn: Object.freeze({
                  buildShareIntentUri: function (shareUrl) {
                    return (
                      'https://www.linkedin.com/shareArticle?url=' +
                      encodeURIComponent(shareUrl)
                    );
                  },
                  shareIntentText: 'Post on LinkedIn\u2026',
                  showCopyLinkButton: false,
                }),
                Twitter: Object.freeze({
                  buildShareIntentUri: function (shareUrl) {
                    return (
                      'https://x.com/intent/post?url=' +
                      encodeURIComponent(shareUrl)
                    );
                  },
                  shareIntentText: 'Post on X\u2026',
                  showCopyLinkButton: false,
                }),
                Weibo: Object.freeze({
                  buildShareIntentUri: function (shareUrl) {
                    return (
                      'https://service.weibo.com/share/share.php?url=' +
                      encodeURIComponent(shareUrl)
                    );
                  },
                  shareIntentText: 'Post on Weibo\u2026',
                  showCopyLinkButton: false,
                }),
                WeChat: Object.freeze({
                  buildShareIntentUri: function () {
                    return 'https://web.wechat.com';
                  },
                  shareIntentText: 'Open WeChat',
                  showCopyLinkButton: true,
                }),
                Mastodon: Object.freeze({
                  buildShareIntentUri: function (shareUrl) {
                    var msg = 'Read my Story on Kudos: ' + shareUrl;
                    return '/tootpick/#text=' + encodeURIComponent(msg);
                  },
                  shareIntentText: 'Post on Mastodon\u2026',
                  showCopyLinkButton: false,
                }),
                Bluesky: Object.freeze({
                  buildShareIntentUri: function (shareUrl) {
                    return (
                      'https://bsky.app/intent/compose?text=' +
                      encodeURIComponent(shareUrl)
                    );
                  },
                  shareIntentText: 'Post on Bluesky\u2026',
                  showCopyLinkButton: false,
                }),
                WhatsApp: Object.freeze({
                  buildShareIntentUri: function (shareUrl) {
                    return 'https://wa.me/?text=' + encodeURIComponent(shareUrl);
                  },
                  shareIntentText: 'Send with WhatsApp\u2026',
                  showCopyLinkButton: false,
                }),
              });
  
              self.$onInit = function () {
                self.isLoading = false;
                self.shareUrl = '';
              };
  
              self.getEffectiveLabel = function () {
                var label = self.label;
  
                if (self.strictLabel) {
                  label = self.strictLabel;
                } else if (self.showCustomLabel()) {
                  label = self.customLabel;
                }
  
                return label;
              };
  
              self.generateShareableLink = function () {
                var labelToSend = self.getEffectiveLabel();
  
                self.isLoading = true;
                ShareService.generateShareableLink(self.doi, labelToSend)
                  .then(function (intervention) {
                    self.shareResult = self.buildShareResult(
                      intervention.share_url
                    );
                  })
                  .finally(function () {
                    self.isLoading = false;
  
                    if (angular.isFunction(self.onShareUrlCreated)) {
                      self.onShareUrlCreated();
                    }
                  });
              };
  
              self.shareUrlLoaded = function () {
                return !!self.shareUrl && !self.isLoading;
              };
  
              self.isSelectVisible = function () {
                return !self.strictLabel;
              };
  
              self.buildShareResult = function (shareUrl) {
                var config =
                  self.shareConfigs[self.getEffectiveLabel()] ||
                  self.defaultShareConfig;
  
                return {
                  shareUrl: shareUrl,
                  shareIntentUri: config.buildShareIntentUri(shareUrl),
                  shareIntentText: config.shareIntentText,
                  showCopyLinkButton: config.showCopyLinkButton,
                };
              };
  
              self.confirmCopy = function () {
                NotificationService.success('Link copied to clipboard!');
              };
  
              self.showCustomLabel = function () {
                return self.label === 'custom';
              };
  
              self.copyToClipboard = function () {
                $element.find('input[type="text"]').select();
                $document[0].execCommand('copy');
              };
            },
          ],
        };
      },
    ]);
  })(window.angular);
  
  (function (angular, _) {
    'use strict';
  
    angular
      .module('kudosShares')
      .directive('kudosSharePublicationBox', [
        '$rootScope',
        '$timeout',
        'SessionService',
        'NotificationService',
        function ($rootScope, $timeout, SessionService, NotificationService) {
          return {
            scope: {},
            bindToController: {
              doi: '@',
              onShareSuccess: '=?',
              recommendation: '=',
              strictChannelName: '@?'
            },
            templateUrl: 'kudosShares/kudosSharePublicationBox.directive.html',
            controllerAs: 'vm',
            controller: ['$q', 'ShareService', function($q, ShareService) {
              var self = this;
  
              self.shareMessage = '';
              self.processState = 'init';
              self.progressButtonStates = getProgressButtonStates();
              self.isChannelSelected = isChannelSelected;
              self.shareToChannels = shareToChannels;
              self.canShareToChannels = canShareToChannels;
              self.hasSelectedChannels = hasSelectedChannels;
              self.calculateRemainingCharacters = calculateRemainingCharacters;
              self.shareToChannelAndNotify = shareToChannelAndNotify;
              self.$onInit = init;
  
              function init() {
                self.availableChannels = getAvailableChannels();
                self.strictChannel = getStrictChannel();
              }
  
              function getStrictChannel() {
                return _.find(ShareService.availableChannels(), function(channel) {
                  return channel.name === self.strictChannelName;
                });
              }
  
              $rootScope.$on(SessionService.CURRENT_USER_UPDATE_EVENT_NAME, function () {
                self.availableChannels = getAvailableChannels();
              });
  
              function isChannelSelected (channel) {
                return channel.shareSelected && channel.enabled;
              }
  
              self.markChannelSharedSuccessfully = function(channel) {
                channel.shareSuccess = true;
                channel.shareFail = false;
                // De-selects databound checkbox on view if succeeds.
                channel.shareSelected = false;
                channel.shareInProgress = false;
              }
  
              self.markChannelSharedFailed = function(channel) {
                channel.shareSuccess = false;
                channel.shareFail = true;
                channel.shareInProgress = false;
              }
  
              self.markChannelShareStart = function(channel) {
                // reset statuses
                channel.shareSuccess = false;
                channel.shareFail = false;
                //mark as in progress
                channel.shareInProgress = true;
              }
  
              function calculateRemainingCharacters (channel) {
                if (angular.isUndefined(channel.characterLimit)) {
                  return 'unlimited';
                }
  
                var count = channel.characterLimit - self.shareMessage.length;
                if (count < 0) {
                  count = 0;
                }
                return count;
              }
  
              function hasSelectedChannels () {
                var channelSelected = false;
                _.each(self.availableChannels, function (channel) {
                  if (channel.shareSelected && channel.enabled) {
                    channelSelected = true;
                  }
                });
                return channelSelected;
              }
  
              function canShareToChannels () {
                // to be able to share, the user must have entered a message and selected at least one channel
                return self.shareMessage.length > 0 && self.hasSelectedChannels();
              }
  
              function shareToChannelAndNotify () {
                changeShareButtonState('waiting');
  
                ShareService.sharePublicationToChannel(self.strictChannel.name, self.doi, self.shareMessage)
                .then(function () {
                  changeShareButtonState('success');
                  NotificationService.success('Successfully posted to ' + self.strictChannel.title)
                })
                .then(self.callOnShareSuccess)
                .catch(function () {
                  changeShareButtonState('error');
                  NotificationService.error('Errors in posting to ' + self.strictChannel.title + '. Please retry.')
                })
                .finally(function () {
                  $timeout(function () {
                    changeShareButtonState('init');
                  }, 3000);
                });
              }
  
              function shareToChannels () {
                changeShareButtonState('waiting');
  
                ShareService.sharePublicationToChannels(
                  self.availableChannels.filter(self.isChannelSelected),
                  self.doi,
                  self.shareMessage,
                  self.shareToChannel
                ).then(function () {
                  changeShareButtonState('success');
                })
                .then(self.callOnShareSuccess)
                .catch(function () {
                  changeShareButtonState('error');
                })
                .finally(function () {
                  $timeout(function () {
                    changeShareButtonState('init');
                  }, 3000);
                });
              }
  
              self.callOnShareSuccess = function() {
                if (angular.isDefined(self.onShareSuccess) && angular.isFunction(self.onShareSuccess)) {
                  // Run optional post share success callback (if provided)
                  self.onShareSuccess();
                }
              }
  
              function changeShareButtonState (state) {
                if (self.processState === 'error' && state === 'success') {
                  return;
                }
  
                self.processState = state;
              }
  
              self.shareToChannel = function(channel, doi, shareMessage) {
                self.markChannelShareStart(channel);
  
                return ShareService.sharePublicationToChannel(channel.name, doi, shareMessage, self.recommendation)
                         .then(function () {
                           self.markChannelSharedSuccessfully(channel);
  
                           return channel;
                         })
                         .catch(function () {
                          self.markChannelSharedFailed(channel);
  
                           return $q.reject(channel);
                         });
                  }
  
              function getAvailableChannels () {
                var channels = ShareService.availableChannels();
                if (self.strictChannelName) {
                  channels = _.filter(ShareService.availableChannels(), function(channel) {
                    return channel.name === self.strictChannelName;
                  });
                }
  
                return _.map(channels, function (channel) {
                  channel.shareSuccess = false;
                  channel.shareFail = false;
                  channel.shareInProgress = false;
                  channel.shareSelected = channel.enabled;
  
                  return channel;
                });
              }
  
              function getProgressButtonStates () {
                return {
                  init: {
                    buttonText: 'Share'
                  },
                  waiting: {
                    buttonText: 'Sharing'
                  },
                  success: {
                    buttonText: 'Shared'
                  }
                };
              }
            }
          ]
        };
      }
    ]);
  
  } (window.angular, window._));
  
  (function (angular) {
    'use strict';
  
    angular
      .module('kudosShares')
      .directive('kudosPdfShareLabel', [
        function () {
          return {
            scope: {},
            bindToController: {
              link: '@',
              strictLabel: '@'
            },
            templateUrl: 'kudosShares/kudosPdfShareLabel.directive.html',
            controllerAs: 'vm',
            controller: [function() {
              this.$onInit = function() {
                this.selectedLabel = this.strictLabel;
                this.userFreeTextLabel = '';
              };
  
              this.isCustomLabel = function() {
                return this.selectedLabel === "custom";
              };
  
              this.isSelectVisible = function() {
                return !this.strictLabel;
              };
  
              this.PDFLink = function() {
                var labelToSend = this.selectedLabel;
  
                if (this.isCustomLabel()) {
                  labelToSend = encodeURIComponent(this.userFreeTextLabel);
                }
  
                if (!labelToSend) {
                  return this.link;
                }
  
                return this.link + '?label=' + labelToSend;
              };
            }]
          };
        }
       ]);
  } (window.angular));
  
  (function (angular) {
    'use strict';
  
    angular.module('kudosShares').component('kudosShareMultiChannel', {
      templateUrl: 'kudosShares/kudosShareMultiChannel.component.html',
      bindings: {
        doi: '@',
        onSuccess: '<',
        generatePdfUrl: '@',
        pdfDisabled: '=',
        analyticsCategory: '@',
        analyticsLabel: '@',
        startOpen: '@',
      },
      controllerAs: 'vm',
      controller: function () {
        var self = this;
  
        self.selected = null;
  
        self.channels = Object.freeze([
          {
            id: 'link',
            label: 'Link',
            displayName: 'Link',
            iconClass: 'fa fa-link',
          },
          {
            id: 'researchgate',
            label: 'ResearchGate',
            displayName: 'ResearchGate',
            iconClass: 'ai ai-researchgate',
            requiresPdfFeature: true,
          },
          {
            id: 'pdf',
            label: 'PDF',
            displayName: 'PDF',
            iconClass: 'fa fa-file-pdf-o',
            requiresPdfFeature: true,
          },
          {
            id: 'bluesky',
            label: 'Bluesky',
            displayName: 'Bluesky',
            iconClass: 'fa-brands fa-bluesky',
            usesStandardGenerateShareableLinkBox: true,
          },
          {
            id: 'facebook',
            label: 'Facebook',
            displayName: 'Facebook',
            iconClass: 'fa fa-facebook',
            usesStandardGenerateShareableLinkBox: true,
          },
  
          {
            id: 'linkedin',
            label: 'LinkedIn',
            displayName: 'LinkedIn',
            iconClass: 'fa fa-linkedin',
            usesStandardGenerateShareableLinkBox: true,
          },
          {
            id: 'mastodon',
            label: 'Mastodon',
            displayName: 'Mastodon',
            iconClass: 'fa-brands fa-mastodon',
            usesStandardGenerateShareableLinkBox: true,
          },
          {
            id: 'weibo',
            label: 'Weibo',
            displayName: 'Weibo',
            iconClass: 'fa fa-weibo',
            usesStandardGenerateShareableLinkBox: true,
          },
          {
            id: 'wechat',
            label: 'WeChat',
            displayName: 'WeChat',
            iconClass: 'fa fa-wechat',
            usesStandardGenerateShareableLinkBox: true,
          },
          {
            id: 'whatsapp',
            label: 'WhatsApp',
            displayName: 'WhatsApp',
            iconClass: 'fa-brands fa-whatsapp',
            usesStandardGenerateShareableLinkBox: true,
          },
          {
            id: 'twitter',
            label: 'Twitter',
            displayName: 'X',
            iconClass: 'fa-brands fa-x-twitter',
            usesStandardGenerateShareableLinkBox: true,
          },
        ]);
  
        self.$onInit = function () {
          if (self.startOpen) {
            self.selected = self.startOpen;
          }
        };
  
        self.getActiveChannels = function () {
          return self.channels.filter(function (c) {
            return !(c.requiresPdfFeature && self.pdfDisabled);
          });
        };
  
        self.getChannelsWithStandardShareableLinkBox = function () {
          return self.channels.filter(function (c) {
            return c.usesStandardGenerateShareableLinkBox;
          });
        };
  
        self.select = function (channel) {
          if (self.selected === channel) {
            self.selected = null;
          } else {
            self.selected = channel;
          }
        };
      },
    });
  })(window.angular);
  

}(window.angular));

; (function (angular) {
  'use strict';

  angular.module('kudosModals', ['templates']);
  ; (function (angular) {
    'use strict';
  
    angular
      .module('kudosModals')
        .factory(
          'ModalService',
          [
            '$uibModal',
            '$rootScope',
            function ($uibModal, $rootScope) {
  
              var ModalService = {
                openConfirmationModal: function (options) {
  
                  options = angular.extend({}, options, {});
  
                  var modalInstance;
                  var modalScope = $rootScope.$new();
  
                  function createModalOptions(options) {
                    var modalOptions = {};
                    var modalDefaultOkButton = {
                      text: 'Ok',
                      isDismissed: false,
                      classes: 'btn-primary'
                    };
  
                    var modalDefaultCancelButton = {
                      text: 'Cancel',
                      isDismissed: true,
                      classes: 'btn-muted'
                    };
  
                    modalOptions.okButton = (options.okButton || modalDefaultOkButton);
                    modalOptions.cancelButton = (options.cancelButton || modalDefaultCancelButton);
                    modalOptions.title = (options.title || 'Please Confirm');
                    modalOptions.content = (options.content || false);
  
                    if (angular.isUndefined(options.subContent)) {
                      modalOptions.subContent = 'Are you sure you want to do this?';
                    } else {
                      modalOptions.subContent = options.subContent;
                    }
  
                    return modalOptions;
                  }
  
                  modalScope.options = createModalOptions(options);
  
                  modalScope.pressButton = function (button) {
                    if (button.isDismissed) {
                      modalInstance.dismiss('cancel');
                    } else {
                      modalInstance.close('ok');
                    }
                  };
  
                  modalInstance = $uibModal.open({
                    animation: true,
                    template: '<kudos-confirmation-modal options="options" button-handler="pressButton"></kudos-confirmation-modal>',
                    scope: modalScope
                  });
  
                  return modalInstance;
                }
              };
  
              return ModalService;
            }
          ]
        );
  
  }(window.angular));
  
  ; (function (angular) {
  
    'use strict';
  
    angular.module('kudosModals')
      .directive('kudosConfirmationModal', [
        function () {
          return {
            scope: {},
            bindToController: {
              options: '=',
              buttonHandler: '='
            },
            templateUrl: 'kudosModals/confirmation-modal.directive.html',
            controllerAs: 'vm',
            controller: function () {
              var self = this;
  
              // Closes the modal when the X is clicked on the top right
              self.cancel = function () {
                self.buttonHandler({
                  isDismissed: true
                });
              };
            }
          };
        }
      ]);
  
  }(window.angular));
  

}(window.angular));

; (function (angular) {
  'use strict';

  angular.module('kudosQueries', []);
  ; (function (angular) {
    'use strict';
    angular
      .module('kudosQueries')
      .factory(
        'PublisherQueryService',
        [
          '$http',
          function ($http) {
            return {
              getQuery: getQuery
            };
  
            function getQuery (shortCode, queryId) {
              return $http.get('/internal_api/publishers/' + shortCode + '/queries/' + queryId);
            }
          }
        ]
      );
  
  }(window.angular));
  
  
  

}(window.angular));


; (function (angular) {
  'use strict';

  angular.module('kudosRegistration', [
    'angulartics',
    // This module depends on hitCallback from Google Analytics.
    // TODO: upgrade angulartics so it can be used with hitCallback.
    'angulartics.google.analytics'
  ]);
  ; (function (angular, _) {
    'use strict';
  
    var module = angular.module('kudosRegistration');
  
    module.controller('KudosRegisterSubscriptionsController', [
      '$q',
      function (
        $q
      ) {
        this.valid = function (form, model) {
          if (!form || !model) {
            return false;
          }
          var modelValid = _.every(model, function (value) {
            return angular.isDefined(value);
          });
          return form.$valid && modelValid;
        };
  
        this.trackAndSubmit = function ($event, form, model) {
          if ($event.target.tagName !== 'FORM') {
            return;
          }
          if (!this.valid(form, model)) {
            return;
          }
  
          $event.preventDefault();
  
          // Only submit once all analytics requests are done.
  
          var submit = function () {
            $event.target.submit();
          };
          var track = this._ga;
  
          var submitAnalyticsPromises = _.map(model, function (value, key) {
            var deferred = $q.defer();
            // TODO: upgrade angulartics so it can be used with hitCallback.
            track('send', {
              hitType: 'event',
              eventAction: (value === true || value === 'true') ? 'opt-in' : 'opt-out',
              eventCategory: 'subscription ' + _.startCase(key).toLowerCase(),
              eventLabel: 'registration flow',
              hitCallback: deferred.resolve
            });
            return deferred.promise;
          });
  
          $q.all(submitAnalyticsPromises).then(submit);
        };
  
        this._ga = function () {
          if (window.ga) {
            window.ga.apply(null, arguments);
          } else if (arguments[1] && arguments[1].hitCallback) {
            // If GA is not available, we need to ensure any hitCallbacks are still
            // called to avoid the UI not responding
            arguments[1].hitCallback();
          }
        };
      }
    ]);
  
  }(window.angular, window._));
  
  ; (function (angular, _) {
      'use strict';
  
      var module = angular.module('kudosRegistration');
  
      module.controller('KudosRegistrationFormController', [
          '$scope',
          '$element',
          function (
              $scope,
              $element
          ) {
              $scope.submitted = false;
              $scope.submitting = false;
  
              $scope.register = function () {
                  $scope.submitted = true;
  
                  if($element.length !== 0 && $element[0].checkValidity()) {
                      $scope.submitting = true;
  
                      $element[0].submit();
                  }
              };
          }
      ]);
  
  }(window.angular, window._));
  

}(window.angular));

; (function (angular) {
  "use strict";

  angular.module('kudosComponents', ['templates']);
  ; (function (angular, _) {
  
    'use strict';
  
    angular.module('kudosComponents').directive('kudosPopoverBox', [function () {
      return {
        scope: {
          displayText: '@',
          className: '@',
        },
        templateUrl: 'kudosPopoverBox/kudosPopoverBox.html',
        link: function(_, element, attrs){
          var placement = 'bottom';
          if (angular.isDefined(attrs.placement)) {
            placement = attrs.placement;
          }
  
          var triggerElement = element.children('a');
          triggerElement.attr('data-placement', placement);
          triggerElement.attr('data-content', attrs.helpText);
  
          // Use window.jQuery so it can be spied on in tests.
          window.jQuery(triggerElement).popover({trigger: 'hover focus', html: true});
        }
      };
    }]);
  }(window.angular, window._));
  

} (window.angular));

; (function (angular) {

  "use strict";

  angular.module('kudosHub', ['kudosNotifications', 'sessions', 'templates', 'kudosHub.featuredPublication']);
  ; (function (angular) {
  
    'use strict';
  
    angular.module('kudosHub').factory('SearchService', ['$http', function($http) {
      var apiBase = '/internal_api/search/';
  
      function byDoi(doi) {
        return $http.get(apiBase + encodeURIComponent(doi))
          .then(function (response) {
            return {
              doi: response.data.data.doi,
              authors: response.data.data.authors,
              title: response.data.data.title,
              url: response.data.data.url,
              containerTitleDate: response.data.data.container_title_date,
            };
          });
      }
  
      return { byDoi: byDoi };
    }]
  );
  
  }(window.angular));
  
  ; (function (angular) {
  
    'use strict';
  
    angular.module('kudosHub').directive('kudosSearchByDoi', function () {
      return {
        templateUrl: 'kudosHub/kudosSearchByDoi/kudosSearchByDoi.html',
        scope: {},
        controllerAs: 'vm',
        bindToController: {},
        controller: [
          'SearchService',
          'NotificationService',
          'SessionService',
          function(SearchService, NotificationService, SessionService) {
            var vm = this;
            vm.$onInit = init;
            vm.submit = submit;
            vm.submitIsDisabled = submitIsDisabled;
            vm.userIsAuthor = userIsAuthor;
  
            function init() {
              vm.submitDisabled = false;
              vm.doi = null;
              vm.hasSearchResult = false;
              vm.doiNotFound = false;
              vm.pub = {};
            }
  
            function submit() {
              NotificationService.clear();
              vm.submitDisabled = true;
              vm.doiNotFound = false;
              vm.hasSearchResult = false;
  
              SearchService.byDoi(vm.doi)
                .then(onFindSuccess)
                .catch(onFindError)
                .finally(function() {
                  vm.submitDisabled = false;
                });
            }
  
            function submitIsDisabled() {
              return vm.submitDisabled;
            }
  
            function onFindSuccess (response) {
              vm.hasSearchResult = true;
              vm.pub = response;
            }
  
            function onFindError (response) {
              if (response.status === 404) {
                vm.doiNotFound = true;
              } else {
                NotificationService.error(response.data.errors);
              }
            }
  
            function userIsAuthor() {
              return SessionService.userIsAuthorFor(vm.pub.doi);
            }
          }
        ],
      };
    });
  }(window.angular));
  
  ; (function (angular) {
  
    'use strict';
  
    angular.module('kudosHub').component('passwordField', {
        templateUrl: 'kudosHub/settings/kudosPasswordField.html',
        bindings: {
          name: '@',
          autocomplete: '@',
          placeholder: '@'
        },
        controllerAs: 'vm',
        controller: function () {
          this.hidden = true;
          this.toggleType = toggleType;
          this.displayText = displayText;
          this.inputType = inputType;
  
          function toggleType () {
            this.hidden = !this.hidden;
          }
  
          function displayText () {
            if (this.hidden) {
              return 'show';
            }
            return 'hide';
          }
  
          function inputType () {
            if (this.hidden) {
              return 'password';
            }
            return 'text';
          }
        }
    });
  
  } (window.angular));
  
  ; (function (angular) {
  
    "use strict";
  
    angular.module('kudosHub.featuredPublication', ['kudosNotifications', 'kudosPublications', 'templates']);
    (function (angular) {
      'use strict';
    
      angular.module('kudosHub.featuredPublication').component('kudosHubFeaturedPublication', {
        templateUrl: 'kudosHub/featuredPublication/kudosHubFeaturedPublication.component.html',
        bindings: {
          doi: '@',
          featured: '@'
        },
        controllerAs: 'vm',
        controller: [
          '$q',
          '$http',
          'NotificationService',
          'PublicationService',
          function (
            $q,
            $http,
            NotificationService,
            PublicationService
          ) {
            var self = this;
    
            var labelSelected = 'Featured publication: select to remove from your featured publications';
            var labelUnselected = 'Not a featured publication: select to add to your featured publications';
    
            self.$onInit = function () {
              self._setState(self.featured === 'true', false);
            };
    
            self._setState = function (isFeatured, isWaiting) {
              self.isFeatured = isFeatured === true;
              self.isWaiting = isWaiting === true;
              updateState(isFeatured, isWaiting);
            };
    
            self.setStateWaiting = function (isFeatured) {
              self._setState(isFeatured, true);
            };
            self.setStateDone = function (isFeatured) {
              self._setState(isFeatured, false);
            };
    
            self.onClick = function () {
              if (self.isWaiting) {
                return;
              }
    
              var oldValue = self.isFeatured;
              var newValue = !oldValue;
              self.setStateWaiting(newValue);
              self.updatePublication(self.doi, newValue)
                .then(function (added) {
                  if (added) {
                    NotificationService.success('Publication added to featured publications. Go to your Public Profile to see this change.');
                  } else {
                    NotificationService.success('Publication removed from featured publications list.');
                  }
    
                  self.setStateDone(newValue);
                })
                .catch(function () {
                  // TODO: monitoring, we should log the rejection reason
                  NotificationService.error('Unable to change publication. Please try again or contact support.');
    
                  self.setStateDone(oldValue);
                });
            };
    
            self.updatePublication = function (doi, submitValue) {
              if (typeof submitValue !== 'boolean') {
                return $q.reject('Value must be a boolean, but is: ' + JSON.stringify(submitValue));
              }
    
              var url = '/internal_api/publications_featured/' + PublicationService.encodeDOI(doi);
              if (submitValue) {
                return $http.put(url).then(function () {
                  return true;
                });
              }
              return $http.delete(url).then(function () {
                return false;
              });
            };
    
            function updateState(isFeatured, isWaiting) {
              self.classname = isFeatured ? 'selected' : 'unselected';
              if (isWaiting) {
                self.classname += ' waiting';
              }
              self.iconClassname = isFeatured ? 'fa fa-star' : 'fa fa-star-o';
              self.label = isFeatured ? labelSelected : labelUnselected;
            }
          }
        ]
      });
    
    }(window.angular));
    
  
  }(window.angular));
  
} (window.angular));

(function (angular) {
  'use strict';

  angular.module('facebook', []);
  (function (angular) {
    'use strict';
  
    angular.module('facebook').service('FacebookService', [
      '$q', '$window',
      function ($q, $window) {
        var self = this;
  
        var sdk;
        initsdk();
  
        self.ERROR_UNAVAILABLE = 'Facebook SDK not available';
        self.UI_CLOSED = 'Facebook UI was closed';
  
        self.share = function (url) {
          if (!sdk) {
            return $q.reject(self.ERROR_UNAVAILABLE);
          }
  
          var opts = {
            method: 'share',
            mobile_iframe: true,
            href: url
          };
          var deferred = $q.defer();
          sdk.ui(opts, function (response) {
            if (!response) {
              deferred.reject(self.UI_CLOSED);
              return;
            }
            deferred.resolve(response);
          });
          return deferred.promise;
        };
  
        function initsdk() {
          $window.fbAsyncInit = function() {
            sdk = $window.FB;
            sdk.init({
              appId            : $window.FACEBOOK_APP_ID,
              autoLogAppEvents : false,
              xfbml            : false,
              version          : 'v3.1'
            });
          };
          // Snippet that creates window.FB object.
          (function(d, s, id){
             var js, fjs = d.getElementsByTagName(s)[0];
             if (d.getElementById(id)) {return;}
             js = d.createElement(s); js.id = id;
             js.src = "https://connect.facebook.net/en_US/sdk.js";
             fjs.parentNode.insertBefore(js, fjs);
           }(document, 'script', 'facebook-jssdk'));
        }
      }
    ]);
  
  }(window.angular));
  

}(window.angular));

//= require 'lib/unsplash/unsplash.module.js'

/* Cookies Directive - The rewrite. Now a jQuery plugin
 * Version: 2.0.1
 * Author: Ollie Phillips
 * 24 October 2013
 */

;(function($) {
  'use strict';

  $.cookiesDirective = function(options) {

    // Default Cookies Directive Settings
    var settings = $.extend({
      //Options
      explicitConsent: true,
      position: 'top',
      duration: 10,
      limit: 0,
      message: null,
      cookieScripts: null,
      privacyPolicyUri: 'privacy.html',
      scriptWrapper: function(){},
      // Styling
      fontFamily: 'helvetica',
      fontColor: '#FFFFFF',
      fontSize: '13px',
      backgroundColor: '#000000',
      backgroundOpacity: '80',
      linkColor: '#CA0000'
    }, options);

    // Perform consent checks
    if(!getCookie('cookiesDirective')) {
      if(settings.limit > 0) {
        // Display limit in force, record the view
        if(!getCookie('cookiesDisclosureCount')) {
          setCookie('cookiesDisclosureCount',1,1);
        } else {
          var disclosureCount = getCookie('cookiesDisclosureCount');
          disclosureCount ++;
          setCookie('cookiesDisclosureCount',disclosureCount,1);
        }

        // Have we reached the display limit, if not make disclosure
        if(settings.limit >= getCookie('cookiesDisclosureCount')) {
          disclosure(settings);
        }
      } else {
        // No display limit
        disclosure(settings);
      }

      // If we don't require explicit consent, load up our script wrapping function
      if(!settings.explicitConsent) {
        settings.scriptWrapper.call();
      }
    } else {
      // Cookies accepted, load script wrapping function
      settings.scriptWrapper.call();
    }
  };

  // Used to load external javascript files into the DOM
  $.cookiesDirective.loadScript = function(options) {
    var settings = $.extend({
      uri:    '',
      appendTo:   'body'
    }, options);

    var elementId = String(settings.appendTo);
    var sA = document.createElement("script");
    sA.src = settings.uri;
    sA.type = "text/javascript";
    sA.onload = sA.onreadystatechange = function() {
      if ((!sA.readyState || sA.readyState === "loaded" || sA.readyState === "complete")) {
        return;
      }
    };
    switch(settings.appendTo) {
      case 'head':
        $('head').append(sA);
          break;
      case 'body':
        $('body').append(sA);
          break;
      default:
        $('#' + elementId).append(sA);
    }
  };

  // Helper scripts
  // Get cookie
  var getCookie = function(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)===' ') {
        c = c.substring(1,c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length,c.length);
      }
    }
    return null;
  };

  // Set cookie
  var setCookie = function(name,value,days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      expires = "; expires="+date.toGMTString();
    } else {
      expires = "";
    }
    document.cookie = name+"="+value+expires+"; path=/";
  };

  // Detect IE < 9
  var checkIE = function(){
    var version;
    if (navigator.appName === 'Microsoft Internet Explorer') {
          var ua = navigator.userAgent;
          var re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
          if (re.exec(ua) !== null) {
              version = parseFloat(RegExp.$1);
      }
      if (version <= 8.0) {
        return true;
      } else {
        if(version === 9.0) {
          if(document.compatMode === "BackCompat") {
            // IE9 in quirks mode won't run the script properly, set to emulate IE8
            var mA = document.createElement("meta");
            mA.content = "IE=EmulateIE8";
            document.getElementsByTagName('head')[0].appendChild(mA);
            return true;
          } else {
            return false;
          }
        }
        return false;
      }
      } else {
      return false;
    }
  };

  // Disclosure routines
  var disclosure = function(options) {
    var settings = options;
    settings.css = 'fixed';

    // IE 9 and lower has issues with position:fixed, either out the box or in compatibility mode - fix that
    if(checkIE()) {
      settings.position = 'top';
      settings.css = 'absolute';
    }

    // Any cookie setting scripts to disclose
    var scriptsDisclosure = '';
    if (settings.cookieScripts) {
      var scripts = settings.cookieScripts.split(',');
      var scriptsCount = scripts.length;
      var scriptDisclosureTxt = '';
      if(scriptsCount>1) {
        for(var t=0; t < scriptsCount - 1; t++) {
           scriptDisclosureTxt += scripts[t] + ', ';
        }
        scriptsDisclosure = ' We use ' +  scriptDisclosureTxt.substring(0,  scriptDisclosureTxt.length - 2) + ' and ' + scripts[scriptsCount - 1] + ' scripts, which all set cookies. ';
      } else {
        scriptsDisclosure = ' We use a ' + scripts[0] + ' script which sets cookies.';
      }
    }

    // Create overlay, vary the disclosure based on explicit/implied consent
    // Set our disclosure/message if one not supplied
    var html = '';
    html += '<div id="epd">';
    html += '<div id="cookiesdirective" style="position:'+ settings.css +';'+ settings.position + ':-300px;left:0px;width:100%;';
    html += 'height:auto;background:' + settings.backgroundColor + ';opacity:.' + settings.backgroundOpacity + ';';
    html += '-ms-filter: “alpha(opacity=' + settings.backgroundOpacity + ')”; filter: alpha(opacity=' + settings.backgroundOpacity + ');';
    html += '-khtml-opacity: .' + settings.backgroundOpacity + '; -moz-opacity: .' + settings.backgroundOpacity + ';';
    html += 'color:' + settings.fontColor + ';font-family:' + settings.fontFamily + ';font-size:' + settings.fontSize + ';';
    html += 'text-align:center;z-index:1000;">';
    html += '<div style="position:relative;height:auto;width:90%;padding:10px;margin-left:auto;margin-right:auto;">';

    if(!settings.message) {
      if(settings.explicitConsent) {
        // Explicit consent message
        settings.message = 'This site uses cookies. Some of the cookies we ';
        settings.message += 'use are essential for parts of the site to operate and have already been set.';
      } else {
        // Implied consent message
        settings.message = 'We have placed cookies on your computer to help make this website better.';
      }
    }
    html += settings.message;

    // Build the rest of the disclosure for implied and explicit consent
    if(settings.explicitConsent) {
      // Explicit consent disclosure
      html += scriptsDisclosure + 'You may delete and block all cookies from this site, but parts of the site will not work.';
      html += 'To find out more about cookies on this website, see our <a style="color:'+ settings.linkColor + ';font-weight:bold;';
      html += 'font-family:' + settings.fontFamily + ';font-size:' + settings.fontSize + ';" href="'+ settings.privacyPolicyUri + '">privacy policy</a>.<br/>';
      html += '<div id="epdnotick" style="color:#ca0000;display:none;margin:2px;"><span style="background:#cecece;padding:2px;">You must tick the "I accept cookies from this site" box to accept</span></div>';
      html += '<div style="margin-top:5px;">I accept cookies from this site <input type="checkbox" name="epdagree" id="epdagree" />&nbsp;';
      html += '<input class="btn btn-default" type="submit" name="explicitsubmit" id="explicitsubmit" value="Continue"/><br/></div></div>';

    } else {
      // Implied consent disclosure
      html += scriptsDisclosure + ' More details can be found in our <a style="color:'+ settings.linkColor + ';';
      html += 'font-weight:bold;font-family:' + settings.fontFamily + ';font-size:' + settings.fontSize + ';" href="'+ settings.privacyPolicyUri + '">privacy policy</a>.';
      html += '<div style="margin-top:5px;"><input class="btn btn-default" type="submit" name="impliedsubmit" id="impliedsubmit" value="Do not show this message again"/></div></div>';
    }
    html += '</div></div>';
    $('body').append(html);

    // Serve the disclosure, and be smarter about branching
    var dp = settings.position.toLowerCase();
    if(dp !== 'top' && dp!== 'bottom') {
      dp = 'top';
    }
    var opts = [];
    if(dp === 'top') {
      opts.in = {'top':'0'};
      opts.out = {'top':'-300'};
    } else {
      opts.in = {'bottom':'0'};
      opts.out = {'bottom':'-300'};
    }

    // Start animation
    $('#cookiesdirective').animate(opts['in'], 1000, function() {
      // Set event handlers depending on type of disclosure
      if(settings.explicitConsent) {
        // Explicit, need to check a box and click a button
        $('#explicitsubmit').click(function() {
          if($('#epdagree').is(':checked')) {
            // Set a cookie to prevent this being displayed again
            setCookie('cookiesDirective',1,365);
            // Close the overlay
            $('#cookiesdirective').animate(opts.out,1000,function() {
              // Remove the elements from the DOM and reload page
              $('#cookiesdirective').remove();
              location.reload(true);
            });
          } else {
            // We need the box checked we want "explicit consent", display message
            $('#epdnotick').css('display', 'block');
          }
        });
      } else {
        // Implied consent, just a button to close it
        $('#impliedsubmit').click(function() {
          // Set a cookie to prevent this being displayed again
          setCookie('cookiesDirective',1,365);
          // Close the overlay
          $('#cookiesdirective').animate(opts.out,1000,function() {
            // Remove the elements from the DOM and reload page
            $('#cookiesdirective').remove();
          });
        });
      }

      // Set a timer to remove the warning after 'settings.duration' seconds
      setTimeout(function(){
        $('#cookiesdirective').animate({
          opacity:'0'
        },2000, function(){
          $('#cookiesdirective').css(dp,'-300px');
        });
      }, settings.duration * 1000);
    });
  };
})(jQuery);

// IE6+SSL fix courtesy of http://www.tribalogic.net/

;(function(window) {
  // Zendesk Feedback Tab version 2.6
  // Most of the assets are from version 2.1. There is a slight difference
  // in the markup inside the feedback tab and some changes to the CSS for
  // that markup.
  // v.26: Reverts 2.5 and adds structure for future language support.
  var document = window.document,
      urlWithScheme = /^([a-zA-Z]+:)?\/\//,
      settings = {
        url:            null, // required
        dropboxID:      null, // required
        tabColor:       "#000000",

        // the remaining settings are optional and listed here so users of the library know what they can configure:
        assetHost:      "//assets.zendesk.com",
        tabTooltip:     "support",  // tooltip text (appears when hovering over tab)
        tabImageURL:    null,       // optional; overrides URL generated from tabID
        tabPosition:    'Left',     // or 'Right'
        hide_tab:       false,      // if true, don't display the tab after initialization
        request_subject:      null,  // pre-populate the ticket submission form subject
        request_description:  null,  //  "     "      "     "      "        "   description
        requester_name:       null,  //  "     "      "     "      "        "   user name
        requester_email:      null   //  "     "      "     "      "        "   user email
      },
      // references to elements on the page:
      tab,
      overlay,
      container,
      closeButton,
      iframe,
      scrim;

  function attempt(fn) {
    try {
      return fn();
    } catch(e) {
      if (window.console && window.console.log && window.console.log.apply) {
        window.console.log("Zenbox Error: ", e);
      }
    }
  }

  function bindEvent(element, eventName, callback) {
    if (element && element.addEventListener) {
      element.addEventListener(eventName, callback, false);
    } else if (element && element.attachEvent) {
      element.attachEvent('on' + eventName, callback);
    }
  }

  function prependSchemeIfNecessary(url) {
    if (url && !(urlWithScheme.test(url))) {
      return document.location.protocol + '//' + url;
    } else {
      return url;
    }
  }

  // must be called after the DOM is loaded
  function createElements() {
    tab = document.createElement('div');
    tab.setAttribute('id', 'zenbox_tab');
    tab.setAttribute('href', '#');
    tab.style.display = 'none';
    document.body.appendChild(tab);

    overlay = document.createElement('div');
    overlay.setAttribute('id', 'zenbox_overlay');
    overlay.style.display = 'none';
    overlay.innerHTML = '<div id="zenbox_container">' +
                        '  <div class="zenbox_header"><img id="zenbox_close" /></div>' +
                        '  <iframe id="zenbox_body" frameborder="0" scrolling="auto" allowTransparency="true"></iframe>' +
                        '</div>' +
                        '<div id="zenbox_scrim">&nbsp;</div>';
    document.body.appendChild(overlay);

    container   = document.getElementById('zenbox_container');
    closeButton = document.getElementById('zenbox_close');
    iframe      = document.getElementById('zenbox_body');
    scrim       = document.getElementById('zenbox_scrim');

    bindEvent(tab,          'click', function() { window.Zenbox.show(); });
    bindEvent(closeButton,  'click', function() { window.Zenbox.hide(); });
    bindEvent(scrim,        'click', function() { window.Zenbox.hide(); });
  }

  function configure(options) {
    var prop;
    for (prop in options) {
      if (options.hasOwnProperty(prop)) {
        if (prop === 'url' || prop === 'assetHost') {
          settings[prop] = prependSchemeIfNecessary(options[prop]);
        } else {
          settings[prop] = options[prop];
        }
      }
    }
  }

  function updateTabImage() {
      tab.innerHTML = '<img src="' + settings.tabImageURL + '" />';
  }

  function updateTab() {
    if (settings.hide_tab) {
      tab.style.display = 'none';
    } else {
      updateTabImage();
      tab.setAttribute('title', settings.tabTooltip);
      tab.setAttribute('class', 'ZenboxTab' + settings.tabPosition);
      tab.setAttribute('className', 'ZenboxTab' + settings.tabPosition);
      tab.style.backgroundColor = settings.tabColor;
      tab.style.borderColor = settings.tabColor;
      tab.style.display = 'block';
    }
  }

  function cancelEvent(e) {
    var event = e || window.event || {};
    event.cancelBubble = true;
    event.returnValue = false;
    event.stopPropagation && event.stopPropagation();
    event.preventDefault && event.preventDefault();
    return false;
  }

  function getDocHeight(){
    return Math.max(
      Math.max(document.body.scrollHeight, document.documentElement.scrollHeight),
      Math.max(document.body.offsetHeight, document.documentElement.offsetHeight),
      Math.max(document.body.clientHeight, document.documentElement.clientHeight)
    );
  }

  function getScrollOffsets(){
    return {
      left: window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
      top:  window.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
    };
  }

  function closeButtonURL() {
    return settings.assetHost + '/external/zenbox/images/close_big.png';
  }

  // get the URL for the "loading" page to be used as iframe src while
  // loading the Dropbox.
  function loadingURL() {
    return settings.assetHost + '/external/zenbox/v2.1/loading.html';
  }

  function dropboxURL() {
    var url = settings.url + "/account/dropboxes/" + settings.dropboxID + '?x=1';
    if (settings.request_subject)     { url += '&subject='      + settings.request_subject; }
    if (settings.request_description) { url += '&description='  + settings.request_description; }
    if (settings.requester_name)      { url += '&name='         + settings.requester_name; }
    if (settings.requester_email)     { url += '&email='        + settings.requester_email; }
    return url;
  }

  function initialize(options) {
    if (!tab) { createElements(); }
    configure(options);
    updateTab();
    closeButton.src = closeButtonURL();
    iframe.src = loadingURL();

    window.addEventListener('message', function(e) {
      if (e.data === 'hideZenbox') {
        hide();
      }
    }, false);
  }

  function show(evt) {
    iframe.src = dropboxURL();
    overlay.style.height = scrim.style.height = getDocHeight() + 'px';
    container.style.top = getScrollOffsets().top + 50 + 'px';
    overlay.style.display = "block";
    return cancelEvent(evt);
  }

  function hide(evt) {
    overlay.style.display = 'none';
    iframe.src = loadingURL();
    return cancelEvent(evt);
  }

  var Zenbox = {

    /*
        PUBLIC API

        Methods in the public API can be used as callbacks or as direct calls. As such,
        they will always reference "Zenbox" instead of "this." Each one is wrapped
        in a try/catch block to ensure that including Zenbox doesn't break the page.
    */

    /*
     *  Build and render the Zenbox tab and build the frame for the Zenbox overlay,
     *  but do not display it.
     *  @see settings for options
     *  @param {Object} options
     */
    init: function(options) {
      attempt(function() { return initialize(options); });
    },

    /*
     * Alias for #init.
     */
    update: function(options) {
      attempt(function() { return initialize(options); });
    },

    /*
     *  Render the Zenbox. Alias for #show.
     *  @see #show
     */
    render: function(evt) {
      attempt(function() { return show(evt); });
    },

    /*
     *  Show the Zenbox. Aliased as #render.
     *  @params {Event} event the DOM event that caused the show; optional
     *  @return {false} false always, in case users want to bind it to an
     *                  onclick or other event and want to prevent default behavior.
     */
    show: function(evt) {
      attempt(function() { return show(evt); });
    },

    /*
     *  Hide the Zenbox.
      *  @params {Event} event the DOM event that caused the show; optional
      *  @return {false} false always, in case users want to bind it to an
      *                  onclick or other event and want to prevent default behavior.
      */
    hide: function (evt){
      attempt(function() { return hide(evt); });
    }
  };

  bindEvent(window, 'load', function() {
    if (window.zenbox_params) {
      Zenbox.init(window.zenbox_params);
    }
  });

  window.Zenbox = Zenbox;

}(this.window || this));


(function (angular) {
  "use strict";

  // Setup
  angular
    .module("growkudos", [
      "core", // ensure that core is always loaded first (or atleast above other Kudos modules)
      "altmetric",
      "angularFileUpload",
      "angulartics",
      "angulartics.google.analytics",
      "angulartics.kudos.analytics",
      "avatarEditor",
      "controllers",
      "kudos",
      "kudosAbout",
      "kudosAdmin",
      "kudosCards",
      "kudosCountries",
      "kudosInstitutions",
      "kudosLists",
      "kudosNotifications",
      "kudosOrganisations",
      "kudosProfiles",
      "kudosComponents",
      "kudosPublications",
      "kudosPublishers",
      "kudosRegistration",
      "kudosResources",
      "kudosQueries",
      "kudosModals",
      "ngAnimate",
      "ngSanitize",
      "kudosHub",
      "sessions",
      "showcase",
      "templates",
      "trendmd",
      "ui.bootstrap",
      "ui.bootstrap.tpls",
      "ui.grid",
      "ui.grid.resizeColumns",
      "ui.grid.treeView",
      "ui.grid.selection",
      "kudosCharts",
      "kudosPageTracker",
      "kudosShares",
      "ngPromiseExtras",
      "ngCookies",
    ])

    .run([
      "$rootScope",
      "$state",
      "$stateParams",
      "$uiViewScroll",
      "$transitions",
      function ($rootScope, $state, $stateParams, $uiViewScroll, $transitions) {
        // State loader functions
        $rootScope.appStateLoading = false;

        $transitions.onStart({}, function (transition) {
          $rootScope.$broadcast("sidebar.hide");
          $rootScope.appStateLoading = true;

          transition.promise.finally(function () {
            $rootScope.appStateLoading = false;
            $uiViewScroll(angular.element("main[ui-view]"));
          });
        });
      },
    ]);
})(window.angular);
