; (function (angular) {

  'use strict';

  angular.module('kudos')
    .factory('ValidationService', [
      function () {
        return {

          isUrl: function (input, error, optional) {
            if(optional && input === '') {
              return undefined;
            }

            if(angular.isUndefined(input) || !input.match(/^https?:\/\/.+$/)) {
              return error;
            }
          },

          isTrue: function (input, error) {
            if (input === false) {
              return error;
            }
          },

          isTruthy: function (input, error) {
            if (!input) {
              return error;
            }
          }

        };
      }
    ]
  );

}(window.angular));

