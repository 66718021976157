; (function (angular, _) {
  'use strict';

  /**
    WARNING!

    This controller exists solely to pass in legacy server-generated variables via ng-init.

    IT SHOULD NOT BE USED!

    We need to refactor much of this out, particularly the current user code, which can be
    handled as part of the authentication mechanism (which hasn't been implemented in Angular
    at the time of writing).

    If you're thinking about adding anything to this controller, think again! It should be added
    to a service instead.
  **/
  angular.module('controllers')
    .controller('KudosAppController', ['SessionService', 'NotificationService',
      function(SessionService, NotificationService) {
        var self = this;

        self.init = function(flashMessagesString) {
          var flashMessages = JSON.parse(flashMessagesString);

          // Consume the server-generated flash messages
          _.each(flashMessages, function(message, type) {
            switch (type) {
              case 'error':
              case 'danger': //bootstrap uses 'danger'
                NotificationService.error(message);
                break;
              case 'warning':
                NotificationService.warning(message);
                break;
              case 'success':
                NotificationService.success(message);
                break;
            }
          });

          // Set up the user
          SessionService.getSession()
            // Unhandled promise rejections raise an error. TODO: monitoring
            .catch(function () {});
        };
      }
    ]);

}(window.angular, window._));
