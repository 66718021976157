(function (angular) {
  'use strict';

  angular
    .module('kudosProfiles')
    .directive('kudosProfileInfoBox', [
      function () {
        return {
          scope: {
            account: '=',
            isEditable: '=?'
          },
          templateUrl: 'kudosProfiles/directives/kudosProfileInfoBox.html',
          compile: function (elem, attrs) {
            if (angular.isUndefined(attrs.isEditable)) {
              attrs.isEditable = false;
            }
          },
          link: function (scope) {
            scope.loaded = false;
          }
        };
      }
    ]);

} (window.angular));
