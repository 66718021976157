; (function (angular) {

  'use strict';
  angular.module('kudosCards')
    .directive('kudosPromoteCard', [
      function () {
        return {
          templateUrl: 'kudosCards/kudosPromoteCard.directive.html',
          controllerAs: 'vm',
          scope: {},
          bindToController: {
            organisation: '=',
            publication: '=',
            recommendation: '='
          },
          controller: [
            'OrganisationShowcaseService',
            'CardDeckService',
            function (OrganisationShowcaseService, CardDeckService) {
              var self = this;

              self.cardState = {
                completed: false
              };

              self.addPublicationToShowcase = addPublicationToShowcase;
              self.nextCard = CardDeckService.nextCard;

              function addPublicationToShowcase () {
                OrganisationShowcaseService.addPublicationToShowcase(self.organisation.short_code, self.publication.kudos_internal_id, self.recommendation)
                  .then(function (response) {
                    self.cardState.completed = angular.isDefined(response);
                  });
              }
            }
          ]
        };
      }
    ]);

}(window.angular));
