; (function (angular) {

  "use strict";

  angular.module('controllers')
    .controller(
      'InstitutionSearchController',
      [
        'institution',
        'InstitutionService',
        'NotificationService',
        function (institution, InstitutionService, NotificationService) {
          var self = this;

          self.institution = institution;

          self.searchForm = {};
          self.searching = false;
          self.searchResults = false;

          self.submitSearch = function () {
            self.searching = true;
            InstitutionService.searchInstitutionUsers(institution, self.searchForm.email, self.searchForm.name)
              .then(function (response) {
                self.searchResults = response.data.data.profiles;
              })
              .catch(function () {
                NotificationService.error('User search failed, please try again');
              })
              .finally(function () {
                self.searching = false;
              });
          };

          self.canSubmit = function () {
            return !!self.searchForm.email || !!self.searchForm.name;
          };

        }
      ]
    );

} (window.angular));


