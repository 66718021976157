; (function (angular, _) {

  'use strict';

  angular.module('kudosLists')
    .factory('ListItemService', [
      '$http',
      'NotificationService',
      function ($http, NotificationService) {
        return {
          addListItemProperty: addListItemProperty
        };

        function addListItemProperty (listItem, newProperty, recommendation) {

          var attributes = {};

          if (angular.isUndefined(listItem.properties)) {
            listItem.properties = [];
          }

          attributes.properties = listItem.properties.concat({
            name: newProperty
          });

          return updateListItem(listItem.id, attributes, recommendation);
        }

        function updateListItem (id, attributes, recommendation) {
          var payload = attributes;

          if (angular.isDefined(recommendation)) {
            payload.triggering_recommendation = {id: recommendation.id}; // see internal_api/app.rb
          }

          return $http.put('/internal_api/list_items/' + id , payload)
            .catch(function (response) {
              _.each(response.data.errors, function (error) {
                NotificationService.error(error);
              });
            });
        }
      }
    ]
  );

}(window.angular, window._));


