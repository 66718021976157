(function(angular) {
  'use strict';

  angular.module("showcase").config(["$stateProvider", function($stateProvider) {
    $stateProvider
      .state('angular_component_showcase', {
        url: '/angular_component_showcase',
        abstract: true,
        templateUrl: "showcase/showcase.html",
        controller: "ShowcaseController"
      })
      .state('angular_component_showcase.universal', {
        url: '',
        templateUrl: "showcase/showcase.universal.html"
      })
      .state('angular_component_showcase.publication', {
        url: '/publication',
        templateUrl: "showcase/showcase.publication.html"
      })
      .state('angular_component_showcase.edit_in_place_options', {
        url: '/edit_in_place_options',
        templateUrl: "showcase/showcase.edit_in_place_options.html"
      })
      .state('angular_component_showcase.charts', {
        url: '/charts',
        templateUrl: "showcase/showcase.charts.html"
      })
      .state('angular_component_showcase.cards', {
        url: '/cards',
        templateUrl: 'showcase/showcase.cards.html',
        controller: 'ShowcaseCardController',
        controllerAs: 'vm',
        resolve: {
          publication: [
            'PublicationService',
            function (PublicationService) {
              return PublicationService.getPublication('10.1087/20120103')
                .then(function (response) {
                  return response.data.data.article;
                })
                .catch(function (response) {
                  return;
                });
            }
          ],
          organisation: [
            function () {
              return {
                short_code: 'test'
              };
            }
          ],
        }
      });
  }]);
}(window.angular));
