; (function (angular) {
  'use strict';
  angular
    .module('kudosCards')
    .factory(
      'CardDeckService',
      [
        '$rootScope',
        function ($rootScope) {
          return {
            nextCard: nextCard
          };

          function nextCard () {
            $rootScope.$emit('cardDeck.next');
          }
        }
      ]
    );

}(window.angular));

