; (function (angular) {

  'use strict';

  angular.module('kudosHub').directive('kudosSearchByDoi', function () {
    return {
      templateUrl: 'kudosHub/kudosSearchByDoi/kudosSearchByDoi.html',
      scope: {},
      controllerAs: 'vm',
      bindToController: {},
      controller: [
        'SearchService',
        'NotificationService',
        'SessionService',
        function(SearchService, NotificationService, SessionService) {
          var vm = this;
          vm.$onInit = init;
          vm.submit = submit;
          vm.submitIsDisabled = submitIsDisabled;
          vm.userIsAuthor = userIsAuthor;

          function init() {
            vm.submitDisabled = false;
            vm.doi = null;
            vm.hasSearchResult = false;
            vm.doiNotFound = false;
            vm.pub = {};
          }

          function submit() {
            NotificationService.clear();
            vm.submitDisabled = true;
            vm.doiNotFound = false;
            vm.hasSearchResult = false;

            SearchService.byDoi(vm.doi)
              .then(onFindSuccess)
              .catch(onFindError)
              .finally(function() {
                vm.submitDisabled = false;
              });
          }

          function submitIsDisabled() {
            return vm.submitDisabled;
          }

          function onFindSuccess (response) {
            vm.hasSearchResult = true;
            vm.pub = response;
          }

          function onFindError (response) {
            if (response.status === 404) {
              vm.doiNotFound = true;
            } else {
              NotificationService.error(response.data.errors);
            }
          }

          function userIsAuthor() {
            return SessionService.userIsAuthorFor(vm.pub.doi);
          }
        }
      ],
    };
  });
}(window.angular));
