; (function (angular) {
  'use strict';
  angular
    .module('kudosInstitutions')
    .service(
      'InstitutionSuggestionService',
      [
        '$rootScope',
        '$timeout',
        'InstitutionService',
        InstitutionSuggestionService
      ]
    );

  function InstitutionSuggestionService ($rootScope, $timeout, InstitutionService) {
    var queryInstitutionTimeoutPromise;

    var self = this;

    self.state = {};

    // Resets the service state
    resetState();

    self.selectInstitution = selectInstitution;
    self.getSelectedInstitution = getSelectedInstitution;
    self.getSuggestedInstitutions = getSuggestedInstitutions;
    self.displaySuggestedInstitutions = displaySuggestedInstitutions;
    self.resetState = resetState;

    $rootScope.$watch(
      function () {
        return self.state.search;
      },
      function (newValue) {
        if (self.state.original === newValue) {
          return;
        }

        if (!newValue) {
          return self.resetState();
        }

        if (angular.isDefined(queryInstitutionTimeoutPromise)) {
          $timeout.cancel(queryInstitutionTimeoutPromise);
        }

        queryInstitutionTimeoutPromise = $timeout(queryInstitutions, 800);
      }
    );

    function queryInstitutions () {
      self.state.loading = true;

      InstitutionService
        .findInstitutionsByPartialName(self.state.search, 10, 0, 'academic')
        .then(handleInstitutionQueryResponse)
        .finally(handleInstitutionQueryFinally);
    }

    function handleInstitutionQueryResponse (institutions) {
      self.state.results = institutions;

      // If a new search is made, reset the institution selection
      self.state.selected = false;
    }

    function handleInstitutionQueryFinally () {
      self.state.loading = false;
    }

    function getSelectedInstitution () {
      return self.state.selected;
    }

    function selectInstitution (institution) {
      self.state.selected = institution;
      self.state.original = self.state.search = institution.displayName;
    }

    function getSuggestedInstitutions () {
      return self.state.results;
    }

    function displaySuggestedInstitutions () {
      if (self.getSelectedInstitution()) {
        return false;
      }
      return self.state.results;
    }

    function resetState () {
      self.state.search = '';
      self.state.results = false;
      self.state.loading = false;
      self.state.selected = false;
    }
  }

}(window.angular));
