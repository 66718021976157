; (function (angular) {

  'use strict';
  angular.module('kudos')
    .directive('kudosConfirmationBanner', function () {
      return {
        templateUrl: 'kudos/directives/kudosConfirmationBanner.html',
        controllerAs: 'banner',
        controller: ['SessionService', function (SessionService) {
          var self = this;

          self.currentUser = SessionService.currentUser;
          self.userIsLoggedIn = SessionService.userIsLoggedIn;
          self.userIsVerified = SessionService.userIsVerified;


          self.resendConfirmationEmail = SessionService.resendConfirmationEmail;

          self.showConfirmationBanner = function () {
            return self.userIsLoggedIn() && !self.userIsVerified();
          };
        }]
      };
    });

}(window.angular));
